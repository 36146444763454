import angular from 'angular';
import app from '../app';

app.service('LabelTemplatesService', [
  '$rootScope',
  'CompanyService',
  function ($rootScope, CompanyService) {
    const ctx = this;
    let labels = [];

    ctx.labelPrintSettings = {
      minFontSize: 6,
      maxFontSize: 24,
      fontSize: 12,
      fontStep: 1,
      hazardSize: 35,
      hazardStep: 4,
      qrcodeSize: 100,
      codeStep: 10
    };
    ctx.known = [
      {
        name: '3 1/3" x 4" (6 per page) - Avery 5164 / 5264',
        height: '3.3333333in',
        width: '4in',
        numRows: 3,
        numLabelsPerRow: 2,
        pageMargin: '0.5in 0.15in 0in 0.16in',
        pageSize: '8.5in 11in',
        additionalColumnPaddingLeft: '0.15625in',
        class: '',
        qrcodeDisabled: false,
        colorHatchPx: 42,
        whiteHatchPx: 20
      },
      {
        name: '8 1/8" x 5" (2 per page) - Avery 6573',
        height: '5in',
        width: '8.125in',
        numRows: 2,
        numLabelsPerRow: 1,
        pageMargin: '0.5in 0.18in 0.42in 0.19in',
        pageSize: '8.5in 11in',
        additionalColumnPaddingLeft: '0',
        class: 'large',
        qrcodeDisabled: false,
        colorHatchPx: 50,
        whiteHatchPx: 30
      },
      {
        name: '8 1/2" x 11" (1 per page) - Avery 5265',
        height: '9.95in',
        width: '7.65in',
        numRows: 1,
        numLabelsPerRow: 1,
        pageMargin: '0.5in 0.4in 0.5in 0.4in',
        pageSize: '8.5in 11in',
        additionalColumnPaddingLeft: '0',
        class: 'large',
        qrcodeDisabled: false,
        njPrintLabelDisabled: true,
        colorHatchPx: 29,
        whiteHatchPx: 17
      },
      {
        name: '4" x 6" (1 per page) - Avery 5292',
        height: '6in',
        width: '4in',
        numRows: 1,
        numLabelsPerRow: 1,
        pageMargin: '0in 0in 0in 0in',
        pageSize: '4in 6in',
        additionalColumnPaddingLeft: '0',
        class: '',
        qrcodeDisabled: false,
        njPrintLabelDisabled: true,
        colorHatchPx: 32,
        whiteHatchPx: 17
      },
      {
        name: '2" x 4" (10 per page) - Avery 5163 / 8163',
        height: '2in',
        width: '4in',
        numRows: 5,
        numLabelsPerRow: 2,
        pageMargin: '0.5in 0.15in 0.42in 0.16in',
        pageSize: '8.5in 11in',
        additionalColumnPaddingLeft: '0.15625in',
        class: 'small',
        qrcodeDisabled: false,
        colorHatchPx: 33,
        whiteHatchPx: 28
      },
      {
        name: '1" x 2 5/8" (30 per page) - Avery 5160',
        template: '5160',
        height: '1in',
        width: '2.625in',
        numRows: 10,
        numLabelsPerRow: 3,
        pageMargin: '0.5in 0.15in 0.42in 0.16in',
        pageSize: '8.5in 11in',
        additionalColumnPaddingLeft: '0.15625in',
        class: 'x-small',
        qrcodeDisabled: true,
        njContainerDisabled: true,
        colorHatchPx: 29,
        whiteHatchPx: 12
      },
      {
        name: '2" x 2" (12 per page) - Avery 60506',
        height: '2in',
        width: '2in',
        numRows: 4,
        numLabelsPerRow: 3,
        pageMargin: '0.6in 0.3in 0.3in 0.55in',
        pageSize: '8.5in 11in',
        additionalColumnPaddingLeft: '0.7in',
        class: 'x-small x-small-long',
        qrcodeDisabled: false,
        colorHatchPx: 33,
        whiteHatchPx: 28,
        additionalPaddingBottom: '0.65in'
      },
      {
        name: '3 1/2" x 5" (4 per page) - Avery 60503',
        height: '5in',
        width: '3.5in',
        numRows: 2,
        numLabelsPerRow: 2,
        pageMargin: '0.5in 0.5in 0.42in 0.5in',
        pageSize: '8.5in 11in',
        additionalColumnPaddingLeft: '0.5in',
        class: 'small',
        qrcodeDisabled: false,
        colorHatchPx: 33,
        whiteHatchPx: 28
      },
      {
        name: '1" x 2 1/2" (24 per page) - Avery 60517',
        template: '5160',
        height: '1in',
        width: '2.5in',
        numRows: 8,
        numLabelsPerRow: 3,
        pageMargin: '0.62in 0.31in 0.42in 0.31in',
        pageSize: '8.5in 11in',
        additionalColumnPaddingLeft: '0.18in',
        class: 'x-small',
        qrcodeDisabled: true,
        njContainerDisabled: true,
        colorHatchPx: 29,
        whiteHatchPx: 12,
        additionalPaddingBottom: '0.26in'
      },
      {
        name: '2" x 4" (10 per page) - Avery 60505',
        height: '2in',
        width: '4in',
        numRows: 5,
        numLabelsPerRow: 2,
        pageMargin: '0.3in 0.15in 0.3in 0.1in',
        pageSize: '8.5in 11in',
        additionalColumnPaddingLeft: '0.21625in',
        class: 'small',
        qrcodeDisabled: false,
        colorHatchPx: 33,
        whiteHatchPx: 28,
        additionalPaddingBottom: '0.1in'
      },
      {
        name: '6" x 3" (1 per page) - DuraLabel Pro 300',
        height: '3in',
        width: '6in',
        numRows: 1,
        numLabelsPerRow: 1,
        pageMargin: '0in 0in 0in 0in',
        pageSize: '3in 6in',
        additionalColumnPaddingLeft: '0',
        class: 'rotated hazards-right',
        qrcodeDisabled: false,
        njPrintLabelDisabled: true,
        colorHatchPx: 32,
        whiteHatchPx: 17,
        printType: 'continuous'
      },
      {
        name: '6" x 4" (1 per page) - Brady BBP31',
        height: '4in',
        width: '5.99in',
        numRows: 1,
        numLabelsPerRow: 1,
        pageMargin: '0in 0in 0in 0in',
        pageSize: '4in 6in',
        additionalColumnPaddingLeft: '0',
        class: 'rotated hazards-right',
        qrcodeDisabled: false,
        njPrintLabelDisabled: true,
        colorHatchPx: 32,
        whiteHatchPx: 17,
        printType: 'continuous'
      },
      {
        name: '6" x 2.4" (1 per page) - Brother QL-800',
        height: '2.4in',
        width: '5.97in',
        numRows: 1,
        numLabelsPerRow: 1,
        pageMargin: '0in 0in 0in 0in',
        pageSize: '2.4in 6in',
        additionalColumnPaddingLeft: '0',
        class: 'rotated hazards-right x-narrow-long',
        qrcodeDisabled: false,
        njPrintLabelDisabled: true,
        colorHatchPx: 32,
        whiteHatchPx: 17,
        printType: 'continuous'
      }
    ];

    ctx.calculateMeasuranceInPixel = function (el) {
      // 0.01041666666667 = ration between inch & pixel
      el.widthPx = parseFloat(el.width) / 0.01041666666667;
      el.heightPx = parseFloat(el.height) / 0.01041666666667;
      const sin45 = Math.sin((45 * Math.PI) / 180);
      const colorHatch = el.colorHatchPx * sin45;
      const whiteHatch = el.whiteHatchPx * sin45;
      const hatchCount = (el.widthPx * sin45 + el.heightPx * sin45) / (colorHatch + whiteHatch);
      const hatchCountInt = Math.round(hatchCount);
      // last ...+ (colorHatch) is necessary because the hatch at the end must ended by color,
      // not by white color
      const sideLength = (colorHatch + whiteHatch) * hatchCountInt + colorHatch;
      const coefficient = (el.widthPx * sin45 + el.heightPx * sin45) / sideLength;

      el.colorHatch = colorHatch * coefficient;
      el.whiteHatch = whiteHatch * coefficient;
      return el;
    };

    ctx.getKnownNames = function () {
      if (ctx.knownNames) {
        return ctx.knownNames;
      }
      ctx.knownNames = ctx.known.map(function (itm) {
        return itm.name;
      });
      return ctx.knownNames;
    };

    ctx.pick = function (names) {
      if (names == null) {
        return ctx.known;
      } else if (!names.length) {
        // at least something when there are no templates at all
        return [ctx.known[2]];
      }
      return ctx.known.filter(function (itm) {
        return ~names.indexOf(itm.name);
      });
    };

    ctx.defaultLabelStyle = {
      safeHandling: {
        textAlign: 'center'
      },
      additionalInformation: {
        textAlign: 'center'
      },
      subtitle: {
        fontWeight: '700',
        textDecoration: 'none'
      }
    };

    //adds <b> tags to the beginning text of paragraphs
    //and to any text that appears to need it.
    ctx.addEmphasis = function addEmphasis(content, emphases) {
      var newContent = '';
      var isNewParagraph = true;
      var lines = content.split(/\n/);

      lines.forEach(function (line) {
        var matchIndex = getEmphasisMatchIndex(line, emphases);

        if (matchIndex >= 0) {
          //bold all the things in the ephases list
          newContent += line.replace(emphases[matchIndex].replace, function (matchStr) {
            return '<b>' + matchStr + '</b>';
          });
          newContent += '<br>\n';
          isNewParagraph = false;
        } else if (isNewParagraph) {
          if (/\./.test(line)) {
            //bold the first sentence up the the period
            newContent += line.replace(/.*?\./, function (matchStr) {
              return '<b>' + matchStr + '</b>';
            });
            newContent += '<br>\n';
          } else {
            //no period so just bold the first sentence
            newContent += '<b>' + line + '</b><br>\n';
          }
          isNewParagraph = false;
        } else {
          //add new content as normal and look for blank lines that mean a new paragraph should be coming
          newContent += line + '<br>\n';
          if (line == '') {
            isNewParagraph = true;
          }
        }
      });

      return newContent;
    };

    ctx.getLabelSettings = function getLabelSettings(companyId) {
      return new CompanyService.CompanySettings(companyId).$promise.then(function (data) {
        if ($rootScope.checkIfUserIs('admin')) {
          data.borderStyles = [
            'Crosshatched 1',
            'Crosshatched 2',
            'dashed',
            'dotted',
            'solid',
            'double',
            'groove',
            'ridge',
            'inset',
            'outset',
            'hidden'
          ];
        }
        return data;
      });
    };

    ctx.registerNewLabel = function (scope, el) {
      labels = labels.concat({
        labelScope: scope,
        element: el
      });
    };

    ctx.flushPreviousLabels = function () {
      labels = [];
    };

    ctx.adjustLabelsSize = function (vm) {
      vm.borderWidth = 10;
      labels.forEach(function (label) {
        const hazardImgs = Array.prototype.slice.call(angular.element('img', label.element));
        const qrCode = angular.element('canvas', label.element)[0];

        reset(label.labelScope, label.element, hazardImgs);

        decrease(label.labelScope, label.element, hazardImgs, qrCode, vm);
        increase(label.labelScope, label.element, hazardImgs, qrCode);
        // decrease again in case increase got overboard
        decrease(label.labelScope, label.element, hazardImgs, qrCode, vm);
      });

      // because border width we applied a minimal value among all labels, so:
      // -in the first attempt eval the size with minimal border width
      // -in the second attempt we must eval the accurate label size.
      labels.forEach(function (label) {
        const hazardImgs = Array.prototype.slice.call(angular.element('img', label.element));
        const qrCode = angular.element('canvas', label.element)[0];

        increase(label.labelScope, label.element, hazardImgs, qrCode);
        // decrease again in case increase got overboard
        decrease(label.labelScope, label.element, hazardImgs, qrCode, vm);
      });
    };

    ctx.upDownControl = function (target, amount) {
      if (!['fontSize'].includes(target)) return;

      labels.forEach(function (label) {
        const card = label.element;
        card.style[target] = parseInt(card.style[target]) + amount + 'px';
      });
    };

    ctx.getLabelsStateData = function () {
      return labels.map(function (label) {
        return {
          fontSize: label.element.style.fontSize
        };
      });
    }

    function reset(scope, el, hazardImgs, vm) {
      el.style.fontSize = ctx.labelPrintSettings.fontSize + 'px';

      hazardImgs.forEach(function (hazardImg) {
        hazardImg.style.width = ctx.labelPrintSettings.hazardSize + 'px';
        hazardImg.style.height = ctx.labelPrintSettings.hazardSize + 'px';
      });

      scope.qrcodeSize = ctx.labelPrintSettings.qrcodeSize;
    }

    function decrease(scope, card, hazardImgs, qrCode, vm) {
      const hazardImg = hazardImgs[0];
      let fontSize = window.getComputedStyle(card).fontSize;
      let hazardSize = hazardImg && window.getComputedStyle(hazardImg).width;
      scope.$apply();

      if (isContentBigger(card)) {
        while (isContentBigger(card) && parseInt(fontSize) > ctx.labelPrintSettings.minFontSize) {
          card.style.fontSize = fontSize =
            parseInt(fontSize) - ctx.labelPrintSettings.fontStep + 'px';

          if (!isContentBigger(card)) break;

          if (hazardImg) {
            const size = (hazardSize =
              parseInt(hazardSize) - ctx.labelPrintSettings.hazardStep + 'px');

            hazardImgs.forEach(function (hazardImg) {
              hazardImg.style.width = size;
              hazardImg.style.height = size;
            });
          }

          if (!isContentBigger(card)) break;

          if (qrCode) {
            scope.qrcodeSize -= ctx.labelPrintSettings.codeStep;
            scope.$apply();
          }
        }

        // in cases when the size hase a maximum decrease, I try to decrease the border
        if (isContentBigger(card)) {
          const minBorderWidth = 3;
          const step = 1;
          while (isContentBigger(card) && vm.borderWidth > minBorderWidth) {
            vm.borderWidth -= step;
            scope.$apply();
          }
        }

        // in cases when the size hase a maximum decrease with border, I try to decrease the hazard
        if (isContentBigger(card)) {
          const minHazardSize = 11;
          const step = 1;
          let size = (hazardSize = parseInt(hazardSize) - ctx.labelPrintSettings.hazardStep + 'px');
          while (isContentBigger(card) && size > minHazardSize) {
            hazardImgs.forEach(function (hazardImg) {
              hazardImg.style.width = size;
              hazardImg.style.height = size;
              size -= step;
            });
          }
        }
      }
    }

    function increase(scope, card, hazardImgs, qrCode) {
      const hazardImg = hazardImgs[0];
      let fontSize = window.getComputedStyle(card).fontSize;
      let hazardSize = hazardImg && window.getComputedStyle(hazardImg).width;

      if (isContentSmaller(card)) {
        while (isContentSmaller(card) && parseInt(fontSize) <= ctx.labelPrintSettings.maxFontSize) {
          card.style.fontSize = fontSize =
            parseInt(fontSize) + ctx.labelPrintSettings.fontStep + 'px';

          if (!isContentSmaller(card)) return;

          if (hazardImg) {
            const size = (hazardSize =
              parseInt(hazardSize) + ctx.labelPrintSettings.hazardStep + 'px');

            hazardImgs.forEach(function (hazardImg) {
              hazardImg.style.width = size;
              hazardImg.style.height = size;
            });
          }

          if (!isContentSmaller(card)) return;

          if (qrCode) {
            scope.qrcodeSize += ctx.labelPrintSettings.codeStep;
            scope.$apply();
          }
        }
      }
    }

    ctx.isContentSmaller = isContentSmaller;
    ctx.isContentBigger = isContentBigger;

    function isContentSmaller(card) {
      return getCardContentHeight(card) < card.clientHeight;
    }

    function isContentBigger(card) {
      return getCardContentHeight(card) > card.clientHeight;
    }

    function getCardContentHeight(card) {
      const children = card.children;
      const childrenHeight = [];
      const getChildHeight = function (elem) {
        childrenHeight.push(elem.offsetTop + elem.offsetHeight);
        elem.children.forEach(child => {
          getChildHeight(child);
        });
      };
      children.forEach(child => {
        getChildHeight(child);
      });
      return Math.max(...childrenHeight);
    }

    //returns this index of emphasis that was matched
    function getEmphasisMatchIndex(line, emphases) {
      for (var i = 0; i < emphases.length; ++i) {
        if (emphases[i].match.test(line)) return i;
      }
      return -1;
    }
  }
]);
