const helpTranslations = {
  firstResponderSearch: {
    title: 'HELP_TRANSLATIONS.FIRST_RESPONDER_SEARCH.TITLE',
    content: 'HELP_TRANSLATIONS.FIRST_RESPONDER_SEARCH.CONTENT'
  }
};

export default function help(type) {
  if (!helpTranslations[type]) return;

  return {
    templateUrl: require('./help.html'),
    resolve: {
      title: [
        '$translate',
        function ($translate) {
          return $translate(helpTranslations[type].title);
        }
      ],
      content: [
        '$translate',
        function ($translate) {
          return $translate(helpTranslations[type].content);
        }
      ]
    },
    controller: [
      '$uibModalInstance',
      'title',
      'content',
      function ($uibModalInstance, title, content) {
        const ctrl = this;
        ctrl.title = title;
        ctrl.content = content;
        ctrl.confirm = $uibModalInstance.close;
      }
    ],
    controllerAs: 'ctrl'
  };
}
