import app from '../../app';
import scheduleReport from '../../modals/scheduleReport';
import { getOffset } from '../../helpers';
import selectInventory from '../../modals/selectInventory';

app.controller('wclReportCtrl', [
  '$rootScope',
  '$scope',
  'Inventory',
  'CompanyService',
  'ModalService',
  'SchedulerService',
  'SpinnerService',
  'ExportService',
  function (
    $rootScope,
    $scope,
    Inventory,
    CompanyService,
    ModalService,
    SchedulerService,
    SpinnerService,
    ExportService
  ) {
    const vm = this;
    vm.startFrom = 0;
    vm.PER_PAGE = 10;
    vm.totalItems = 0;
    vm.reportData = [];
    vm.selectedCompany = {};

    const getReportData = SpinnerService.wrap(async function (companyId, offset) {
      const response = await Inventory.getWCLreport({
        companyId,
        offset,
        limit: vm.PER_PAGE,
        lang: $rootScope.siteLanguage
      }).$promise;

      vm.totalItems = response.totalItems;
      vm.reportData = response.data;

      $scope.$apply();

      return response;
    });

    vm.onCompanySelected = function (selectedCompany) {
      vm.selectedCompany = selectedCompany;
      vm.startFrom = 0;

      getReportData(selectedCompany.id, vm.startFrom);
    };

    vm.handlePaginate = function () {
      getReportData(vm.selectedCompany.id, getOffset(vm.startFrom, vm.PER_PAGE));
    };

    vm.handleXlsxExport = function () {
      exportReport('exportWCL2Excel');
    };

    vm.handlePdfExport = function () {
      exportReport('exportWCL2Pdf');
    };

    vm.openScheduleModal = function () {
      return ModalService.open(scheduleReport()).then(res =>
        createScheduledReport(res, $rootScope.companyId)
      );
    };

    init();

    function init() {
      $scope.$watch('siteLanguage', function (newValue, oldValue) {
        if (newValue === oldValue) return;
        getReportData(vm.selectedCompany.id, vm.startFrom);
      });

      if (!$rootScope.checkIfUserIs('admin')) {
        CompanyService.getCurrentCompanyPromise().$promise.then(function () {
          if ($rootScope.currentCompany.childSdsCompanies.length) return; // handle this by companySelector

          vm.onCompanySelected({ id: $rootScope.companyId });
        });
      }
    }

    function createScheduledReport(formObj, companyId, params = {}) {
      params.lang = $rootScope.siteLanguage;
      return SchedulerService.createReport(
        SchedulerService.jobs.report.WCL_REPORT,
        companyId,
        formObj.emails,
        formObj.frequency,
        params
      );
    }

    async function exportReport(name) {
      try {
        vm.selectedInventoryResult = await ModalService.open(
          selectInventory(vm.selectedCompany.id, {
            selectAllOnInit: true
          })
        );

        const inventoryIds = vm.selectedInventoryResult.map(inv => inv.id);

        return await ExportService[name](
          vm.selectedCompany.id,
          $rootScope.siteLanguage,
          inventoryIds
        );
      } catch (err) {
        if (err === 'backdrop click') return;

        console.log(err);
      }
    }
  }
]);
