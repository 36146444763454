import angular from 'angular';
import { uniqBy, get } from 'lodash';
import app from '../app';

app.service('AttachmentService', [
  '$q',
  '$rootScope',
  '$window',
  '$http',
  'Attachment',
  'SolrDocument',
  'Company',
  'ModalService',
  'ErrorService',
  attachmentService
]);

function attachmentService(
  $q,
  $rootScope,
  $window,
  $http,
  Attachment,
  SolrDocument,
  Company,
  ModalService,
  ErrorService
) {
  const simpleCatch = ErrorService.simpleCatch;

  this.defineTitle = function (attachment) {
    attachment.companyTitle = attachment.title;
    for (let index = 0; index < attachment.companies.length; index++) {
      const element = attachment.companies[index];
      if (element.id === $rootScope.companyId) {
        attachment.companyTitle = element.title;
        break;
      }
    }
  };

  function companySelectEvent(newCollection, oldCollection) {
    const whatChanged = newCollection.length - oldCollection.length;
    let firstCollection, secondCollection;
    const res = { action: undefined, diff: [] };
    if (whatChanged < 0) {
      // delete from array
      firstCollection = oldCollection;
      secondCollection = newCollection;
      res.action = 'delete';
    } else if (whatChanged > 0) {
      // insert into array
      firstCollection = newCollection;
      secondCollection = oldCollection;
      res.action = 'insert';
    } else return res;
    res.diff = firstCollection.filter(function (el) {
      for (let index = 0; index < secondCollection.length; index++) {
        const element = secondCollection[index];
        if (element.id === el.id) return false;
      }
      return true;
    });

    return res;
  }

  function objectUpdateCompany(company) {
    return {
      id: company.id,
      destroy: company.destroy
    };
  }

  this.onCompanySelect = function (attachment, newCollection, oldCollection) {
    const event = companySelectEvent(newCollection, oldCollection);
    if (event.action === 'insert' && event.diff.length > 0) {
      const element = event.diff[0];
      const companies = [objectUpdateCompany(event.diff[0])];

      if (element.id) {
        // insert a new company, the global company should be unselect
        attachment.companies = newCollection.filter(function (el_new) {
          if (!el_new.id) {
            const elForUpdate = angular.copy(el_new);
            elForUpdate.destroy = true;
            companies.push(objectUpdateCompany(elForUpdate));
            return false;
          }
          return true;
        });
      } else {
        // insert the global company, a new companies should be unselect
        attachment.companies = newCollection.filter(function (el_new) {
          if (el_new.id) {
            const elForUpdate = angular.copy(el_new);
            elForUpdate.destroy = true;
            companies.push(objectUpdateCompany(elForUpdate));
            return false;
          }
          return true;
        });
      }

      return Attachment.makeMarksForCompanies({
        id: attachment.id,
        companies: companies
      }).$promise.then(function (res) {
        return res;
      });
    }
    if (event.action === 'delete' && event.diff.length > 0) {
      if (newCollection.length === 0) {
        // All companies can't be unselect
        attachment.companies = oldCollection;
        return $q.resolve(false);
      }
      const element = event.diff[0];
      const elForUpdate = angular.copy(element);
      elForUpdate.destroy = true;
      const companies = [objectUpdateCompany(elForUpdate)];
      return Attachment.makeMarksForCompanies({
        id: attachment.id,
        companies: companies
      }).$promise.then(function (res) {
        return res;
      });
    } else return $q.resolve(true);
  };

  this.showAttach = function (attachment) {
    if (attachment.hasOwnProperty('externalLink') && attachment.hasOwnProperty('link')) {
      if (attachment.externalLink) {
        $window.open(attachment.link, '_blank');
      } else {
        let config = {
          responseType: 'blob'
        };
        $http.get('/api/Attachments/download/' + attachment.id, config).then(function (response) {
          let blob = response.data;
          let fContent = URL.createObjectURL(blob);
          $window.open(fContent, '_blank');
        });
      }
    }
  };

  this.destroyById = function (attachment) {
    Attachment.destroyById({ id: attachment.id });
  };

  this.isGlobal = function (attachment, alwaysCheck) {
    let res = false;

    if (alwaysCheck || !$rootScope.checkIfUserIs('admin')) {
      for (let index = 0; index < attachment.companies.length; index++) {
        const element = attachment.companies[index];
        if (element.id === null) {
          res = true;
          break;
        }
      }
    }

    return res;
  };

  this.filterAttachments = function (attachments) {
    attachments = attachments.filter(function (el) {
      if (!el.companies || el.companies.length === 0) return false;
      else {
        let ret = false;
        for (let index = 0; index < el.companies.length; index++) {
          const element = el.companies[index];
          if (
            (element.id === $rootScope.companyId && $rootScope.IPallowed) ||
            element.id === get($rootScope, 'currentUser.companyId') ||
            $rootScope.checkIfUserIs(['admin'])
          ) {
            ret = true;
          } else if (element.id === null) ret = true;
          if (ret) break;
        }

        return ret;
      }
    });

    return attachments;
  };

  this.addAttachmentModal = function (id, parentScope) {
    let scope = {};
    if (!parentScope) scope = $rootScope.$new();
    else scope = parentScope.$new();

    scope.sourceId = id;
    scope.id = '';

    const searchId = { idList: 'id:' + id };
    return ModalService.open({
      keyboard: false,
      backdrop: true,
      controller: 'editAttachmentCtrl',
      templateUrl: require('../controllers/editAttachmentCtrl/editAttachment.html'),

      resolve: {
        companies: [
          function () {
            return SolrDocument.findByIdList(searchId).$promise.then(function (results) {
              const document = results.response.docs[0];
              return Company.find({
                filter: {
                  where: {
                    id: {
                      inq: document.companyId
                    }
                  },
                  include: 'childSdsCompanies'
                }
              })
                .$promise.then(function (resp) {
                  const childSdsCompanies = resp.reduce(
                    (sum, parentCompany) => sum.concat(parentCompany.childSdsCompanies),
                    []
                  );

                  return uniqBy(resp.concat(childSdsCompanies), 'id');
                })
                .then(function (resp) {
                  return [{ name: 'Global', id: null }].concat(resp);
                })
                .catch(simpleCatch);
            });
          }
        ]
      },
      scope: scope
    });
  };
}
