import app from '../app';

app.factory('ContainerService', function (Container, FileUploader, ErrorService) {
  const simpleCatch = ErrorService.simpleCatch;

  class ContainerService {
    constructor(container) {
      this.container = container;
    }

    getUploader(formData = [], tokenId, uniqName = false) {
      Container.getContainers()
        .$promise.then(existedContainers => {
          const containerExists = existedContainers.some(
            existedContainer => existedContainer.name === this.container
          );

          if (containerExists) return;

          return Container.createContainer({}, { name: this.container }).$promise;
        })
        .catch(simpleCatch);

      return new FileUploader({
        url: `/api/containers/${this.container}/uploadFile?access_token=${tokenId}&uniqName=${uniqName}`,
        formData
      });
    }

    removeFile(filename) {
      return Container.removeFile({ container: this.container, file: filename }).$promise.catch(
        simpleCatch
      );
    }

    static getFileLink(container, filename) {
      return `/api/containers/${container}/download/${filename}`;
    }
  }

  return ContainerService;
});
