import app from '../../app';

app.directive('searchTags', function () {
  return {
    restrict: 'E',
    templateUrl: require('./searchTags.html'),
    scope: {
      tags: '=',
      select: '&onSelect'
    },
    controllerAs: 'vm',
    controller: function ($rootScope, $scope, UserService, MessagesService) {

      const vm = this;
      vm.searchTag = '';
      vm.tagsPivot = $scope.tags.reduce(function (sum, tag) {
        sum[tag.id] = tag;
        return sum;
      }, {});

      vm.tagsSearch = $scope.tags.map(tag => ({
        id: tag.id,
        title: tag.title,
        parentTagTitle: tag.tagId && tag.tagId !== '' ? vm.tagsPivot[tag.tagId].title : null
      }));

      vm.selectTag = function (tag) {
        vm.searchTag = tag.title;
        $scope.select({ tag });
      };
    }
  };
});
