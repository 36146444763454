import app from '../../app';

app.directive('ppesSelector', [
  'PpeService',
  function (PpeService) {
    return {
      restrict: 'E',
      scope: {
        ppeFacets: '=',
        startCollapsed: '=',
        onChange: '&'
      },
      templateUrl: require('./ppesSelector.html'),
      link: function (scope) {
        scope.PpeService = PpeService;
        scope.findPpe = value => ppe => ppe.value === value;
      }
    };
  }
]);
