import app from '../../app';
import selectTags from '../../modals/selectTags';
import selectInventory from '../../modals/selectInventory';
import { isLink } from '../../helpers';
import { get } from 'lodash';

app.controller('requestModalCtrl', [
  '$uibModalInstance',
  '$rootScope',
  'request',
  'SdsRequestService',
  'ModalService',
  'behaviourOptions',
  requestModalCtrl
]);

function requestModalCtrl(
  $uibModalInstance,
  $rootScope,
  request,
  SdsRequestService,
  ModalService,
  behaviourOptions
) {
  const modal = this;

  modal.isLink = isLink;
  modal.request = request;
  modal.cancel = $uibModalInstance.dismiss;
  modal.service = get(behaviourOptions, 'service', null) || SdsRequestService;

  modal.getLink = function (filename) {
    return modal.service.getDownloadLink(filename);
  };

  modal.isStatus = modal.service.statusChecker(request);

  if (!modal.isStatus(['open'])) {
    // if already approved, denied or completed
    modal.deny = modal.approve = null;
  } else {
    modal.deny = function () {
      return ModalService.open({
        keyboard: true,
        backdrop: true,
        controller: 'denyRequestModalCtrl',
        templateUrl: require('../denyRequestModalCtrl/deny-sds-request.html'),
        resolve: {
          title: [
            '$translate',
            function ($translate) {
              return $translate('COMMON.LABELS.NOTE');
            }
          ]
        }
      })
        .then(function (note) {
          return modal.service.deny(request.id, request.companyId, note).then(function (res) {
            request.note = note;

            return res;
          });
        })
        .then(function (res) {
          $uibModalInstance.close(res.statusCode);
        });
    };

    modal.approve = async function () {
      if (get(behaviourOptions, 'disableTagsSelection', null)) {
        return modal.service.approve(request.id, request.companyId).then(function (res) {
          $uibModalInstance.close(res.statusCode);
        });
      } else {
        const tagIds = $rootScope.companySettings.hideTagsWorkflowEnabled
          ? request.tagIds
          : await ModalService.open(
              selectTags(request.companyId, {
                message: 'TAGS.SELECT_TAGS_2',
                messageStyles: {
                  fontSize: '18px',
                  fontWeight: 'bold'
                },
                allowSearchByParentTags: false,
                disabledTags: $rootScope.currentUser.restrictedTags,
                checkedTags:
                  request.tagIds && request.tagIds.length
                    ? request.tagIds.map(id => ({ id, checked: true }))
                    : []
              })
            ).then(result => result.tags.map(tag => tag.id));

        const inventoryIds = !$rootScope.companySettings.inventoryInSearchEnabled
          ? request.inventoryIds
          : (
              await ModalService.open(
                selectInventory(request.companyId, {
                  checkedInventory: request.inventoryIds.map(id => ({ id }))
                })
              )
            ).map(result => result.id);

        return modal.service
          .approve(request.id, request.companyId, tagIds, inventoryIds)
          .then(function (res) {
            $uibModalInstance.close(res.statusCode);
          })
          .then(function () {
            return modal.service.getRecords();
          });
      }
    };
  }

  if ($rootScope.checkIfUserIs('admin') && modal.isStatus(['approved'])) {
    // if already approved
    modal.complete = function () {
      return modal.service.complete(request.id, request.companyId).then(function (res) {
        $uibModalInstance.close(res.statusCode);
      });
    };
  }
}
