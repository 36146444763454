import app from '../../app';
import ppeSelectionModal from '../../modals/ppeSelectionModal';

app.directive('viewDocument', [
  '$rootScope',
  'SdsDocumentSolrMap',
  'StorageCodes',
  'DisposalCodes',
  'Attachment',
  'AttachmentService',
  'ModalService',
  'CompanyService',
  'SolrDocumentService',
  'ExportService',
  'SpinnerService',
  'PpeService',
  'SolrDocument',
  function (
    $rootScope,
    SdsDocumentSolrMap,
    StorageCodes,
    DisposalCodes,
    Attachment,
    AttachmentService,
    ModalService,
    CompanyService,
    SolrDocumentService,
    ExportService,
    SpinnerService,
    PpeService,
    SolrDocument
  ) {
    return {
      restrict: 'E',
      scope: {
        document: '=',
        parentCtrl: '=',
        sdsTextSections: '=',
        hazardTypes: '=',
        showBackButton: '=',
        preview: '=',
        companies: '='
      },
      templateUrl: require('./viewDocument.html'),
      link: function (scope) {
        scope.PpeService = PpeService;
        scope.accessAddAttachments = $rootScope.accessAddAttachments();
        scope.nfpaShow = true;
        getSdsDocumentData(scope.document.id);

        const handleDocPpeInfo = function (doc) {
          scope.sdsIdForPpe = doc.id;
          scope.ppe_private_companyIds = doc.ppe_private_companyIds || [];
          scope.publicPpes = doc.ppe && doc.ppe.length ? doc.ppe : [];
          const getSelectedPPE = function () {
            if (
              $rootScope.checkIfUserIs('admin') &&
              (!($rootScope.companySettings && $rootScope.companySettings.enablePPE) ||
                !(scope.document.companyId && scope.document.companyId.includes($rootScope.companyId))) &&
              doc.ppe &&
              doc.ppe.length
            ) {
              return doc.ppe;
            }
            if (
              $rootScope.companySettings &&
              $rootScope.companySettings.enablePPE &&
              doc[`ppe_${$rootScope.companyId}`] &&
              doc[`ppe_${$rootScope.companyId}`].length
            ) {
              return doc[`ppe_${$rootScope.companyId}`];
            }
            if (
              $rootScope.companySettings &&
              $rootScope.companySettings.enablePPE &&
              doc.companyId &&
              !doc.companyId.includes($rootScope.companyId) &&
              doc.ppe &&
              doc.ppe.length
            ) {
              return doc.ppe;
            }
            return [];
          };
          scope.ppeSelected = getSelectedPPE();
        };
        const getPpeInfo = function (mode) {
          scope.ppeSelected = null;
          let idList;
          if (mode === 'general') {
            idList = 'id:' + scope.document.id;
          } else if (
            !scope.document.language.includes($rootScope.siteLanguage) &&
            scope.document[`${$rootScope.siteLanguage}VersionId`]
          ) {
            idList = 'id:' + scope.document[`${$rootScope.siteLanguage}VersionId`];
          } else {
            idList = 'id:' + scope.document.id;
          }
          const options = { idList };
          SolrDocument.findByIdList(options).$promise.then(function (results) {
            const doc = results.response.docs[0];
            handleDocPpeInfo(doc);
          });
        };
        getPpeInfo('general');

        CompanyService.getCurrentCompanyPromise().$promise.then(function () {
          Attachment.find({ filter: { where: { sourceId: [scope.document.id] } } }).$promise.then(
            function (res) {
              scope.attachments = AttachmentService.filterAttachments(res);
            }
          );
        });

        scope.printableAttrStrings = [];
        scope.selectedDisposalCode = {};
        scope.selectedStorageCode = {};
        scope.attachments = [];
        scope.attachmentOpened = true;
        scope.languages = $rootScope.languages;
        scope.numberOfLangVersions = getNumberOfLangVersions(scope.document);

        scope.$on('attachmentsChange', function (event) {
          scope.attachments = AttachmentService.filterAttachments(scope.attachments);
        });

        scope.$watch('document', function (newValue, oldValue) {
          SpinnerService.wrap(async function () {
            return await getSdsDocumentData(scope.document.id);
          })();
        });

        $rootScope.$watch('siteLanguage', function (newValue, oldValue) {
          if (!scope.document[`${newValue}VersionId`]) {
            localize();
          }
          if (newValue === oldValue) return;
          getPpeInfo();
        });

        scope.isTabActive = function () {
          return scope.document.id === scope.parentCtrl.currentDocumentId;
        };

        scope.goBack = function () {
          window.history.back();
        };

        scope.setModalContent = function (hazard) {
          $('#hazardModalTitle').text(scope.hazardTypes[hazard].title);
          $('#hazardModalBody').html(scope.hazardTypes[hazard].description);
        };

        scope.setPpeModalContent = function (ppe) {
          $('#ppeModalTitle').text(scope.PpeService.getTitle(ppe));
          $('#ppeModalBody').html(scope.PpeService.getDescription(ppe));
        };

        scope.showAsPdf = function () {
          const pdfOnly = $rootScope.checkIfUserIs('admin')
            ? false
            : $rootScope.companySettings.pdfOnly;
          return (
            !scope.preview &&
            (pdfOnly ||
              scope.document.no_sds ||
              scope.document.msds_format ||
              !scope.document.isVerified ||
              scope.document.kit_cover)
          );
        };

        scope.accessAddAttachments = $rootScope.accessAddAttachments();

        scope.addAttachment = function (event) {
          event.stopPropagation();
          AttachmentService.addAttachmentModal(scope.document.id, scope).then(function (res) {
            for (let i in res) {
              const item = res[i];

              AttachmentService.defineTitle(item);
              scope.attachments.push(item);
            }
            scope.attachments = AttachmentService.filterAttachments(scope.attachments);
          });
        };

        scope.editPPE = function (event) {
          event.stopPropagation();

          ModalService.open(
            ppeSelectionModal({
              companyId: $rootScope.companyId,
              sdsId: scope.sdsIdForPpe,
              ppeSelected: [...scope.ppeSelected],
              ppe_private_companyIds: [...scope.ppe_private_companyIds],
              publicPpes: [...scope.publicPpes]
            })
          ).then(({ ppeSelected, ppe_private_companyIds }) => {
            scope.ppeSelected = ppeSelected;
            scope.ppe_private_companyIds = ppe_private_companyIds;
          });
        };

        scope.toggleAttachment = function (glyphId) {
          var documentSection = '#collapseAttachment';
          glyphId = '#glyphAttachment';

          if ($(glyphId).hasClass('glyphicon-chevron-right')) {
            $(glyphId).removeClass('glyphicon-chevron-right');
            $(glyphId).addClass('glyphicon-chevron-down');
            $(documentSection).removeClass('no-print');
          } else {
            $(glyphId).removeClass('glyphicon-chevron-down');
            $(glyphId).addClass('glyphicon-chevron-right');
            $(documentSection).addClass('no-print');
          }
        };

        scope.toggleCollapseGlyph = function (glyphId) {
          var documentSection = '#documentSection' + glyphId;
          glyphId = '#glyph' + glyphId;

          if ($(glyphId).hasClass('glyphicon-chevron-right')) {
            $(glyphId).removeClass('glyphicon-chevron-right');
            $(glyphId).addClass('glyphicon-chevron-down');
            $(documentSection).removeClass('no-print');
          } else {
            $(glyphId).removeClass('glyphicon-chevron-down');
            $(glyphId).addClass('glyphicon-chevron-right');
            $(documentSection).addClass('no-print');
          }
        };

        scope.printDocument = function () {
          let promise = Promise.resolve(scope.printableAttrStrings);

          if (!scope.printableAttrStrings.length)
            promise = SolrDocumentService.getCompanyPrintableAttrs(
              scope.document.id,
              $rootScope.companyId
            ).then(data => {
              scope.printableAttrStrings = data;
            });

          promise.then(() => {
            setTimeout(() => {
              window.print();
            });
          });
        };

        scope.handleInventoriesModal = function (inventories) {
          ModalService.open({
            templateUrl: require('../../../views/modals/list-of-inventories.html'),
            controller: [
              '$uibModalInstance',
              function ($uibModalInstance) {
                var modal = this;

                modal.hideWithoutProducts = true;
                modal.inventories = modal.hideWithoutProducts
                  ? removeEmptyInventories(_.cloneDeep(inventories))
                  : inventories;

                console.log(modal.inventories);
                modal.openedProducts = {};
                modal.toogleProducts = ($inventoryId) => {
                  modal.openedProducts[$inventoryId] = !modal.openedProducts[$inventoryId];
                };

                modal.export = (format) => {
                  return ExportService.exportInventories(modal.inventories, { tags: [], andOrStrategy: 'and', includeAll: true }, format);
                };

                modal.cancel = function () {
                  $uibModalInstance.dismiss('cancel');
                };
              }
            ]
          });
        };

        scope.handleProductsModal = function (cachedProducts) {
          ModalService.open({
            templateUrl: require('../../../views/modals/list-of-products.html'),
            resolve: {
              products: [
                '$q',
                'Company',
                function ($q, Company) {
                  if (cachedProducts && cachedProducts.length) return $q.resolve(cachedProducts);

                  return Company.products({
                    id: $rootScope.companyId,
                    filter: {
                      where: { sdsRequired: true },
                      include: 'manufacturer'
                    }
                  }).$promise;
                }
              ]
            },
            controller: [
              '$uibModalInstance',
              'products',
              function ($uibModalInstance, products) {
                var modal = this;
                var companyProducts = products.filter(function (product) {
                  return scope.document.products.some(function (documentProductId) {
                    return documentProductId === product.id;
                  });
                });

                modal.products = scope.products = companyProducts;

                modal.cancel = function () {
                  $uibModalInstance.dismiss('cancel');
                };
              }
            ]
          });
        };

        scope.updateDocumentTagField = function (params) {
          scope.document.tag = params.ids;
          updateRestricts(params);
        };

        function getNumberOfLangVersions(document) {
          let numberOfLangVersions = 0;
          $rootScope.languages.forEach(langEl => {
            if (document[`${langEl}VersionId`]) numberOfLangVersions++;
          });
          return numberOfLangVersions;
        }

        function updateRestricts(params) {
          scope.document['restricted_in_' + $rootScope.companyId] = params.restrictedNote;

          if (params.restricted) {
            scope.document.hasRestricts = true;

            scope.document.restrictedInCompanies = (
              scope.document.restrictedInCompanies || []
            ).concat($rootScope.companyId);
          } else {
            scope.document.hasRestricts = false;

            scope.document.restrictedInCompanies = (
              scope.document.restrictedInCompanies || []
            ).filter(function (companyId) {
              return companyId !== $rootScope.companyId;
            });
          }
        }

        /**
         * Remove inventories without products and calculate number of products in the inventory
         *
         * @param {Array} inventories Inventorues list
         * @returns {Array} Inventorues list
         */
        function removeEmptyInventories(inventories) {
          let r = [];
          inventories.forEach(element => {
            if (element.inventories && element.inventories.length > 0) {
              element.inventories = removeEmptyInventories(element.inventories);
            }

            // add product quantity
            if (element.products) {
              for (let i in element.products) {
                element.products[i].quantity = element.products[i].amounts
                  .filter(obj => obj.inventoryId === element.id)
                  .reduce((sum, el) => sum + el.amount, 0);
              }
            }

            if ((element.inventories && element.inventories.length > 0)
              || (element.products && element.products.length > 0)) {
              r.push(element);
            }
          });
          return r;
        }

        function getSdsDocumentData(documentId) {
          return SdsDocumentSolrMap.find({
            filter: { where: { solrId: documentId } }
          }).$promise.then(
            function (response) {
              var additionalData = response[0];

              if (!additionalData) return true;
              let promises = [];
              if (additionalData.storageCodes && additionalData.storageCodes[$rootScope.companyId]) {
                promises.push(
                  StorageCodes.findById({
                    id: additionalData.storageCodes[$rootScope.companyId]
                  }).$promise
                );
              }
              if (additionalData.disposalCodes && additionalData.disposalCodes[$rootScope.companyId]) {
                promises.push(
                  DisposalCodes.findById({
                    id: additionalData.disposalCodes[$rootScope.companyId]
                  }).$promise
                );
              }
              return Promise.all(promises)
                .then(res => {
                  scope.selectedStorageCode = res[0];
                  scope.selectedDisposalCode = res[1];
                  localize();
                });
            },
            function (error) {}
          );
        }

        function localize() {
          ['selectedStorageCode', 'selectedDisposalCode'].forEach(el => {
            if (scope[el]) {
              ['title', 'description'].forEach(field => {
                scope[el][`localized_${field}`] = scope[el][`${field}_${$rootScope.siteLanguage}`] || scope[el][field];
              });
            }
          });
        }
      }
    };
  }
]);
