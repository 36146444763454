import app from '../../app';

app.directive('docLangs', [
  function () {
    return {
      restrict: 'E',
      transclude: true,
      scope: {
        langs: '='
      },
      templateUrl: require('./docLangs.html')
    };
  }
]);
