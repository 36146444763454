import app from '../../app';

app.controller('editAttrCtrl', [
  '$rootScope',
  '$uibModalInstance',
  'isEdit',
  'attrsByLang',
  'known',
  function ($rootScope, $uibModalInstance, isEdit, attrsByLang, known) {
    const vm = this;
    vm.title = isEdit ? 'SETTINGS.ATTRS.EDIT' : 'SETTINGS.ATTRS.ADD';
    vm.action = isEdit ? 'COMMON.ACTIONS.SAVE' : 'COMMON.ACTIONS.ADD';
    vm.entry = attrsByLang;
    vm.language = Object.keys(attrsByLang)[0];
    vm.known = known;
    vm.majorLanguage = $rootScope.majorLanguage;

    vm.dismiss = $uibModalInstance.dismiss;
    vm.apply = function () {
      $uibModalInstance.close(vm.entry);
    };
  }
]);
