module.exports = getWaterfallInventoryToAlert;

/**
 * Get IDs of all children of the given inventory if they don't have alerts enabled
 *
 * @param {Object} inventory an inventory instance with 'inventories' included
 * @param {string} alertType type of the alerts
 * @returns {Array<string>} an array of inventory IDs
 */
function getWaterfallInventoryToAlert(inventory, alertType) {
  let startInventoryId = inventory.id;
  let inventoryIds = [];
  let childrenInventories = [];

  getChildren(inventory);

  return inventoryIds;

  function getChildren(inventory) {
    if (inventory.id !== startInventoryId && inventory.alertEnabled[alertType])
      return;

    childrenInventories =
      typeof inventory.inventories === 'function'
        ? inventory.inventories()
        : inventory.inventories;
    inventoryIds.push(inventory.id.toString());

    if (childrenInventories && childrenInventories.length) {
      childrenInventories.forEach(getChildren);
    }
  }
}
