import app from '../../app';

app.directive('uploadSds', function (FileUploaderFactory, $rootScope, Container, SolrDocument) {
  return {
    restrict: 'E',
    templateUrl: require('./uploadSds.html'),
    scope: {
      endpoint: '@',
      formData: '=',
      processingTemplate: '=',
      onUpload: '&',
      onRemove: '&'
    },
    link: {
      pre: function (scope) {
        scope.processingTemplate = scope.processingTemplate || 'default';
        scope.endpoint = scope.endpoint || '/api/SolrDocuments/parse';
        scope.formData = scope.formData || {};
        scope.uploader = new FileUploaderFactory({
          url:
            scope.endpoint +
            '?container=upload&template=' +
            scope.processingTemplate +
            '&access_token=' +
            $rootScope.currentUser.tokenId,
          formData: scope.formData
        });
      },
      post: function (scope) {
        scope.$watch('processingTemplate', function (processingTemplate) {
          scope.processingTemplate = processingTemplate;
          scope.uploader.url =
            scope.endpoint +
            '?container=upload&template=' +
            scope.processingTemplate +
            '&access_token=' +
            $rootScope.currentUser.tokenId;
        });
        scope.uploader.filters.push({
          name: 'accept only pdfs',
          fn: function (item) {
            return /\.pdf$/i.test(item.name);
          }
        });
        scope.uploader.onAfterAddingFile = function (item) {
          item.checking = true;
          return Container.fileExists({
            container: 'sds',
            // fileExists decodes the file
            file: encodeURIComponent(item.file.name)
          }).$promise.then(function (resp) {
            item.checking = false;
            item.exists = resp.exists;
            if (resp.exists) {
              item.error = 'File with this name already exists';
              item.cancel();
            }
          });
        };
        scope.uploader.onErrorItem = function (item, response, status, headers) {
          item.error = (response.error || {}).message || 'something failed';
        };
        scope.uploader.onSuccessItem = function (item, resp, status, headers) {
          item.solrId = resp.doc.id;
          scope.onUpload({ doc: resp.doc });
        };

        scope.getReady = function () {
          return scope.uploader.queue.filter(function (itm) {
            return !itm.exists && (!itm.isUploaded || !itm.isSuccess);
          });
        };
        scope.hasReady = function () {
          return !!scope.getReady().length;
        };
        scope.hasAnyToClear = function () {
          return !!scope.uploader.queue.length;
        };
        scope.clear = function () {
          scope.uploader.queue.forEach(function (item) {
            scope.onRemove({ filename: item.file.name });
          });
          return scope.uploader.clearQueue();
        };
        scope.upload = function () {
          const itms = scope.getReady();
          return itms.forEach(function (itm) {
            itm.error = null;
            itm.upload();
          });
        };

        scope.remove = function (item) {
          scope.onRemove({ filename: item.file.name });
          item.remove();
        };
        scope.delete = function (item) {
          item.deleting = true;
          item.isUploading = false;
          item.isSuccess = false;
          item.isError = false;
          item.error = null;
          return SolrDocument.deleteById({ id: item.solrId })
            .$promise.then(function () {
              item.deleting = false;
              item.remove();
              scope.onRemove({ filename: item.file.name });
            })
            .catch(function (err) {
              console.error(err);
              item.deleting = false;
              item.isUploaded = false;
              item.error = 'Failed to delete file';
            });
        };
      }
    }
  };
});
