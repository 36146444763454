import app from '../../app';

app.directive('previousVersionBtn', [
  'SolrDocument',
  'usSpinnerService',
  function (SolrDocument, usSpinnerService) {
    return {
      templateUrl: require('./previousVersionBtn.html'),
      restrict: 'E',
      scope: {
        document: '=',
        showSpinner: '='
      },
      replace: true,
      link: function (scope, element, attrs, uiGridCtrl) {
        scope.previousVersionIsLoaded = false;
        scope.previousVersions = [];
        // spinner logic
        scope.startSpinner = function () {
          scope.showSpinner = true;
          usSpinnerService.spin('spinner-1');
        };
        scope.stopSpinner = function () {
          scope.showSpinner = false;
          usSpinnerService.stop('spinner-1');
        };

        // get documents with previous version (recursive)
        function getDocumentVersion(documentId, callback) {
          if (documentId) {
            var searchId = { idList: 'id:' + documentId };
            SolrDocument.findByIdList(searchId).$promise.then(function (
              results
            ) {
              var res = results.response.docs[0] || {};
              if (res.id) {
                scope.previousVersions.push({
                  id: res.id,
                  date: res.issueDate
                    ? decodeURIComponent(res.issueDate)
                    : res.dateModified
                });
              }

              if (res.previousVersionId) {
                getDocumentVersion(res.previousVersionId, callback);
              } else {
                return callback(null, scope.previousVersions);
              }
            });
          } else {
            return callback(null, scope.previousVersions);
          }
        }

        scope.buttonPressed = function () {
          if (!scope.previousVersionIsLoaded) {
            scope.startSpinner();
          }
        };

        // init data
        if (scope.document.previousVersionId) {
          getDocumentVersion(scope.document.previousVersionId, function (
            error,
            versions
          ) {
            scope.previousVersionIsLoaded = true;
            scope.stopSpinner();
          });
        } else {
          scope.previousVersionIsLoaded = true;
        }
      }
    };
  }
]);
