import app from '../app';

app.filter('generateUniqueID', function () {
  return function (length) {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first {{length}} characters
    // after the decimal.
    return Math.random()
      .toString(36)
      .substr(2, length);
  };
});
