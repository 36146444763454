import app from '../app';

app.service('SdsDocumentService', function ($location, $state) {
  this.getViewUrl = function (doc = {}) {
    const viewSdsState = $state.get('viewSds');

    return `${$location.protocol()}://${$location.host()}${viewSdsState.url.replace(
      /:id/,
      doc.id
    )}`;
  };
});
