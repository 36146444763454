import app from '../app';

app.directive('touched', function () {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, elem, attrs, ctrl) {
      ctrl.$setTouched();
    }
  };
});
