import app from '../../app';

app.directive('truncateString', [
  function () {
    let maxWidth = 0;

    return {
      restrict: 'E',
      templateUrl: require('./truncateString.html'),
      scope: {
        source: '=', // can be a string or an array of strings/objects
        key: '@', // if "source" is an array of objects, "key" is used to get the field value of each object
        decodeOutput: '=' // false by default
      },
      controllerAs: 'vm',
      controller: function ($scope, $timeout, $element) {
        const decodeOutput = $scope.decodeOutput === true ? true : false;
        const $truncatedStringElement = $element.find('.truncated-string');
        const vm = this;

        vm.toggleVisibility = function () {
          vm.isHidden = !vm.isHidden;
        };

        maxWidth = Math.max(maxWidth, $truncatedStringElement.width());

        // we have to do this to fix max-width for this element before rendering its content
        setMaxWidth($truncatedStringElement);

        $scope.$watch('source', applySource);

        function applySource() {
          vm.textOverflow = false;
          vm.isHidden = true;

          if (Array.isArray($scope.source)) {
            if ($scope.key != null && $scope.key !== '') {
              vm.source = $scope.source.map(i => i[$scope.key]).join('; ');
            } else {
              vm.source = $scope.source
                .map(i =>
                  typeof i === 'object'
                    ? decodeOutput
                      ? decodeURIComponent(JSON.stringify(i))
                      : JSON.stringify(i)
                    : i.toString
                    ? decodeOutput
                      ? decodeURIComponent(i.toString())
                      : i.toString()
                    : decodeOutput
                    ? decodeURIComponent(i)
                    : i
                )
                .join('; ');
            }
          } else {
            vm.source = $scope.source
              ? decodeOutput
                ? decodeURIComponent($scope.source)
                : $scope.source
              : '';
          }

          $timeout(function () {
            vm.textOverflow = checkOverflow($truncatedStringElement[0]);

            // we have to do this to apply max-width for all elements after rendering their content
            setMaxWidth($truncatedStringElement);
          });
        }
      }
    };

    function setMaxWidth($element) {
      $element.css({ 'max-width': `${maxWidth}px` });
    }
  }
]);

// Determines if the passed element is overflowing its bounds, either vertically or horizontally.
// Will temporarily modify the "overflow" style to detect this if necessary.
function checkOverflow(el) {
  const curOverflow = el.style.overflow;

  if (!curOverflow || curOverflow === 'visible') el.style.overflow = 'hidden';

  const isOverflowing = el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;
  el.style.overflow = curOverflow;

  return isOverflowing;
}
