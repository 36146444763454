import angular from 'angular';
import app from '../../app';

app.directive('inventoryAlerts', [
  'ModalService',
  function (ModalService) {
    return {
      restrict: 'E',
      templateUrl: require('./inventoryAlerts.html'),
      scope: {
        inventory: '=',
        getParent: '='
      },
      link: function (scope, element, attrs) {
        scope.openModal = function (inventory) {
          ModalService.open({
            templateUrl: require('./inventoryAlertsModal.html'),
            resolve: {
              lowAlertLabelInfo: [
                '$translate',
                function ($translate) {
                  return $translate('INVENTORY.ALERTS.LOW_THRESHOLD_INFO');
                }
              ],
              maxAlertLabelInfo: [
                '$translate',
                function ($translate) {
                  return $translate('INVENTORY.ALERTS.MAX_THRESHOLD_INFO');
                }
              ],
              companyUsers: [
                'SdsUser',
                function (SdsUser) {
                  const allowedRoles = ['teamAdmin', 'teacher'];

                  return SdsUser.getCompanyUsersByRoleNames({
                    roleNames: allowedRoles,
                    companyId: inventory.companyId
                  }).$promise;
                }
              ]
            },
            controller: [
              '$q',
              '$uibModalInstance',
              'lowAlertLabelInfo',
              'maxAlertLabelInfo',
              'companyUsers',
              'MessagesService',
              'InventoryService',
              function (
                $q,
                $uibModalInstance,
                lowAlertLabelInfo,
                maxAlertLabelInfo,
                companyUsers,
                MessagesService,
                InventoryService
              ) {
                let modal = this;
                let shouldCreateAlerts = {
                  low: false,
                  max: false
                };

                modal.lowAlertLabelInfo = lowAlertLabelInfo;
                modal.maxAlertLabelInfo = maxAlertLabelInfo;
                modal.companyUsers = companyUsers;
                modal.inventory = angular.copy(inventory);
                modal.cancel = $uibModalInstance.dismiss;

                const saveAlertSettings = bindSavingWithInventory(
                  modal.inventory
                );

                modal.handleChanges = function (alertType) {
                  shouldCreateAlerts[alertType] = true;
                };

                modal.addEmailToList = function (email, alertType) {
                  if (!email) return;

                  const index = modal.inventory.alertEmailsList[
                    alertType
                  ].indexOf(email);

                  if (!~index)
                    modal.inventory.alertEmailsList[
                      alertType
                    ] = modal.inventory.alertEmailsList[alertType].concat(
                      email
                    );
                };

                modal.removeEmailFromList = function (email, alertType) {
                  modal.inventory.alertEmailsList[
                    alertType
                  ] = modal.inventory.alertEmailsList[alertType].filter(
                    function (emailInList) {
                      return emailInList !== email;
                    }
                  );
                };

                modal.save = function () {
                  return $q
                    .all([saveAlertSettings('low'), saveAlertSettings('max')])
                    .then(function () {
                      $uibModalInstance.close();
                      MessagesService.success('COMMON.MESSAGES.UPDATED');
                    });
                };

                function bindSavingWithInventory(updatedInventory) {
                  return function (alertType) {
                    const parentAlert = scope.getParent(updatedInventory)[
                      alertType
                    ];

                    return InventoryService.saveAlertSettings(
                      updatedInventory,
                      parentAlert,
                      alertType,
                      shouldCreateAlerts[alertType]
                    ).then(function () {
                      const newAlertSettins = {
                        alertEnabled: {},
                        alertThreshold: {},
                        alertEmailsList: {}
                      };
                      newAlertSettins.alertEnabled[alertType] =
                        updatedInventory.alertEnabled[alertType];
                      newAlertSettins.alertThreshold[alertType] =
                        updatedInventory.alertThreshold[alertType];
                      newAlertSettins.alertEmailsList[alertType] =
                        updatedInventory.alertEmailsList[alertType];

                      angular.extend(
                        inventory.alertEnabled,
                        newAlertSettins.alertEnabled
                      );
                      angular.extend(
                        inventory.alertThreshold,
                        newAlertSettins.alertThreshold
                      );
                      angular.extend(
                        inventory.alertEmailsList,
                        newAlertSettins.alertEmailsList
                      );
                    });
                  };
                }
              }
            ]
          });
        };
      }
    };
  }
]);
