import app from '../../app';

// storage/disposal code
app.directive('sdCode', [
  '$rootScope',
  'HelperService',
  function ($rootScope, HelperService) {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        code: '=',
        noBg: '='
      },
      templateUrl: require('./sdCode.html'),
      link: function (scope) {
        scope.getLangTitle = obj => obj[`title_${$rootScope.siteLanguage}`] || obj.title;
        scope.HelperService = HelperService;
        scope.getStyle = function (code) {
          const color = code.color;
          if (!color || scope.noBg) {
            return '#fff';
          }
          return '' + color;
        };
      }
    };
  }
]);
