import app from '../app';
import { getNameFromFile } from '../helpers';

app.filter('nameFromFile', function () {
  return function (filePath) {
    const name = getNameFromFile(filePath);
    try {
      return decodeURIComponent(name);
    } catch (err) {
      console.error(err);
      return name;
    }
  };
});
