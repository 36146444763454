import app from '../../app';

app.directive('bannedMessage', [
  '$rootScope',
  '$translate',
  '$q',
  function ($rootScope, $translate, $q) {
    return {
      restrict: 'E',
      scope: {
        banned: '='
      },
      templateUrl: require('./bannedMessage.html'),
      link: function (scope) {
        const composeTemplate = function (template, value) {
            return template.replace('{chemical}', '"' + value + '"');
          },
          trKey = 'COMMON.MESSAGES.BANNED',
          composeComment = () => {
            scope.comment = $rootScope.siteLanguage === 'en'
              ? scope.banned.comment
              : scope.banned[`comment_${$rootScope.siteLanguage}`] || scope.banned.comment;
            
            //get not empty comment
            if (!scope.comment) {
              $rootScope.languages.some(lang => {
                scope.comment = lang === 'en'
                  ? scope.banned.comment
                  : scope.banned[`comment_${lang}`];

                return scope.comment;
              });
            }
          },
          composeGeneric = function (value, company) {
            return $translate([trKey], {
              name: value,
              company: company
            }).then(function (tr) {
              return tr[trKey];
            });
          },
          company = ($rootScope.currentUser || {}).company || '';
        
        composeComment();
        let template = ($rootScope.companySettings || {})[`bannedTemplateMessage_${$rootScope.siteLanguage}`]
            || ($rootScope.companySettings || {}).bannedTemplateMessage;
        scope.composeMsg = function () {
          return template
            ? $q.resolve(composeTemplate(template, scope.banned.name || scope.banned.title))
            : composeGeneric(scope.banned.name || scope.banned.title, company);
        };
        scope.$watch('banned', function (banned) {
          scope.banned = banned;
          scope.composeMsg().then(function (msg) {
            scope.msg = msg;
          });
        });

        $rootScope.$watch('siteLanguage', function (newValue, oldValue) {
          if (newValue === oldValue) return;
          template = ($rootScope.companySettings || {})[`bannedTemplateMessage_${$rootScope.siteLanguage}`]
            || ($rootScope.companySettings || {}).bannedTemplateMessage;
          scope.composeMsg().then(function (msg) {
            scope.msg = msg;
          });
          composeComment();
        });
      }
    };
  }
]);
