import { get } from 'lodash';

export default function attrsModal({ available, enabled, companyId, sdsId }) {
  return {
    templateUrl: require('./attrsModal.html'),
    keyboard: true,
    backdrop: true,
    controllerAs: 'ctrl',
    controller: [
      '$rootScope',
      '$uibModalInstance',
      'SolrDocument',
      'SpinnerService',
      'CompanyService',
      function ($rootScope, $uibModalInstance, SolrDocument, SpinnerService, CompanyService) {
        const vm = this;
        let notUniqueAttrs = [];
        vm.available = available;
        vm.attrs = Object.keys(available).reduce(function (sum, ky) {
          sum[ky] = enabled[ky];
          return sum;
        }, {});
        vm.cancel = $uibModalInstance.dismiss;
        vm.save = SpinnerService.wrap(function (attrs) {
          return SolrDocument.getNotUniqueAttrs({
            attrs: attrs,
            companyId: companyId,
            sdsId: sdsId
          }).$promise.then(function (res) {
            if (!res.notUniqueAttrs.length) return $uibModalInstance.close(attrs);

            notUniqueAttrs = res.notUniqueAttrs;
          });
        });
        vm.isAttrNotUnique = function (attr) {
          return !!~(notUniqueAttrs || []).indexOf(attr);
        };
        vm.getAttrLangName = CompanyService.getAttrLangName;
      }
    ]
  };
}
