import app from '../../app';

app
  // The example of the full functionality
  .controller('uploadCtrl', [
    '$scope',
    '$rootScope',
    'FileUploaderFactory',
    'SolrDocument',
    function ($scope, $rootScope, FileUploaderFactory, SolrDocument) {
      const renamePdfRegexp = /\.(?=[^.]*\.)/g;

      // create a uploader with options
      var uploader = ($scope.uploader = new FileUploaderFactory({
        scope: $scope, // to automatically update the html. Default: $rootScope
        url: '/api/containers/upload/upload?access_token=' + $rootScope.currentUser.tokenId,
        formData: [{ key: 'value' }]
      }));

      // FILTERS

      uploader.filters.push({
        name: 'queueLengthFilter',
        fn: function (item /*{File|FileLikeObject}*/, options) {
          return this.queue.length < 50;
        }
      });

      uploader.filters.push({
        name: 'pdfFilter',
        fn: function (item) {
          return /\.pdf$/i.test(item.name);
        }
      });

      uploader.onWhenAddingFileFailed = function (item /*{File|FileLikeObject}*/, filter, options) {
        console.info('onWhenAddingFileFailed', item, filter, options);
        if (filter.name == 'queueLengthFilter') {
          $scope.queueLimitReached = true;
        } else if (filter.name == 'pdfFilter') {
          $scope.fileTypeError = true;
        }
      };

      //check if document already exists
      function checkDocument(file) {
        var params = {};
        params.container = 'sds';
        params.file = encodeURIComponent(file.file.name.replace(renamePdfRegexp, '_'));
        SolrDocument.checkDocument(
          params,
          function (data) {
            console.log(data);
          },
          function (err) {
            // file exists
            file.file.exists = true;
            console.log(err);
          }
        );
      }

      uploader.onAfterAddingFile = function (fileItem) {
        console.info('onAfterAddingFile', fileItem);
        $scope.fileExists = false;
        checkDocument(fileItem);
      };

      uploader.onBeforeUploadItem = function (item) {
        console.info('onBeforeUploadItem', item);
        if (item.file.exists) {
          $scope.fileExists = true;
          item.cancel();
        }
      };

      uploader.onCompleteAll = function () {
        console.info('onCompleteAll');
        $scope.queueLimitReached = false;
        $scope.fileTypeError = false;
      };

      console.info('uploader', uploader);
    }
  ]);
