import app from '../../app';

app.directive('docDuplicate', [
  '$rootScope',
  function ($rootScope) {
    return {
      restrict: 'E',
      transclude: true,
      scope: {
        doc: '='
      },
      templateUrl: require('./docDuplicate.html'),
      link: function (scope) {
        scope.isAdmin = $rootScope.checkIfUserIs(['admin', 'sdsEditor']);
        scope.isDuplicate = function (doc) {
          return doc.duplicateOfId;
        };
      }
    };
  }
]);
