import app from '../../app';

app.directive('docInv', [
  function () {
    return {
      restrict: 'E',
      transclude: true,
      scope: {
        inv: '='
      },
      templateUrl: require('./docInv.html'),
      link: function (scope) {
        scope.quickFilter = function (obj, filterClass) {
          scope.$emit('onQuickFilter', { obj: obj, filterClass: filterClass });
        };
      }
    };
  }
]);
