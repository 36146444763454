import app from '../app';

app.service('QuizService', function (Quiz) {
  this.upsert = function (quiz) {
    var data = {
      title: quiz.title,
      companyId: quiz.companyId,
      questions: quiz.questions,
      submitBy: quiz.submitBy
    };

    if (quiz.id) data.id = quiz.id;
    if (quiz.qrcode) data.qrcode = quiz.qrcode;

    if (quiz.courseID && quiz.courseWorkID) {
      data.courseID = quiz.courseID;
      data.courseWorkID = quiz.courseWorkID;
    }

    if (quiz.submitBy.email && quiz.recipientEmail) data.recipientEmail = quiz.recipientEmail;

    // TO DO: add Google Classroom

    return Quiz.upsert(data).$promise;
  };

  this.find = function (where) {
    var filterObj = {
      filter: {
        where: {
          companyId: where.companyId
        }
      }
    };

    return Quiz.find(filterObj).$promise;
  };

  this.deleteById = function (id) {
    return Quiz.deleteById({ id: id }).$promise;
  };

  this.emailResults = function (mailTo, participantName, qrCode, quiz, results) {
    const data = {
      mailTo: mailTo,
      'Participant name': participantName,
      qrCode: {
        id: qrCode.id,
        title: qrCode.title
      },
      quiz: {
        id: quiz.id,
        title: quiz.title
      },
      results: results
    };

    return Quiz.emailResults({}, data).$promise;
  };
});
