const sortNameCaseInsensitive = function sortNameCaseInsensitive(a, b) {
  const nameA = (a.title || a.originalName || a.name).toLowerCase();
  const nameB = (b.title || b.originalName || b.name).toLowerCase();

  if (nameA < nameB) return -1; // sort string ascending
  if (nameA > nameB) return 1; // sort string descending
  return 0; // default return value (no sorting)
};

module.exports = {
  sortNameCaseInsensitive
};
