import angular from 'angular';
import app from '../../app';
import { deObjectify } from '../../helpers';

app.controller('bulkDocumentEditCtrl', [
  '$scope',
  '$rootScope',
  '$q',
  'SolrDocument',
  'Tag',
  'SearchFactory',
  'SearchSettings',
  'TagFactory',
  'CompanyFactory',
  'usSpinnerService',
  'manufacturers',
  bulkDocumentEditCtrl
]);

function bulkDocumentEditCtrl(
  $scope,
  $rootScope,
  $q,
  SolrDocument,
  Tag,
  SearchFactory,
  SearchSettings,
  TagFactory,
  CompanyFactory,
  usSpinnerService,
  manufacturers
) {
  //create instances of TagFactory for our viewmodel
  var UniversalTags = new TagFactory(Tag);
  var CompanyTags = new TagFactory(Tag);

  //setup the viewmodel
  var vm = this;
  vm.SearchFactory = SearchFactory;
  vm.searchResults = SearchFactory;
  vm.start = SearchFactory.searchParams.start;
  vm.totalPages = SearchFactory.totalPages;
  vm.UniversalTags = UniversalTags;
  vm.CompanyTags = CompanyTags;
  vm.tagLookup = {};
  vm.companyFactory = CompanyFactory;
  vm.documentSelectedIds = {};
  vm.documentsSelected = [];
  vm.selectAll = false;
  vm.showRestrictedFields = false;
  vm.companies;
  vm.selectedCompany;
  vm.manufacturers = manufacturers;

  var tagSelection = [];

  $scope.showTag = new TagFactory(Tag).showTag;

  //company
  $scope.currentCompanyId = '';

  $scope.advancedSearch = function search(start) {
    $('#advancedSearchBody').collapse('hide');
    vm.documentSelectedIds = {};
    vm.documentsSelected = [];
    vm.selectAll = false;
    SearchSettings.lastSearchPage = start;

    if (!$scope.newSearch.searchTerms) $scope.newSearch.searchTerms = '*';
    SearchFactory.searchTerms = $scope.newSearch.searchTerms;

    var searchOptions = {};
    var searchByManufacturers = deObjectify(vm.selectedManufactures);
    var searchByTags = SearchSettings.UniversalTags.getSelectedTags().concat(
      SearchSettings.CompanyTags.getSelectedTags()
    );
    var searchByLanguages = SearchFactory.getSelectedLanguages();
    searchOptions.searchParams =
      '[' +
      '{"searchKey": "chemicalFriendlyName", "searchTerms": "' +
      encodeURIComponent($scope.newSearch.searchTerms) +
      '"},' +
      '{"searchKey": "product_and_company_identification", "searchTerms": "' +
      encodeURIComponent($scope.newSearch.searchTerms) +
      '"},' +
      '{"searchKey": "other_fields", "searchTerms": "' +
      encodeURIComponent($scope.newSearch.searchTerms) +
      '"},' +
      '{"searchKey": "tag", "searchTerms": "' +
      encodeURIComponent(
        UniversalTags.getSelectedTags()
          .concat(CompanyTags.getSelectedTags())
          .join()
      ) +
      '"},' +
      '{"searchKey": "language", "searchTerms": "' +
      encodeURIComponent(SearchFactory.getSelectedLanguages().join()) +
      '"},' +
      '{"searchKey": "manufacturer", "searchTerms": "' +
      searchByManufacturers.join() +
      '"}' +
      ']';

    searchOptions.companyId = vm.searchOnlyCompanyDocuments ? $scope.currentCompanyId : '';
    searchOptions.returnFields =
      'id,chemicalFriendlyName,isVerified,dateCreated,dateModified,resourcePath,tag,companyId,restricted_in_*,manufacturer,synonyms,kit_ids';

    searchOptions.returnFields +=
      ',' + $rootScope.languages.map(langEl => `${langEl}VersionId`).join(',');

    searchOptions.start = start;
    searchOptions.rows = 20;
    searchOptions.filter = '-isArchived:true';
    searchOptions.hlFl = ['synonyms'];

    if (vm.unverifiedFilter) {
      searchOptions.filter += ' AND isVerified:false';
    }

    SearchFactory.advancedSearch(
      searchOptions,
      searchByManufacturers,
      searchByTags,
      searchByLanguages,
      false,
      function () {
        vm.start = start;
        vm.totalPages = SearchFactory.totalPages;

        SearchFactory.documents.forEach(function (document) {
          if (document.companyId && document.companyId.indexOf($scope.currentCompanyId) >= 0) {
            document.hasCompany = vm.selectedCompany.name;
          }

          if (document['restricted_in_' + $scope.currentCompanyId]) {
            document['restricted_in_' + $scope.currentCompanyId] = decodeURIComponent(
              document['restricted_in_' + $scope.currentCompanyId]
            );
          } else if (document.restricted_in_common) {
            document['restricted_in_' + $scope.currentCompanyId] = decodeURIComponent(
              document.restricted_in_common
            );
          }
        });
      }
    );
  };
  $scope.showSynonymFor = SearchFactory.showSynonymFor;

  $rootScope.$on('$translateChangeSuccess', function () {
    SearchFactory.textTranslate();
  });
  SearchFactory.textTranslate();

  $scope.toggleSelectAllCheckbox = function () {
    vm.selectAll = !vm.selectAll;
    vm.documentsSelected = [];

    SearchFactory.documents.forEach(function (document) {
      var idx = vm.documentsSelected.indexOf(document);
      vm.documentSelectedIds[document.id] = vm.selectAll;

      if (vm.selectAll) {
        vm.documentsSelected.push(document);
      } else {
        vm.documentsSelected.splice(idx, 1);
      }
    });
  };

  $scope.addSelectedDocumentsToCompany = function () {
    var selectedDocumentsId = getSelectedDocumentsId();
    var documentIndex = getDocumentIndices();

    $q.all(
      selectedDocumentsId.map(function (id) {
        return $scope.addDocumentToCompany(documentIndex[id], id);
      })
    ).then(function (affectedSdsIds) {
      const sdsIds = affectedSdsIds
        .reduce(function (arr, arrIds) {
          return arr.concat(arrIds);
        }, [])
        .filter(function (id) {
          return !!id;
        });

      if (!sdsIds.length) return;

      return SolrDocument.notify(
        { actionName: 'added' },
        { companyIds: [$scope.currentCompanyId], sdsIds: sdsIds }
      ).$promise;
    });
  };

  $scope.addDocumentToCompany = function (index, id) {
    if (!SearchFactory.documents[index].companyId) {
      SearchFactory.documents[index].companyId = [];
    }

    var arrayIndex = SearchFactory.documents[index].companyId.indexOf($scope.currentCompanyId);

    if (arrayIndex < 0) {
      SearchFactory.documents[index].companyId.push($scope.currentCompanyId);
      var document = SearchFactory.documents[index];

      return saveData(document, function () {
        SearchFactory.documents[index].hasCompany = vm.selectedCompany.name;
      });
    } else {
      return $q.resolve(null);
    }
  };

  $scope.removeSelectedDocumentsFromCompany = function () {
    var selectedDocumentsId = getSelectedDocumentsId();
    var documentIndex = getDocumentIndices();

    selectedDocumentsId.forEach(function (id) {
      $scope.removeDocumentFromCompany(documentIndex[id], id);
    });
  };

  $scope.removeDocumentFromCompany = function (index, id) {
    if (SearchFactory.documents[index] && SearchFactory.documents[index].companyId) {
      var arrayIndex = SearchFactory.documents[index].companyId.indexOf($scope.currentCompanyId);
      if (arrayIndex >= 0) {
        SearchFactory.documents[index].companyId.splice(arrayIndex, 1);
        var document = SearchFactory.documents[index];
        saveData(document, function () {
          SearchFactory.documents[index].hasCompany = false;
        });
      }
    }
  };

  function saveData(document, callback) {
    //add in the data
    var sdsDocument = {};
    sdsDocument.id = document.id;
    sdsDocument.companyId = { set: document.companyId };

    //example post data = {data : '[{"id":"doc1", "content":"newcontent"}]'}
    var cleanData = { data: '[' + JSON.stringify(sdsDocument) + ']' };

    //update solr with the document data containing companies
    return SolrDocument.update(cleanData)
      .$promise.then(function (results) {
        return SolrDocument.propagateToLinked({ id: document.id }).$promise;
      })
      .then(function () {
        callback();
        const r = [document.id, document.kit_ids];
        $rootScope.languages.forEach(langEl => {
          r.push(document[`${langEl}VersionId`]);
        });
        return r;
      });
  }

  // toggle selection for a given tag
  $scope.toggleDocumentCheckbox = function (document) {
    var id = document.id;
    var idx = vm.documentsSelected.indexOf(document);

    if (vm.documentSelectedIds[id]) {
      vm.documentSelectedIds[id] = false;
      vm.documentsSelected.splice(idx, 1);
    } else {
      vm.documentSelectedIds[id] = true;
      vm.documentsSelected.push(document);
    }

    console.log('selected : ' + JSON.stringify(getSelectedDocumentsId()));
  };

  //convenience method to get an array of selected tags
  function getSelectedDocumentsId() {
    var selectedDocumentsId = [];
    for (var docId in vm.documentSelectedIds) {
      if (vm.documentSelectedIds[docId]) {
        selectedDocumentsId.push(docId);
      }
    }

    return selectedDocumentsId;
  }

  function getDocumentIndices() {
    var documentIndex = {};
    SearchFactory.documents.forEach(function (document, index) {
      documentIndex[document.id] = index;
    });

    return documentIndex;
  }

  $scope.OnCompanySelected = function (triggeredBySelect) {
    // if (!saveCompany.id && CompanyFactory.selectedCompany) {
    //     angular.copy(CompanyFactory.selectedCompany, saveCompany);
    // } else if (saveCompany.id && !CompanyFactory.selectedCompany) {
    //     vm.companyFactory.selectedCompany = {};
    //     angular.copy(saveCompany, vm.companyFactory.selectedCompany);
    // }

    if (vm.selectedCompany.id) $rootScope.onChangeMainCompany(vm.selectedCompany.name);
    vm.showRestrictedFields = false;

    tagSelection = [];
    vm.documentsSelected = [];
    vm.tagLookup = {};
    HandleUniversalTagsLoaded();
    SearchSettings.bulkSelectedCompany = vm.selectedCompany;
    $scope.currentCompanyId = vm.selectedCompany.id;
    CompanyTags.getTags($scope.currentCompanyId, HandleCompanyTagsLoaded);
    CompanyTags.tagSelected = {};

    if (triggeredBySelect) {
      SearchFactory.reset();
    }
  };

  // toggle selection for a given tag by name
  $scope.toggleTag = function (tagId) {
    UniversalTags.toggleTagSelected(tagId);
    CompanyTags.toggleTagSelected(tagId);
    SearchFactory.reset();
  };

  $scope.toggleTagOnDoc = function (tagId) {
    var idx = tagSelection.indexOf(tagId);
    if (idx > -1) {
      tagSelection.splice(idx, 1);

      if (vm.tagLookup[tagId] && vm.tagLookup[tagId].title.toLowerCase() === 'restricted') {
        vm.showRestrictedFields = false;
      }
    } else {
      tagSelection.push(tagId);

      if (vm.tagLookup[tagId] && vm.tagLookup[tagId].title.toLowerCase() === 'restricted') {
        vm.showRestrictedFields = true;
      }
    }
  };

  $scope.removeTag = function (document, tagId) {
    var idx = document.tag.indexOf(tagId);
    var sdsDocument = {};
    var cleanData;

    document.tag.splice(idx, 1);

    sdsDocument.id = document.id;
    sdsDocument.tag = { set: document.tag };
    cleanData = { data: '[' + JSON.stringify(sdsDocument) + ']' };
    SolrDocument.update(cleanData)
      .$promise.then(function () {
        return SolrDocument.propagateToLinked({ id: document.id }).$promise;
      })
      .then(function () {
        console.log('success');
      });
  };

  $scope.addTagsToDocs = function () {
    var selectedDocumentsId = getSelectedDocumentsId();

    selectedDocumentsId.forEach(function (id) {
      startSpinner();

      SearchFactory.documents.forEach(function (document, index) {
        if (document.id === id) {
          var sdsDocument = {};
          var documentTagSelection;

          sdsDocument.id = id;

          if (document.tag) {
            documentTagSelection = document.tag.concat(tagSelection);

            documentTagSelection = documentTagSelection.filter(function (elem, index, self) {
              return index == self.indexOf(elem);
            });
          } else {
            documentTagSelection = tagSelection;
          }

          sdsDocument.tag = { set: documentTagSelection };

          if (vm.showRestrictedFields) {
            sdsDocument['restricted_in_' + $scope.currentCompanyId] = {
              set: encodeURIComponent(document['restricted_in_' + $scope.currentCompanyId] || '')
            };
          }

          //example post data = {data : '[{"id":"doc1", "content":"newcontent"}]'}
          var cleanData = { data: '[' + JSON.stringify(sdsDocument) + ']' };

          //update solr with the document data containing companies
          SolrDocument.update(cleanData)
            .$promise.then(function (resp) {
              return SolrDocument.propagateToLinked({
                id: document.id
              }).$promise.then(function () {
                return resp;
              });
            })
            .then(function (results) {
              $scope.advancedSearch(
                SearchSettings.lastSearchPage ? SearchSettings.lastSearchPage : 0
              );
              vm.documentsSelected = [];

              stopSpinner();
            });
        }
      });
    });
  };

  $scope.restrictedTag = function (tagId) {
    return (vm.tagLookup[tagId] || {}).restricted;
  };

  $scope.notRectrictedTag = function (tagId) {
    return !$scope.restrictedTag(tagId);
  };

  $scope.universalTag = function (tagId) {
    return !$scope.restrictedTag(tagId) && !(vm.tagLookup[tagId] || {}).companyId;
  };

  $scope.companyTag = function (tagId) {
    return !$scope.restrictedTag(tagId) && !$scope.universalTag(tagId);
  };

  $scope.getTagTitle = function (tagId) {
    return (
      (vm.tagLookup[tagId] || {})['title_' + $rootScope.siteLanguage] ||
      (vm.tagLookup[tagId] || {}).title
    );
  };

  //universal tags loaded, update the view model
  function HandleUniversalTagsLoaded() {
    vm.tagLookup = $.extend(vm.tagLookup, UniversalTags.tagLookup);
  }

  //company tags loaded, update the viewmodel
  function HandleCompanyTagsLoaded() {
    vm.tagLookup = $.extend(vm.tagLookup, CompanyTags.tagLookup);
    // vm.tagLookup = CompanyTags.tagLookup;
  }

  //populate the search form with data from the previous search
  function InitilizeSearchForm() {
    $scope.newSearch = { searchTerms: '', companyId: '' };
    $scope.newSearch.companyId = $rootScope.currentUser.companyId;

    if (SearchFactory.searchParams && SearchFactory.searchParams.searchTerms) {
      $scope.newSearch.searchTerms = SearchFactory.searchParams.searchTerms;
    } else if ($rootScope.currentUser.role == 'admin') {
      $scope.newSearch.searchTerms = '*';
    }
  }

  function startSpinner() {
    $scope.spinnerActive = true;
    usSpinnerService.spin('spinner-1');
  }

  function stopSpinner() {
    usSpinnerService.stop('spinner-1');
    $scope.spinnerActive = false;
  }

  //setup the search page
  function Initialize() {
    InitilizeSearchForm();
    UniversalTags.getTags('', HandleUniversalTagsLoaded);
    if ($rootScope.currentUser && $rootScope.currentUser.companyId) {
      CompanyTags.getTags($rootScope.currentUser.companyId, HandleCompanyTagsLoaded);
      $scope.currentCompanyId = $rootScope.currentUser.companyId;
    }

    if ($rootScope.currentUser.role === 'admin') {
      CompanyFactory.GetCompanies(function (companies) {
        vm.companies = companies;
        if ($rootScope.sitename) {
          for (let i = 0; i < vm.companies.length; i++) {
            if ($rootScope.sitename === vm.companies[i].name.toLowerCase()) {
              vm.selectedCompany = vm.companies[i];
              break;
            }
          }
        }
        if (!vm.selectedCompany)
          vm.selectedCompany = SearchSettings.bulkSelectedCompany
            ? SearchSettings.bulkSelectedCompany
            : companies[0];

        $scope.OnCompanySelected();
      });
    }
  }

  Initialize();
}
