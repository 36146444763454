import app from '../../app';
import { getOffset } from '../../helpers';
import scheduleReport from '../../modals/scheduleReport';

app.controller('cerclaPriorityListReport', [
  '$rootScope',
  '$scope',
  'AtsdrSubstancePriorityList',
  'CompanyService',
  'ModalService',
  'SpinnerService',
  'ExportService',
  'SchedulerService',
  function (
    $rootScope,
    $scope,
    AtsdrSubstancePriorityList,
    CompanyService,
    ModalService,
    SpinnerService,
    ExportService,
    SchedulerService
  ) {
    const vm = this;
    vm.startFrom = 0;
    vm.PER_PAGE = 100;
    vm.totalItems = 0;
    vm.reportData = [];
    vm.allReportData = [];
    vm.headers = ['name', 'substance', 'manufacturer', 'cas'];
    vm.selectedCompany = {};

    const getReportData = SpinnerService.wrap(async function (companyId) {
      const response = await AtsdrSubstancePriorityList.getSdsReportData({
        companyId
      }).$promise;

      vm.allReportData = response.data;
      vm.reportData = vm.allReportData.slice(0, vm.PER_PAGE);
      vm.totalItems = vm.allReportData.length;
      $scope.$apply();

      return response;
    });

    vm.openScheduleModal = function () {
      return ModalService.open(scheduleReport()).then(res =>
        createScheduledReport(res, $rootScope.companyId)
      );
    };

    vm.onCompanySelected = function (selectedCompany) {
      vm.selectedCompany = selectedCompany;
      vm.startFrom = 0;

      getReportData(selectedCompany.id);
    };

    vm.handlePaginate = function () {
      const offset = getOffset(vm.startFrom, vm.PER_PAGE);
      vm.reportData = vm.allReportData.slice(offset, offset + vm.PER_PAGE);
    };

    vm.handleExport = async function (type) {
      const getBinaryFunc = async () => {
        const response = await AtsdrSubstancePriorityList.export({
          companyId: vm.selectedCompany.id,
          type
        }).$promise;
        return response.data;
      }

      await ExportService.cbBinaryToFile(getBinaryFunc, type);
    };

    init();

    function init() {
      $scope.$watch('siteLanguage', function (newValue, oldValue) {
        if (newValue === oldValue) return;
        getReportData(vm.selectedCompany.id);
      });

      if (!$rootScope.checkIfUserIs('admin')) {
        CompanyService.getCurrentCompanyPromise().$promise.then(function () {
          if ($rootScope.currentCompany.childSdsCompanies.length) return; // handle this by companySelector

          vm.onCompanySelected({ id: $rootScope.companyId });
        });
      }
    }
    function createScheduledReport(formObj, companyId, params = {}) {
      params.lang = $rootScope.siteLanguage;
      return SchedulerService.createReport(
        SchedulerService.jobs.report.CERCLA_REPORT,
        companyId,
        formObj.emails,
        formObj.frequency,
        params
      );
    }    
  }
]);
