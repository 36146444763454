import app from '../app';

app.directive('customValidationMessage', [
  '$translate',
  '$rootScope',
  function ($translate, $rootScope) {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        if (attrs.ngRequired && !scope.$eval(attrs.ngRequired)) return;
        let currentLang;
        let translation;
        const setCustomValidityMessage = async function () {
          if (element[0].value === '') {
            const lang = $rootScope.siteLanguage;
            if (currentLang !== lang) {
              currentLang = lang;
              await $translate.use(lang);
              translation = await $translate('COMMON.MESSAGES.FILL_OUT_FIELD');
            }
            element[0].setCustomValidity(translation);
            element[0].title = translation;
          } else {
            element[0].setCustomValidity('');
            element[0].title = '';
          }
        };
        element.on('input', setCustomValidityMessage);

        $rootScope.$watch('siteLanguage', function () {
          setCustomValidityMessage();
        });

        scope.$on('$destroy', function () {
          element.off('input', setCustomValidityMessage);
        });
      }
    };
  }
]);
