import app from '../app';

app.service('LabelBatchService', [
  '$rootScope',
  'CompanyService',
  function ($rootScope, CompanyService) {
    const self = this;
    let printSettings = {};
    let labelBatchQueue = [];
    let labelBatchIsAvailable = null;

    this.toggleDocInLabelBatch = function (doc) {
      if (self.isDocAlreadyAdded(doc)) return self.removeFromLabelBatch(doc);

      doc.labelsQuantity = 1;
      labelBatchQueue = labelBatchQueue.concat(doc);
    };

    this.removeFromLabelBatch = function (doc) {
      labelBatchQueue = labelBatchQueue.filter(function (addedDoc) {
        return addedDoc.id !== doc.id;
      });

      return labelBatchQueue;
    };

    this.clearBatch = function () {
      labelBatchQueue = [];
    };

    this.getLabelBatch = function () {
      return labelBatchQueue;
    };

    this.getLabelsQuantity = function () {
      return labelBatchQueue.reduce(function (total, doc) {
        if (!doc.labelsQuantity) return total;

        return (total += doc.labelsQuantity);
      }, 0);
    };

    this.getLabelsQuantityByDoc = function () {
      return labelBatchQueue.reduce(function (obj, doc) {
        obj[getIdByLang(doc)] = doc.labelsQuantity;
        return obj;
      }, {});
    };

    this.getDocIds = function () {
      return labelBatchQueue.map(getIdByLang);
    };

    this.printSettings = function (settingName, value) {
      if (settingName && value != undefined) {
        printSettings[settingName] = value;

        return value;
      } else if (settingName && value == undefined) {
        return printSettings[settingName];
      } else {
        return printSettings;
      }
    };

    this.isTemplateSet = function () {
      return !!Object.keys(printSettings).length;
    };

    this.isDocAlreadyAdded = function (doc) {
      return labelBatchQueue.some(function (addedDoc) {
        return addedDoc.id === doc.id;
      });
    };

    $rootScope.$watch('$root.companySettings', function (newValue) {
      if (newValue && $rootScope.isLabelBatchAvailable == undefined) {
        CompanyService.getIPWhitelistedPromise().then(function () {
          $rootScope.isLabelBatchAvailable = function () {
            if (labelBatchIsAvailable === false) $rootScope.labelBatchMode = 'off';
            if (labelBatchIsAvailable != null) return labelBatchIsAvailable;

            const roleName = $rootScope.currentUser && $rootScope.currentUser.role;

            if ($rootScope.checkIfUserIs('admin') || !$rootScope.companySettings.labelBatch)
              return (labelBatchIsAvailable = false);

            if (!roleName && $rootScope.checkIfUserIs()) {
              return (labelBatchIsAvailable = $rootScope.companySettings.labelBatchAccess.some(
                function (role) {
                  return role === 'teamMember';
                }
              )); // if teamMember is in the list of allowed roles and IP is whitelisted then show section
            }

            return (labelBatchIsAvailable = $rootScope.companySettings.labelBatchAccess.some(
              function (role) {
                return role === roleName;
              }
            ));
          };
        });
      }
    });

    $rootScope.$watch('$root.currentUser', function (newValue, oldValue) {
      if ((newValue && newValue.role) !== (oldValue && oldValue.role)) {
        labelBatchIsAvailable = null;
      }
    });

    function getIdByLang(doc) {
      const lang = $rootScope.siteLanguage;

      return doc[lang + 'VersionId'] || doc.id;
    }
  }
]);
