import angular from 'angular';
import app from '../../app';

app.controller('quizCtrl', [
  '$scope',
  '$rootScope',
  'QuizService',
  'QRCode',
  'GoogleClassroomService',
  'MessagesService',
  '$translate',
  function (
    $scope,
    $rootScope,
    QuizService,
    QRCode,
    GoogleClassroomService,
    MessagesService,
    $translate
  ) {
    var vm = this;
    var companyId = $rootScope.companyId;
    var qrcodes = {};

    vm.quizzes = [];
    vm.handleAuthClick = GoogleClassroomService.handleAuthClick;
    vm.handleSignoutClick = GoogleClassroomService.handleSignoutClick;
    vm.isClassroomSignedIn = GoogleClassroomService.isSignedIn;
    vm.courses = [];
    vm.courseWorks = [];
    vm.selectedCourseID = 0;
    vm.selectedCourseWorkID = 0;

    $scope.showClassroomSpinner = false;

    vm.addQuestion = function (quiz) {
      quiz.questions = quiz.questions || [];

      var questionModel = {
        text: '',
        type: 'textarea'
      };

      quiz.questions.push(questionModel);
    };

    vm.addAnswer = function (question, isInitial) {
      question.answers = question.answers || [];

      if (isInitial && question.answers.length) return;

      var answerModel = {
        text: ''
      };

      question.answers.push(answerModel);
    };

    vm.removeFromArr = function (arr, index) {
      arr.splice(index, 1);
    };

    vm.selectQuizToUpdate = function (newQuiz, srcQuiz) {
      if (srcQuiz.courseID && srcQuiz.courseWorkID) {
        vm.selectedCourseID = srcQuiz.courseID;
        vm.selectedCourseWorkID = srcQuiz.courseWorkID;
      } else {
        vm.selectedCourseID = 0;
        vm.selectedCourseWorkID = 0;
      }

      angular.extend(newQuiz, srcQuiz);
    };

    vm.cloneQuiz = function (newQuiz, srcQuiz) {
      srcQuiz = angular.copy(srcQuiz);

      delete srcQuiz.id;
      delete newQuiz.id;

      srcQuiz.title += ' clone';

      vm.selectQuizToUpdate(newQuiz, srcQuiz);
    };

    vm.saveQuiz = function (quiz) {
      if (!validateSubmitOptions(quiz)) return;

      if (!isQuizTitleUnique(quiz)) return MessagesService.warning('QUIZ.QUIZ_EXISTS');

      quiz.companyId = companyId;

      if (quiz.submitBy.gClassroom && vm.selectedCourseID) {
        quiz.courseID = vm.selectedCourseID;
        quiz.courseWorkID = vm.selectedCourseWorkID;
      } else if (quiz.submitBy.gClassroom && !vm.isClassroomSignedIn()) {
        return MessagesService.warning('QUIZ.GCLASSROOM_SIGNIN');
      } else {
        delete quiz.courseID;
        delete quiz.courseWorkID;
      }

      if (quiz.courseWorkID == 0) {
        GoogleClassroomService.createCourseWork(vm.selectedCourseID, quiz.title).then(
          function (response) {
            quiz.courseWorkID = response.result.id;

            getCourseWorks(vm.selectedCourseID);
            saveQuizToDB(quiz);
          },
          function (err) {
            console.error(err);
            MessagesService.error(err.result.error.message);
          }
        );
      } else {
        saveQuizToDB(quiz);
      }
    };

    vm.clearFields = function (quiz) {
      Object.keys(quiz).forEach(function (key) {
        delete quiz[key];
      });

      // make the "print" option active by default
      quiz.submitBy = {};
      quiz.submitBy.print = true;
    };

    vm.getQuizzes = function () {
      var where = {
        companyId: companyId
      };

      return QuizService.find(where).then(
        function (quizzes) {
          vm.quizzes = quizzes;
        },
        function (err) {
          console.error(err);
          MessagesService.error();
        }
      );
    };

    vm.deleteQuiz = function (quiz) {
      var id = quiz.id;

      QuizService.deleteById(id).then(function (res) {
        vm.getQuizzes();

        if (quiz.qrcode && quiz.qrcode.length) {
          quiz.qrcode.forEach(function (qrcode) {
            removeQuizFromQrCodes(qrcodes[qrcode], quiz.id);
          });
        }
      });
    };

    vm.onCompanySelected = function (selectedCompany) {
      companyId = selectedCompany.id;

      if (
        !$rootScope.checkIfUserIs('admin') &&
        selectedCompany.settings &&
        !$rootScope.isModuleEnabled($rootScope.moduleNames.hse, false, selectedCompany.settings)
      ) {
        return MessagesService.warning('SETTINGS.MODULE_REQUIRED', {
          moduleName: $rootScope.moduleNames.hse
        });
      }

      return vm.getQuizzes();
    };

    vm.onCourseSelected = function (courseId) {
      if (courseId == 0) return;

      getCourseWorks(courseId);
    };

    if ($rootScope.checkIfUserIs(['teamAdmin', 'teacher'])) {
      companyId = $rootScope.currentUser.companyId;

      vm.getQuizzes();
    }

    GoogleClassroomService.handleClientLoad($scope, classroomSignedIn, classroomSignedOut);

    QRCode.find(
      {},
      function (qrs) {
        qrs.forEach(function (qr) {
          qrcodes[qr.id] = qr;
        });
      },
      function (err) {
        console.error('ERROR: ', err);
        MessagesService.error();
      }
    );

    function saveQuizToDB(quiz) {
      QuizService.upsert(quiz).then(
        function (res) {
          vm.clearFields(quiz);
          vm.getQuizzes();

          MessagesService.success('QUIZ.QUIZ_CREATED');
        },
        function (err) {
          console.error(err);
          MessagesService.error();
        }
      );
    }

    function classroomSignedIn() {
      getCourses();
    }

    function classroomSignedOut() {
      vm.courses = vm.courseWorks = [];
    }

    function removeQuizFromQrCodes(qrCode, quizId) {
      if (!qrCode.quizzes[quizId]) return;

      delete qrCode.quizzes[quizId];

      QRCode.upsert(qrCode);
    }

    function validateSubmitOptions(quiz) {
      if (!quiz.submitBy) return false;

      return Object.keys(quiz.submitBy).some(function (submitOption) {
        return quiz.submitBy[submitOption];
      });
    }

    function getCourses() {
      vm.noCourses = false;

      GoogleClassroomService.listCourses().then(function (response) {
        var courses = response.result.courses;

        if (courses) {
          vm.courses = courses;
        } else {
          vm.noCourses = true;
        }
      });
    }

    function getCourseWorks(courseId) {
      var createCourseWorkMessage = '';

      $translate('QUIZ.CREATE_NEW_COURSEWORK').then(function (message) {
        createCourseWorkMessage = message;
      });

      GoogleClassroomService.listCourseWorks(courseId).then(function (response) {
        var courseWorks = response.result.courseWork;
        var associatedWithDeveloperCourseWorks = [];
        vm.courseWorks = [];

        if (courseWorks && courseWorks.length) {
          associatedWithDeveloperCourseWorks = courseWorks.filter(function (courseWork) {
            return courseWork.associatedWithDeveloper;
          });
        }

        vm.courseWorks = vm.courseWorks.concat(associatedWithDeveloperCourseWorks, {
          title: createCourseWorkMessage,
          id: 0
        });
        vm.selectedCourseWorkID = vm.courseWorks[0].id;
      });
    }

    function isQuizTitleUnique(quiz) {
      if (!vm.quizzes || (vm.quizzes && !vm.quizzes.length)) return true;

      return !vm.quizzes.some(function (q) {
        return quiz.title === q.title && quiz.id !== q.id;
      });
    }
  }
]);
