import { waitForProp } from '../../helpers';

export default function downloadNotification(action) {
  return {
    templateUrl: require('./downloadNotification.html'),
    keyboard: true,
    backdrop: true,
    controller: [
      '$uibModalInstance',
      function ($uibModalInstance) {
        const vm = this;
        vm.email = '';
        vm.dismiss = $uibModalInstance.dismiss;
        vm.action = action;
        vm.doubleSidedPrinting = false;
        vm.addContentTableToPrinting = false;
        vm.printOnlyUpdated = false;
        vm.showPrintingOption = vm.action === 'print';
        vm.rangePickerApi = null;

        vm.proceed = function () {
          $uibModalInstance.close({
            email: vm.email,
            doubleSidedPrinting: vm.doubleSidedPrinting,
            addContentTableToPrinting: vm.addContentTableToPrinting,
            printOnlyUpdated: vm.printOnlyUpdated,
            printOnlyUpdatedLimit: vm.printOnlyUpdatedLimit || vm.rangePickerApi && vm.rangePickerApi.getEvalDate()
          });
        };
        vm.changeDate = function (printOnlyUpdatedLimit) {
          vm.printOnlyUpdatedLimit = printOnlyUpdatedLimit;
        };
      }
    ],
    controllerAs: 'ctrl'
  };
}
