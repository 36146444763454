import app from '../app';

app.service('MessagesService', [
  'toastr',
  '$translate',
  function (toastr, $translate) {
    /**
     *
     *
     * @param {string} message | a translate property, e.g. 'COMMON.MESSAGES.UPDATED'
     * @param {any} interpolateParams | an object with a translate parameter, e.g. {name: 'Name'}
     * @returns {Promise}
     */
    this.success = function (message, interpolateParams, toastrOptions) {
      const translateStrings = ['COMMON.MESSAGES.SUCCESS'];
      toastrOptions = toastrOptions || {};

      if (message) translateStrings.push(message);

      return $translate(translateStrings, interpolateParams).then(function (
        translations
      ) {
        toastr.success(translations[message], translations['COMMON.MESSAGES.SUCCESS'], toastrOptions);
      });
    };

    this.warning = function (message, interpolateParams, toastrOptions) {
      toastrOptions = toastrOptions || {};
      if (!message) return console.error('this method needs a message');

      return $translate(['COMMON.MESSAGES.WARNING', message], interpolateParams).then(function (
        translations
      ) {
        toastr.warning(translations[message], translations['COMMON.MESSAGES.WARNING'], toastrOptions);
      });
    };

    this.error = function (message, interpolateParams, toastrOptions) {
      toastrOptions = toastrOptions || {};
      message = message || 'COMMON.MESSAGES.SOMETHING_WRONG';

      return $translate(['COMMON.MESSAGES.ERROR', message], interpolateParams).then(function (
        translations
      ) {
        toastr.error(translations[message], translations['COMMON.MESSAGES.ERROR'], toastrOptions);
      });
    };

    this.info = function (message, interpolateParams, toastrOptions) {
      toastrOptions = toastrOptions || {};
      if (!message) return console.error('this method needs a message');
      $translate([message], interpolateParams).then(function (translations) {
        toastr.info(translations[message], '', toastrOptions);
      });
    };
  }
]);
