import app from '../app';

app.directive('sourceJuggler', [
  '$http', '$rootScope',
  function ($http, $rootScope) {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        var alreadyJuggled = false;
        var src = attrs.ngSrc || attrs.ngHref;
        var filenameRegex = /[^\\/]*\.\w+$/;
        var containerRegex = /api\/containers\/([^\\/]+)/;
        var filename = filenameRegex.test(src) && src.match(filenameRegex)[0];
        var container = containerRegex.test(src) && src.match(containerRegex)[1];

        // temp IE fix
        if ($rootScope.isIe()) {
          setTimeout(function () {
            attrs.$set(
              'ngSrc',
              'https://s3.ca-central-1.amazonaws.com/canadasds/' + container + '/' + filename
            );
          }, 50);

          return;
        }

        if (element.is('iframe')) {
          $http.get(src).catch(function (err) {
            if (alreadyJuggled) return;

            attrs.$set(
              'ngSrc',
              'https://s3.ca-central-1.amazonaws.com/canadasds/' + container + '/' + filename
            );
            fileReplaced(err);

            alreadyJuggled = true;
          });
        } else if (element.is('a') && filenameRegex.test(src)) {
          $http.get(src).catch(function (err) {
            if (alreadyJuggled) return;

            attrs.$set(
              'ngHref',
              'https://s3.ca-central-1.amazonaws.com/canadasds/' + container + '/' + filename
            );
            fileReplaced(err);

            alreadyJuggled = true;
          });
        } else {
          element.bind('error', function (event) {
            if (alreadyJuggled) return;

            attrs.$set(
              'ngSrc',
              'https://s3.ca-central-1.amazonaws.com/canadasds/' + container + '/' + filename
            );
            fileReplaced(event);

            alreadyJuggled = true;
          });
        }
      }
    };
  }
]);

function fileReplaced(err) {
  console.warn('Local file not found. Replaced from S3.', err);
}
