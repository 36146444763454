import app from '../../../app';

app.directive('pinnedTag', [
  function () {
    return {
      restrict: 'E',
      scope: {
        pinnedTag: '=',
        onSelect: '='
      },
      templateUrl: require('./pinnedTag.html'),
      link: function (scope) {
        scope.filterObj = {};
        scope.selectTag = function (tag) {
          scope.onSelect(tag);
        };
        scope.filterTag = function (tag) {
          let result =
            tag.amount &&
            tag.title.toLowerCase().includes((scope.filterObj.filter || '').toLowerCase());

          if (tag.tags && tag.tags.length) result = tag.tags.some(tag => scope.filterTag(tag));

          return result;
        };
      }
    };
  }
]);
