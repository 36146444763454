import app from '../app';

app.service('MeasureService', function (Measure, ErrorService) {
  const simpleCatch = ErrorService.simpleCatch;
  let getMeasureUnitsPromise = null;

  this.measureUnits = [];

  this.getMeasureUnits = function (force = false) {
    if (!force && this.measureUnits.length) return Promise.resolve(this.measureUnits);
    if (!force && getMeasureUnitsPromise) return getMeasureUnitsPromise;

    getMeasureUnitsPromise = Measure.find()
      .$promise.then(measures => (this.measureUnits = measures))
      .catch(simpleCatch);

    return getMeasureUnitsPromise;
  };
});
