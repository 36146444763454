import app from '../../app';
import attrsModal from '../../modals/attrsModal';
import { get } from 'lodash';

app.directive('attrsPanel', function (
  $rootScope,
  SolrDocument,
  ModalService,
  SpinnerService,
  CompanySetting,
  ErrorService,
  CompanyService
) {
  return {
    restrict: 'E',
    scope: {
      id: '=',
      companyId: '=',
      editable: '=',
      documentCompanies: '='
    },
    templateUrl: require('./attrsPanel.html'),
    link: function (scope) {
      const getCompanyAttrs = SpinnerService.wrap(function () {
        return SolrDocument.getCompanyAttrs({
          id: scope.id,
          companyId: scope.companyId,
          includeAllAtrrs:
            $rootScope.checkIfUserIs(['admin']) || $rootScope.accessModifyCustomFieldData()
        }).$promise.then(function (resp) {
          scope.attrs = resp.attrs;
        });
      });
      scope.attrs = {};
      scope.available = {};
      scope.documentCompanies = scope.documentCompanies || [];
      scope.getAttrLangName = CompanyService.getAttrLangName;

      scope.isCompanyInSds = function () {
        const docCompanyIds = scope.documentCompanies.map(company => company.id);

        return (docCompanyIds || []).some(function (docCompanyId) {
          return docCompanyId === scope.companyId;
        });
      };
      scope.hasAttrs = function (attrs) {
        if (!attrs) return false;

        return !!Object.keys(attrs).length;
      };

      if (!scope.companyId) return;

      scope.$watch('id', getCompanyAttrs);

      CompanySetting.getAttrs({
        id: scope.companyId
      })
        .$promise.then(function (resp) {
          return (scope.available = resp.attrs);
        })
        .catch(ErrorService.simpleCatch);
      scope.edit = function () {
        return ModalService.open(
          attrsModal({
            available: scope.available,
            enabled: scope.attrs,
            companyId: scope.companyId,
            sdsId: scope.id
          })
        )
          .then(
            SpinnerService.wrap(function (attrs) {
              return SolrDocument.setCompanyAttrs({
                id: scope.id,
                companyId: scope.companyId,
                attrs: attrs
              }).$promise;
            })
          )
          .then(function (resp) {
            scope.attrs = resp.attrs;
          });
      };
    }
  };
});
