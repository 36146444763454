import app from '../../app';

app.controller('cersTestCtrl', [
  '$scope',
  '$state',
  'SolrDocument',
  'SpinnerService',
  function cersTestCtrl($scope, $state, SolrDocument, SpinnerService) {
    const vm = this;
    vm.columnName = 'column';
    let ids = [];
    vm.docs = [];

    ids = $state.params.ids
      .split(' OR ')
      .filter(function (el) {
        return el.trim();
      })
      .map(function (el) {
        return {
          id: el,
          additionalData: {}
        };
      });
    vm.testCERS = SpinnerService.wrap(function (params, append) {
      const query = {
        query:
          'hazards_identification: Signal word OR product_and_company_identification: Signal word',
        filters: ['language:"en"', '-isArchived:true', 'isVerified:true'],
        fields:
          'physical_chemical_properties, id, chemicalFriendlyName, hazards_identification, product_and_company_identification',
        limit: 999999,
        offset: 0
      };

      if (ids.length > 0) {
        query.filters.push(
          ids
            .map(function (el) {
              return 'id:' + el.id;
            })
            .join(' OR ')
        );
      }

      ids = $state.params.ids.split(' OR ').map(function (el) {
        return {
          id: el,
          additionalData: {}
        };
      });

      const options = {
        ids: ids,
        start: 0,
        rows: 2147483647,
        detailedResponse: true
      };

      return SolrDocument.CERS(options).$promise.then(function (resp) {
        vm.docs = resp;
        return resp;
      });
    });
    if (ids) {
      vm.testCERS();
    }

    vm.getTitle = function (fireCodeHazardClass) {
      return JSON.stringify(fireCodeHazardClass.testConclusion.result);
    };
  }
]);
