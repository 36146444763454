import app from '../app';

app.filter('getCompanyNameAggregate', [
  '$rootScope',
  function ($rootScope) {
    return function (doc) {
      return doc._id.companyName;
    };
  }
]);
