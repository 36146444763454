import angular from 'angular';
import app from '../../app';

app
  .controller('createSupportTicketCtrl', [
    '$rootScope',
    '$http',
    '$translate',
    'LoopBackResource',
    'CompanyService',
    'SpinnerService',
    'MessagesService',
    'ErrorService',
    'SupportTicket',
    function createSupportTicketCtrl(
      $rootScope,
      $http,
      $translate,
      LoopBackResource,
      CompanyService,
      SpinnerService,
      MessagesService,
      ErrorService,
      SupportTicket
    ) {
      const simpleCatch = ErrorService.simpleCatch;
      const vm = this;
      
      vm.formModel = {};
      vm.state = null;

      vm.onSubmit = SpinnerService.wrap(function () {
        return SupportTicket.create({
          userId: $rootScope.currentUser && $rootScope.currentUser.id,
          ...vm.formModel
        }).$promise
          .then(function (res) {
            MessagesService.success();
            vm.state = 'SENT';
          })
          .catch(simpleCatch);
      });

      vm.resetForm = function () {
        vm.formModel = {};
        vm.state = null;
      };
    }
  ]);