import app from '../../app';

app.directive('importSheetJs', [SheetJSImportDirective]);

function SheetJSImportDirective() {
  return {
    scope: { data: '=', sheets: '=' },
    restrict: 'A',
    link: function ($scope, $elm) {
      $elm.on('change', function (changeEvent) {
        const reader = new FileReader();

        const readAsBinaryString = function (fileData) {
          reader.onload = function (e) {
            $scope.$emit('onLoadCERSData', { result: reader.result, name: fileData.name });
          };

          if (fileData) reader.readAsArrayBuffer(fileData);
          else $scope.$emit('onLoadCERSData', {});
        };

        const file = changeEvent.originalEvent.droppedFile || changeEvent.target.files[0];
        readAsBinaryString(file);
      });
    }
  };
}
