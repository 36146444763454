import app from '../../app';

app.directive('docKit', [
  function () {
    return {
      restrict: 'E',
      scope: {
        kitName: '='
      },
      templateUrl: require('./docKit.html'),
      link: function (scope) {
        scope.showKitName = function () {
          return scope.kitName && scope.kitName !== 'undefined';
        };
      }
    };
  }
]);
