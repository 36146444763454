import app from '../../app';

app.directive('sdsAttachments', [
  '$rootScope',
  'SpinnerService',
  'Attachment',
  'AttachmentService',
  function ($rootScope, SpinnerService, Attachment, AttachmentService) {
    return {
      restrict: 'E',
      scope: {
        attachments: '=',
        companySelection: '=',
        companies: '='
      },
      templateUrl: require('./sdsAttachments.html'),
      link: function ($scope) {
        $scope.$on('deleteAttachment', function (event, attachment) {
          deleteAttachment(attachment);
        });
        $scope.accessAddAttachments = $rootScope.accessAddAttachments();

        const deleteAttachment = SpinnerService.wrap(function (attachment) {
          let company;
          attachment.companies.some(function (el) {
            if (el.id === $rootScope.companyId || !el.id) {
              company = el;
              return true;
            }
            return false;
          });

          return Attachment.updateForCompany({
            id: attachment.id,
            company: {
              id: company.id,
              destroy: true
            }
          }).$promise.then(function (res) {
            if (res.updated) {
              attachment.companies = attachment.companies.filter(function (el) {
                return el.id !== company.id;
              });
              $scope.$emit('attachmentsChange');
              return;
            }
          });
        });

        if ($scope.companySelection) {
          $scope.companies = [{ name: 'Global', id: null }].concat(
            $scope.companySelection.map(function (el) {
              return $scope.companies[el];
            })
          );
        }

        $scope.attachments.forEach(function (el) {
          AttachmentService.defineTitle(el);
        });
        $scope.AttachmentService = AttachmentService;
      }
    };
  }
]);
