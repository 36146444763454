import app from '../app';

app.factory('ProcessingTemplates', function ($cookies) {
  var ProcessingTemplates = function () {
    this.processingTemplates = [
      'Default',
      'Ward\'s Science',
      'Flinn Scientific CDN',
      'Flinn Scientific US',
      'Fisher Scientific'
    ];

    this.selectedTemplate = $cookies.get('processingTemplate') || 'Default';

    this.onTemplateSelected = function () {
      var expireDate = new Date();
      expireDate.setFullYear(expireDate.getFullYear() + 1);
      $cookies.put('processingTemplate', this.selectedTemplate, {
        expires: expireDate
      });
    };

    this.fisherScientific = {
      labels: [
        /^(CAS-No)/,
        /^(Synonyms)/,
        /^(Recommended Use)/,
        /^(Uses advised against)/,
        /^(WHMIS 2015 Classification)/,
        /^(Flammable liquids)/,
        /^(Skin Corrosion\s*\/\s*irritation)/,
        /^(Serious Eye Damage\s*\/\s*Eye Irritation)/,
        /^(General Advice)/,
        /^(Eye Contact)/,
        /^(Skin Contact)/,
        /^(Inhalation)/,
        /^(Ingestion)/,
        /^(Most important symptoms\s*\/\s*effects)/,
        /^(Notes to Physician)/,
        /^(Suitable Extinguishing Media)/,
        /^(Unsuitable Extinguishing Media)/,
        /^(Flash Point)/,
        /^(Method -)/,
        /^(Autoignition Temperature)/,
        /^(Upper)/,
        /^(Lower)/,
        /^(Sensitivity to Mechanical Impact)/,
        /^(Sensitivity to Static Discharge)/,
        /^(Personal Precautions)/,
        /^(Environmental Precautions)/,
        /^(Methods for Containment and Clean)/,
        /^(Handling)/,
        /^(Storage )/,
        /^(Eye Protection)/,
        /^(Hand Protection)/,
        /^(Physical State)/,
        /^(Appearance)/,
        /^(Odor)/,
        /^(Odor Threshold)/,
        /^(pH)/,
        /^(Melting Point\s*\/\s*Range)/,
        /^(Boiling Point\s*\/\s*Range)/,
        /^(Flash Point)/,
        /^(Evaporation Rate)/,
        /^(Flammability \(solid,\s*gas\))/,
        /^(Upper)/,
        /^(Lower)/,
        /^(Vapor Pressure)/,
        /^(Vapor Density)/,
        /^(Specific Gravity)/,
        /^(Solubility)/,
        /^(Partition coefficient;\s*n-octanol\s*\/\s*water)/,
        /^(Autoignition Temperature)/,
        /^(Decomposition Temperature)/,
        /^(Viscosity)/,
        /^(Molecular Formula)/,
        /^(Molecular Weight)/,
        /^(Reactive Hazard)/,
        /^(Stability)/,
        /^(Conditions to Avoid)/,
        /^(Incompatible Materials)/,
        /^(Hazardous Decomposition Products)/,
        /^(Hazardous Polymerization)/,
        /^(Hazardous Reactions)/,
        /^(Toxicologically Synergistic)/,
        /^(Irritation)/,
        /^(Sensitization)/,
        /^(Carcinogenicity)/,
        /^(Mutagenic Effects)/,
        /^(Reproductive Effects)/,
        /^(Developmental Effects)/,
        /^(Teratogenicity)/,
        /^(STOT - single exposure)/,
        /^(STOT - repeated exposure)/,
        /^(Aspiration hazard)/,
        /^(Symptoms\s*\/\s*effects,\s*both acute and)/,
        /^(Endocrine Disruptor Information)/,
        /^(Other Adverse Effects)/,
        /^(Persistence and Degradability)/,
        /^(Bioaccumulation\s*\/\s*Accumulation)/,
        /^(Mobility)/,
        /^(Waste Disposal Methods)/,
        /^(UN-No)/,
        /^(Proper Shipping Name)/,
        /^(Hazard Class)/,
        /^(Subsidiary Hazard Class)/,
        /^(Packing Group)/,
        /^(Prepared By)/,
        /^(Creation Date)/,
        /^(Revision Date)/,
        /^(Print Date)/,
        /^(Revision Summary)/
      ],
      bolds: [
        /^(Company\s*)/,
        /^(Manufacturer)/,
        /^(Importer\s*\/\s*Distributor)/,
        /^(Emergency Telephone Number)/,
        /^(Classification\s*)/,
        /^(Label Elements\s*)/,
        /^(Signal Word)/,
        /^(Hazard Statements)/,
        /^(Precautionary Statements)/,
        /^(Prevention)/,
        /^(Response)/,
        /^(Storage)$/,
        /^(Disposal)/,
        /^(Explosion Limits)/,
        /^(Specific Hazards Arising from the Chemical)/,
        /^(Hazardous Combustion Products)/,
        /^(Protective Equipment and Precautions for Firefighters)/,
        /^(NFPA\s*)/,
        /^(Exposure Guidelines\s*)/,
        /^(Engineering Measures)/,
        /^(Personal protective equipment\s*)/,
        /^(Respiratory Protection)/,
        /^(Environmental exposure controls)/,
        /^(Hygiene Measures)/,
        /^(Acute Toxicity\s*)/,
        /^(Product Information)/,
        /^(Component Information)/,
        /^(Products)/,
        /^(Ecotoxicity\s*)/,
        /^(DOT\s*)/,
        /^(TDG\s*)/,
        /^(IATA\s*)/,
        /^(IMDG\s*\/\s*IMO\s*)/,
        /^(International Inventories)/,
        /^(Disclaimer)/
      ],
      skipText: ['Up', 'delayed']
    };
  };

  return ProcessingTemplates;
});

app.service('ProcessingService', [
  'ProcessingTemplate',
  'ErrorService',
  'MessagesService',
  function (ProcessingTemplate, ErrorService, MessagesService) {
    const self = this;
    const simpleCatch = ErrorService.simpleCatch;

    this.templates = null;

    this.getTemplates = function () {
      return ProcessingTemplate.find()
        .$promise.then(function (templates) {
          self.templates = templates;

          return self.templates;
        })
        .catch(simpleCatch);
    };

    this.upsertTemplate = function (id, template) {
      const data = {
        title: template.title,
        selections: template.selections
      };

      if (id) data.id = id;

      return ProcessingTemplate.upsert(data)
        .$promise.then(function (upsertedTemplate) {
          MessagesService.success();

          self.templates = self.templates
            .filter(function (template) {
              return template.id !== upsertedTemplate.id;
            })
            .concat(upsertedTemplate);

          return self.templates;
        })
        .catch(simpleCatch);
    };

    this.deleteTemplate = function (template) {
      return ProcessingTemplate.deleteById({ id: template.id })
        .$promise.then(function () {
          MessagesService.success();

          self.templates = self.templates.filter(function (t) {
            return t.id !== template.id;
          });

          return self.templates;
        })
        .catch(simpleCatch);
    };
  }
]);
