import app from '../../app';

app
  .controller('printoutCtrl', [
    '$rootScope',
    '$state',
    '$location',
    'docs',
    'LabelBatchService',
    printoutCtrl
  ]);

function printoutCtrl($rootScope, $state, $location, docs, LabelBatchService) {
  const vm = this;
  const lbs = LabelBatchService;

  if (!docs.length) $location.url('/');

  vm.docs = docs;
  vm.isTemplateSet = lbs.isTemplateSet;

  vm.removeFromLabelBatch = function (doc) {
    vm.docs = lbs.removeFromLabelBatch(doc);
  };

  vm.prepareTemplate = function () {
    $state.go('labelBatchMaker', {
      ids: lbs.getDocIds(),
      state: getState()
    });
  };

  vm.clearBatch = function () {
    vm.docs = [];
    lbs.clearBatch();
  };

  vm.printDocument = function (form) {
    form.commit({
      url: getUrl(),
      state: getState(),
      currentUser: getCurrentUser(),
      printOpts: '{"pageRanges": ""}'
    });
  };

  function getUrl() {
    const location = window.location;

    // NOTE: presence of query string messes things up
    return (
      location.protocol +
      '//' +
      location.hostname +
      '/labelBatchMaker/' +
      lbs.getDocIds()
    );
  }

  function getState() {
    const state = {
      selectedTemplate: lbs.printSettings('selectedTemplate'),
      borderWidth: lbs.printSettings('borderWidth'),
      addNJCASToLabel: lbs.printSettings('addNJCASToLabel'),
      showNJLabels: lbs.printSettings('showNJLabels'),
      borderColor: lbs.printSettings('borderColor'),
      labelTemplate: lbs.printSettings('labelTemplate'),
      qrcode: lbs.printSettings('qrcode'),
      qrcodeSize: lbs.printSettings('qrcodeSize'),
      borderStyle: lbs.printSettings('borderStyle'),
      showManu: lbs.printSettings('showManu'),
      showStorageCode: lbs.printSettings('showStorageCode'),
      showDisposalCode: lbs.printSettings('showDisposalCode'),
      labelStyle: lbs.printSettings('labelStyle'),
      additionalInformation: lbs.printSettings('additionalInformation'),
      labelsQuantity: lbs.getLabelsQuantityByDoc(),
      customFields: lbs.printSettings('customFields'),
      printQrCodeOnly: lbs.printSettings('printQrCodeOnly'),
      showPPE: lbs.printSettings('showPPE')
    };
    // for (let index = 0; index < vm.codes.customs.length; index++) {
    //   const element = vm.codes.customs[index];
    //   state['showCustomFields' + index] = vm['showCustomFields' + index] || false;

    // }
    return JSON.stringify(state);
  }

  function getCurrentUser() {
    return $rootScope.currentUser
      ? JSON.stringify($rootScope.currentUser)
      : null;
  }
}
