import app from '../../app';
import { createTree } from '../../helpers';

const checkTypeEnums = {
  checkbox: 'checkbox',
  radio: 'radio'
};

app.directive('tagsView', [
  'CompanyService',
  function (CompanyService) {
    return {
      restrict: 'E',
      templateUrl: require('./tagsView.html'),
      scope: {
        tcollapsed: '=',
        companyId: '=',
        tags: '=',
        showRestrictedTag: '=',
        showArchivedTag: '=',
        onChange: '&',
        checkType: '=',
        allowSearchByParentTags: '=',
        disabledTags: '='
      },
      link: function (scope) {
        scope.checkType = checkTypeEnums[scope.checkType] || checkTypeEnums.checkbox;

        const structurize = function (flatTags) {
          const tags = createTree(flatTags, 'tagId', 'tags');

          return tags.filter(function (tag) {
            if (!scope.showRestrictedTag && tag.restricted) return false;
            if (!scope.showArchivedTag && tag.archived) return false;

            return true;
          });
        };
        const allowSearchByParentTags =
          scope.allowSearchByParentTags != null ? scope.allowSearchByParentTags : true;

        scope.searchByParentTags = false;
        scope.$watch('tags', function (tags = []) {
          for (const disabledTag of scope.disabledTags || []) {
            for (const tag of tags) {
              if (tag.disabled) continue;

              tag.disabled = tag.id === disabledTag.id || tag.id === disabledTag;
            }
          }

          scope.flatTags = tags;
          scope.tagsTree = structurize(tags);

          for (const tag of tags) {
            checkParentTagIfAllChildrenAreSelected(tag);
          }
        });
        scope.$watch('tcollapsed', function (tcollapsed) {
          scope.tcollapsed = tcollapsed;
        });
        scope.$watch('companyId', function (companyId) {
          scope.companyId = companyId;

          CompanyService.CompanySettings(companyId, false).$promise.then(settings => {
            scope.searchByParentTags = allowSearchByParentTags && settings.searchByParentTags;
          });
        });
        scope.onChangeTagState = function (tag, parentTag) {
          checkParentTagIfAllChildrenAreSelected(tag, parentTag);
          scope.onChange({ tag });
        };
        scope.toggleParentTag = function (parentTag, originalParent) {
          for (let tag of parentTag.tags) {
            if (parentTag.parentChecked != null) {
              if (tag.tags && tag.tags.length) {
                tag.parentChecked = parentTag.parentChecked;

                scope.toggleParentTag(tag, originalParent);
              } else {
                tag.checked = parentTag.parentChecked;

                scope.onChangeTagState(tag, originalParent);
              }
            } else {
              if (tag.tags && tag.tags.length) {
                scope.toggleParentTag(tag);
              } else {
                tag.checked = parentTag.checked;
                tag.disabled = parentTag.checked;
              }
            }
          }
        };

        function checkParentTagIfAllChildrenAreSelected(tag, originalParent) {
          let parentTag = scope.flatTags.find(t => t.id.toString() === tag.tagId.toString());

          if (!parentTag) return;

          const childrenWhichAreNotParents = parentTag.tags.filter(t => !t.tags || !t.tags.length);
          const childrenWhichAreParents = parentTag.tags.filter(t => t.tags && t.tags.length);

          if (parentTag !== originalParent) {
            parentTag.parentChecked =
              childrenWhichAreNotParents.every(t => t.checked) &&
              childrenWhichAreParents.every(t => t.parentChecked);
          }

          if (parentTag.tagId) {
            checkParentTagIfAllChildrenAreSelected(parentTag, originalParent);
          }
        }
      }
    };
  }
]);
