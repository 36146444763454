import app from '../../app';

app.directive('nfpaDiamond', [
  '$rootScope',
  function (
    $rootScope
  ) {
    return {
      restrict: 'E',
      scope: {
        docNfpa: '='
      },
      templateUrl: require('./nfpaDiamond.html'),
      link: function (scope) {
        scope.loaded = false;
        scope.showValues = () => {
          scope.loaded = true;
        };
        scope.nfpa = (scope.docNfpa || []).reduce((all, el) => {
          let value = el.split('_');
          all[value[0]] = value[1];

          return all;
        }, {});
        
        scope.specialClass = '';
        switch (scope.nfpa.special) {
          case 'radioactive': 
            scope.specialClass = 'nfpa-object-special-radiocative';
            scope.nfpa.special = '';
            break;
          case 'waterReactive': 
            scope.specialClass = 'nfpa-object-special-water';
            scope.nfpa.special = 'W';
            break;
          case 'oxidizer': 
            scope.nfpa.special = 'OXY';
            break;
          case 'corrosive': 
            scope.nfpa.special = 'COR';
            break;
          default: scope.nfpa.special = scope.nfpa.special || '';
        }
      }
    };
  }
]);
