import app from '../../app';

app.directive('searchResults', function () {
  return {
    restrict: 'E',
    scope: {
      qty: '='
    },
    templateUrl: require('./searchResults.html'),
    link: function (scope) {
      scope.$watch('qty', function (qty) {
        scope.s = qty == 1 ? '' : 's';
      });
    }
  };
});
