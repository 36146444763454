import app from '../../app';

app.controller('newVersionCtrl', [
  '$uibModalInstance',
  'document',
  '$filter',
  'FileUploader',
  '$rootScope',
  'Container',
  function ($uibModalInstance, document, $filter, FileUploader, $rootScope, Container) {
    const vm = this;
    vm.curr = document;
    vm.close = function () {
      $uibModalInstance.dismiss('close');
    };

    vm.uploader = new FileUploader({
      url: '/api/containers/upload/upload?access_token=' + $rootScope.currentUser.tokenId
    });
    vm.uploader.filters.push({
      name: 'accept only pdfs',
      fn: function (item) {
        return /\.pdf$/i.test(item.name);
      }
    });
    vm.uploader.onAfterAddingFile = function (item) {
      item.checking = true;
      return Container.fileExists({
        container: 'sds',
        // fileExists decodes the file
        file: encodeURIComponent(item.file.name)
      }).$promise.then(function (resp) {
        item.checking = false;
        item.exists = resp.exists;
        if (resp.exists) {
          item.error = 'File with this name already exists';
          item.cancel();
        }
      });
    };
    vm.uploader.onErrorItem = function (item, response, status, headers) {
      item.error = response.error.message;
    };
    vm.uploader.onSuccessItem = function (item, response, status, headers) {
      $uibModalInstance.close(item.file.name);
    };

    vm.getReady = function () {
      return vm.uploader.queue.filter(function (itm) {
        return !itm.exists && (!itm.isUploaded || !itm.isSuccess);
      });
    };
    vm.hasReady = function () {
      return !!vm.getReady().length;
    };
    vm.upload = function () {
      const itms = vm.getReady();
      return itms.forEach(function (itm) {
        itm.error = null;
        itm.upload();
      });
    };
  }
]);
