import app from '../../app';

app.directive('radioSwitcher', [
  function () {
    return {
      restrict: 'E',
      scope: {
        name: '=',
        currentValue: '=',
        values: '=',
        translations: '=',
        onChange: '='
      },
      templateUrl: require('./radioSwitcher.html'),
      link: function (scope) {
        scope.handleChange = function () {
          scope.onChange && scope.onChange(scope.currentValue);
        };
      }
    };
  }
]);
