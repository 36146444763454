import app from '../../app';

app.directive('docSynonyms', [
  function () {
    return {
      restrict: 'E',
      scope: {
        matched: '='
      },
      templateUrl: require('./docSynonyms.html')
    };
  }
]);
