import angular from 'angular';
import uiRouter from 'angular-ui-router';
import { constants } from './constants';
import * as resolvers from './resolvers';

export const routes = 'routes';

angular.module(routes, [uiRouter, constants]).config([
  '$stateProvider',
  '$urlRouterProvider',
  'moduleNames',
  function ($stateProvider, $urlRouterProvider, moduleNames) {
    $stateProvider
      .state('search', {
        url: '/search?state&query&tag',
        templateUrl: require('./controllers/searchCtrl/search.html'),
        controller: 'searchCtrl',
        controllerAs: 'ctrl',
        authenticate: true,
        roleAccess: [], // empty array means any role
        resolve: resolvers.searchCtrlResolve
      })
      .state('viewSds', {
        url: '/viewSds/:id',
        templateUrl: require('./controllers/viewSdsCtrl/viewSds.html'),
        controller: 'viewSdsCtrl',
        controllerAs: 'vmViewSds',
        authenticate: false,
        roleAccess: [],
        resolve: resolvers.searchCtrlResolve
      })
      .state('previewSds', {
        url: '/previewSds/:id',
        templateUrl: require('./controllers/viewSdsCtrl/previewSds.html'),
        controller: 'viewSdsCtrl',
        controllerAs: 'vmViewSds',
        authenticate: true,
        roleAccess: ['admin'],
        resolve: resolvers.searchCtrlResolve
      })
      .state('labelMaker', {
        url: '/labelMaker/:type/:id?state',
        templateUrl: require('./controllers/labelMakerCtrl/labelMaker.html'),
        controller: 'labelMakerCtrl',
        controllerAs: 'vm',
        authenticate: true,
        roleAccess: [],
        params: {
          type: {
            value: 'sds'
          }
        }
      })
      .state('labelBatchMaker', {
        url: '/labelBatchMaker/:ids?state',
        templateUrl: require('./controllers/labelBatchMakerCtrl/labelBatchMaker.html'),
        controller: 'labelBatchMakerCtrl',
        controllerAs: 'ctrl',
        authenticate: true,
        roleAccess: []
      })
      .state('login', {
        url: '/login',
        templateUrl: require('./controllers/loginCtrl/login.html'),
        roleAccess: [],
        controller: 'loginCtrl'
      })
      .state('logout', {
        url: '/logout',
        templateUrl: require('./controllers/logoutCtrl/logout.html'),
        roleAccess: [],
        controller: 'logoutCtrl'
      })
      .state('qrcode', {
        url: '/qrcode',
        templateUrl: require('./controllers/qrcodeCtrl/qrcode.html'),
        controller: 'qrcodeCtrl',
        controllerAs: 'qrCodeViewModel',
        authenticate: true,
        roleAccess: ['admin', 'teamAdmin', 'teacher'],
        partOfModule: moduleNames.hse
      })
      .state('viewQRcode', {
        url: '/qrcode/:id',
        templateUrl: require('./controllers/viewQRcodeCtrl/viewQRcode.html'),
        controller: 'viewQRcodeCtrl',
        controllerAs: 'vmViewQRcode',
        authenticate: false,
        roleAccess: [],
        partOfModule: moduleNames.hse
      })
      .state('import', {
        url: '/import',
        templateUrl: require('./controllers/importCtrl/import.html'),
        controller: 'importCtrl',
        controllerAs: 'ctrl',
        authenticate: true,
        roleAccess: ['admin', 'sdsEditor']
      })
      .state('upload', {
        url: '/upload',
        templateUrl: require('./controllers/uploadCtrl/upload.html'),
        controller: 'uploadCtrl',
        authenticate: true,
        roleAccess: ['admin', 'sdsEditor']
      })
      .state('process', {
        url: '/process/:id',
        templateUrl: require('./controllers/processCtrl/process.html'),
        controller: 'processCtrl',
        controllerAs: 'processViewModel',
        authenticate: true,
        roleAccess: ['admin', 'sdsEditor'],
        resolve: resolvers.processCtrlResolve
      })
      .state('company', {
        url: '/company',
        templateUrl: require('./controllers/companyCtrl/company.html'),
        controller: 'companyCtrl',
        controllerAs: 'companyViewModel',
        authenticate: true,
        roleAccess: ['admin']
      })
      .state('tags', {
        url: '/tags',
        templateUrl: require('./controllers/tagCtrl/tags.html'),
        controller: 'tagCtrl',
        controllerAs: 'vm',
        authenticate: true,
        roleAccess: ['admin']
      })
      .state('users', {
        url: '/users',
        templateUrl: require('./controllers/userCtrl/users.html'),
        controller: 'userCtrl',
        controllerAs: 'vm',
        authenticate: true,
        roleAccess: ['admin', 'teamAdmin']
      })
      .state('requestSds', {
        url: '/addSds',
        templateUrl: require('./controllers/requestSdsCtrl/requestSds.html'),
        controller: 'requestSdsCtrl',
        controllerAs: 'ctrl',
        authenticate: true,
        roleAccess: []
      })
      .state('locationRequest', {
        url: '/locationRequest',
        templateUrl: require('./controllers/locationRequestCtrl/request.html'),
        controller: 'locationRequestCtrl',
        controllerAs: 'ctrl',
        authenticate: true,
        roleAccess: []
      })
      .state('portal', {
        url: '/portal',
        templateUrl: require('./controllers/portalCtrl/portal.html'),
        controller: 'portalCtrl',
        controllerAs: 'vmPortal',
        authenticate: true,
        roleAccess: [],
        partOfModule: moduleNames.hse
      })
      .state('gaReports', {
        url: '/gaReports',
        templateUrl: require('./controllers/gaReportsCtrl/gaReports.html'),
        controller: 'gaReportsCtrl',
        controllerAs: 'vmGaReports',
        authenticate: true,
        roleAccess: ['admin', 'teamAdmin']
      })
      .state('printout', {
        url: '/printout',
        templateUrl: require('./controllers/printoutCtrl/printout.html'),
        controller: 'printoutCtrl',
        controllerAs: 'ctrl',
        authenticate: true,
        roleAccess: [],
        resolve: resolvers.printoutCtrlResolve
      })
      .state('settings', {
        url: '/settings',
        templateUrl: require('./controllers/settingsCtrl/settings.html'),
        controller: 'settingsCtrl',
        controllerAs: 'vmSettings',
        authenticate: true,
        roleAccess: ['admin', 'teamAdmin'],
        resolve: resolvers.settingsCtrlResolve
      })
      .state('linkDocument', {
        url: '/linkDocument/:id',
        templateUrl: require('./controllers/linkDocumentCtrl/linkDocument.html'),
        controller: 'linkDocumentCtrl',
        controllerAs: 'vm',
        authenticate: true,
        roleAccess: ['admin', 'sdsEditor']
      })
      .state('bulkDocumentEdit', {
        url: '/bulkDocumentEdit',
        templateUrl: require('./controllers/bulkDocumentEditCtrl/bulkDocumentEdit.html'),
        controller: 'bulkDocumentEditCtrl',
        controllerAs: 'bulkViewModel',
        authenticate: true,
        roleAccess: ['admin'],
        resolve: resolvers.processCtrlResolve
      })
      .state('equipment', {
        url: '/equipment',
        templateUrl: require('./controllers/equipmentCtrl/equipment.html'),
        controller: 'equipmentCtrl',
        controllerAs: 'equipment',
        authenticate: true,
        roleAccess: ['admin', 'teamAdmin', 'teacher'],
        partOfModule: moduleNames.hse
      })
      .state('quiz', {
        url: '/quiz',
        templateUrl: require('./controllers/quizCtrl/quiz.html'),
        controller: 'quizCtrl',
        controllerAs: 'vmQuiz',
        authenticate: true,
        roleAccess: ['admin', 'teamAdmin', 'teacher'],
        partOfModule: moduleNames.hse
      })
      .state('inventory', {
        url: '/inventory',
        templateUrl: require('./controllers/inventoryCtrl/inventory.html'),
        controller: 'inventoryCtrl',
        controllerAs: 'vmInventory',
        authenticate: true,
        roleAccess: [],
        partOfModule: moduleNames.inv
      })
      .state('products', {
        url: '/products',
        templateUrl: require('./controllers/productsCtrl/products.html'),
        controller: 'productsCtrl',
        controllerAs: 'vmProducts',
        authenticate: true,
        roleAccess: [],
        partOfModule: moduleNames.inv,
        resolve: resolvers.processCtrlResolve
      })
      .state('unverifiedProducts', {
        url: '/unverifiedProducts',
        templateUrl: require('./controllers/unverifiedProductsCtrl/unverifiedProducts.html'),
        controller: 'unverifiedProductsCtrl',
        controllerAs: 'vmUnverifiedProducts',
        authenticate: true,
        roleAccess: ['admin'],
        partOfModule: moduleNames.inv,
        resolve: resolvers.processCtrlResolve
      })
      .state('inventoryContainers', {
        url: '/inventoryContainers',
        templateUrl: require('./controllers/inventoryContainersCtrl/inventoryContainers.html'),
        controller: 'inventoryContainersCtrl',
        controllerAs: 'vmInventoryContainers',
        authenticate: true,
        roleAccess: [],
        partOfModule: moduleNames.inv
      })
      .state('manufacturers', {
        url: '/manufacturers',
        templateUrl: require('./controllers/manufacturersCtrl/manufacturers.html'),
        controller: 'manufacturersCtrl',
        controllerAs: 'vmManufacturers',
        authenticate: true,
        roleAccess: ['admin', 'sdsEditor']
      })
      .state('bannedSearchJournal', {
        url: '/bannedSearchJournal',
        templateUrl: require('./controllers/bannedSearchJournalCtrl/bannedSearchJournal.html'),
        controller: 'bannedSearchJournalCtrl',
        controllerAs: 'ctrl',
        authenticate: true,
        roleAccess: ['admin', 'teamAdmin']
      })
      .state('checkedDateReport', {
        url: '/checkedDateReport',
        templateUrl: require('./controllers/checkedDateReportCtrl/checkedDateReport.html'),
        controller: 'checkedDateReportCtrl',
        controllerAs: 'ctrl',
        authenticate: true,
        roleAccess: ['admin', 'teamAdmin']
      })
      .state('archivedSdsReport', {
        url: '/archivedSdsReport',
        templateUrl: require('./controllers/archivedSdsReportCtrl/archivedSdsReport.html'),
        controller: 'archivedSdsReportCtrl',
        controllerAs: 'ctrl',
        authenticate: true,
        roleAccess: ['admin', 'teamAdmin']
      })
      .state('productsDontRequireSdsReport', {
        url: '/productsDontRequireSdsReport',
        templateUrl: require('./controllers/productsDontRequireSdsReportCtrl/productsDontRequireSdsReport.html'),
        controller: 'productsDontRequireSdsReportCtrl',
        controllerAs: 'ctrl',
        authenticate: true,
        roleAccess: ['admin']
      })
      .state('bannedProductsReport', {
        url: '/bannedProductsReport',
        templateUrl: require('./controllers/bannedProductsReportCtrl/bannedProductsReport.html'),
        controller: 'bannedProductsReportCtrl',
        controllerAs: 'ctrl',
        authenticate: true,
        roleAccess: ['admin']
      })
      .state('sdsSearchJournal', {
        url: '/sdsSearchJournal',
        templateUrl: require('./controllers/sdsSearchJournalCtrl/sdsSearchJournal.html'),
        controller: 'sdsSearchJournalCtrl',
        controllerAs: 'ctrl',
        authenticate: true,
        roleAccess: ['admin', 'teamAdmin']
      })
      .state('inventoryAuditTrail', {
        url: '/inventoryAuditTrail',
        templateUrl: require('./controllers/systemAuditCtrl/systemAudit.html'),
        controller: 'inventoryAuditTrailCtrl',
        controllerAs: 'ctrl',
        authenticate: true,
        roleAccess: ['admin', 'teamAdmin'],
        partOfModule: moduleNames.inv
      })
      .state('productsWithoutSds', {
        url: '/productsWithoutSds',
        templateUrl: require('./controllers/productsWithoutSdsCtrl/productsWithoutSds.html'),
        controller: 'productsWithoutSdsCtrl',
        controllerAs: 'vmProductsWithoutSds',
        authenticate: true,
        roleAccess: ['admin'],
        partOfModule: moduleNames.inv,
        resolve: resolvers.productsWithoutSdsCtrlResolve
      })
      .state('resetPassword', {
        url: '/reset-password?:access_token',
        templateUrl: require('./controllers/changePasswordCtrl/changePassword.html'),
        controller: 'changePasswordCtrl',
        authenticate: false,
        roleAccess: []
      })
      .state('addedRemovedSdsReport', {
        url: '/addedRemovedSdsReport',
        templateUrl: require('./controllers/addedRemovedSdsReportCtrl/addedRemovedSdsReport.html'),
        controller: 'addedRemovedSdsReportCtrl',
        controllerAs: 'ctrl',
        authenticate: true,
        roleAccess: ['admin', 'teamAdmin']
      })
      .state('bannedChemicalList', {
        url: '/banned-chemical-list',
        templateUrl: require('./controllers/bannedChemicalListCtrl/bannedChemicalList.html'),
        controller: 'bannedChemicalListCtrl',
        controllerAs: 'ctrl',
        authenticate: true,
        roleAccess: []
      })
      .state('sdsAuditLog', {
        url: '/sdsAuditLog',
        templateUrl: require('./controllers/systemAuditCtrl/systemAudit.html'),
        controller: 'sdsAuditLogCtrl',
        controllerAs: 'ctrl',
        authenticate: true,
        roleAccess: ['admin', 'teamAdmin']
      })
      .state('sdsRequests', {
        url: '/sdsRequests',
        templateUrl: require('./controllers/sdsRequestsCtrl/sdsRequests.html'),
        controller: 'sdsRequestsCtrl',
        controllerAs: 'ctrl',
        authenticate: true,
        roleAccess: ['admin', 'teamAdmin']
      })
      .state('locationRequests', {
        url: '/locationRequests',
        templateUrl: require('./controllers/locationRequestsCtrl/locationRequests.html'),
        controller: 'locationRequestsCtrl',
        controllerAs: 'ctrl',
        authenticate: true,
        roleAccess: ['admin', 'teamAdmin']
      })
      .state('paHssf', {
        url: '/pa-hssf-report',
        templateUrl: require('./controllers/paHssfReportCtrl/paHssfReport.html'),
        controller: 'paHssfReportCtrl',
        controllerAs: 'ctrl',
        authenticate: true
      })
      .state('cersTest', {
        url: '/cers-test/:ids',
        templateUrl: require('./controllers/cersTestCtrl/cersTest.html'),
        controller: 'cersTestCtrl',
        controllerAs: 'ctrl'
      })
      .state('exportImportCERS', {
        url: '/export-import-cers/',
        templateUrl: require('./controllers/exportImportCersCtrl/exportImportCERS.html'),
        controller: 'exportImportCERSCtrl',
        controllerAs: 'ctrl',
        roleAccess: ['admin', 'teamAdmin']
      })
      .state('tier2Report', {
        url: '/tier2Report/',
        templateUrl: require('./controllers/exportImportCersCtrl/tier2Report.html'),
        controller: 'exportImportCERSCtrl',
        controllerAs: 'ctrl',
        roleAccess: ['admin', 'teamAdmin']
      })
      .state('ReportTier2Texas', {
        url: '/ReportTier2Texas/',
        templateUrl: require('./controllers/exportImportCersCtrl/tier2Report.html'),
        controller: 'exportImportCERSCtrl',
        controllerAs: 'ctrl',
        roleAccess: ['admin', 'teamAdmin']
      })
      .state('createSupportTicket', {
        url: '/createSupportTicket',
        templateUrl: require('./controllers/supportTicketCtrl/createSupportTicket.html'),
        controller: 'createSupportTicketCtrl',
        controllerAs: 'ctrl',
        authenticate: true,
        roleAccess: ['admin', 'teamAdmin']
      })
      .state('sdsListByTagReport', {
        url: '/sdsListByTagReport',
        templateUrl: require('./controllers/sdsListByTagReportCtrl/sdsListByTagReport.html'),
        controller: 'sdsListByTagReportCtrl',
        controllerAs: 'ctrl',
        authenticate: true
      })
      .state('fireResponseReport', {
        url: '/fireResponseReport',
        templateUrl: require('./controllers/fireResponseReport/fireResponseReport.html'),
        controller: 'fireResponseReport',
        controllerAs: 'ctrl',
        authenticate: true
      })
      .state('cerclaPriorityListReport', {
        url: '/cerclaPriorityListReport',
        templateUrl: require('./controllers/cerclaPriorityListReportCtrl/cerclaPriorityListReport.html'),
        controller: 'cerclaPriorityListReport',
        controllerAs: 'ctrl',
        authenticate: true
      })
      .state('wclReport', {
        url: '/wclReport',
        templateUrl: require('./controllers/wclReportCtrl/wclReport.html'),
        controller: 'wclReportCtrl',
        controllerAs: 'ctrl',
        authenticate: true
      })
      .state('section313Report', {
        url: '/section313Report',
        templateUrl: require('./controllers/section313ReportCtrl/section313Report.html'),
        controller: 'section313ReportCtrl',
        controllerAs: 'ctrl',
        authenticate: true
      })
      .state('scheduledReports', {
        url: '/scheduledReports',
        templateUrl: require('./controllers/scheduledReportsCtrl/scheduledReports.html'),
        controller: 'scheduledReportsCtrl',
        controllerAs: 'ctrl',
        authenticate: true,
        roleAccess: ['admin', 'teamAdmin']
      })
      .state('discontinuedReport', {
        url: '/reports/discontinued',
        templateUrl: require('./controllers/discontinuedReportCtrl/discontinuedReport.html'),
        controller: 'discontinuedReportCtrl',
        controllerAs: 'ctrl',
        authenticate: true,
        roleAccess: ['admin', 'teamAdmin']
      });

    $urlRouterProvider.otherwise('/');
  }
]);
