import app from '../../app';
import selectTags from '../../modals/selectTags';

app.controller('paHssfReportCtrl', [
  '$rootScope',
  'CompanyService',
  'ModalService',
  'MessagesService',
  'SpinnerService',
  'HazardousSubstanceList',
  paHssfReportCtrl
]);

function paHssfReportCtrl(
  $rootScope,
  CompanyService,
  ModalService,
  MessagesService,
  SpinnerService,
  HazardousSubstanceList
) {
  const vm = this;
  let companyId = null;

  vm.filterSdsByTags = false;
  vm.selectedTagsResult = {};
  vm.selectedTagNames = [];
  vm.selectedFilterName = '';

  vm.onCompanySelected = function (selectedCompany) {
    companyId = selectedCompany.id;
    vm.filterSdsByTags = false;
    vm.selectedTagsResult = {};
    vm.selectedTagNames = [];
    vm.selectedFilterName = '';
  };
  vm.selectTags = function () {
    return ModalService.open(
      selectTags(companyId, {
        showAndOrDropdown: true,
        checkedTags: vm.selectedTagsResult.tags,
        andOrStrategy: vm.selectedTagsResult.andOrStrategy
      })
    )
      .then(result => {
        vm.selectedTagsResult = result;
        vm.selectedTagNames = result.tags.map(tag => tag.title);
        vm.selectedFilterName = vm.selectedTagNames.join(' ' + vm.selectedTagsResult.andOrStrategy.toUpperCase() + ' ');
      })
      .finally(() => {
        if (!vm.selectedTagsResult.tags || !vm.selectedTagsResult.tags.length) {
          vm.filterSdsByTags = false;
        }
      });
  };
  vm.export = SpinnerService.wrap(function () {
    const tagIdsToFilter = vm.filterSdsByTags ? vm.selectedTagsResult.tags.map(tag => tag.id) : [];
    const andOrStrategy = vm.selectedTagsResult.andOrStrategy;

    if (companyId == null) return console.error(`can't get companyId`);

    return HazardousSubstanceList.sendPAHSSFReport(
      {},
      { email: vm.email, companyId, tagIds: tagIdsToFilter, andOrStrategy, filterName: tagIdsToFilter.length ? vm.selectedFilterName : '' }
    ).$promise.then(() => {
      resetForm();
      MessagesService.info(
        'COMMON.MESSAGES.WILL_BE_NOTIFIED',
        {},
        {
          closeButton: true,
          timeOut: 0,
          extendedTimeOut: 0
        }
      );
    });
  });

  init();

  function init() {
    if (!$rootScope.checkIfUserIs('admin')) {
      return CompanyService.getCurrentCompanyPromise().$promise.then(() => {
        companyId = $rootScope.companyId;
      });
    }
  }

  function resetForm() {
    vm.email = '';
    vm.reportForm.$setUntouched();
  }
}
