import './firstResponderSearch.html';
import { injectDependencies, anchorSmoothScroll } from '../../../helpers';

export const firstResponderSearch = function ($scope) {
  const [SearchPageService, SdsFilterService] = injectDependencies([
    'SearchPageService',
    'SdsFilterService'
  ]);
  this.sps = SearchPageService;
  this.sps.initSensitiveTags();
  this.sfs = SdsFilterService;

  $scope.$on('filterSdsByFinished', () => {
    setTimeout(() => anchorSmoothScroll('search-criteria', 70));
  });
};
