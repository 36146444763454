import app from '../app';

app.service('ReportService', function ReportService(
  $rootScope,
  $q,
  $translate,
  CompanyService,
  ManufacturerService,
  TagFactory,
  DisposalCodes,
  StorageCodes,
  Inventory
) {
  const pivotByTitle = (arr, fieldName = 'title') =>
    arr.reduce((sum, t) => {
      sum[t.id] = { name: t[fieldName] };
      return sum;
    }, {});
  let dictionary;
  let dictionaryPromise;

  this.getDictionary = async () => {
    if (dictionaryPromise) return dictionaryPromise;

    await CompanyService.getCurrentCompanyPromise().$promise;

    dictionaryPromise = $q.all({
      manufacturer: ManufacturerService.manufacturersPromise(),
      tag: Promise.all([
        new TagFactory().getTags($rootScope.companyId).$promise,
        new TagFactory().getTags().$promise
      ]).then(res => pivotByTitle(res[0].concat(res[1]))),
      disposalCodes: DisposalCodes.find().$promise.then(res => pivotByTitle(res)),
      storageCodes: StorageCodes.find().$promise.then(res => pivotByTitle(res)),
      inventory: Inventory.find({
        filter: {
          where: Object.assign(
            { groupId: 'root' },
            $rootScope.companyId ? { companyId: $rootScope.companyId } : {}
          )
        }
      }).$promise.then(res => pivotByTitle(res, 'name'))
    });
    dictionary = await dictionaryPromise;

    return dictionaryPromise;
  };

  this.fillRecordWithFilterValues = record => {
    if (!dictionary) return console.error('run ReportService.getDictionary first');

    const filterValues = {};
    (record.filter || []).forEach(f => {
      if (
        [
          'hazardType',
          'language',
          'manufacturer',
          'tag',
          'storageCodes',
          'disposalCodes',
          'inventory'
        ].includes(f.field)
      ) {
        filterValues[f.field] = filterValues[f.field] || [];

        let value =
          dictionary[f.field] && dictionary[f.field][f.value]
            ? dictionary[f.field][f.value].name
            : f.value;

        if (f.field === 'hazardType') {
          value = $translate.instant(
            `PROCESS.SOLR_FORM.HAZARDS.${f.value.replace('_old', '').toUpperCase()}.TITLE`
          );
        }

        filterValues[f.field].push(value);
      }
    });
    record.filterValues = filterValues;
  };
});
