import app from '../../app';

app.directive('ppesPanel', [
  'PpeService',
  '$translate',
  '$rootScope',
  function (PpeService) {
    return {
      restrict: 'E',
      scope: {
        publicPpes: '=',
        ppeSelected: '=',
        editable: '=',
        onEditHandler: '='
      },
      templateUrl: require('./ppesPanel.html'),
      link: function (scope) {
        scope.PpeService = PpeService;
        scope.publicPpes = scope.publicPpes || [];
        scope.edit = function (event) {
          scope.onEditHandler(event);
        };
      }
    };
  }
]);
