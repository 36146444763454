import app from '../../app';

app.directive('sdsFilter', function (SdsFilterService) {
  return {
    restrict: 'E',
    scope: {
      companyId: '=',
      startCollapsed: '='
    },
    templateUrl: require('./sdsFilter.html'),
    link: function (scope) {
      scope.startCollapsed = scope.startCollapsed || true;
      scope.sfs = SdsFilterService;
      scope.getFilterTitle = SdsFilterService.getFilterTitle;

      scope.isCollapsed = filter => {
        return filter.collapsed != null ? filter.collapsed : scope.startCollapsed;
      };

      scope.onClick = (sdsFilter, uiTreeNode) => {
        if (sdsFilter.children && sdsFilter.children.length) {
          return uiTreeNode.toggle();
        }

        SdsFilterService.filterSdsBy(sdsFilter.id).then(() =>
          scope.$emit('filterSdsByFinished')
        );
      };

      scope.companyItem = function (item) {
        // this means the item is inventory
        if (item.amount == null) return true;

        return (
          item.companyId &&
          (!scope.companyId || scope.companyId === item.companyId) &&
          !item.restricted &&
          item.amount
        );
      };

      scope.hasAmount = function (item) {
        // this means the item is inventory
        if (item.amount == null) return true;

        return item.amount;
      };

      SdsFilterService.fetchFilters(scope.companyId).then(unveilActiveItem);

      function unveilActiveItem(items = []) {
        return items.some(item => {
          const active =
            item.active ||
            !!(item.children && item.children.length && unveilActiveItem(item.children));

          item.collapsed = !active;

          return active;
        });
      }
    }
  };
});
