import app from '../../app';

app.directive('restrictedTag', [
  '$filter',
  function ($filter) {
    return {
      restrict: 'E',
      scope: {
        document: '=',
        companyId: '='
      },
      templateUrl: require('./restrictedTag.html'),
      link: function (scope) {
        const tryDecode = $filter('decodeURIComponent');

        scope.showForThisCompany = function () {
          return (scope.document.restrictedInCompanies || []).some(function (id) {
            return scope.companyId === id;
          });
        };
        scope.getMessage = function () {
          const companyProp = 'restricted_in_' + scope.companyId,
            commonProp = 'restricted_in_common',
            msg = tryDecode(scope.document[companyProp] || scope.document[commonProp] || '');
          return msg ? ': ' + msg : '';
        };
      }
    };
  }
]);
