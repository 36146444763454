import app from '../app';

app.factory('HelperService', [
  'MessagesService',
  function (MessagesService) {
    function luminanace(r, g, b) {
      var a = [r, g, b].map(function (v) {
        v /= 255;
        return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
      });
      return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
    }

    function hexToRgb(hex) {
      // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
      const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
      hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
      });

      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
        ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        }
        : '#fff';
    }

    function stringToRgb(string) {
      const result = /(?:rgba?\()(\d+)(?:\s*,\s*)(\d+)(?:\s*,\s*)(\d+)/i.exec(string);
      return result
        ? {
          r: result[1],
          g: result[2],
          b: result[3]
        }
        : '#fff';
    }

    function getRGB(color) {
      if (color.trim().startsWith('rgb(') || color.trim().startsWith('rgba(')) {
        return stringToRgb(color);
      } else if (color.trim().startsWith('hsl(') || color.trim().startsWith('hsla(')) {
        // Not ready.
        return color;
      } else return hexToRgb(color);
    }

    function contrast(color1, color2) {
      const rgb1 = getRGB(color1);
      const rgb2 = getRGB(color2);
      const contrast =
        (luminanace(rgb1.r, rgb1.g, rgb1.b) + 0.05) / (luminanace(rgb2.r, rgb2.g, rgb2.b) + 0.05);

      return contrast > 1 ? contrast : 1 / contrast;
    }

    function getBrighterColor(color) {

      const contrastArr = [];

      contrastArr.push({color: '#2a2a2a', contrast: contrast(color, '#2a2a2a')});
      // Math.pow (, 2.4): only very light background color must have black color
      contrastArr.push({color: '#ffffff', contrast: Math.pow(contrast(color, '#ffffff'), 2.4)});
      contrastArr.sort(function (a, b) {
        return b.contrast - a.contrast;
      });

      return contrastArr[0].color;
    }

    return {
      getBrighterColor: getBrighterColor
    };
  }
]);
