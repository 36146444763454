export default function updateSystemUser(user = {}, systemRoles = [], companies = []) {
  return {
    templateUrl: require('./updateSystemUser.html'),
    keyboard: true,
    backdrop: true,
    controllerAs: 'vm',
    controller: [
      '$uibModalInstance',
      'SdsUser',
      'SpinnerService',
      'ErrorService',
      function ($uibModalInstance, SdsUser, SpinnerService, ErrorService) {
        const vm = this;
        const simpleCatch = ErrorService.simpleCatch;
        vm.dismiss = $uibModalInstance.dismiss;
        vm.user = { ...user };
        vm.systemRoles = systemRoles;
        vm.companies = companies;

        vm.onFocusPassword = e => {
          e.target.removeAttribute('readonly');
        };

        vm.onBlurPassword = e => {
          e.target.setAttribute('readonly', true);
        };

        vm.save = SpinnerService.wrap(function () {
          vm.user.password = vm.user.password || null;
          vm.user.companyIds = vm.user.allowedCompanies.map(company => company.id);

          return SdsUser.saveSystemUser(null, vm.user)
            .$promise.then(() => {
              $uibModalInstance.close(vm.user);
            })
            .catch(simpleCatch);
        });
      }
    ]
  };
}
