import app from '../app';

app.directive('dateSpan', function () {
  return {
    restrict: 'E',
    scope: {
      day: '=',
      span: '=',
      onChange: '@'
    },
    link: function (scope, elem) {}
  };
});
