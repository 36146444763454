import app from '../app';

app.filter('getSdsId', [
  '$rootScope',
  function ($rootScope) {
    return function (doc) {
      const lang = $rootScope.siteLanguage,
        same = ~doc.language.indexOf(lang);

      return doc[`${lang}VersionId`] || (same && doc.id) || doc.enVersionId || doc.id;
    };
  }
]);
