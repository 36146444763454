import app from '../../app';
import scheduleReport from '../../modals/scheduleReport';
import confirmMessage from '../../modals/confirmMessage';

app.controller('scheduledReportsCtrl', ['SchedulerService', 'ModalService', scheduledReportsCtrl]);

function scheduledReportsCtrl(SchedulerService, ModalService) {
  const vm = this;

  vm.title = 'REPORTS.SCHEDULED_REPORTS.TITLE';
  vm.scheduledReportsService = SchedulerService;

  vm.onCompanySelected = function (company) {
    return SchedulerService.onCompanySelected(company).then(companyId =>
      SchedulerService.getReports(companyId)
    );
  };

  vm.updateReport = function (report) {
    return ModalService.open(scheduleReport(report))
      .then(res => {
        return SchedulerService.updateReport(report.name, report.id, res.emails, res.frequency);
      })
      .then(updatedReport => {
        report.emails = updatedReport.emails;
        report.frequency = updatedReport.frequency;
      });
  };

  vm.removeReport = function (report) {
    return ModalService.open(confirmMessage())
      .then(() => SchedulerService.removeReport(report.name, report.id))
      .then(() => {
        SchedulerService.records = SchedulerService.records.filter(
          record => record.id !== report.id
        );
      });
  };

  SchedulerService.reportsInit();
}
