import app from '../../app';

app.directive('facetContainer', [
  function () {
    return {
      restrict: 'EA',
      transclude: true,
      scope: {
        isCollapsed: '=',
        title: '@'
      },
      templateUrl: require('./facetContainer.html'),
      link: function (scope) {
        scope.isCollapsed = scope.isCollapsed == null ? true : scope.isCollapsed;
      }
    };
  }
]);
