import app from '../app';
import { deObjectify } from '../helpers';

app.factory('TagFactory', [
  'Tag',
  '$rootScope',
  '$translate',
  '$filter',
  'ModalService',
  function (Tag, $rootScope, $translate, $filter, ModalService) {
    var translations;
    var decodeURIComponent = $filter('decodeURIComponent');

    $translate(['COMMON.LABELS.ADMINISTRATIVE']).then(function (translationsObj) {
      translations = translationsObj;
    });

    //tag factory public interface
    var TagFactory = function () {
      //tag factory properties
      this.tags = [];
      this.tagsById = null;
      this.tagLookup = {};
      this.tagSelected = {};

      //reference to this so we can update our properties from within functions
      var self = this;

      /**
       * Get all tags by parent tag Id
       * @param {String} companyId Company Id
       * @param {String} parentTagId Parent tag Id
       * @returns Promise
       */
      this.getIncludedTags = function (companyId, parentTagId) {
        const withoutSensitiveTags = $rootScope.checkIfUserIs(['admin', 'teamAdmin'])
          ? false
          : $rootScope.currentUser
          ? !$rootScope.currentUser.allowToViewSensitiveSds
          : true;
        const filterObj = {
          restrictByParent: true,
          filter: {
            order: 'order ASC, title ASC',
            where: {
              companyId: companyId || '',
              tagId: parentTagId,
              ...(withoutSensitiveTags ? { sensitive: false } : {})
            }
          }
        };
        return Tag.find(filterObj);
      };

      //Populates the tags and updates the tagLookup
      this.getTags = function (
        companyId,
        callback,
        lookUpInCompanyId,
        forceToGetAllTags,
        options = {}
      ) {
        options = Object.assign({ disableParentColor: false }, options);
        const withoutSensitiveTags = $rootScope.checkIfUserIs(['admin', 'teamAdmin'])
          ? false
          : $rootScope.currentUser
          ? !$rootScope.currentUser.allowToViewSensitiveSds
          : true;
        let filterObj = {
          filter: {
            order: 'order ASC, title ASC',
            where: {
              companyId: companyId || '',
              ...(withoutSensitiveTags ? { sensitive: false } : {})
            }
          }
        };

        if (options.parentTagId !== undefined) {
          filterObj.filter.where.tagId = options.parentTagId;
          filterObj.restrictByParent = true;
        }

        filterObj.disableParentColor = options.disableParentColor;

        return Tag.find(
          filterObj,
          function (tags) {
            self.tags = self.allCompanyTags = tags;
            self.tagLookup = CreateTagLookup(self.tags);
            self.tagSelected = {};
            AddEmptyTags(self.tags);
            AddChildTagsToParents(self.tags);
            self.tags = RemoveExtraTags(self.tags);
            if (options.sortByIdFor) {
              self.tagsById = self.sortById(self[options.sortByIdFor]);
            }

            if (forceToGetAllTags) return callback && callback();

            if (typeof callback === 'function') callback();
          },
          function (err) {
            console.log('ERROR : ' + err);
            if (typeof callback === 'function') callback();
          }
        );
      };

      this.getAllTagsModels = function () {
        return Tag.find({}).$promise;
      };

      /**
       * Get tags data by tag Id
       *
       * @param {Array} tags List of tags objects
       * @returns {Object}
       */
      this.sortById = function (tags = null) {
        const sourceTags = tags || self.allCompanyTags;
        let r = {};
        sourceTags.forEach(el => {
          r[el.id] = el;
        });
        return r;
      };

      this.getTagCompanyName = function (tagCompanyId, companies) {
        for (var i = 0; i < companies.length; i++) {
          if (companies[i].id === tagCompanyId) return companies[i].name;
        }
      };

      // toggle selection for a given tag
      this.toggleTagSelected = function (tagId, isSelected) {
        if (self.tagLookup[tagId]) {
          self.tagSelected[tagId] = isSelected == null ? !self.tagSelected[tagId] : isSelected;

          if (self.tagLookup[tagId].title.toLowerCase() === 'restricted')
            self.restricted = isSelected == null ? !self.restricted : isSelected;

          if (self.tagLookup[tagId].title.toLowerCase() === 'archived')
            self.archived = isSelected == null ? !self.archived : isSelected;

          if (self.tagLookup[tagId].title.toLowerCase() === 'sensitive')
            self.sensitive = isSelected == null ? !self.sensitive : isSelected;
        }
      };

      // set selection 'true' for a given tag
      this.setTagSelected = function (tagId, cb) {
        if (self.tagLookup[tagId]) {
          self.tagSelected[tagId] = true;

          if (self.tagLookup[tagId].title.toLowerCase() === 'restricted') {
            self.restricted = true;
            cb();
          }

          if (self.tagLookup[tagId].title.toLowerCase() === 'archived') {
            self.archived = true;
            cb();
          }

          if (self.tagLookup[tagId].title.toLowerCase() === 'sensitive') {
            self.sensitive = true;
            cb();
          }
        }
      };

      //convenience method to get an array of selected tags
      this.getSelectedTags = function () {
        var selectedTags = [];
        for (var tagId in self.tagSelected) {
          if (self.tagSelected[tagId]) {
            selectedTags.push(tagId);
          }
        }

        return selectedTags;
      };

      this.reset = function () {
        for (var id in self.tagSelected) {
          self.tagSelected[id] = false;
        }
      };

      /**
       * returns the all selected tags models
       * @param  {Array}   tags - array of IDs of selected tags
       * @param  {Object}  tagsByCompanyId - the object with companyIds and their tags, e.g. vm.tagsByCompanyId
       * @return {Array}   array of the models of the selected tags
       */
      this.getSelectedTagsModels = function (tagsIds, tagsByCompanyId) {
        if (!tagsIds) return console.warn(`the first argument can't be undefined`);

        return fillWithTags({ tagsIds: tagsIds, tagsByCompanyId: tagsByCompanyId }, true);
      };

      /**
       * returns all tags from the first array parameter
       * it needs the second parameter to search for both Universal and Company tags
       * @param  {Array}  tagsIds - array of IDs of tags those models you want to get
       * @param  {Object} tagsByCompanyId - the object with companyIds and their tags, e.g. vm.tagsByCompanyId
       * @return {Array}  array of the models of the all tags
       */
      this.getTagsModelsFromLookups = function (tagsIds, tagsByCompanyId) {
        if (!tagsIds || !tagsByCompanyId)
          return console.warn(`the first and the second arguments can't be undefined`);

        return fillWithTags({ tagsIds: tagsIds, tagsByCompanyId: tagsByCompanyId });
      };

      this.showTag = function (vm, tagId) {
        if (vm.tagLookup[tagId]) {
          if (vm.tagLookup[tagId].administrative) {
            if ($rootScope.currentUser && $rootScope.currentUser.role === 'admin') {
              return true;
            }

            return false;
          }

          return true;
        }

        return false;
      };

      this.showTitleOverTag = function (document, tag) {
        return tag.administrative
          ? translations['COMMON.LABELS.ADMINISTRATIVE']
          : document['restricted_in_' + tag.companyId] && tag.title.toLowerCase() === 'restricted'
          ? decodeURIComponent(document['restricted_in_' + tag.companyId])
          : document.restricted_in_common && tag.title.toLowerCase() === 'restricted'
          ? decodeURIComponent(document.restricted_in_common)
          : '';
      };

      /**
       * Opens a dialog window to select company tags,
       * can pre-select a tag that has the same name as company inventory
       *
       * @param {string} companyId the company ID
       * @param {string} tagNameToPreSelect a name of the tag that should be pre-selected
       * @param {string} message a path in i18n files (locale-en.json)
       * @param {Object} styles
       * @param {Object} buttons
       * @returns
       */
      this.openModalToSelectCompanyTags = function (
        companyId,
        tagNameToPreSelect,
        message,
        styles,
        buttons
      ) {
        let companyTags = this;

        return ModalService.open({
          templateUrl: require('../../views/modals/tags-to-add.html'),
          resolve: {
            message: [
              '$translate',
              function ($translate) {
                return $translate(message || 'TAGS.SELECT_TAGS');
              }
            ]
          },
          controller: [
            '$uibModalInstance',
            'message',
            function ($uibModalInstance, message) {
              let modal = this;

              companyTags
                .getTags(companyId, null, null, true)
                .$promise.then(function (tags) {
                  let selectedTags = {};

                  if (tagNameToPreSelect)
                    tags.forEach(function (tag) {
                      selectedTags[tag.id] =
                        tag.tags && tag.tags.length ? false : tag.title === tagNameToPreSelect;
                    });

                  return selectedTags;
                })
                .then(function (selectedTags) {
                  modal.selectedTags = selectedTags;
                });

              modal.message = message;
              modal.styles = styles || {};
              modal.buttons = buttons || {
                ok: true,
                cancel: true
              };
              modal.companyTags = companyTags;

              modal.confirm = function (tagsObj) {
                $uibModalInstance.close(deObjectify(tagsObj));
              };

              modal.cancel = $uibModalInstance.dismiss;
            }
          ]
        });
      };

      /**
       * returns the models of the all tags
       * @param  {Object}  tagsObj - the object with tagsIds property that is array
       *                             with IDs of tags those models should return
       * @param  {Boolean} onlySelected - if 'true' return only tags those were selected
       * @return {Array}   array of the models of the all tags
       */
      function fillWithTags(tagsObj, onlySelected) {
        var tags = {},
          allTags = [],
          isThisTagSelected = false;

        tags.tagsIds = tagsObj.tagsIds;
        if (tagsObj.tagsByCompanyId) tags.tagsByCompanyId = tagsObj.tagsByCompanyId;

        Object.keys(self.tagLookup).forEach(function (uTagId) {
          if (onlySelected === true) {
            isThisTagSelected = tags.tagsIds.some(function (tagId) {
              return uTagId === tagId;
            });

            if (isThisTagSelected) allTags.push(self.tagLookup[uTagId]);
          } else {
            tags.tagsIds.forEach(function (tagId) {
              if (uTagId === tagId) allTags.push(self.tagLookup[uTagId]);
            });
          }
        });

        if (tags.tagsByCompanyId !== undefined && typeof tags.tagsByCompanyId === 'object') {
          Object.keys(tags.tagsByCompanyId).forEach(function (companyId) {
            Object.keys(tags.tagsByCompanyId[companyId].tagLookup).forEach(function (cTagId) {
              if (onlySelected === true) {
                isThisTagSelected = tags.tagsIds.some(function (tagId) {
                  return cTagId === tagId;
                });

                if (isThisTagSelected)
                  allTags.push(tags.tagsByCompanyId[companyId].tagLookup[cTagId]);
              } else {
                tags.tagsIds.forEach(function (tagId) {
                  if (cTagId === tagId)
                    allTags.push(tags.tagsByCompanyId[companyId].tagLookup[cTagId]);
                });
              }
            });
          });
        }

        return allTags;
      }
    };

    //creates a lookup table for tags based on id
    function CreateTagLookup(tags) {
      var tagLookup = {};
      tags.forEach(function (tag) {
        tagLookup[tag.id] = tag;
      });

      return tagLookup;
    }

    // make sure every tag has a tags array
    function AddEmptyTags(tags) {
      tags.forEach(function (tag) {
        if (!tag.tags) tag.tags = [];
        else if (tag.tags.length > 0) AddEmptyTags(tag.tags);
      });
    }

    // tags comes back as a completed flat struture.
    // we need to organize so that child tags are properly parented;
    function AddChildTagsToParents(tags) {
      //go through all tags and see if they are a child
      tags.forEach(function (tag) {
        if (tag.tagId) {
          //this is a child tag, so find the parent
          tags.forEach(function (parentTag) {
            if (parentTag.id == tag.tagId) {
              tag.path = [...(parentTag.path || []), parentTag.title];
              parentTag.tags.push(tag);
            }
          });
        }
      });
    }

    //after added all tags to their parent we have to remove them
    //from the root level of the structure
    function RemoveExtraTags(tags) {
      var newTags = [];
      tags.forEach(function (tag) {
        if (!tag.tagId) {
          newTags.push(tag);
        }
      });

      return newTags;
    }

    return TagFactory;
  }
]);
