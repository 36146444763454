import app from '../../app';
import { tryDecodeURIComponent, getNameFromFile } from '../../helpers';
import selectTags from '../../modals/selectTags';
import selectInventory from '../../modals/selectInventory';
import addSdsRequestModal from '../../modals/addSdsRequestModal';

let broaderSearchAvailabilityPromise;
let requestFromBroaderSearchAvailable;

app.directive('docBroaderRequest', [
  '$rootScope',
  'CompanyService',
  'SpinnerService',
  'SearchPageService',
  'SdsDocumentService',
  'SdsRequestService',
  'ManufacturerService',
  'MessagesService',
  'ModalService',
  function (
    $rootScope,
    CompanyService,
    SpinnerService,
    SearchPageService,
    SdsDocumentService,
    SdsRequestService,
    ManufacturerService,
    MessagesService,
    ModalService
  ) {
    async function isRequestFromBroaderSearchAvailable() {
      await CompanyService.getCurrentCompanyPromise().$promise;

      if ($rootScope.accessToBroaderSearch()) {
        return isUserAllowedToRequest();
      } else {
        return false;
      }
    }

    function isUserAllowedToRequest() {
      return (
        !!$rootScope.currentUser ||
        ($rootScope.checkIfUserIs() && $rootScope.companySettings.requestSdsByIpEnabled)
      );
    }

    return {
      restrict: 'E',
      templateUrl: require('./docBroaderRequest.html'),
      scope: {
        btnClass: '@',
        doc: '='
      },
      link: function (scope, _element, _attrs) {
        const getSdsManufacturer = SpinnerService.wrap(async function (doc) {
          if (doc.manufacturer == null) return null;

          if (typeof doc.manufacturer === 'string') {
            const manufacturers = await ManufacturerService.manufacturersPromise();
            return manufacturers[doc.manufacturer];
          }

          return doc.manufacturer;
        });

        scope.available = false;
        scope.disabled = false;
        scope.throughBroaderSearchForUnloggedUser = false;

        scope.onClick = async function () {
          scope.disabled = true;

          const immediatelyAddedSds =
            $rootScope.companySettings.addSdsFromBroaderSearch ||
            ($rootScope.currentUser && $rootScope.currentUser.autoApproveSdsRequest);
          const autoApproveSdsRequest =
            $rootScope.currentUser &&
            $rootScope.accessAddTags() &&
            $rootScope.currentUser.autoApproveSdsRequest;
          const tagsWorkflowEnabled =
            $rootScope.companySettings.addSdsFormEnabled &&
            $rootScope.companySettings.addSdsForm.some(field => field.tagsWorkflow);
          let tagOptions = {};
          let tagIds = [];
          let inventoryIds = [];
          try {
            if (!$rootScope.companySettings.hideTagsWorkflowEnabled) {
              tagOptions = await ModalService.open(
                selectTags($rootScope.companyId, {
                  allowSearchByParentTags: false,
                  showAndOrDropdown: false,
                  saveButtonText: 'COMMON.ACTIONS.SEND',
                  checkType: 'checkbox',
                  showRestrictedTag: !tagsWorkflowEnabled,
                  showArchivedTag: !tagsWorkflowEnabled
                })
              );
              tagIds = (tagOptions.tags || []).map(tag => tag.id);
            }

            if (!autoApproveSdsRequest && $rootScope.companySettings.inventoryInSearchEnabled) {
              inventoryIds = (await ModalService.open(selectInventory($rootScope.companyId))).map(
                result => result.id
              );
            }
          } catch (err) {
            MessagesService.warning('COMMON.MESSAGES.SDS_REQUEST_CANCELED');
            scope.disabled = false;
            if (err && err !== 'backdrop click') throw err;

            return;
          }

          const requestData = {
            'Product Name': scope.doc.chemicalFriendlyName
              ? tryDecodeURIComponent(scope.doc.chemicalFriendlyName)
              : tryDecodeURIComponent(getNameFromFile(scope.doc.resourcePath, true)),
            'SDS url': SdsDocumentService.getViewUrl(scope.doc)
          };
          const manufacturer = await getSdsManufacturer(scope.doc);

          if (manufacturer) {
            requestData.Manufacturer = manufacturer.name;
          }

          if (scope.throughBroaderSearchForUnloggedUser) {
            try {
              const sdsAddRequestOptions = await ModalService.open(addSdsRequestModal());
              if (sdsAddRequestOptions.email) {
                requestData.From = sdsAddRequestOptions.email;
              }
            } catch (err) {
              scope.disabled = false;
              if (err && err !== 'backdrop click') throw err;
              return;
            }
          }

          return SdsRequestService.sendRequest(requestData, {
            existingSdsId: scope.doc.id,
            tagIds,
            inventoryIds
          })
            .then(result => {
              MessagesService.success(immediatelyAddedSds ? 'REQUEST.ADDED' : 'REQUEST.SENT');

              scope.doc.companyId = (scope.doc.companyId || []).concat(result.companyId);
            })
            .finally(() => {
              scope.available = false;
              scope.disabled = false;
              scope.throughBroaderSearchForUnloggedUser = false;
            });
        };

        init();

        async function init() {
          await checkBroaderSearchAvailability();
          apply();
        }

        async function checkBroaderSearchAvailability() {
          if (isDocAlreadyInCompany()) return (scope.available = false);

          if (broaderSearchAvailabilityPromise == null) {
            broaderSearchAvailabilityPromise = isRequestFromBroaderSearchAvailable();
          }

          if (requestFromBroaderSearchAvailable == null) {
            requestFromBroaderSearchAvailable = await broaderSearchAvailabilityPromise;
          }

          scope.available =
            (SearchPageService.throughBroaderSearch && requestFromBroaderSearchAvailable) ||
            (SearchPageService.throughOtherCompany && isUserAllowedToRequest());

          scope.throughBroaderSearchForUnloggedUser =
            SearchPageService.throughBroaderSearch &&
            requestFromBroaderSearchAvailable &&
            !$rootScope.currentUser;
        }

        function isDocAlreadyInCompany() {
          return (scope.doc.companyId || []).some(
            companyId => $rootScope.currentCompany && companyId === $rootScope.currentCompany.id
          );
        }

        function apply() {
          if (!scope.$$phase) {
            scope.$apply();
          }
        }
      }
    };
  }
]);
