export default function scheduleReport(report = {}) {
  return {
    templateUrl: require('./scheduleReport.html'),
    keyboard: true,
    backdrop: true,
    controllerAs: 'vm',
    controller: [
      '$uibModalInstance',
      'SchedulerService',
      function ($uibModalInstance, SchedulerService) {
        const vm = this;

        vm.frequencies = SchedulerService.frequencies;
        vm.dismiss = $uibModalInstance.dismiss;
        vm.form = {
          emails: report.emails ? [...report.emails] : [],
          frequency: report.frequency || vm.frequencies.d
        };
        vm.email = '';

        vm.removeEmailField = function (index) {
          vm.form.emails.splice(index, 1);
        };
        vm.addEmailField = function (email) {
          if (vm.form.emails.includes(email)) {
            vm.errorMessage = 'SETTINGS.EMAIL_EXISTS';
            return;
          }

          vm.form.emails.push(email);
          resetForm();
        };

        vm.save = $uibModalInstance.close.bind(null, vm.form);

        function resetForm() {
          vm.errorMessage = '';
          vm.email = '';
          vm.scheduleReportForm.$setUntouched();
        }
      }
    ]
  };
}
