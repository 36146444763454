import app from '../app';

app.service('InventoryReviewService', [
  '$q',
  'ErrorService',
  'InventoryReview',
  function ($q, ErrorService, InventoryReview) {
    const simpleCatch = ErrorService.simpleCatch;

    let _reviewPeriod;
    let _latestReviews;

    this.getReviews = function (inventoryId, offset, limit) {
      const filter = {
        filter: {
          where: { inventoryId: inventoryId },
          offset: offset || 0,
          limit: limit || 0
        }
      };

      return InventoryReview.find(filter).$promise.catch(simpleCatch);
    };

    this.getLatestReview = function (inventoryId) {
      if (_latestReviews)
        return $q.resolve(getLatestReviewByInventoryId(inventoryId));

      const filter = {
        filter: {
          where: { inventoryId: inventoryId }
        }
      };

      return InventoryReview.findOne(filter).$promise.catch(simpleCatch);
    };

    this.getLatestReviews = function (companyId) {
      return InventoryReview.getLatestReviews({ companyId: companyId })
        .$promise.then(function (reviews) {
          _latestReviews = reviews;
        })
        .catch(simpleCatch);
    };

    this.countReviews = function (inventoryId) {
      const filter = {
        where: {
          inventoryId: inventoryId
        }
      };

      return InventoryReview.count(filter)
        .$promise.then(function (response) {
          return response.count;
        })
        .catch(simpleCatch);
    };

    this.checkIfReviewIsPassed = function (inventoryId) {
      const review = getLatestReviewByInventoryId(inventoryId)[0];

      // if the review period is set to 0 then review is never passed
      if (_reviewPeriod === 0) return false;
      if (!review) return true;

      return isReviewPassed(review.date);
    };

    this.nextPassedDateIs = function (inventoryId) {
      if (_reviewPeriod === 0) return;

      const reviewDate = getLatestReviewByInventoryId(inventoryId)[0].date;

      return getNextPassedDate(reviewDate);
    };

    this.setReviewPeriod = function (period) {
      _reviewPeriod = period;
    };

    this.updateReviews = function (reviewer, inventoryId, companyId) {
      const data = {
        reviewer: reviewer,
        inventoryId: inventoryId,
        companyId: companyId
      };

      return InventoryReview.create(data)
        .$promise.then(updateFetchedLatestReview)
        .catch(simpleCatch);
    };

    function updateFetchedLatestReview(newReview) {
      let updated = false;

      _latestReviews = _latestReviews.map(function (review) {
        if (review.inventoryId !== newReview.inventoryId) return review;

        updated = true;

        return newReview;
      });

      if (!updated) {
        _latestReviews = _latestReviews.concat(newReview);
      }
    }

    function isReviewPassed(reviewDate) {
      if (_reviewPeriod === 0) return false;

      const currentTime = new Date().getTime();
      const nextPassedTime = getNextPassedDate(reviewDate).getTime();

      return currentTime >= nextPassedTime;
    }

    function getLatestReviewByInventoryId(inventoryId) {
      return (_latestReviews || []).filter(function (review) {
        return review.inventoryId === inventoryId;
      });
    }

    function getReviewIndexByInventoryId(inventoryId) {
      let index;

      _latestReviews.some(function (review, i) {
        if (review.inventoryId === inventoryId) {
          index = i;

          return true;
        }
      });

      return index;
    }

    function getNextPassedDate(srcDate) {
      if (_reviewPeriod == undefined) throw Error('review period isn\'t set');

      let date = new Date(srcDate);

      date.setDate(date.getUTCDate() + _reviewPeriod);

      return date;
    }

    // not in use right now
    function getStartOfDay(date) {
      date = date || new Date();

      return new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate()
      );
    }
  }
]);
