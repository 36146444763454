import app from '../app';

app.factory('EquipmentService', [
  '$q',
  'Equipment',
  'InventoryEquipmentMapping',
  'MessagesService',
  'SystemAuditService',
  function (
    $q,
    Equipment,
    InventoryEquipmentMapping,
    MessagesService,
    SystemAuditService
  ) {
    function getEquipments() {
      return Equipment.find({}).$promise;
    }

    function getEquipmentsByCompany(companyId) {
      const filter = { filter: { where: { companyId: companyId } } };

      return Equipment.find(filter).$promise;
    }

    function updateEquipment(equipment) {
      return Equipment.upsert(equipment).$promise;
    }

    function deleteEquipmentById(id) {
      return Equipment.deleteById({ id: id }).$promise;
    }

    function findEquipmentByName(name) {
      return Equipment.find({ filter: { where: { name: name } } }).$promise;
    }

    function updateAmounts(amounts, auditOptions) {
      return $q
        .all(
          amounts.map(function (amount) {
            InventoryEquipmentMapping.upsert(amount);
          })
        )
        .then(function () {
          MessagesService.success('COMMON.MESSAGES.UPDATED');

          if (auditOptions) {
            auditOptions.type = SystemAuditService.EQUIPMENT_TYPE;

            SystemAuditService.createAuditEntryUpdateInInventories(auditOptions);
          }
        });
    }

    return {
      getEquipments: getEquipments,
      getEquipmentsByCompany: getEquipmentsByCompany,
      updateEquipment: updateEquipment,
      deleteEquipmentById: deleteEquipmentById,
      findEquipmentByName: findEquipmentByName,
      updateAmounts: updateAmounts
    };
  }
]);
