import app from '../../app';

app.directive('manuSelector', [
  function () {
    return {
      restrict: 'E',
      scope: {
        manus: '=',
        startCollapsed: '=',
        onChange: '&'
      },
      templateUrl: require('./manuSelector.html')
    };
  }
]);
