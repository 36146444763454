import app from '../../app';

app.controller('logoutCtrl', [
  '$scope',
  '$state',
  'AuthService',
  function ($scope, $state, AuthService) {
    $scope.login = function () {
      window.location.href = '/';
    };

    AuthService.clearInitialLocation();
    AuthService.logout();
  }
]);
