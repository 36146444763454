import app from '../../app';

app.directive('labelBatchDoc', [
  function () {
    return {
      restrict: 'E',
      scope: {
        doc: '=',
        onRemoveFromLabelBatch: '='
      },
      templateUrl: require('./labelBatchDoc.html'),
      link: function (scope) {
        scope.removeFromLabelBatch = scope.onRemoveFromLabelBatch;
      }
    };
  }
]);
