import app from '../../app';

app.directive('dateRangePicker', [
  function () {
    return {
      restrict: 'E',
      templateUrl: require('./dateRangePicker.html'),
      scope: {
        onChange: '=',
        api: '='
      },
      controller: [
        '$scope',
        '$rootScope',
        'AddedRemovedSdsReportService',
        function ($scope, $rootScope, AddedRemovedSdsReportService) {
          $scope.api = $scope.api || {};
          $scope.date = {
            start: 0,
            startStr: '30daysAgo',
            end: 0,
            endStr: 'today'
          };
          $scope.showDateRangeComponent = true;
          $scope.showDateRange = false;

          $scope.changeDate = function (dateStr, datepickerDate) {
            if (!datepickerDate) {
              $scope.showDateRange = false;
              $scope.date.startStr = dateStr;
              $scope.date.endStr = 'today';

              AddedRemovedSdsReportService.evalDate($scope.date);
            } else {
              $scope.showDateRange = true;

              if (dateStr === 'start') {
                $scope.date.startStr = datepickerDate.format('YYYY-MM-DD');
              } else {
                $scope.date.endStr = datepickerDate.format('YYYY-MM-DD');
              }

              AddedRemovedSdsReportService.evalDate($scope.date);
            }

            $scope.onChange($scope.date);
          };

          $scope.api.getEvalDate = function () {
            AddedRemovedSdsReportService.evalDate($scope.date);

            return $scope.date;
          };

          AddedRemovedSdsReportService.evalDate($scope.date);

          $rootScope.$watch('siteLanguage', function () {
            $scope.showDateRangeComponent = false;
            setTimeout(() => {
              $scope.$apply(() => {
                $scope.showDateRangeComponent = true;
              });
            }, 0);
          });
        }
      ]
    };
  }
]);
