import angular from 'angular';
import app from '../../app';
import moment from 'moment';

app.controller('loginCtrl', [
  '$scope',
  '$state',
  '$cookies',
  '$rootScope',
  '$location',
  'CookieManager',
  'AuthService',
  'ModalService',
  'RoleFactory',
  function (
    $scope,
    $state,
    $cookies,
    $rootScope,
    $location,
    CookieManager,
    AuthService,
    ModalService,
    RoleFactory
  ) {
    $scope.rememberMe = true;
    $scope.loginError = '';
    $scope.errorMessage = $location.search().error;

    $scope.credentials = {
      username: '',
      password: ''
    };

    $scope.login = function () {
      $scope.loginError = false;
      $scope.setRememberMeCookie();

      if (typeof $scope.credentials.password === 'string') {
        $scope.credentials.password = $scope.credentials.password.trim();
      }

      $scope.user = AuthService.login($scope.rememberMe, $scope.credentials, afterLogin).catch(
        () => {
          $scope.loginError = true;
        }
      );
    };

    $scope.toggleRememberMe = function () {
      $scope.setRememberMeCookie();
    };

    $scope.setRememberMeCookie = function () {
      if ($scope.rememberMe) {
        $cookies.put('rememberMe', $scope.rememberMe);
        //$cookies.put('username', $scope.credentials.username);
      } else {
        CookieManager.removeCookies(['rememberMe', 'username']);
      }
    };

    $scope.forgotPassword = function () {
      ModalService.open({
        keyboard: true,
        backdrop: true,
        controllerAs: 'ctrl',
        controller: 'forgotPasswordCtrl',
        templateUrl: require('../forgotPasswordCtrl/forgotPassword.html'),
        resolve: {}
      }).catch(angular.noop);
    };

    function afterLogin(response) {
      const currentUserToStore = {
        id: response.user.id,
        tokenId: response.id
      };

      $rootScope.enforcePasswordChange = false;
      $rootScope.sitename = '';
      $rootScope.onChangeMainCompany($rootScope.getSchoolName(response.role === 'admin'));

      $rootScope.currentUser = {
        id: response.user.id,
        doNotAllowUserToChangePassword: response.user.doNotAllowUserToChangePassword,
        tokenId: response.id,
        username: $scope.credentials.username,
        role: response.role,
        company: response.company || '',
        companyId: response.companyId,
        azureBond: response.azureBond,
        adfsBond: response.adfsBond,
        googleBond: response.googleBond,
        allowedCompanies: getAllowedCompanies(response),
        restrictedTags: response.user.restrictedTags || [],
        autoApproveSdsRequest: response.user.autoApproveSdsRequest,
        allowToViewSensitiveSds: response.user.allowToViewSensitiveSds || false
      };

      if (
        !$rootScope.checkIfUserIs('admin', $rootScope.currentUser) &&
        !checkAllowedCompanies($rootScope.currentUser)
      ) {
        $scope.loginError = true;

        return AuthService.logout()
          .then(() => {
            $scope.loginResult = 'Logged Out';
          })
          .catch(() => {
            $scope.loginResult = 'Logout Error';
          });
      }

      RoleFactory.extendRole($rootScope.currentUser);

      if ($scope.rememberMe) {
        RememberUser(currentUserToStore);
      } else {
        storeUserForSession(currentUserToStore);
      }

      let enforcePasswordChange = false;
      if ($rootScope.companySettings.password_change && response.user) {
        const passwordResetDate = response.user.passwordResetDate || response.user.created;
        if (passwordResetDate) {
          let momentFrom = new moment(passwordResetDate),
            momentTo = new moment(),
            duration = moment.duration(momentTo.diff(momentFrom)).asMonths();
          if (duration > $rootScope.companySettings.password_change_months) {
            $rootScope.enforcePasswordChange = true;
            $rootScope.changeUserPassword();
          }
        }
      }

      if (!enforcePasswordChange) {
        $state.go(
          (($rootScope.checkIfUserIs('admin')
            ? false
            : $rootScope.checkIfUserIs()
            ? true
            : false) &&
            $rootScope.companySettings &&
            $rootScope.isModuleEnabled($rootScope.moduleNames.hse) &&
            $rootScope.companySettings.firstPage) ||
            'search'
        );
      }

      function getAllowedCompanies(resp) {
        return resp.allowedCompanies
          ? resp.allowedCompanies
          : resp.company && resp.companyId
          ? [{ name: resp.company, id: resp.companyId }]
          : [];
      }
    }

    function checkAllowedCompanies(user) {
      return user.allowedCompanies.some(
        company =>
          company.name.toLowerCase() === $rootScope.sitename.toLowerCase().replace(/-ca$|-us$/, '')
      );
    }

    function InitRememberMe() {
      if ($cookies.get('rememberMe') == 'true') {
        $scope.rememberMe = true;
        $scope.credentials.username = $cookies.get('username');
      } else {
        $scope.rememberMe = false;
      }
    }

    function RememberUser(user) {
      $cookies.put('currentUser', JSON.stringify(user));
    }

    function storeUserForSession(user) {
      sessionStorage.setItem('currentUser', JSON.stringify(user));
    }

    $rootScope.$watch('companySettings', function (companySettings) {
      $scope.azureLogin = companySettings && companySettings.azureLogin;
      $scope.adfsLogin = companySettings && companySettings.adfsLogin;
      $scope.googleLogin = companySettings && companySettings.googleLogin;
    });

    InitRememberMe();
  }
]);
