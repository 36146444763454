import angular from 'angular';
import app from '../../app';
import updateUser from '../../modals/updateUser';
import selectTags from '../../modals/selectTags';
import { getOffset as getOffsetHelper } from '../../helpers';
import { legislations, getLegislationCountryByCode } from '../../../../common/helpers/legislations';

app.controller('userCtrl', [
  '$scope',
  '$rootScope',
  'RoleFactory',
  'UserService',
  'ModalService',
  'MessagesService',
  'ErrorService',
  'Tag',
  function (
    $scope,
    $rootScope,
    RoleFactory,
    UserService,
    ModalService,
    MessagesService,
    ErrorService,
    Tag
  ) {
    const vm = this;

    vm.roleFactory = RoleFactory;
    vm.newUser = {};
    vm.startFrom = 1;
    vm.PER_PAGE = 20;
    vm.totalItems = 0;
    vm.searchUserString = '';

    vm.userInventory = UserService.InventoryAccess;
    vm.selectAllInventory = vm.userInventory.selectAll;
    vm.unselectAllInventory = vm.userInventory.unselectAll;
    vm.showInventoryAccess = vm.userInventory.showInventoryAccess;

    let companyTags = [];
    vm.userRestrictedTagsList = [];
    vm.showRestrictedBlock = false;
    vm.legislations = legislations;

    vm.isShowRestrictedBlock = function () {
      return (
        UserService.isShowRestrictedTagsBlock(vm.roleFactory.selectedRole) &&
        vm.userRestrictedTagsList.length
      );
    };

    vm.setRestrictedTagsList = function (tags) {
      vm.userRestrictedTagsList = tags.map(el => {
        el.checked = true;
        return el;
      });
    };

    vm.roleChange = function () {
      if (!vm.isShowRestrictedBlock()) {
        vm.setRestrictedTagsList(companyTags[vm.currentCompanyId]);
        vm.showRestrictedBlock = false;
      } else {
        vm.showRestrictedBlock = true;
      }
    };

    vm.handlePaginate = function () {
      getUsersByCompany(vm.currentCompanyId, getOffset());
    };

    //load the users for the new company
    vm.onCompanySelected = function (selectedCompany) {
      if (selectedCompany.id) {
        loadCompanyTags(selectedCompany.id);
      }

      vm.startFrom = 1;
      UserService.getCompanySettings(selectedCompany.id);
      vm.currentCompanyId = selectedCompany.id;
      getUsersByCompany(vm.currentCompanyId, 0);
      vm.userInventory
        .getInventories(null, vm.currentCompanyId)
        .then(() => vm.selectAllInventory());
    };

    vm.onAddUser = function () {
      const companyId = vm.currentCompanyId;
      const roleId = vm.roleFactory.selectedRole.id;

      vm.newUser.restrictedTags = [];

      if (vm.isShowRestrictedBlock()) {
        vm.userRestrictedTagsList.forEach(el => {
          if (!el.checked) {
            vm.newUser.restrictedTags.push(el.id);
          }
        });
      }

      return UserService.createUser(vm.newUser, roleId, companyId).then(function () {
        vm.newUser = {
          sdsSearchRegion: $rootScope.defaultLegislation.code
        };
        vm.setRestrictedTagsList(companyTags[companyId]);
        vm.showRestrictedBlock = false; //needs to reset collapses
        setTimeout(() => (vm.showRestrictedBlock = vm.isShowRestrictedBlock()), 200);

        MessagesService.success('COMMON.MESSAGES.ADDED');
        vm.roleFactory.SetSelectedRole(vm.roleFactory.defaultRole.id);
        vm.selectAllInventory();

        return getUsersByCompany(vm.currentCompanyId, getOffset());
      });
    };

    vm.onUserClicked = user => {
      return ModalService.open(
        updateUser(user, vm.currentCompanyId, companyTags[vm.currentCompanyId])
      )
        .then(function (updatedUser) {
          angular.extend(user, updatedUser);
          MessagesService.success('COMMON.MESSAGES.UPDATED');
        })
        .finally(() => vm.selectAllInventory());
    };

    vm.onDeleteUser = function (user, e) {
      e.stopPropagation();

      const lastUserOnPage = vm.users.length > 1 ? false : true;

      return UserService.deleteUser(user).then(function () {
        MessagesService.success('COMMON.MESSAGES.DELETED');
        const offset = getOffset();

        // if the last user on the page is deleted, redirect to the next last page
        if (lastUserOnPage && offset >= vm.PER_PAGE) {
          return getUsersByCompany(vm.currentCompanyId, offset - vm.PER_PAGE);
        }

        return getUsersByCompany(vm.currentCompanyId, offset);
      });
    };

    vm.searchUsers = function () {
      getUsersByCompany(vm.currentCompanyId, 0);
      vm.startFrom = 1;
    };

    vm.selectRestrictedTags = function () {
      return ModalService.open(
        selectTags(vm.currentCompanyId, {
          checkedTags: vm.userRestrictedTagsList.filter(tag => tag.checked),
          allowSearchByParentTags: false,
          showAndOrDropdown: false,
          message: null
        })
      ).then(result => {
        // reset all tags in userRestrictedTagsList
        for (const tag of vm.userRestrictedTagsList) {
          tag.checked = false;
        }

        // mark tags from result.tags as "checked" in userRestrictedTagsList
        for (const tag of result.tags) {
          const index = vm.userRestrictedTagsList.findIndex(el => el.id === tag.id);
          if (index > -1) {
            vm.userRestrictedTagsList[index].checked = tag.checked;
          }
        }
      });
    };

    vm.getLegislationCountryByCode = getLegislationCountryByCode;

    // get the users for a company and add them to the list
    function getUsersByCompany(companyId, offset) {
      $scope.isLoading = true;

      return UserService.searchUsers(
        vm.searchUserString,
        companyId,
        offset < 0 ? 0 : offset,
        vm.PER_PAGE
      )
        .then(function (data) {
          vm.users = data.users;
          vm.totalItems = data.count;
        })
        .finally(function () {
          $scope.isLoading = false;
        });
    }

    // get company tags
    function loadCompanyTags(companyId) {
      if (!companyTags[companyId]) {
        Tag.find({
          filter: {
            where: {
              companyId: companyId
            }
          }
        })
          .$promise.then(function (resp) {
            companyTags[companyId] = resp.map(t => {
              t.checked = true;
              return t;
            });
            vm.setRestrictedTagsList(companyTags[companyId]);
          })
          .catch(ErrorService.simpleCatch);
      } else {
        vm.setRestrictedTagsList(companyTags[companyId]);
      }
    }

    function getOffset() {
      return getOffsetHelper(vm.startFrom, vm.PER_PAGE);
    }

    function init() {
      if ($rootScope.checkIfUserIs('teamAdmin')) {
        vm.currentCompanyId = $rootScope.currentUser.companyId;
        getUsersByCompany(vm.currentCompanyId, 0);
        vm.userInventory
          .getInventories(null, vm.currentCompanyId)
          .then(() => vm.selectAllInventory());
        loadCompanyTags(vm.currentCompanyId);
      } else if (!$rootScope.checkIfUserIs('admin')) {
        throw 'no current user';
      }

      RoleFactory.getRoles().then(roles => {
        vm.roles = roles;

        if (!$rootScope.isModuleEnabled($rootScope.moduleNames.inv, true)) {
          vm.roles = vm.roles.filter(role => role.name !== 'invManagerRead');
        }
      });
    }

    init();
  }
]);
