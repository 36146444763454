import app from '../../app';

app.directive('azureRoles', [
  function () {
    return {
      restrict: 'E',
      templateUrl: require('./azureRoles.html'),
      scope: {
        roles: '=',
        commonSettings: '=',
        companyId: '='
      },
      controllerAs: 'vm',
      controller: function (
        $rootScope,
        $scope,
        CompanySetting,
        SpinnerService,
        MessagesService,
        ErrorService
      ) {
        if (!$rootScope.checkIfUserIs('admin')) return;

        const vm = this;
        const simpleCatch = ErrorService.simpleCatch;
        const companyRoleNames = ['teamAdmin', 'teacher', 'firstResponder', 'invManagerRead'];

        vm.azureRoleByProps = ['groups', 'mail', 'userPrincipalName', 'jobTitle'];
        vm.roleNames = ($scope.roles || [])
          .map(function (role) {
            return role.name;
          })
          .filter(function (roleName) {
            return companyRoleNames.includes(roleName);
          });

        vm.updateAzureRoles = SpinnerService.wrap(function (roleName) {
          const azureRoleSettingsName = `azureRoleSettings.${roleName}`;

          return CompanySetting.prototype$updateAttributes(
            { id: $scope.companyId },
            { [azureRoleSettingsName]: vm.azureRoles[roleName] }
          )
            .$promise.then(() => MessagesService.success('COMMON.MESSAGES.SAVE_COMPLETE'))
            .catch(simpleCatch);
        });

        init();

        function init() {
          $scope.$watch('commonSettings', function (newValue, oldValue) {
            vm.azureRoles =
              $scope.commonSettings.azureRoleSettings || createAzureRoles(vm.roleNames);
          });
        }

        function createAzureRoles(roleNames = []) {
          if (!roleNames.length) console.error('no rolenames');

          return roleNames.reduce((sum, roleName) => {
            sum[roleName] = {
              azureRoleBy: vm.azureRoleByProps[0],
              azureStaffMark: ''
            };

            return sum;
          }, {});
        }
      }
    };
  }
]);
