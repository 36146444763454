import angular from 'angular';
import { get } from 'lodash';
import app from '../../app';

app.directive('tagPanel', [
  '$rootScope',
  'Tag',
  'ErrorService',
  'SolrDocument',
  'ModalService',
  'SpinnerService',
  '$q',
  'Manufacturer',
  'CompanyService',
  '$filter',
  function (
    $rootScope,
    Tag,
    ErrorService,
    SolrDocument,
    ModalService,
    SpinnerService,
    $q,
    Manufacturer,
    CompanyService,
    $filter
  ) {
    return {
      restrict: 'E',
      scope: {
        parentCtrl: '=',
        editable: '=',
        documentId: '=',
        documentCompanies: '=',
        enabledIds: '=',
        manufacturerId: '=',
        isMsds: '=',
        isNoSds: '=',
        companyId: '=',
        onSave: '=',
        restrictedNote: '='
      },
      templateUrl: require('./tagPanel.html'),
      link: function (scope, elem) {
        const getTagsTitle = $filter('tagTitle');
        scope.groupTagsByParent = false;
        CompanyService.CompanySettings(scope.companyId, false).$promise.then(settings => {
          scope.groupTagsByParent = settings.groupTagsByParent;
        });
        scope.tags = [];
        scope.manufacturer = {};
        scope.companyTags = [];
        scope.visible = function (tag) {
          return tag.enabled;
        };
        scope.restricted = function (tag) {
          return tag.restricted;
        };
        scope.archived = function (tag) {
          return tag.archived;
        };
        scope.sensitive = function (tag) {
          return tag.sensitive;
        };
        scope.universalTag = function (tag) {
          return !scope.restricted(tag) && !tag.companyId;
        };
        scope.companyTag = function (tag) {
          return !scope.restricted(tag) && tag.companyId;
        };
        scope.tagTitle = function (tag) {
          return getTagsTitle(tag);
        };
        scope.hasEnabledTags = function (tags) {
          return tags.filter(scope.visible).length;
        };
        scope.noEnabledTags = function (tags) {
          return !scope.hasEnabledTags(tags);
        };
        function getTitleRecursive(tagId, flatTags) {
          let ret = [];
          for (let index = 0; index < flatTags.length; index++) {
            const element = flatTags[index];
            if (element.id === tagId) {
              if (element.tagId) {
                ret = getTitleRecursive(element.tagId, flatTags);
                ret.push(element.title);
              } else ret.push(element.title);
              break;
            }
          }

          return ret;
        }
        function groupTags(flatTags, ids, docCompanyIds) {
          const tags = angular.copy(flatTags).reduce(function (acc, parentTag) {
            parentTag.tags = flatTags.filter(function (tag) {
              if (tag.tagId !== parentTag.id) {
                return false;
              } else if (ids.indexOf(tag.id) < 0) {
                return false;
              } else if (tag.companyId && !~(docCompanyIds || []).indexOf(tag.companyId)) {
                return false;
              }
              return true;
            }, []);

            if (parentTag.tags.length > 0) {
              const groupTagEnabled =
                $rootScope.companySettings.groupTagEnabled ||
                $rootScope.companySettings.groupTagsByParent;
              parentTag.groupTitle = parentTag.title;
              if (groupTagEnabled && parentTag.tags.length !== 1) {
                const parentTitle = getTitleRecursive(parentTag.id, flatTags);
                parentTag.groupTitle = parentTitle.join(' / ');
                parentTag.enabled = true;
                acc.push(parentTag);
              } else acc = acc.concat(parentTag.tags);
            } else if (!parentTag.tagId) {
              acc = acc.concat(parentTag);
            }

            return acc;
          }, []);
          return tags;
        }
        function getMainParentTag(tag, flatTags, path = []) {
          let rootTag = angular.copy(flatTags.find(el => el.id === tag.tagId));
          rootTag.pathToChild = path;
          if (rootTag.tagId && rootTag.tagId.length > 0) {
            rootTag = getMainParentTag(rootTag, flatTags, [rootTag.title, ...path]);
          }
          return rootTag;
        }

        function groupTagsByMain(flatTags, ids, docCompanyIds) {
          let tags = {};
          const tagsFiltered = flatTags.filter(function (tag) {
            if (ids.indexOf(tag.id) < 0) {
              return false;
            } else if (tag.companyId && !~(docCompanyIds || []).indexOf(tag.companyId)) {
              return false;
            }
            return true;
          }, []);

          angular.copy(tagsFiltered).forEach(tag => {
            if (tag.tagId && tag.tagId.length > 0) {
              let rootTag = getMainParentTag(tag, flatTags);
              rootTag.groupTitle = rootTag.title;
              tag.title = [...rootTag.pathToChild, tag.title].join(' > ');
              rootTag.tags = [...get(tags, `${rootTag.id}.tags`, []), tag];
              rootTag.enabled = true;

              tags[rootTag.id] = rootTag;
            } else {
              tags[tag.id] = tag;
            }
          });
          return Object.values(tags);
        }

        scope.getAvailableTags = function (tags) {
          const knownIds = tags.map(function (tag) {
              return tag.id;
            }),
            // either has no parent or parent present among known ids
            hasValidParent = function (tag) {
              return !tag.tagId || ~knownIds.indexOf(tag.tagId);
            },
            isParent = function (tag) {
              return tags.some(function (otherTag) {
                return otherTag.tagId === tag.id;
              });
            };
          return tags.filter(function (tag) {
            return hasValidParent(tag) && !isParent(tag);
          });
        };
        scope.getTags = function (companyId, enabledIds) {
          const universalFilter = { where: { companyId: '' } };
          let tagsPromise;

          if (companyId) {
            const companyFilter = { where: { companyId: companyId } };

            tagsPromise = $q.all([
              Tag.find({ filter: universalFilter }).$promise,
              Tag.find({ filter: companyFilter }).$promise
            ]);
          } else {
            tagsPromise = $q.all([Tag.find({ filter: universalFilter }).$promise]);
          }

          return tagsPromise
            .then(function (resp) {
              const documentCompanyIds = scope.documentCompanies.map(company => company.id);
              const universalTags = resp[0];
              scope.companyTags = companyId ? resp[1] : [];
              scope.tags = universalTags.concat(scope.companyTags).map(function (tag) {
                tag.enabled =
                  !!~enabledIds.indexOf(tag.id) &&
                  (documentCompanyIds || []).some(function (cid) {
                    return tag.companyId ? tag.companyId === cid : true;
                  });
                return tag;
              });

              scope.parentCtrl.tagsFromPanel = scope.getAvailableTags(scope.tags);
              scope.tags = scope.groupTagsByParent
                ? groupTagsByMain(scope.tags, enabledIds, documentCompanyIds)
                : groupTags(scope.tags, enabledIds, documentCompanyIds);
            })
            .catch(ErrorService.simpleCatch);
        };
        scope.getManufacturer = function (manufacturerId) {
          return (
            manufacturerId &&
            Manufacturer.findOne({
              filter: {
                where: {
                  id: manufacturerId
                },
                fields: ['id', 'name']
              }
            }).$promise.then(function (resp) {
              scope.manufacturer = scope.parentCtrl.manufacturerFromPanel = resp;
            })
          );
        };
        scope.$watch('enabledIds', function (enabledIds) {
          scope.enabledIds = enabledIds || [];
          scope.getTags(scope.companyId, scope.enabledIds);
          scope.getManufacturer(scope.manufacturerId);
        });
        const setTags = SpinnerService.wrap(function (params) {
          const payload = {
            id: scope.documentId,
            companyId: scope.companyId,
            ids: params.ids
          };
          return SolrDocument.setTags(payload)
            .$promise.then(function () {
              return SolrDocument.updateRestrictedNote(
                { id: scope.documentId },
                { companyId: scope.companyId, text: params.restrictedNote }
              ).$promise;
            })
            .then(function () {
              scope.onSave(params);
            })
            .catch(ErrorService.simpleCatch);
        });
        scope.edit = function () {
          return ModalService.open({
            controller: 'tagsModalCtrl',
            controllerAs: 'ctrl',
            templateUrl: require('../../controllers/tagsModalCtrl/tagsModal.html'),
            resolve: {
              companyTags: function () {
                return scope.companyTags;
              },
              enabledIds: function () {
                return scope.enabledIds;
              },
              restrictedNote: function () {
                return scope.restrictedNote;
              }
            }
          }).then(setTags);
        };
      }
    };
  }
]);
