import app from '../../app';

app.directive('docCode', [
  function () {
    return {
      restrict: 'E',
      scope: {
        code: '='
      },
      templateUrl: require('./docCode.html')
    };
  }
]);
