import app from '../app';

app.service('InventoryContainerService', [
  '$q',
  'InventoryContainer',
  'InventoryContainerMapping',
  'MessagesService',
  'ErrorService',
  'SystemAuditService',
  function (
    $q,
    InventoryContainer,
    InventoryContainerMapping,
    MessagesService,
    ErrorService,
    SystemAuditService
  ) {
    var simpleCatch = ErrorService.simpleCatch;

    this.createContainer = function (container) {
      return InventoryContainer.create(container)
        .$promise.then(function (createdContainer) {
          MessagesService.success('COMMON.MESSAGES.CREATED');

          SystemAuditService.create({
            action: 'created',
            objectType: SystemAuditService.CONTAINER_TYPE,
            objectName: createdContainer.name,
            objectId: createdContainer.id,
            companyId: createdContainer.companyId,
            newValue: 'size: ' + createdContainer.size
          });

          return createdContainer;
        })
        .catch(simpleCatch);
    };

    this.removeContainer = function (container) {
      return InventoryContainer.deleteById({ id: container.id })
        .$promise.then(function () {
          MessagesService.success('COMMON.MESSAGES.DELETED');

          SystemAuditService.create({
            action: 'removed',
            objectType: SystemAuditService.CONTAINER_TYPE,
            objectName: container.name,
            objectId: container.id,
            companyId: container.companyId
          });
        })
        .catch(simpleCatch);
    };

    this.updateAmounts = function (amounts, auditOptions) {
      return $q
        .all(
          amounts.map(function (amount) {
            InventoryContainerMapping.upsert(amount);
          })
        )
        .then(function () {
          MessagesService.success('COMMON.MESSAGES.UPDATED');

          if (auditOptions) {
            auditOptions.type = SystemAuditService.CONTAINER_TYPE;

            SystemAuditService.createAuditEntryUpdateInInventories(auditOptions);
          }
        });
    };

    this.getContainers = function (perPage, startFrom, companyId) {
      const filter = {
        filter: {
          limit: perPage,
          offset: startFrom,
          where: { companyId: companyId },
          include: {
            relation: 'product',
            scope: {
              fields: ['sdsId', 'sdsRequired', 'bannedInCompanies']
            }
          }
        }
      };

      return InventoryContainer.find(filter).$promise.catch(simpleCatch);
    };

    this.getContainerById = function (id) {
      return InventoryContainer.findById({ id: id }).$promise.catch(
        simpleCatch
      );
    };

    this.inventories = function (containerId) {
      return InventoryContainer.inventories({ id: containerId }).$promise.catch(
        simpleCatch
      );
    };

    /**
     * Returns a promise that resolves with an array with length equals the number of pages.
     * This array is used to build a pagination with ng-repeat through it.
     *
     * @param {number} perPage
     * @param {string} companyId
     * @returns {Promise<Array>}
     */
    this.getTotalPages = function (perPage, companyId) {
      var filter = { where: { companyId: companyId } };
      perPage = perPage || 20;

      return InventoryContainer.count(filter)
        .$promise.then(function (totalContainers) {
          return totalContainers.count;
        })
        .catch(simpleCatch);
    };
  }
]);
