import angular from 'angular';
import app from '../../app';

app.directive('customSelect', [
  '$http',
  '$rootScope',
  function ($http, $rootScope) {
    return {
      templateUrl: require('./customSelect.html'),
      restrict: 'E',
      scope: {
        itemList: '=',
        selected: '=',
        onSelectedChange: '='
      },
      link: function (scope, element, attrs, uiGridCtrl) {
        if (!scope.onSelectedChange) {
          scope.onSelectedChange = angular.noop;
        }

        scope.$watch('selectedValue.value', function (newValue, oldValue) {
          newValue = newValue || {};
          oldValue = oldValue || {};
          scope.selected = newValue.id;

          if (newValue.id != oldValue.id) {
            scope.onSelectedChange();
          }
        });
        scope.getLangTitle = obj => obj[`title_${$rootScope.siteLanguage}`] || obj.title;
        if (Array.isArray(scope.itemList)) {
          scope.itemList.unshift({ id: '', title: '' });

          if (scope.selected) {
            const found = scope.itemList.some(function (element) {
              if (element.id == scope.selected) {
                scope.selectedValue = { value: element };
                return true;
              }
              return false;
            });
            if (!found) {
              // happens when _selected_ value is not present amound _item-list_
              scope.selectedValue = { value: scope.itemList[0] };
            }
          } else {
            scope.selectedValue = { value: scope.itemList[0] };
          }
        }
      }
    };
  }
]);
