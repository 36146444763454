import app from '../../app';
import scheduleReport from '../../modals/scheduleReport';
import { getOffset } from '../../helpers';

app.controller('bannedSearchJournalCtrl', [
  '$rootScope',
  'BannedSearchJournal',
  'ErrorService',
  'ExportService',
  'SpinnerService',
  'CompanyService',
  'RoleFactory',
  'SchedulerService',
  'ModalService',
  function (
    $rootScope,
    BannedSearchJournal,
    ErrorService,
    ExportService,
    SpinnerService,
    CompanyService,
    RoleFactory,
    SchedulerService,
    ModalService
  ) {
    const vm = this;

    vm.startFrom = 1;
    vm.PER_PAGE = 20;
    vm.totalItems = 0;
    vm.companies = [];
    vm.journal = [];
    vm.selectedCompany = {};

    vm.find = SpinnerService.wrap(function (companyId, limit, offset, order) {
      const where = companyId == null ? {} : { 'company.id': companyId };
      const filter = {
        where: where,
        limit: limit,
        offset: offset,
        order: order || 'timestamp DESC'
      };
      return BannedSearchJournal.find({ filter: filter }).$promise.catch(ErrorService.simpleCatch);
    });

    vm.retrieve = function (companyId, limit, offset, order) {
      return vm.find(companyId, limit, offset, order).then(function (data) {
        vm.journal = data;
      });
    };

    vm.count = SpinnerService.wrap(function (companyId) {
      const where = companyId == null ? {} : { 'company.id': companyId };
      return BannedSearchJournal.count({ where: where })
        .$promise.then(function (resp) {
          vm.totalItems = resp.count;
        })
        .catch(ErrorService.simpleCatch);
    });

    vm.handleExport = SpinnerService.wrap(function () {
      return vm.find(vm.selectedCompany.id).then(ExportService.exportBannedSearchReport2csv);
    });

    vm.handlePaginate = function () {
      vm.retrieve(vm.selectedCompany.id, vm.PER_PAGE, getOffset(vm.startFrom, vm.PER_PAGE));
    };

    vm.onCompanySelected = function (selectedCompany) {
      vm.selectedCompany = selectedCompany;
      vm.startFrom = 1;

      vm.count(vm.selectedCompany.id);
      vm.retrieve(vm.selectedCompany.id, vm.PER_PAGE, 0);
    };

    vm.composeUser = function (record) {
      return record.user && record.user.role
        ? record.user.username + ' (' + RoleFactory.getVisibleRoleName(record.user.role) + ')'
        : record.user
          ? record.user.username
          : '';
    };

    vm.openScheduleModal = function () {
      return ModalService.open(scheduleReport()).then(res => {
        if ($rootScope.checkIfUserIs('admin') && (!vm.selectedCompany || !vm.selectedCompany.id)) {
          return SchedulerService.createReportForAllCompanies(
            res,
            vm.companies,
            null,
            createScheduledReport
          );
        } else {
          return createScheduledReport(res, $rootScope.companyId);
        }
      });
    };

    init();

    function init() {
      if (!$rootScope.checkIfUserIs('admin')) {
        CompanyService.getCurrentCompanyPromise().$promise.then(function () {
          if ($rootScope.currentCompany.childSdsCompanies.length) return; // handle this by companySelector

          vm.onCompanySelected({ id: $rootScope.companyId });
        });
      }
    }

    function createScheduledReport(formObj, companyId, params = {}, quiet = false) {
      return SchedulerService.createReport(
        SchedulerService.jobs.report.BANNED_CHEMICAL_SEARCHES_REPORT,
        companyId,
        formObj.emails,
        formObj.frequency,
        params,
        quiet
      );
    }
  }
]);
