import app from '../../app';
import scheduleReport from '../../modals/scheduleReport';
import { getOffset } from '../../helpers';

app.controller('sdsSearchJournalCtrl', [
  '$rootScope',
  'CompanyService',
  'CompanyFactory',
  'SpinnerService',
  'SdsSearchJournal',
  'ExportService',
  'RoleFactory',
  'SchedulerService',
  'ModalService',
  'ReportService',
  function (
    $rootScope,
    CompanyService,
    CompanyFactory,
    SpinnerService,
    SdsSearchJournal,
    ExportService,
    RoleFactory,
    SchedulerService,
    ModalService,
    ReportService
  ) {
    const vm = this;
    const getJournalRecords = SpinnerService.wrap(async function () {
      const companyFilter = vm.selectedCompany.id ? { 'company.id': vm.selectedCompany.id } : {};
      const offset = getOffset(vm.startFrom, vm.PER_PAGE);

      await ReportService.getDictionary();

      return SdsSearchJournal.count({
        where: companyFilter
      }).$promise.then(function (response) {
        const count = response.count;

        if (!count) return (vm.journal = []);

        return SdsSearchJournal.find({
          filter: {
            where: companyFilter,
            limit: vm.PER_PAGE,
            offset
          }
        }).$promise.then(function (records) {
          vm.totalItems = vm.totalItems || count;
          vm.journal = records;
          vm.journal.forEach(ReportService.fillRecordWithFilterValues);
        });
      });
    });
    vm.startFrom = 1;
    vm.PER_PAGE = 20;
    vm.journal = [];
    vm.selectedCompany = {};

    vm.handlePaginate = getJournalRecords;

    vm.handleExport = function () {
      ExportService.exportSdsSearchJournal2csv(vm.selectedCompany.id);
    };

    vm.composeUser = function (record) {
      return record.user && record.user.role
        ? record.user.username + ' (' + RoleFactory.getVisibleRoleName(record.user.role) + ')'
        : record.user
          ? record.user.username
          : '';
    };

    vm.onCompanySelected = function (selectedCompany) {
      vm.selectedCompany = selectedCompany;
      vm.startFrom = 1;
      vm.totalItems = null;

      getJournalRecords(vm.startFrom);
    };

    vm.openScheduleModal = function () {
      return ModalService.open(scheduleReport()).then(res => {
        if ($rootScope.checkIfUserIs('admin') && (!vm.selectedCompany || !vm.selectedCompany.id)) {
          return SchedulerService.createReportForAllCompanies(
            res,
            vm.companies,
            null,
            createScheduledReport
          );
        } else {
          return createScheduledReport(res, $rootScope.companyId);
        }
      });
    };

    if ($rootScope.checkIfUserIs('admin')) {
      CompanyFactory.GetCompanies(function (companies) {
        vm.companies = companies;
      });
    } else {
      CompanyService.getCurrentCompanyPromise().$promise.then(function () {
        if ($rootScope.currentCompany.childSdsCompanies.length) return; // handle this by companySelector

        vm.onCompanySelected({ id: $rootScope.companyId });
      });
    }

    function createScheduledReport(formObj, companyId, params = {}, quiet = false) {
      return SchedulerService.createReport(
        SchedulerService.jobs.report.SEARCHES_WITHOUT_RESULTS_REPORT,
        companyId,
        formObj.emails,
        formObj.frequency,
        params,
        quiet
      );
    }
  }
]);
