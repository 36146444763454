import { values } from 'lodash';
import app from '../app';
import { getTagPath, getTreePath } from '../helpers';

app.service(
  'SchedulerService',
  function (
    $rootScope,
    $filter,
    SchedulerApi,
    CompanyService,
    SpinnerService,
    MessagesService,
    TagService,
    InventoryService,
    ErrorService
  ) {
    const self = this;
    const simpleCatch = ErrorService.simpleCatch;
    const yesNoFilter = $filter('yesNo');
    const ADDITIONAL_PARAMS = {
      tags2exclude: 'tags2exclude',
      msdsFormat: 'msdsFormat',
      companyInclude: 'companyInclude',
      showSDSTags: 'showSDSTags',
      showSDSInventory: 'showSDSInventory',
      tagIds: 'tagIds',
      inventoryIds: 'inventoryIds'
    };
    const additionalParamTexts = {
      [ADDITIONAL_PARAMS.tags2exclude]: 'REPORTS.CHECKED_DATE.HIDE_FROM_REPORT',
      [ADDITIONAL_PARAMS.msdsFormat]: 'REPORTS.CHECKED_DATE.MSDS_ONLY',
      [ADDITIONAL_PARAMS.companyInclude]: 'COMMON.LABELS.INCLUDE',
      [ADDITIONAL_PARAMS.showSDSTags]: 'COMMON.LABELS.SHOW_SDS_TAGS',
      [ADDITIONAL_PARAMS.showSDSInventory]: 'COMMON.LABELS.SHOW_SDS_INVENTORY',
      [ADDITIONAL_PARAMS.tagIds]: 'TAGS.FILTER_BY_TAGS',
      [ADDITIONAL_PARAMS.inventoryIds]: 'INVENTORY.FILTER_BY_INVENTORY'
    };

    let initPromise;
    let companyId;
    let universalTagsObj;
    let companyTagsObj;
    let companyInventoryObj;

    this.records = [];

    this.frequencies = {
      d: 'daily',
      w: 'weekly',
      m: 'monthly'
    };
    this.jobs = {
      email: { OPEN_SDS_REQUESTS_REMINDER: 'open_SDS_requests_reminder' },
      report: {
        PAGE_VIEW_REPORT: 'page_view_report',
        INVENTORY_AUDIT_REPORT: 'inventory_audit_report',
        SDS_AUDIT_REPORT: 'sds_audit_report',
        BANNED_CHEMICAL_SEARCHES_REPORT: 'banned_chemical_searches_report',
        CHECKED_DATE_REPORT: 'checked_date_report',
        ARCHIVED_SDS_REPORT: 'archived_sds_report',
        SDS_LIST_BY_TAG_REPORT: 'sds_list_by_tag_report',
        SEARCHES_WITHOUT_RESULTS_REPORT: 'searches_without_results_report',
        ADDED_REMOVED_SDSS_REPORT: 'added_removed_sdss_report',
        WCL_REPORT: 'wcl_report',
        DISCONTINUED_REPORT: 'discontinued_report',
        FIRE_RESPONSE_REPORT: 'fire_response_report',
        SECTION313_REPORT: 'section313_report',
        CERCLA_REPORT: 'CERCLA_report'
      }
    };

    this.onCompanySelected = function (company) {
      companyId = company.id;

      return Promise.all([
        TagService.getCompanyTags(companyId).then(() => {
          companyTagsObj = TagService.companyTagsLookup;
        }),
        InventoryService.getTreeInfo(companyId).then(response => {
          companyInventoryObj = companyInventoryObj || {};
          companyInventoryObj[companyId] = response.toJSON();
        })
      ]).then(() => companyId);
    };

    this.getReports = SpinnerService.wrap(function (companyId) {
      return self
        .reportsInit()
        .then(() =>
          SchedulerApi.getReports({ companyId }).$promise.then(res => {
            self.records = res.map(record => ({
              id: record._id,
              name: record.name,
              nameTranslationKey: getReportNameTranslationKey(record.name),
              companyId: record.data.companyId,
              frequency: record.data.frequency,
              emails: record.data.emails,
              params: composeReadableParams(companyId, record.data, record.name),
              failed: !!record.failCount
            }));
          })
        )
        .catch(simpleCatch);
    });
    this.createReport = function (
      reportName,
      companyId,
      emails,
      frequency,
      params = {},
      quiet = false
    ) {
      return SchedulerApi.postReports(
        {
          reportName
        },
        {
          companyId,
          emails,
          frequency,
          params: { ...params }
        }
      )
        .$promise.then(res => {
          if (!quiet) MessagesService.success();

          return res;
        })
        .catch(err => {
          if (!quiet) return simpleCatch(err);

          return err;
        });
    };
    this.updateReport = function (reportName, id, emails, frequency, quiet = false) {
      return SchedulerApi.putReports(
        {
          reportName,
          id
        },
        {
          emails,
          frequency
        }
      )
        .$promise.then(res => {
          if (!quiet) MessagesService.success();

          return res;
        })
        .catch(err => {
          if (!quiet) return simpleCatch(err);

          return err;
        });
    };
    this.removeReport = function (reportName, id, quiet = false) {
      return SchedulerApi.deleteReports({
        reportName,
        id
      })
        .$promise.then(res => {
          if (!quiet) MessagesService.success();

          return res;
        })
        .catch(err => {
          if (!quiet) return simpleCatch(err);

          return err;
        });
    };

    this.createReportForAllCompanies = SpinnerService.wrap(function (
      formObj = {},
      companies = [],
      params = {},
      fn
    ) {
      if (!$rootScope.checkIfUserIs('admin')) return Promise.reject('Forbidden');

      const quiet = true;
      const companiesWithId = companies.filter(company => company.id);

      return Promise.all(
        companiesWithId.map(company => fn(formObj, company.id, params, quiet))
      ).then(results => {
        const successResults = results.filter(result => !result.data || !result.data.error);
        const failedResults = results.filter(result => result.data && result.data.error);

        if (successResults.length) MessagesService.success();

        failedResults.forEach(failedResult => {
          const failedCompanyId = failedResult.config.data.companyId;
          const failedCompany = companiesWithId.find(company => company.id === failedCompanyId);
          const message = failedResult.data.error.message;

          MessagesService.error(`${message} - ${failedCompany.name}`);
        });
      });
    });

    this.reportsInit = SpinnerService.wrap(function () {
      if (initPromise) return initPromise;

      let promise = Promise.resolve();

      if (!$rootScope.checkIfUserIs('admin')) {
        promise = CompanyService.getCurrentCompanyPromise().$promise.then(function () {
          return (companyId = $rootScope.companyId);
        });
      }

      initPromise = Promise.all([
        promise,
        TagService.getUniversalTags({ fields: ['id', 'title'] })
      ]).then(([companyId]) => {
        universalTagsObj = TagService.universalTagsLookup;

        return Promise.all([
          TagService.getCompanyTags(companyId).then(() => {
            companyTagsObj = TagService.companyTagsLookup;

            if (companyId) self.getReports(companyId);
          }),
          InventoryService.getTreeInfo(companyId).then(response => {
            companyInventoryObj = companyInventoryObj || {};
            companyInventoryObj[companyId] = response.toJSON();
          })
        ]);
      }).finally(() => {
        initPromise = undefined;
      });

      return initPromise;
    });

    function composeReadableParams(companyId, jobAttrs = {}, jobName = '') {
      const params = Object.keys(ADDITIONAL_PARAMS).reduce((sum, aParam) => {
        if (jobAttrs[aParam] != null) {
          sum.push({
            key: aParam,
            translationKey: additionalParamTexts[aParam],
            value: jobAttrs[aParam]
          });
        }

        return sum;
      }, []);

      addDefaultCompanyInclude(params, jobName);

      params.forEach(param => {
        switch (param.key) {
          case ADDITIONAL_PARAMS.tags2exclude:
            param.value = param.value.map(tagId => universalTagsObj[tagId].title).join(', ');
            break;
          case ADDITIONAL_PARAMS.showSDSTags:
          case ADDITIONAL_PARAMS.showSDSInventory:
          case ADDITIONAL_PARAMS.msdsFormat:
            param.value = yesNoFilter(param.value);
            break;
          case ADDITIONAL_PARAMS.companyInclude:
            param.value = jobAttrs.tagsChange
              ? 'REPORTS.ADDED_REMOVED_SDS.STAGE.TAGS_CHANGE'
              : param.value != null
              ? param.value
                ? 'REPORTS.ADDED_REMOVED_SDS.STAGE.ADDED'
                : 'REPORTS.ADDED_REMOVED_SDS.STAGE.REMOVED'
              : 'REPORTS.ADDED_REMOVED_SDS.STAGE.ALL';
            break;
          case ADDITIONAL_PARAMS.tagIds:
            param.value =
              param.value.length && companyId && companyTagsObj
                ? param.value
                    .map(tagId => getTagPath(values(companyTagsObj[companyId]), tagId).join(' / '))
                    .join('; ')
                : yesNoFilter(!!param.value.length);
            break;
          case ADDITIONAL_PARAMS.inventoryIds:
            param.value =
              param.value.length && companyId && companyInventoryObj
                ? param.value
                    .map(inventoryId =>
                      getTreePath(
                        values(companyInventoryObj[companyId]).map(i => i.data),
                        inventoryId
                      ).join(' / ')
                    )
                    .join('; ')
                : yesNoFilter(!!param.value.length);
            break;
        }
      });

      return params;
    }

    function addDefaultCompanyInclude(params, jobName) {
      const aParam = ADDITIONAL_PARAMS.companyInclude;

      if (
        jobName === self.jobs.report.ADDED_REMOVED_SDSS_REPORT &&
        !params.some(param => param.key === aParam)
      ) {
        params.push({
          key: aParam,
          translationKey: additionalParamTexts[aParam],
          value: null
        });
      }
    }

    function getReportNameTranslationKey(reportName) {
      switch (reportName) {
        case self.jobs.report.PAGE_VIEW_REPORT:
          return 'REPORTS.GA.TITLE';
        case self.jobs.report.INVENTORY_AUDIT_REPORT:
          return 'REPORTS.INVENTORY_AUDIT_TRAIL.TITLE';
        case self.jobs.report.SDS_AUDIT_REPORT:
          return 'REPORTS.AUDIT_LOG.TITLE';
        case self.jobs.report.BANNED_CHEMICAL_SEARCHES_REPORT:
          return 'REPORTS.BANNED_SEARCH_JOURNAL.TITLE';
        case self.jobs.report.CHECKED_DATE_REPORT:
          return 'REPORTS.CHECKED_DATE.TITLE';
        case self.jobs.report.ARCHIVED_SDS_REPORT:
          return 'REPORTS.ARCHIVED_SDS_REPORT.TITLE';
        case self.jobs.report.SDS_LIST_BY_TAG_REPORT:
          return 'REPORTS.SDS_LIST_BY_TAG.TITLE';
        case self.jobs.report.SEARCHES_WITHOUT_RESULTS_REPORT:
          return 'REPORTS.SDS_SEARCH_JOURNAL.TITLE';
        case self.jobs.report.ADDED_REMOVED_SDSS_REPORT:
          return 'REPORTS.ADDED_REMOVED_SDS.TITLE';
        case self.jobs.report.WCL_REPORT:
          return 'REPORTS.WCL.TITLE';
        case self.jobs.report.DISCONTINUED_REPORT:
          return 'REPORTS.DISCONTINUED.TITLE';
        case self.jobs.report.FIRE_RESPONSE_REPORT:
          return 'REPORTS.FIRE_RESPONSE_REPORTS.TITLE';
        case self.jobs.report.SECTION313_REPORT:
          return 'REPORTS.SECTION313_REPORT.TITLE';
        case self.jobs.report.CERCLA_REPORT:
          return 'REPORTS.CERCLA_PRIORITY_LIST_REPORT.TITLE';
      }
    }
  }
);
