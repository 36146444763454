import app from '../../app';

app.controller('viewQRcodeCtrl', [
  '$window',
  '$state',
  '$stateParams',
  '$scope',
  '$rootScope',
  '$translate',
  'SolrDocument',
  'CompanyService',
  'QuizService',
  'GoogleClassroomService',
  'MessagesService',
  'usSpinnerService',
  'ErrorService',
  'SpinnerService',
  function (
    $window,
    $state,
    $stateParams,
    $scope,
    $rootScope,
    $translate,
    SolrDocument,
    CompanyService,
    QuizService,
    GoogleClassroomService,
    MessagesService,
    usSpinnerService,
    ErrorService,
    SpinnerService
  ) {
    var vm = this;
    var QRcodeId = $stateParams.id;
    var quizzesAvailable = true;
    var translations = {};
    var GCpromise = GoogleClassroomService.handleClientLoad();
    var simpleCatch = ErrorService.simpleCatch;
    var spinner = {
      show: function (spinner) {
        usSpinnerService.spin(spinner);
        vm.showSpinner = true;
      },
      hide: function (spinner) {
        usSpinnerService.stop(spinner);
        vm.showSpinner = false;
      }
    };

    vm.QRcodeModel = {};
    vm.quizAnswers = {};
    vm.languages = $rootScope.languages;

    $translate(['QUIZ.ANSWERS', 'QR_CODE.QUIZ_ANSWERS.PARTICIPANT_NAME']).then(function (results) {
      translations = results;
    });

    vm.goBack = function () {
      window.history.back();
    };

    vm.getEquipmentDocumentPreviewLink = function (doc) {
      switch (doc.type) {
        case 'link':
          return /^https?:\/\//i.test(doc.src) ? doc.src : 'http://' + doc.src;
        case 'file':
          return '/api/containers/equipment/download/' + doc.src;
      }
    };

    vm.toggleAnswer = function (quiz, question, answer) {
      var answers = quiz[question] || [];
      var answerIndex = answers.indexOf(answer);

      if (~answerIndex) {
        answers.splice(answerIndex, 1);
      } else {
        answers.push(answer);
      }

      quiz[question] = answers;
    };

    vm.validateLinksShouldBeViewed = linksShouldBeViewed();

    vm.isQuizzesAvailable = function () {
      if (!quizzesAvailable) {
        MessagesService.warning('QR_CODE.QUIZ_REQUIREMENTS');
      }

      return quizzesAvailable;
    };

    vm.printResults = function () {
      $window.print();
    };

    vm.emailResults = function (answeredQuiz, originalQuiz) {
      spinner.show('spinner-1');

      const results = justSortResults(answeredQuiz, originalQuiz);
      const mailTo = originalQuiz.recipientEmail;

      QuizService.emailResults(mailTo, vm.participantName, vm.QRcodeModel, originalQuiz, results)
        .then(function (res) {
          MessagesService.success('QUIZ.SUBMITTED');
          spinner.hide('spinner-1');
          clearFormFields(answeredQuiz, originalQuiz);
        })
        .catch(function (err) {
          simpleCatch(err);
          spinner.hide('spinner-1');
          throw Error(err);
        });
    };

    vm.sendResultsToClassroom = function (answeredQuiz, originalQuiz) {
      spinner.show('spinner-1');

      var results = formatResults(answeredQuiz, originalQuiz);
      var afterUserSignedIn = userSignedIn(
        originalQuiz.courseID,
        originalQuiz.courseWorkID,
        results,
        originalQuiz.title
      );

      GCpromise.then(function () {
        return GoogleClassroomService.handleAuthClick().then(afterUserSignedIn);
      }).catch(function () {
        vm.showSpinner = false;
      });
    };

    vm.shouldShowHiddenDocument = function (doc) {
      if (!doc.hidden) return true;

      var allowedRoles = ['teacher', 'teamAdmin'];

      return allowedRoles.some($rootScope.checkIfUserIs);
    };

    vm.isHSEmoduleEnabled = $rootScope.isModuleEnabled.bind(null, $rootScope.moduleNames.hse);

    $scope.$on('$stateChangeSuccess', function (ev, to, toParams, from, fromParams) {
      if (from.name) $scope.showBackButton = true;
    });

    SpinnerService.wrap(function () {
      return CompanyService.getIPWhitelistedPromise().then(() =>
        SolrDocument.getDocumentsByQRCode({
          qrcode: QRcodeId,
          showHiddenEquipmentDocuments: true,
          availableLanguages: $rootScope.languages,
          preferredLanguage: $rootScope.siteLanguage,
          skipEncodingNames: true
        })
          .$promise.then(function (res) {
            const qrcode = res.response && res.response.qrcode;

            if (qrcode) {
              qrcode.documents = res.response.docs.map(doc => {
                doc.tag = [];
                return doc;
              });
              qrcode.equipment = res.response.equipment || [];
              qrcode.quizzes = res.response.quizzes;

              if (qrcode.picture) {
                qrcode.picturePreview =
                  '/api/containers/qrcode_images/download/' + res.response.qrcode.picture;
              }

              linksShouldBeViewed.gather(qrcode.documents);

              qrcode.equipment.forEach(function (equipment) {
                var equipmentDocuments = equipment.documents;

                if (equipmentDocuments && equipmentDocuments.length) {
                  linksShouldBeViewed.gather(equipmentDocuments);
                }
              });
            }

            return qrcode;
          })
          .then(qrcode => {
            if (qrcode && !checkVisibilityAccess(qrcode)) {
              return $state.go('search');
            }

            vm.QRcodeModel = qrcode;
          })
      );
    })();

    function checkVisibilityAccess(qrcode) {
      const visibility = qrcode.visibility || [];
      const showToAll =
        visibility.includes('teamMember') &&
        visibility.includes('teamAdmin') &&
        visibility.includes('teacher') &&
        visibility.includes('admin');

      return (
        showToAll ||
        $rootScope.checkIfUserIs(visibility) ||
        (visibility.includes('teamMember') && $rootScope.checkIfUserIs())
      );
    }

    function userSignedIn(courseId, courseWorkId, results, quizTitle) {
      var submissionMeta = {
        courseId: courseId,
        courseWorkId: courseWorkId
      };

      return function () {
        GoogleClassroomService.listStudentSubmissions(submissionMeta)
          .then(function (studentSubmissions) {
            submissionMeta.submissionId = studentSubmissions[0].id;
            submissionMeta.state = studentSubmissions[0].state;

            if (submissionMeta.state !== 'TURNED_IN') {
              submitResults(submissionMeta, results, quizTitle);
            } else {
              GoogleClassroomService.reclaimSubmission(submissionMeta).then(function () {
                submitResults(submissionMeta, results, quizTitle);
              });
            }
          })
          .catch(function (err) {
            simpleCatch(err);
            spinner.hide('spinner-1');
            throw Error(err);
          });
      };
    }

    function submitResults(submissionMeta, results, quizTitle) {
      GoogleClassroomService.createGdocWithResults(results, quizTitle)
        .then(function (response) {
          submissionMeta.documentId = response.result.id;

          return GoogleClassroomService.modifySubmissionAttachments(submissionMeta);
        })
        .then(function () {
          return GoogleClassroomService.turnInSubmission(submissionMeta);
        })
        .then(function () {
          MessagesService.success('QUIZ.SUBMITTED');
          spinner.hide('spinner-1');
        })
        .catch(function (err) {
          simpleCatch(err);
          spinner.hide('spinner-1');
          throw Error(err);
        });
    }

    function linksShouldBeViewed() {
      var links = [];

      linksShouldBeViewed.gather = function (docs) {
        var notHiddenDocs = docs.filter(function (doc) {
          return vm.shouldShowHiddenDocument(doc);
        });

        quizzesAvailable = notHiddenDocs.length ? false : true;
        links = links.concat(notHiddenDocs);
      };

      return function (link) {
        if (link.isViewed) return;

        link.isViewed = true;

        quizzesAvailable = links.every(function (link) {
          return link.isViewed;
        });
      };
    }

    function formatResults(answeredQuiz, originalQuiz) {
      var formattedResults = '';
      var unsortedArrOfQuestionText = Object.keys(answeredQuiz);
      var sortedArrOfQuestionsText = sortResults(answeredQuiz, originalQuiz).map(function (
        question
      ) {
        var questionText = '';

        unsortedArrOfQuestionText.some(function (unsortedQuestion) {
          if (question[unsortedQuestion]) {
            questionText = unsortedQuestion;
          }
        });

        return questionText;
      });

      formattedResults = [
        '<h3 style="margin: 0;">' + vm.QRcodeModel.title + '</h3>',
        '<p style="margin: 0;">id: ' + vm.QRcodeModel.id + '</p>',
        '<br>',
        '<h4 style="margin: 0;">' + originalQuiz.title + '</h4>',
        '<p style="margin: 0;"><b>' +
          translations['QR_CODE.QUIZ_ANSWERS.PARTICIPANT_NAME'] +
          '</b>: ' +
          vm.participantName +
          '</p>',
        '<br>',
        '<p style="margin: 0;">' + translations['QUIZ.ANSWERS'] + ':</p>'
      ].join('');

      sortedArrOfQuestionsText.forEach(function (question) {
        formattedResults += '<b>' + question + '</b>: ' + answeredQuiz[question] + '<br>';
      });

      return formattedResults;
    }

    // return the array filled with sorted questions and answers
    function sortResults(answeredQuiz, originalQuiz) {
      var questions = Object.keys(answeredQuiz).map(function (question) {
        var order = -1;
        var questionInArr = {};
        questionInArr[question] = { answer: answeredQuiz[question] };

        originalQuiz.questions.some(function (originalQuestion) {
          if (question === originalQuestion.text) {
            order = originalQuestion.order;
            questionInArr.order = originalQuestion.order;
            return true;
          }
        });

        return questionInArr;
      });

      questions.sort(function (a, b) {
        return a.order - b.order;
      });

      return questions;
    }

    function justSortResults(answeredQuiz, originalQuiz) {
      return originalQuiz.questions.map(function (question) {
        const result = {};
        result[question.text] = answeredQuiz[question.text];

        return result;
      });
    }

    function clearFormFields(answeredQuiz, originalQuiz) {
      Object.keys(answeredQuiz).forEach(function (question) {
        delete answeredQuiz[question];
      });
      vm.checkboxesToClear && (vm.checkboxesToClear[originalQuiz.title] = {});
    }
  }
]);
