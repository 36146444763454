import app from '../../app';

app.controller('bannedChemicalListCtrl', [
  '$scope',
  '$rootScope',
  '$state',
  '$q',
  '$window',
  'BannedList',
  'CompanyService',
  'CompanySetting',
  'SpinnerService',
  'ErrorService',
  bannedChemicalListFunction
]);

function bannedChemicalListFunction(
  $scope,
  $rootScope,
  $state,
  $q,
  $window,
  BannedList,
  CompanyService,
  CompanySetting,
  SpinnerService,
  ErrorService
) {
  const vm = this;

  $scope.BannedList = BannedList;
  $scope.pageLoaded = false;

  vm.print = function () {
    $window.print();
  };

  init();

  function init() {
    const wrapRefresh = SpinnerService.wrap(getBannedList);

    if ($rootScope.checkIfUserIs('admin')) {
      $state.go('settings', { '#': 'banned-list' });
    } else {
      wrapRefresh();
    }
  }

  function getBannedList() {
    return CompanyService.getCurrentCompanyPromise().$promise.then(function () {
      $scope.companyId = $rootScope.companyId;
      return getCompanySettings()
        .then(function (data) {
          $rootScope.companySettings = data;

          const companyIds = [];
          if (
            $scope.companyId &&
            $rootScope.companySettings &&
            ($rootScope.companySettings.bannedListType === 'custom' ||
              !$rootScope.companySettings.bannedListCustomDisabled)
          ) {
            companyIds.push($scope.companyId);
          }
          if (
            $rootScope.companySettings &&
            $rootScope.companySettings.bannedListType &&
            $rootScope.companySettings.bannedListType !== 'custom'
          ) {
            companyIds.push($rootScope.companySettings.bannedListType);
          }

          const filterObj = {
            filter: { where: { or: companyIds.map(id => ({ companyId: id })) } }
          };
          return $scope.BannedList.find(filterObj)
            .$promise.then(function (bannedList) {
              return ($scope.BannedList.bannedList = bannedList);
            })
            .catch(ErrorService.simpleCatch);
        })
        .catch(ErrorService.simpleCatch);
    });
  }

  function getCompanySettings() {
    let ret;
    if ($rootScope.companySettings)
      ret = $q(function (resolve) {
        resolve($rootScope.companySettings);
      });
    else ret = CompanySetting.findById({ id: $scope.companyId }).$promise;

    return ret;
  }
}
