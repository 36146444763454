import app from '../app';

app.directive('knownValues', function () {
  return {
    require: 'ngModel',
    scope: {
      knownValues: '=',
      origValue: '='
    },
    link: function (scope, elem, attrs, ctrl) {
      const skip = !scope.knownValues || !scope.knownValues.length;
      scope.knownValues = scope.knownValues.map(function (value) {
        return value.toLowerCase();
      });
      scope.origValue = (scope.origValue || '').toLowerCase();
      ctrl.$validators.knownValues = function (modelValue, viewValue) {
        const value = modelValue.toLowerCase();
        return (
          skip ||
          !modelValue ||
          (scope.origValue && scope.origValue === value) ||
          !~scope.knownValues.indexOf(value)
        );
      };
    }
  };
});
