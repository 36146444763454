import angular from 'angular';
import supplierInfo from '../../modals/supplierInfo';
import app from '../../app';

app.controller('unverifiedProductsCtrl', [
  '$scope',
  'ProductService',
  'ManufacturerService',
  'ModalService',
  'MeasureService',
  unverifiedProductsCtrl
]);

function unverifiedProductsCtrl(
  $scope,
  ProductService,
  ManufacturerService,
  ModalService,
  MeasureService
) {
  const vm = this;
  vm.manufacturers = [];
  vm.products = [];
  $scope.isLoading = true;

  vm.MeasureService = MeasureService;
  vm.sizeTypes = ProductService.sizeTypes;
  vm.startEditingProduct = ProductService.startEditingProduct;
  vm.updateProduct = ProductService.updateProduct;
  vm.getProductSize = ProductService.showCorrectProductSize;
  vm.updateMeasureForProduct = ProductService.updateMeasureForProduct;

  vm.initProductRow = function (product) {
    return angular.copy(product, {});
  };

  vm.verify = function (product, index) {
    ProductService.changeVerified(product, true).then(function () {
      removeProductFromListByIndex(index);
    });
  };

  vm.replace = function (product, index) {
    ProductService.replaceWithAnotherProduct(product).then(function () {
      removeProductFromListByIndex(index);
    });
  };

  vm.deleteProduct = function (product, index) {
    ProductService.deleteProduct(product).then(function () {
      removeProductFromListByIndex(index);
    });
  };

  vm.showInventories = function (inventories) {
    const invs = combineInventoriesByParents(inventories.map(turnInventoriesInsideOut));

    ModalService.open({
      templateUrl: '/views/modals/list-of-inventories.html',
      controller: [
        '$uibModalInstance',
        function ($uibModalInstance) {
          var modal = this;

          modal.hideWithoutProducts = false;
          modal.inventories = invs;

          modal.cancel = function () {
            $uibModalInstance.dismiss('cancel');
          };
        }
      ]
    });
  };

  vm.openSupplierInfoModal = function (product, editable = false) {
    return ModalService.open(supplierInfo({ suppliers: product.suppliers, editable })).then(
      suppliers => {
        product.suppliers = suppliers;
      }
    );
  };

  init();

  function init() {
    vm.MeasureService.getMeasureUnits();

    return ManufacturerService.manufacturersPromise()
      .then(function (manufacturers) {
        vm.manufacturers = manufacturers;

        return ProductService.getUnverifiedProducts();
      })
      .then(function (unverifiedProducts) {
        vm.products = unverifiedProducts;
        $scope.isLoading = false;
      });
  }

  function removeProductFromListByIndex(index) {
    vm.products.splice(index, 1);
  }

  function turnInventoriesInsideOut(inventory) {
    let mainParent;

    getNext(inventory);

    return mainParent;

    function getNext(inventory) {
      const inv = angular.copy(inventory);

      if (inv.parentInventory) {
        const parent = getNext(inv.parentInventory);
        parent.inventories = [inv];
        delete parent.inventories[0].parentInventory;
        return parent.inventories[0];
      }

      mainParent = inv;
      return mainParent;
    }
  }

  function combineInventoriesByParents(inventories) {
    return inventories.reduce(function (sum, inv) {
      if (!inv.inventories) return sum.concat(inv);

      const idx = getInvArrIndex(inv, sum);

      // if inv is already in sum
      if (~idx) {
        const invs = (sum[idx].inventories || []).concat(inv.inventories);
        sum[idx].inventories = combineInventoriesByParents(invs);
      } else {
        sum.push(angular.copy(inv));
      }

      return sum;
    }, []);

    function getInvArrIndex(inv, arr) {
      let index = -1;

      arr.some(function (arrInv, i) {
        if (arrInv.id === inv.id) {
          index = i;

          return true;
        }
      });

      return index;
    }
  }
}
