import app from '../../app';
import { clearObject, validateAmount, getOffset } from '../../helpers';

app
  .controller('inventoryContainersCtrl', [
    '$rootScope',
    '$scope',
    'InventoryService',
    'InventoryContainerService',
    'CompanyFactory',
    'ModalService',
    'Company',
    'SolrDocumentService',
    'ProductService',
    'SpinnerService',
    inventoryContainersCtrl
  ]);

function inventoryContainersCtrl(
  $rootScope,
  $scope,
  InventoryService,
  InventoryContainerService,
  CompanyFactory,
  ModalService,
  Company,
  SolrDocumentService,
  ProductService,
  SpinnerService
) {
  const vm = this;
  let companyId;

  vm.startFrom = 1;
  vm.PER_PAGE = 20;
  vm.products = [];

  $scope.container = {};

  vm.handlePaginate = SpinnerService.wrap(getContainers);

  vm.onCompanySelected = function (selectedCompany) {
    if (!selectedCompany) return;

    vm.startFrom = 1;
    companyId = selectedCompany.id;

    init(companyId);
  };

  vm.validateProduct = function (product, form) {
    if (typeof product === 'string')
      return form.$setValidity('fromProduct', false);

    form.$setValidity('fromProduct', true);
  };

  vm.createContainer = SpinnerService.wrap(function (container, form) {
    container.productId = container.fromProduct.id;
    container.name = container.fromProduct.name;
    container.companyId = companyId;

    return InventoryContainerService.createContainer(container).then(function (
      createdContainer
    ) {
      form.$setUntouched();
      form.$setPristine();

      createdContainer.product = Object.assign({}, container.fromProduct);

      vm.containers.push(createdContainer);
      clearObject(container);
    });
  });

  vm.removeContainer = SpinnerService.wrap(function (container, index) {
    return InventoryContainerService.inventories(container.id)
      .then(function (inventories) {
        var numberOfInventories = inventories.length;

        if (!numberOfInventories) return;

        return ModalService.open({
          templateUrl: require('../../../views/modals/confirm-message.html'),
          resolve: {
            message: [
              '$translate',
              function ($translate) {
                return $translate('CONTAINERS.REMOVE_NOTE', {
                  number: numberOfInventories
                });
              }
            ]
          },
          controller: [
            '$uibModalInstance',
            '$q',
            'message',
            function ($uibModalInstance, $q, message) {
              var modal = this;

              modal.message = message;
              modal.confirm = $uibModalInstance.close;
              modal.cancel = $uibModalInstance.dismiss;
            }
          ]
        });
      })
      .then(function () {
        return InventoryContainerService.removeContainer(container);
      })
      .then(function () {
        vm.containers.splice(index, 1);
      });
  });

  vm.isProductBanned = function (product) {
    return ProductService.isProductBanned(product, companyId);
  };

  vm.handleSDSModal = SolrDocumentService.openModalOrSDSbyProduct;

  vm.openModal = function (container) {
    ModalService.open({
      templateUrl: require('../../../views/modals/inventories-with-item.html'),
      controller: [
        '$uibModalInstance',
        'InventoryContainerService',
        function ($uibModalInstance, InventoryContainerService) {
          let modal = this;
          let flatInventories = [];

          vm.inventoryService.setScope(modal);
          getInventories(0);

          modal.handleInfiniteScroll = getInventories;
          modal.itemType = 'container';

          modal.validateAmount = function (inventory, oldAmount) {
            inventory.itemAmount.amount = validateAmount(
              inventory.itemAmount.amount,
              oldAmount
            );
            inventory.changed = true;
          };

          modal.initInventory = function (inventory) {
            inventory.itemAmount = InventoryService.getContainerAmountFromInventory(
              container.id,
              inventory,
              'containerAmounts',
              'inventoryContainerId'
            );

            flatInventories = flatInventories.concat(inventory);
          };

          modal.save = function () {
            const inventoriesToUpdate = flatInventories.filter(function (
              inventory
            ) {
              return inventory.changed;
            });
            const amountsToUpdate = inventoriesToUpdate.map(function (
              inventory
            ) {
              return inventory.itemAmount;
            });

            InventoryContainerService.updateAmounts(amountsToUpdate, {
              inventories: inventoriesToUpdate,
              item: container,
              companyId: companyId
            }).then(function () {
              $uibModalInstance.close('save');
            });
          };

          modal.cancel = function () {
            $uibModalInstance.dismiss('cancel');
          };

          function getInventories(startFrom) {
            return vm.inventoryService
              .getInventories(null, startFrom)
              .then(function (companyInventories) {
                modal.companyInventories = companyInventories;
              });
          }
        }
      ]
    });
  };

  if ($rootScope.checkIfUserIs('admin')) {
    if (CompanyFactory.companies.length) {
      vm.companies = CompanyFactory.companies;
    } else {
      CompanyFactory.GetCompanies(function (companies) {
        vm.companies = companies;
        vm.onCompanySelected(companies[0]);
      });
    }
  } else {
    companyId = $rootScope.companyId;

    init(companyId);
  }

  function init(companyId) {
    initInventoryService(companyId);
    getProducts(companyId);
    getContainers(0);
    getTotalPages();
  }

  function getProducts(companyId) {
    const filter = { id: companyId, filter: { include: ['manufacturer', 'measure'] } };

    return Company.products(filter).$promise.then(function (products) {
      vm.products = products.filter(function (product) {
        return !ProductService.isProductBanned(product, companyId);
      });

      vm.products.forEach(function (product) {
        product.correctProductSize = ProductService.showCorrectProductSize(product);
      });
    });
  }

  function getContainers(offset) {
    offset = offset != null ? offset : getOffset(vm.startFrom, vm.PER_PAGE);

    return InventoryContainerService.getContainers(
      vm.PER_PAGE,
      offset,
      companyId
    ).then(function (containers) {
      vm.containers = containers;
    });
  }

  function getTotalPages() {
    return InventoryContainerService.getTotalPages(vm.PER_PAGE, companyId).then(
      function (totalItems) {
        vm.totalItems = totalItems;
      }
    );
  }

  function initInventoryService(companyId) {
    vm.inventoryService = new InventoryService(companyId, {}, [
      'containerAmounts'
    ]);

    return vm.inventoryService.count();
  }
}
