import app from '../../../app';

app.directive('pinnedInventory', [
  function () {
    return {
      restrict: 'E',
      scope: {
        pinnedInventory: '='
      },
      templateUrl: require('./pinnedInventory.html'),
      link: function (scope) {
        scope.filterObj = {};
        scope.selectInventory = function (el) {
          scope.$emit('onQuickFilter', { obj: el, filterClass: 'inventory' });
        };
        scope.filterInventory = function (inventory) {
          let result = inventory.name
            .toLowerCase()
            .includes((scope.filterObj.filter || '').toLowerCase());

          if (inventory.inventories && inventory.inventories.length)
            result = inventory.inventories.some(inv => scope.filterInventory(inv));

          return result;
        };
      }
    };
  }
]);
