import app from '../app';

app.directive('spinnerService', [
  'SpinnerService',
  function (SpinnerService) {
    return {
      restrict: 'E',
      scope: false,
      link: function (scope, element) {
        scope.$on('us-spinner:spin', function (event, key) {
          if (key === SpinnerService.key) {
            element.addClass('active');
          }
        });

        scope.$on('us-spinner:stop', function (event, key) {
          if (key === SpinnerService.key) {
            element.removeClass('active');
          }
        });
      }
    };
  }
]);
