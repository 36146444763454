import app from '../../app';
import { standardSearch } from './standardSearch';
import { firstResponderSearch } from './firstResponderSearch';

app.controller('searchCtrl', function searchCtrl($scope, $rootScope) {
  this.isFirstResponder = $rootScope.checkIfUserIs('firstResponder');
  $scope.isNotDisabled = function (filter) {
    return !filter.disabled;
  };

  if (this.isFirstResponder) {
    firstResponderSearch.call(this, $scope);
  } else {
    standardSearch.call(this, $scope);
  }
});
