import app from '../app';

app.service('AuthService', [
  '$rootScope',
  '$cookies',
  '$q',
  'SdsUser',
  'CookieManager',
  'LoopBackAuth',
  'SpinnerService',
  'ErrorService',
  'RoleFactory',
  'CompanyService',
  function (
    $rootScope,
    $cookies,
    $q,
    SdsUser,
    CookieManager,
    LoopBackAuth,
    SpinnerService,
    ErrorService,
    RoleFactory,
    CompanyService
  ) {
    const self = this;
    const simpleCatch = ErrorService.simpleCatch;
    let getUserAndCompanyWithSettingsPromise = null;
    let initialLocation = null;

    this.storedUserHydrated = false;
    this.currentCompanyHydrated = false;

    this.setInitialLocation = function (location) {
      if (location instanceof window.Location) {
        if (
          location.pathname !== '/' &&
          location.pathname !== '/login' &&
          location.pathname !== '/logout'
        ) {
          initialLocation = { ...location };
        }
      } else {
        console.error('location should be an instance of window.Location');
      }
    };
    this.clearInitialLocation = function () {
      initialLocation = null;
    };

    this.getUserRole = function () {
      return $rootScope.currentUser && $rootScope.currentUser.role
        ? $rootScope.currentUser.role
        : $rootScope.IPallowed
        ? 'teamMember'
        : '';
    };

    this.login = SpinnerService.wrap(function (rememberMe, credentials, cb) {
      return SdsUser.login({ rememberMe }, credentials)
        .$promise.catch(() =>
          SdsUser.systemLogin({ rememberMe: rememberMe }, credentials)
            .$promise.then(accessToken => {
              LoopBackAuth.setUser(accessToken.id, accessToken.userId, accessToken.user);
              LoopBackAuth.rememberMe = rememberMe !== false;
              LoopBackAuth.save();
              return accessToken;
            })
            .then(accessToken => {
              return SdsUser.findById({
                id: accessToken.userId,
                filter: {
                  include: { relation: 'allowedCompanies', scope: { fields: ['id', 'name'] } }
                }
              }).$promise.then(user => {
                accessToken.allowedCompanies = user.allowedCompanies || [];

                return accessToken;
              });
            })
            .catch(simpleCatch)
        )
        .then(res => {
          cb && cb(res);
          if (initialLocation) document.location.href = initialLocation.href;

          return res;
        });
    });

    this.getStoredUser = function () {
      const currentUserStr = $cookies.get('currentUser') || sessionStorage.getItem('currentUser');
      if (currentUserStr) {
        return JSON.parse(currentUserStr);
      } else {
        return null;
      }
    };

    this.getUserAndCompanyWithSettings = SpinnerService.wrap(function (force = false) {
      if (force) {
        self.storedUserHydrated = false;
        self.currentCompanyHydrated = false;
        getUserAndCompanyWithSettingsPromise = null;
      }
      if (getUserAndCompanyWithSettingsPromise) return getUserAndCompanyWithSettingsPromise;

      const storedUser = self.getStoredUser();
      if (storedUser) {
        getUserAndCompanyWithSettingsPromise = $q
          .all([
            loginByToken(storedUser.tokenId),
            CompanyService.getCurrentCompanyPromise(force).$promise
          ])
          .then(([currentUser, currentCompany]) => {
            self.storedUserHydrated = true;
            self.currentCompanyHydrated = true;

            return { currentUser, currentCompany };
          });
      } else {
        return CompanyService.getCurrentCompanyPromise(force)
          .$promise.then(currentCompany => {
            self.storedUserHydrated = false;
            self.currentCompanyHydrated = true;

            return { currentUser: null, currentCompany };
          })
          .catch(simpleCatch);
      }

      return getUserAndCompanyWithSettingsPromise.catch(simpleCatch);
    });

    this.logout = SpinnerService.wrap(function () {
      getUserAndCompanyWithSettingsPromise = null;
      self.storedUserHydrated = false;
      $rootScope.currentUser = null;
      CookieManager.removeCookies();
      sessionStorage.setItem('currentUser', '');

      return SdsUser.logout()
        .$promise.then(() => {
          $rootScope.sitename = $rootScope.getSchoolName(false);
        })
        .catch(simpleCatch);
    });

    this.setCurrentCompanyId = function () {
      if (!$rootScope.companyId) {
        $rootScope.companyId =
          $rootScope.currentUser && $rootScope.currentUser.companyId
            ? $rootScope.currentUser.companyId
            : $rootScope.companyId;
      }
      return $rootScope.companyId;
    };

    function loginByToken(tokenId) {
      return SdsUser.loginByToken({
        token: tokenId
      })
        .$promise.then(response => {
          $rootScope.currentUser = {
            id: response.user.id,
            doNotAllowUserToChangePassword: response.user.doNotAllowUserToChangePassword,
            tokenId: response.id,
            username: response.user.username,
            role: response.role,
            company: response.company || '',
            companyId: response.companyId,
            azureBond: response.azureBond,
            adfsBond: response.adfsBond,
            googleBond: response.googleBond,
            allowedCompanies: getAllowedCompanies(response),
            restrictedTags: response.user.restrictedTags || [],
            autoApproveSdsRequest: response.user.autoApproveSdsRequest,
            allowToViewSensitiveSds: response.user.allowToViewSensitiveSds || false
          };

          LoopBackAuth.accessTokenId = $rootScope.currentUser.tokenId;
          LoopBackAuth.currentUserId = $rootScope.currentUser.id;

          RoleFactory.extendRole($rootScope.currentUser);

          return $rootScope.currentUser;
        })
        .catch(err => {
          // the error is because of an interrupted connection, not because of unauthorized, mostly on Mac
          if (err.data == null && err.status === 0) throw err;

          self.logout();

          throw err;
        });
    }
  }
]);

function getAllowedCompanies(resp) {
  return resp.allowedCompanies
    ? resp.allowedCompanies
    : resp.company && resp.companyId
    ? [{ name: resp.company, id: resp.companyId }]
    : [];
}
