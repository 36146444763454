import angular from 'angular';
import app from '../app';

app.factory('InventoryService', [
  '$q',
  '$timeout',
  '$rootScope',
  'Inventory',
  'DenyToInventory',
  'ErrorService',
  'MessagesService',
  'SystemAuditService',
  'SpinnerService',
  function (
    $q,
    $timeout,
    $rootScope,
    Inventory,
    DenyToInventory,
    ErrorService,
    MessagesService,
    SystemAuditService,
    SpinnerService
  ) {
    var simpleCatch = ErrorService.simpleCatch;
    let cashedData = {};

    /**
     * returns an instance of InventoryService
     *
     * @param {string} companyId
     * @param {Object} scope
     * @param {Array<string>} additionalRelations
     * @param {number} perPage
     */
    var InventoryService = function (companyId, scope, additionalRelations, perPage) {
      this.companyId = companyId;
      this.scope = scope || {};
      this.totalInventories = 0;
      this.inventories = [];
      this.startFrom = 0;
      this.PER_PAGE = perPage || 30;
      this.additionalRelations = additionalRelations || [];

      this.getCompanyIdFilter = function () {
        if (!this.companyId) return {};

        return { companyId: this.companyId };
      };

      this.setScope = function (scope) {
        this.scope = scope;
      };
    };

    // helps to control what inventories have to show their content
    var inventoriesShowContent = {
      _showedContent: [],
      _lastShowed: null,
      checkIfInventoryShowsContent: function (inventoryId) {
        return !!~inventoriesShowContent._showedContent.indexOf(inventoryId);
      },
      toggle: function (inventoryId) {
        const index = inventoriesShowContent._showedContent.indexOf(inventoryId);

        if (~index) {
          inventoriesShowContent._showedContent.splice(index, 1);
        } else {
          inventoriesShowContent._showedContent.push(inventoryId);
        }

        return inventoriesShowContent;
      },
      setLastOpenedInventoryId: function (inventoryId) {
        inventoriesShowContent._lastShowed = inventoryId;
      },
      getLastOpenedInventoryId: function () {
        return inventoriesShowContent._lastShowed;
      },
      showAllAgain: function (inventory) {
        show(inventory);

        return inventoriesShowContent;

        function show(inventory) {
          inventory.showContent = inventoriesShowContent.checkIfInventoryShowsContent(inventory.id);

          if (inventory.inventories && inventory.inventories.length) {
            inventory.inventories.forEach(show);
          }
        }
      }
    };

    InventoryService.prototype.count = function () {
      var self = this;

      // because only 'root' matters
      return Inventory.count({
        where: { and: [this.getCompanyIdFilter(), { groupId: 'root' }] }
      })
        .$promise.then(function (response) {
          self.totalInventories = response.count;

          return response.count;
        })
        .catch(simpleCatch);
    };

    InventoryService.pinInv = SpinnerService.wrap(function (el) {
      return Inventory.prototype$updateAttributes(
        { id: el.id },
        { pinned: !el.pinned }
      ).$promise.catch(simpleCatch);
    });

    InventoryService.getTreeInfo = async function (companyId) {
      const filter = {
        where: { companyId }
      };
      return InventoryService.getCached(`getTreeInfo_${companyId}`, function () {
        return Inventory.getTreeInfo({ filter }).$promise;
      });
    };

    InventoryService.getCached = function (name, getter) {
      cashedData[name] = cashedData[name] || getter();

      return cashedData[name].then(data => data);
    };

    InventoryService.prototype.getInventories = function (perPage, start) {
      var self = this;
      var scope = this.scope;

      perPage = perPage != undefined ? perPage : this.PER_PAGE;
      start = start != undefined ? start : this.startFrom;

      if ((start !== 0 && start >= this.totalInventories) || scope.isLoading)
        return $q.resolve(this.inventories);

      scope.isLoading = true;
      scope.inventoryFound = false;

      var filter = {
        filter: {
          where: this.getCompanyIdFilter(),
          limit: perPage,
          offset: start
        },
        additionalRelations: this.additionalRelations
      };

      const inventories = Inventory.getSortedInventories(filter)
        .$promise.then(function (response) {
          self.inventories = start !== 0 ? self.inventories.concat(response) : response;

          self.startFrom = start + perPage;

          $timeout(function () {
            scope.isLoading = false;
            scope.inventoryFound = true;
          });

          return self.inventories;
        })
        .catch(simpleCatch);

      let accessDenied;
      if (!$rootScope.currentUser || $rootScope.checkIfUserIs(['admin', 'teamAdmin']))
        accessDenied = [];
      else
        accessDenied = DenyToInventory.find({
          filter: { where: { userId: $rootScope.currentUser.id } }
        });
      return $q.all([inventories, accessDenied]).then(function (res) {
        const inventories = res[0];
        const access = res[1];
        const filteredInventories = inventories
          .map(function (el) {
            el.accessDeny = false;
            if (access) {
              access.forEach(function (elAcc) {
                if (el.id === elAcc.inventoryId) el.accessDeny = elAcc.deny;
              });
            }
            return el;
          })
          .filter(function (el) {
            return !el.accessDeny;
          });

        self.inventories = filteredInventories;
        return filteredInventories;
      });
    };

    InventoryService.prototype.refreshTotalItemsOfInventory = function (inventory, inventories) {
      if (inventory == undefined) return;

      const rootInventory = getRootInventory(inventory, inventories);

      if (rootInventory == undefined) return;

      const rootInventoryId = rootInventory.id;

      var filter = {
        filter: {
          where: {
            id: rootInventoryId
          }
        },
        additionalRelations: this.additionalRelations
      };

      inventoriesShowContent.setLastOpenedInventoryId(null);

      // if the root inventory element showed content then we have to hide its content and show it again
      // to force reload content items
      // we don't have to update rest inventory elements because of DOM rebuilding
      if (inventoriesShowContent.checkIfInventoryShowsContent(rootInventoryId)) {
        inventoriesShowContent.toggle(rootInventoryId);

        $timeout(function () {
          inventoriesShowContent.toggle(rootInventoryId);
        });
      }

      return Inventory.getSortedInventories(filter)
        .$promise.then(function (inventory) {
          angular.extend(rootInventory, inventory[0]);

          return rootInventory;
        })
        .then(function (rootInventory) {
          let promise = {};

          $timeout(function () {
            angular.extend(promise, inventoriesShowContent.showAllAgain(rootInventory));
          });

          return promise;
        })
        .catch(simpleCatch);
    };

    InventoryService.prototype.addInventory = function (inventory) {
      var scope = this.scope;

      scope.isUpdating = true;

      return Inventory.create(inventory)
        .$promise.then(function (response) {
          delete inventory.isNewInventory;
          inventory.id = response.id;

          $timeout(function () {
            scope.isUpdating = false;
          });

          MessagesService.success('COMMON.MESSAGES.ADDED');

          return inventory;
        })
        .catch(simpleCatch);
    };

    InventoryService.prototype.updateInventoryById = function (id, data) {
      return Inventory.prototype$updateAttributes({ id: id }, data)
        .$promise.then(function (response) {
          MessagesService.success('COMMON.MESSAGES.UPDATED');

          return response;
        })
        .catch(simpleCatch);
    };

    InventoryService.prototype.updateInventories = function (inventories, silent) {
      var scope = this.scope;
      silent = silent || false;

      scope.isUpdating = true;

      return Inventory.updateMultipleInventories(inventories)
        .$promise.then(function () {
          if (!silent) MessagesService.success('COMMON.MESSAGES.UPDATED');

          $timeout(function () {
            scope.isUpdating = false;
          });
        })
        .catch(simpleCatch);
    };

    InventoryService.prototype.removeInventory = function (inventory, dontTurnOffSpinner) {
      if (!inventory.id) return;

      var scope = this.scope;

      scope.isUpdating = true;

      return Inventory.deleteById({ id: inventory.id })
        .$promise.then(function () {
          if (!dontTurnOffSpinner) {
            $timeout(function () {
              scope.isUpdating = false;
            });
          }

          MessagesService.success('COMMON.MESSAGES.DELETED');
        })
        .catch(simpleCatch);
    };

    InventoryService.prototype.updateAmountOfItemsInInventories = function (
      itemsToUpdate,
      inventory,
      relationName
    ) {
      if (!Array.isArray(itemsToUpdate)) itemsToUpdate = [itemsToUpdate];

      return Inventory.updateAmountOfItemsInInventories(
        { relationName: relationName },
        itemsToUpdate
      )
        .$promise.then(function () {
          MessagesService.success('COMMON.MESSAGES.UPDATED');

          itemsToUpdate.forEach(function (item) {
            createAuditEntryUpdateInInventory(
              item,
              inventory,
              {
                action: 'updated in ' + inventoryPathToString(inventory),
                newValue: item.amount
              },
              relationName
            );
          });
        })
        .catch(simpleCatch);
    };

    // TODO: refactor the copypaste link/unlink methods
    InventoryService.prototype.linkItemsToInventory = function (
      itemsToLink,
      inventory,
      relationName
    ) {
      let linkItem = linkToInventory(inventory.id, relationName);

      if (!Array.isArray(itemsToLink)) itemsToLink = [itemsToLink];

      return $q
        .all(itemsToLink.map(linkItem))
        .then(function () {
          MessagesService.success('COMMON.MESSAGES.ADDED');

          itemsToLink.forEach(function (item) {
            createAuditEntryUpdateInInventory(
              item,
              inventory,
              {
                action: 'added to ' + inventoryPathToString(inventory)
              },
              relationName
            );
          });
        })
        .catch(simpleCatch);
    };

    InventoryService.prototype.unlinkItemsFromInventory = function (
      itemsToUnlink,
      inventory,
      relationName
    ) {
      let unlinkItem = unlinkFromInventory(inventory.id, relationName);

      if (!Array.isArray(itemsToUnlink)) itemsToUnlink = [itemsToUnlink];

      return $q
        .all(itemsToUnlink.map(unlinkItem))
        .then(function () {
          MessagesService.success('COMMON.MESSAGES.REMOVED');

          itemsToUnlink.forEach(function (item) {
            createAuditEntryUpdateInInventory(
              item,
              inventory,
              {
                action: 'removed from ' + inventoryPathToString(inventory)
              },
              relationName
            );
          });
        })
        .catch(simpleCatch);
    };

    InventoryService.prototype.getContentItems = function (inventory, relationName) {
      if (inventory.inventories && inventory.inventories.length) {
        var inventories = getWaterfallInventories(inventory);

        return Inventory.getContentItemsNumbersInParentInventory({
          inventories: inventories,
          relationName: relationName
        }).$promise;
      } else {
        return Inventory.getContentItemsInInventory({
          inventoryId: inventory.id,
          relationName: relationName
        }).$promise;
      }
    };

    InventoryService.prototype.getPlainInventoriesByNames = function (names) {
      if (!Array.isArray(names)) names = [names];

      const namesFilter = names.map(function (name) {
        return { name: name };
      });

      return Inventory.find({
        filter: {
          where: { and: [this.getCompanyIdFilter(), { or: namesFilter }] }
        }
      }).$promise.catch(simpleCatch);
    };

    /**
     * Returns a promise that resolves with an array of unique names from the "names" parameter
     *
     * @param {Array<string>} names an array of names
     * @returns {Promise} a promise that resolves with an array of unique names
     */
    InventoryService.prototype.getUniqueInventoryNames = function (names) {
      return this.getPlainInventoriesByNames(names).then(function (inventories) {
        return inventories.reduce(function (uniqueNames, inventory) {
          const name = inventory.name;

          if (!~uniqueNames.indexOf(name)) uniqueNames = uniqueNames.concat(name);

          return uniqueNames;
        }, []);
      });
    };

    InventoryService.inventoriesShowContent = inventoriesShowContent;
    InventoryService.getParentInventoryWithEnabledAlerts = getParentInventoryWithEnabledAlerts;
    InventoryService.findInventoryInSetById = findInventoryInSetById;

    InventoryService.saveAlertSettings = function (
      newInventory,
      prevInventory,
      alertType,
      shouldCreateAlerts
    ) {
      shouldCreateAlerts = shouldCreateAlerts != undefined ? shouldCreateAlerts : true;

      const params = {
        newAlertSettingsInventoryId: newInventory.id,
        prevAlertSettingsInventoryId: prevInventory ? prevInventory.id : '',
        alertType: alertType,
        shouldCreateAlerts: shouldCreateAlerts
      };

      const data = {
        companyId: newInventory.companyId,
        alertEnabled: newInventory.alertEnabled[alertType],
        alertThreshold: newInventory.alertThreshold[alertType],
        alertEmailsList: newInventory.alertEmailsList[alertType]
      };

      return Inventory.saveAlertSettings(params, data).$promise.catch(simpleCatch);
    };

    InventoryService.removeInventoryAlerts = function (movedInventory, oldAlertParent) {
      const params = {
        movedInventoryId: movedInventory.id,
        alertSettingsInventoryId: oldAlertParent.id
      };

      return Inventory.removeInventoryAlerts(params).$promise.catch(simpleCatch);
    };

    InventoryService.updateSettingsLink = function (
      prevInventoryWithAlertSettings,
      newInventoryWithAlertSettings,
      inventory,
      alertType
    ) {
      const parameters = {
        previousSettingsId: prevInventoryWithAlertSettings.id,
        newSettingsId: newInventoryWithAlertSettings.id,
        alertType: alertType,
        alertThreshold: newInventoryWithAlertSettings.alertThreshold,
        companyId: newInventoryWithAlertSettings.companyId,
        inventoryId: inventory ? inventory.id : ''
      };

      return Inventory.updateSettingsLink(parameters, {}).$promise.catch(simpleCatch);
    };

    InventoryService.removeAlertsBySettingsId = function (alertSettingsInventoryId, alertType) {
      return Inventory.removeAlertsBySettingsId({
        alertSettingsInventoryId: alertSettingsInventoryId,
        alertType: alertType
      }).$promise.catch(simpleCatch);
    };

    InventoryService.isModuleAvailable = function (checkAdminAccess = false) {
      const roleName = $rootScope.currentUser && $rootScope.currentUser.role;

      if (!$rootScope.isModuleEnabled($rootScope.moduleNames.inv, checkAdminAccess)) return false;
      if ($rootScope.checkIfUserIs('admin')) return true;

      if (!roleName && $rootScope.checkIfUserIs()) {
        return $rootScope.companySettings.inventoryAccess.some(function (role) {
          return role === 'teamMember';
        }); // if teamMember is in the list of allowed roles and IP is whitelisted then show module
      }

      return $rootScope.companySettings.inventoryAccess.some(function (role) {
        return role === roleName;
      });
    };

    InventoryService.getContainerAmountFromInventory = function (
      itemId,
      inventory,
      amountRelationName,
      relationKeyThrough
    ) {
      let itemAmount = inventory[amountRelationName].filter(function (amount) {
        return amount[relationKeyThrough] === itemId;
      })[0];

      if (!itemAmount) {
        itemAmount = {
          inventoryId: inventory.id,
          amount: 0
        };

        itemAmount[relationKeyThrough] = itemId;
      }

      return itemAmount;
    };

    InventoryService.getWithProductIds = function (companyId = '') {
      if (companyId == null) return Promise.resolve([]);

      return Inventory.getWithProductIds({ companyId }).$promise.catch(simpleCatch);
    };

    return InventoryService;

    function linkToInventory(inventoryId, relationName) {
      return function (item) {
        return Inventory[relationName].link({ id: inventoryId, fk: item.id }, null).$promise;
      };
    }

    function unlinkFromInventory(inventoryId, relationName) {
      return function (item) {
        return Inventory[relationName].unlink({ id: inventoryId, fk: item.id }, null).$promise;
      };
    }

    function createAuditEntryUpdateInInventory(item, inventory, auditData, relationName) {
      var types = {
        products: SystemAuditService.PRODUCT_TYPE,
        inventoryContainers: SystemAuditService.CONTAINER_TYPE,
        equipment: SystemAuditService.EQUIPMENT_TYPE,
        files: SystemAuditService.FILE_TYPE
      };
      var data = {
        objectType: types[relationName],
        objectName: item.originalName || item.name,
        objectId: item.id,
        companyId: inventory.companyId,
        details: JSON.stringify({ inventoryId: inventory.id })
      };

      data = angular.extend({}, data, auditData);

      SystemAuditService.create(data);
    }

    function getWaterfallInventories(inventory) {
      var inventories = [];
      var childrenInventories = inventory.inventories;

      getChildren(inventory);

      return inventories;

      function getChildren(inventory) {
        childrenInventories = inventory.inventories;
        inventories.push(inventory.id);

        if (childrenInventories && childrenInventories.length) {
          childrenInventories.forEach(getChildren);
        }
      }
    }

    function getRootInventory(inventory, inventories) {
      const parentId = inventory.groupId;

      return inventories.filter(function (rootInventory) {
        return (
          rootInventory.id === parentId ||
          !!~getWaterfallInventories(rootInventory).indexOf(inventory.id)
        );
      })[0];
    }

    function getParentInventoryWithEnabledAlerts(inventory, inventories) {
      const parentId = inventory ? inventory.groupId : 'root';
      const parentInventory =
        parentId === 'root' ? inventory : findInventoryInSetById(parentId, inventories);

      return {
        low:
          (parentInventory && parentInventory.alertEnabled.low) || parentId === 'root'
            ? parentInventory
            : getParentInventoryWithEnabledAlerts(parentInventory, inventories).low,
        max:
          (parentInventory && parentInventory.alertEnabled.max) || parentId === 'root'
            ? parentInventory
            : getParentInventoryWithEnabledAlerts(parentInventory, inventories).max
      };
    }

    function findInventoryInSetById(inventoryId, inventories) {
      let searchedInventory = {};

      searchInventory(inventoryId, inventories);

      return searchedInventory;

      function searchInventory(inventoryId, inventories) {
        return inventories.some(function (currInv) {
          if (currInv.id === inventoryId) {
            searchedInventory = currInv;

            return true;
          }

          if (currInv.inventories && currInv.inventories.length)
            return searchInventory(inventoryId, currInv.inventories);

          return false;
        });
      }
    }
  }
]);

function inventoryPathToString(inv = {}) {
  if (Array.isArray(inv.inventoryPath)) {
    return inv.inventoryPath.map(i => i.name).join(' > ');
  }

  return inv.name;
}
