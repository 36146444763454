import angular from 'angular';
import app from '../../app';
import updateSystemUser from '../../modals/updateSystemUser';

app.directive('systemUsers', [
  function () {
    return {
      restrict: 'E',
      templateUrl: require('./systemUsers.html'),
      scope: {
        companies: '=',
        systemRoles: '='
      },
      controllerAs: 'vm',
      controller: function ($scope, SdsUser, SpinnerService, ModalService, MessagesService) {
        const vm = this;

        vm.companies = $scope.companies || [];
        vm.systemRoles = $scope.systemRoles;
        vm.systemUsers = [];
        vm.selectedRole = {};
        vm.newUser = {};
        vm.newUserAllowedCompanies = [];

        vm.onAddUser = SpinnerService.wrap(function () {
          vm.newUser.roleId = vm.selectedRole.id;
          vm.newUser.companyIds = vm.newUserAllowedCompanies.map(company => company.id);

          return SdsUser.saveSystemUser(null, vm.newUser)
            .$promise.then(getSystemUsers)
            .then(resetUserform);
        });
        vm.onUserClicked = function (user) {
          return ModalService.open(updateSystemUser(user, vm.systemRoles, vm.companies)).then(
            function (updatedUser) {
              angular.extend(user, updatedUser);
              MessagesService.success('COMMON.MESSAGES.UPDATED');
            }
          );
        };
        vm.onDeleteUser = SpinnerService.wrap(function (user, e) {
          e.stopPropagation();

          return SdsUser.deleteById({ id: user.id }).$promise.then(function () {
            MessagesService.success('COMMON.MESSAGES.DELETED');

            return getSystemUsers();
          });
        });
        vm.getRoleById = function (roleId) {
          return vm.systemRoles.find(role => role.id === roleId);
        };

        const getSystemUsers = SpinnerService.wrap(function () {
          const systemRoleNames = vm.systemRoles.map(systemRole => systemRole.name);

          return SdsUser.find({ filter: { include: 'allowedCompanies' } }).$promise.then(users => {
            vm.systemUsers = users.filter(user => systemRoleNames.includes(user.roleName));
          });
        });

        init();

        function resetUserform() {
          vm.newUser = {};
          vm.newUserAllowedCompanies = [];
          vm.selectedRole = vm.systemRoles[0] || {};
          vm.userform.$setUntouched();
          vm.userform.$setPristine();
        }

        async function init() {
          vm.selectedRole = vm.systemRoles[0] || {};

          getSystemUsers();
          waitForCompaniesFromOutside();
        }

        function waitForCompaniesFromOutside() {
          return new Promise((resolve, _reject) => {
            const unbind = $scope.$watch('companies', function () {
              if ($scope.companies.length) {
                vm.companies = $scope.companies;

                resolve(vm.companies);
                unbind();
              }
            });
          });
        }
      }
    };
  }
]);
