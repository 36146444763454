// this is the new, more clean service for Tags
// TagFactory is the old one

import app from '../app';
import { makeObjectWithIdsFromArrayOfObjects, createTree } from '../helpers';

app.service('TagService', function (Tag, SpinnerService, ErrorService) {
  const simpleCatch = ErrorService.simpleCatch;
  const self = this;
  const createTagsTree = tags => createTree(tags, 'tagId', 'tags');

  this.allTags = []; // array with all tags
  this.archivedTags = []; // array with archived tags
  this.sensitiveTags = []; // array with sensitive tags
  this.universalTags = []; // a flat array with universal tags
  this.universalTagsTree = []; // an array of root tags with children
  this.universalTagsLookup = {}; // a lookup table with tag ID as the key and the tag as the value

  this.companyTags = {}; // an object with company ID as the key and array of company tags as the value
  this.companyTagsTree = {}; // a lookup table with company ID as the key and an array of root company tags with children as the value
  this.companyTagsLookup = {}; // a lookup table with company ID as the key and a tag lookup as the value

  this.getAll = async function () {
    if (!this.allTags.length) {
      this.allTags = await Tag.find().$promise;
    }

    return this.allTags;
  };

  this.getArchivedTags = async function (companyId) {
    if (!this.archivedTags.length) {
      const filter = { filter: { where: { archived: true } } };
      if (companyId) filter.filter.where.companyId = companyId;

      this.archivedTags = await Tag.find(filter).$promise;
    }

    return this.archivedTags;
  };

  this.getSensitiveTags = async function (companyId) {
    if (!this.sensitiveTags.length) {
      const filter = { filter: { where: { sensitive: true } } };
      if (companyId) filter.filter.where.companyId = companyId;

      this.sensitiveTags = await Tag.find(filter).$promise;
    }

    return this.sensitiveTags;
  };

  this.getUniversalTags = function ({ force = false, fields = [] } = {}) {
    if (!force && self.universalTags.length) return Promise.resolve(self.universalTags);

    return Tag.find({ filter: { where: { companyId: '' }, fields, order: 'order ASC' } })
      .$promise.then(assignUniversalTags)
      .catch(simpleCatch);
  };

  this.getCompanyTags = function (companyId, { force = false, fields = [] } = {}) {
    if (!companyId) return Promise.resolve([]);
    if (!force && self.companyTags[companyId]) return Promise.resolve(self.companyTags[companyId]);

    return Tag.find({ filter: { where: { companyId }, fields, order: 'order ASC' } })
      .$promise.then(res => assignCompanyTags(res, companyId))
      .catch(simpleCatch);
  };

  this.pinTag = SpinnerService.wrap(function (tag) {
    return Tag.prototype$updateAttributes({ id: tag.id }, { pinned: !tag.pinned }).$promise.catch(
      simpleCatch
    );
  });

  function assignUniversalTags(tags) {
    self.universalTags = tags;
    self.universalTagsTree = createTagsTree(tags);
    self.universalTagsLookup = makeObjectWithIdsFromArrayOfObjects(tags);

    return tags;
  }

  function assignCompanyTags(tags, companyId) {
    self.companyTags[companyId] = tags;
    self.companyTagsTree[companyId] = createTagsTree(tags);
    self.companyTagsLookup[companyId] = makeObjectWithIdsFromArrayOfObjects(tags);

    return tags;
  }
});
