import app from '../app';

app.directive('smallSpinner', [
  function () {
    return {
      restrict: 'E',
      template:
        '<div class="spinner-circle" ng-if="showing">' +
        '<div></div>' +
        '</div>',
      scope: {
        showing: '='
      },
      link: function (scope, element, attrs) {
        var parentElement = element.parent();

        parentElement.addClass('spinner-container');
      }
    };
  }
]);
