import app from '../app';

app.service('PpeService', [
  '$translate',
  function ($translate) {
    const ctx = this;
    ctx.ppes = [
      'Apron',
      'Boots',
      'DustMask',
      'EyeWash',
      'FaceShield',
      'FullFaceRespirator',
      'Glasses',
      'Gloves',
      'Goggles',
      'LabCoat',
      'Respirator',
      'SafetyShower',
      'Suit',
      'SuppliedAir'
    ];
    ctx.order = [
      'Apron',
      'LabCoat',
      'Suit',
      'Boots',
      'Gloves',
      'DustMask',
      'Respirator',
      'FullFaceRespirator',
      'SuppliedAir',
      'Glasses',
      'Goggles',
      'FaceShield',
      'EyeWash',
      'SafetyShower'
    ];

    ctx.ppeMapping = {
      Apron: {
        title: 'PROCESS.SOLR_FORM.PPE.APRON.TITLE',
        description: 'PROCESS.SOLR_FORM.PPE.APRON.DESCRIPTION'
      },
      Boots: {
        title: 'PROCESS.SOLR_FORM.PPE.BOOTS.TITLE',
        description: 'PROCESS.SOLR_FORM.PPE.BOOTS.DESCRIPTION'
      },
      DustMask: {
        title: 'PROCESS.SOLR_FORM.PPE.DUST_MASK.TITLE',
        description: 'PROCESS.SOLR_FORM.PPE.DUST_MASK.DESCRIPTION'
      },
      EyeWash: {
        title: 'PROCESS.SOLR_FORM.PPE.EYE_WASH.TITLE',
        description: 'PROCESS.SOLR_FORM.PPE.EYE_WASH.DESCRIPTION'
      },
      FaceShield: {
        title: 'PROCESS.SOLR_FORM.PPE.FACE_SHIELD.TITLE',
        description: 'PROCESS.SOLR_FORM.PPE.FACE_SHIELD.DESCRIPTION'
      },
      FullFaceRespirator: {
        title: 'PROCESS.SOLR_FORM.PPE.FULL_FACE_RESPIRATOR.TITLE',
        description: 'PROCESS.SOLR_FORM.PPE.FULL_FACE_RESPIRATOR.DESCRIPTION'
      },
      Glasses: {
        title: 'PROCESS.SOLR_FORM.PPE.GLASSES.TITLE',
        description: 'PROCESS.SOLR_FORM.PPE.GLASSES.DESCRIPTION'
      },
      Gloves: {
        title: 'PROCESS.SOLR_FORM.PPE.GLOVES.TITLE',
        description: 'PROCESS.SOLR_FORM.PPE.GLOVES.DESCRIPTION'
      },
      Goggles: {
        title: 'PROCESS.SOLR_FORM.PPE.GOGGLES.TITLE',
        description: 'PROCESS.SOLR_FORM.PPE.GOGGLES.DESCRIPTION'
      },
      LabCoat: {
        title: 'PROCESS.SOLR_FORM.PPE.LAB_COAT.TITLE',
        description: 'PROCESS.SOLR_FORM.PPE.LAB_COAT.DESCRIPTION'
      },
      Respirator: {
        title: 'PROCESS.SOLR_FORM.PPE.RESPIRATOR.TITLE',
        description: 'PROCESS.SOLR_FORM.PPE.RESPIRATOR.DESCRIPTION'
      },
      SafetyShower: {
        title: 'PROCESS.SOLR_FORM.PPE.SAFETY_SHOWER.TITLE',
        description: 'PROCESS.SOLR_FORM.PPE.SAFETY_SHOWER.DESCRIPTION'
      },
      Suit: {
        title: 'PROCESS.SOLR_FORM.PPE.SUIT.TITLE',
        description: 'PROCESS.SOLR_FORM.PPE.SUIT.DESCRIPTION'
      },
      SuppliedAir: {
        title: 'PROCESS.SOLR_FORM.PPE.SUPPLIED_AIR.TITLE',
        description: 'PROCESS.SOLR_FORM.PPE.SUPPLIED_AIR.DESCRIPTION'
      }
    };

    ctx.getTitle = function (name) {
      return $translate.instant(ctx.ppeMapping[name].title);
    };

    ctx.getDescriprion = function (name) {
      return $translate.instant(ctx.ppeMapping[name].description);
    };

    ctx.getImageSrc = function (name) {
      return `/images/ppe/${name}.png`;
    };

    ctx.sorting = function (name) {
      return ctx.order.indexOf(name);
    };
  }
]);
