import app from '../app';

app.filter('numPluralizer', function () {
  return function (value, one, showZero) {
    showZero = showZero == null ? false : showZero;
    const many = one + 's';
    return value === 1
      ? one
      : !!value == 0
        ? showZero
          ? '0 ' + many
          : many
        : value + ' ' + many;
  };
});
