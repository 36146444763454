import { uniq } from 'lodash';
import selectTags from '../selectTags';
import { legislations } from '../../../../common/helpers/legislations';

export default function updateUser(user, currentCompanyId, companyTags) {
  return {
    templateUrl: require('./updateUser.html'),
    keyboard: true,
    backdrop: true,
    controller: [
      '$rootScope',
      '$uibModalInstance',
      'RoleFactory',
      'UserService',
      'ModalService',
      function ($rootScope, $uibModalInstance, RoleFactory, UserService, ModalService) {
        const vm = this;

        vm.dismiss = $uibModalInstance.dismiss;
        vm.user = { ...user };
        vm.user.restrictedTags = vm.user.restrictedTags || [];
        vm.collapsedTagsTree = !vm.user.restrictedTags.length;
        vm.legislations = legislations;

        vm.userRestrictedTagsList = companyTags.map(el => {
          el.checked = !vm.user.restrictedTags || !vm.user.restrictedTags.includes(el.id);
          return el;
        });

        vm.userInventory = UserService.InventoryAccess;
        vm.selectAll = vm.userInventory.selectAll;
        vm.unselectAll = vm.userInventory.unselectAll;
        vm.showInventoryAccess = vm.userInventory.showInventoryAccess;

        vm.onFocusPassword = e => {
          const form = vm.userform;

          e.target.removeAttribute('readonly');

          form.password.$validators.checkpassword = UserService.checkpassword;
          form.password.$validate();
        };

        vm.onBlurPassword = e => {
          const form = vm.userform;

          e.target.setAttribute('readonly', true);

          if (!form.password.$viewValue) {
            form.password.$setValidity('checkpassword', true);
          }
        };

        vm.save = () => {
          vm.user.password = vm.user.password || null;
          if (!vm.isShowRestrictedBlock()) {
            vm.user.restrictedTags = [];
          }
          return UserService.updateUser(vm.user).then(updatedUser =>
            $uibModalInstance.close(updatedUser)
          );
        };

        vm.userInventory.getInventories(user, currentCompanyId);

        vm.isShowRestrictedBlock = function () {
          let selectedRole = RoleFactory.roles.find(elem => elem.id == vm.user.roleId);
          return (
            UserService.isShowRestrictedTagsBlock(selectedRole) && vm.userRestrictedTagsList.length
          );
        };

        vm.selectRestrictedTags = function () {
          if (!vm.user.restrictedTags) {
            vm.user.restrictedTags = [];
          }

          return ModalService.open(
            selectTags(currentCompanyId, {
              checkedTags: vm.userRestrictedTagsList.filter(tag => tag.checked),
              allowSearchByParentTags: false,
              showAndOrDropdown: false,
              message: null
            })
          ).then(result => {
            // reset all tags in userRestrictedTagsList
            for (const tag of vm.userRestrictedTagsList) {
              tag.checked = false;
            }

            // mark tags from result.tags as "checked" in userRestrictedTagsList
            for (const tag of result.tags) {
              const index = vm.userRestrictedTagsList.findIndex(el => el.id === tag.id);
              if (index > -1) {
                vm.userRestrictedTagsList[index].checked = tag.checked;
              }
            }

            for (const tag of vm.userRestrictedTagsList) {
              if (tag.checked) {
                const index = vm.user.restrictedTags.indexOf(tag.id);
                if (index > -1) {
                  vm.user.restrictedTags.splice(index, 1);
                }
              } else {
                vm.user.restrictedTags.push(tag.id);
              }
            }

            vm.user.restrictedTags = uniq(vm.user.restrictedTags);
          });
        };

        vm.showInputAllowToViewSensitiveSds = function () {
          const selectedRole = vm.roles.find(val => val.id === vm.user.roleId);
          if (selectedRole && selectedRole.name === 'teamAdmin') {
            return false;
          }
          return true;
        };

        init();

        function init() {
          vm.roles = RoleFactory.roles;

          if (!$rootScope.isModuleEnabled($rootScope.moduleNames.inv, true)) {
            vm.roles = vm.roles.filter(role => role.name !== 'invManagerRead');
          }
        }
      }
    ],
    controllerAs: 'ctrl'
  };
}
