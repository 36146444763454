import app from '../../app';

app.controller('locationRequestCtrl', [
  '$rootScope',
  '$translate',
  'LocationRequestService',
  'CompanyService',
  'SpinnerService',
  'MessagesService',
  'ErrorService',
  'InventoryService',
  function locationRequestCtrl(
    $rootScope,
    $translate,
    LocationRequestService,
    CompanyService,
    SpinnerService,
    MessagesService,
    ErrorService,
    InventoryService
  ) {
    const simpleCatch = ErrorService.simpleCatch;
    const vm = this;
    const defaultForm = [
      {
        name: 'from',
        type: 'email',
        text: $translate.instant('LOCATION_REQUEST.FORM_FIELDS.EMAIL'),
        isRequired: true
      },
      {
        name: 'Request Details',
        type: 'textarea',
        text: $translate.instant('LOCATION_REQUEST.FORM_FIELDS.ADDITIONAL_DETAILS'),
        isRequired: false
      }
    ];
    const sendRequest = SpinnerService.wrap(function () {
      return LocationRequestService.sendRequest(document.getElementById('requestForm'));
    });
    vm.fileTypeError = false;
    vm.files = {};
    vm.formModel = {};
    vm.requestForm = [];

    vm.modes = [
      { type: 'addLocation', label: 'LOCATION_REQUEST.FORM_FIELDS.ADD_NEW_LOCATION' },
      { type: 'addSubLocation', label: 'LOCATION_REQUEST.FORM_FIELDS.ADD_NEW_SUB_LOCATION' },
      { type: 'changeLocation', label: 'LOCATION_REQUEST.FORM_FIELDS.CHANGE_LOCATION_NAME' }
    ];
    vm.selectedMode = vm.modes[0];
    vm.formModel.changeLocation = false;
    vm.formModel.oldLocationName = '';
    vm.formModel.mainLocationName = '';
    vm.onModeSelected = function () {
      if (vm.selectedMode.type === 'changeLocation') {
        vm.formModel.changeLocation = true;
      } else {
        vm.formModel.changeLocation = false;
      }
    };

    vm.onInventoryChange = function () {
      if (vm.inventoryWorkflowModel.length === 0) {
        vm.formModel.oldLocationName = '';
      } else {
        vm.formModel.oldLocationName = vm.inventoryWorkflowModel[0].title;
      }
    };

    vm.onSubLocationInventoryChange = function () {
      if (vm.inventorySubLocationWorkflowModel.length === 0) {
        vm.formModel.mainLocationName = '';
      } else {
        vm.formModel.mainLocationName = vm.inventorySubLocationWorkflowModel[0].title;
      }
    };

    vm.onSubmit = async function () {
      try {
        await sendRequest();
        vm.state = 'SENT';

        return MessagesService.success();
      } catch (err) {
        console.log(err);
        return simpleCatch(err);
      }
    };

    vm.resetForm = function () {
      vm.selectedMode = vm.modes[0];
      vm.formModel = {};
      vm.formModel.changeLocation = false;
      vm.formModel.oldLocationName = '';
      vm.formModel.mainLocationName = '';
      vm.inventoryWorkflowModel = [];
      vm.inventorySubLocationWorkflowModel = [];
      vm.files = {};
      vm.state = null;
    };

    const init = SpinnerService.wrap(function () {
      return CompanyService.getCurrentCompanyPromise()
        .$promise.then(function () {
          return CompanyService.CompanySettings($rootScope.companyId).$promise;
        })
        .then(function () {
          if ($rootScope.companySettings.requestLocationFormEnabled) {
            vm.requestForm = $rootScope.companySettings.requestLocationForm || [];
          } else {
            vm.requestForm = defaultForm;
          }
          initInventoryWorkflow();
        });
    });

    init();

    async function initInventoryWorkflow() {
      const companyId = $rootScope.companyId;

      const items = await InventoryService.getTreeInfo(companyId);

      vm.inventoryValues = Object.values(items).reduce((sum, inv) => {
        if (!inv.data) return sum;
        return sum.concat({
          title: inv.path && inv.path.length ? inv.path.replaceAll('/', ' / ') : inv.data.name,
          value: inv.data.id
        });
      }, []);
    }
  }
]);
