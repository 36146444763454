import app from '../../app';
import { getOffset } from '../../helpers';
import scheduleReport from '../../modals/scheduleReport';

app.controller('section313ReportCtrl', [
  '$rootScope',
  '$scope',
  'Section313Report',
  'CompanyService',
  'ModalService',
  'SpinnerService',
  'ExportService',
  'SchedulerService',
  function (
    $rootScope,
    $scope,
    Section313Report,
    CompanyService,
    ModalService,
    SpinnerService,
    ExportService,
    SchedulerService
  ) {
    const vm = this;
    vm.startFrom = 0;
    vm.PER_PAGE = 100;
    vm.totalItems = 0;
    vm.reportData = [];
    vm.headers = ['name', 'manufacturerName', 'cas'];
    vm.selectedCompany = {};

    const getReportData = SpinnerService.wrap(async function (companyId, offset) {
      const response = await Section313Report.getData({
        companyId,
        offset,
        limit: vm.PER_PAGE
      }).$promise;

      vm.reportData = response.data;
      vm.totalItems = response.numFound;
      $scope.$apply();

      return response;
    });

    vm.openScheduleModal = function () {
      return ModalService.open(scheduleReport()).then(res =>
        createScheduledReport(res, $rootScope.companyId)
      );
    };

    vm.onCompanySelected = function (selectedCompany) {
      vm.selectedCompany = selectedCompany;
      vm.startFrom = 0;

      getReportData(selectedCompany.id, vm.startFrom);
    };

    vm.handlePaginate = function () {
      getReportData(vm.selectedCompany.id, getOffset(vm.startFrom, vm.PER_PAGE));
    };

    vm.handleExport = async function (type) {
      const getBinaryFunc = async () => {
        const response = await Section313Report.export({
          companyId: vm.selectedCompany.id,
          type
        }).$promise;
        return response.data;
      }

      await ExportService.cbBinaryToFile(getBinaryFunc, type);
    };

    init();

    function init() {
      $scope.$watch('siteLanguage', function (newValue, oldValue) {
        if (newValue === oldValue) return;
        getReportData(vm.selectedCompany.id, vm.startFrom);
      });

      if (!$rootScope.checkIfUserIs('admin')) {
        CompanyService.getCurrentCompanyPromise().$promise.then(function () {
          if ($rootScope.currentCompany.childSdsCompanies.length) return; // handle this by companySelector

          vm.onCompanySelected({ id: $rootScope.companyId });
        });
      }
    }

    function createScheduledReport(formObj, companyId, params = {}) {
      params.lang = $rootScope.siteLanguage;
      return SchedulerService.createReport(
        SchedulerService.jobs.report.SECTION313_REPORT,
        companyId,
        formObj.emails,
        formObj.frequency,
        params
      );
    }
  }
]);
