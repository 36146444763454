import app from '../../app';

app.directive('printoutNavItem', [
  'LabelBatchService',
  function (LabelBatchService) {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: require('./printoutNavItem.html'),
      link: function (scope) {
        scope.getLabelsQuantity = LabelBatchService.getLabelsQuantity;
      }
    };
  }
]);
