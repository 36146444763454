import app from '../../app';

app.directive('attachment', [
  '$rootScope',
  '$state',
  'CompanyFactory',
  'Attachment',
  function (rootScope, state, CompanyFactory, Attachment) {
    function attachmentDirective(scope, el) {
      scope.isAdmin = rootScope.checkIfUserIs('admin');
      scope.attachment.typeOfLink = scope.attachment.typeOfLink || 'link';
      scope.attachment.companies = scope.attachment.companies || [];
      scope.multiselectLabels = {
        itemsSelected: ' ',
        select: ' '
      };

      let attachCompaniesHardUpdate = false;
      scope.$watchCollection('attachment.companies', function (newCollection, oldCollection) {
        if (attachCompaniesHardUpdate) {
          attachCompaniesHardUpdate = false;
          return;
        }

        const whatChanged = newCollection.length - oldCollection.length;
        let firstCollection, secondCollection, action;
        if (whatChanged < 0) {
          // delete from array
          firstCollection = oldCollection;
          secondCollection = newCollection;
          action = 'delete';
        } else if (whatChanged > 0) {
          // insert into array
          firstCollection = newCollection;
          secondCollection = oldCollection;
          action = 'insert';
        } else return;
        const diff = firstCollection.filter(function (el) {
          for (let index = 0; index < secondCollection.length; index++) {
            const element = secondCollection[index];
            if (element.id === el.id) return false;
          }
          return true;
        });

        if (action === 'insert' && diff.length > 0) {
          el = diff[0];
          if (el.id) {
            // insert a new company, the global company should be unselect
            scope.attachment.companies = newCollection.filter(function (el_new) {
              if (!el_new.id) {
                attachCompaniesHardUpdate = true;
                return false;
              }
              return true;
            });
          } else {
            // insert the global company, a new companies should be unselect
            scope.attachment.companies = newCollection.filter(function (el_new) {
              if (el_new.id) {
                attachCompaniesHardUpdate = true;
                return false;
              }
              return true;
            });
          }
        }
      });

      scope.delete = function () {
        Attachment.destroyById({ id: scope.attachment.id }).$promise.then(function (result) {
          scope.$emit('attachmentDelete', scope.attachment);
        });
      };

      scope.getTitleForMultiselect = function () {
        return scope.attachment.companies
          .map(function (el) {
            return el.name;
          })
          .join(', ');
      };
    }

    return {
      restrict: 'E',
      scope: {
        attachment: '=',
        companies: '='
      },
      templateUrl: require('./attachment.html'),
      link: attachmentDirective
    };
  }
]);
