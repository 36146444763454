import app from '../../app';

app.directive('displayName', [
  function () {
    return {
      restrict: 'E',
      scope: {
        value: '='
      },
      templateUrl: require('./displayName.html')
    };
  }
]);
