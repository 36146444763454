import app from '../../../app';
import scheduleReport from '../../../modals/scheduleReport';
import selectInventory from '../../../modals/selectInventory';

app.controller('inventoryAuditTrailCtrl', [
  '$rootScope',
  'SystemAuditService',
  'ExportService',
  'SchedulerService',
  'ModalService',
  inventoryAuditTrailCtrl
]);

function inventoryAuditTrailCtrl(
  $rootScope,
  SystemAuditService,
  ExportService,
  SchedulerService,
  ModalService
) {
  const vm = this;
  const objectTypes = ['product', 'container', 'equipment', 'file'];

  vm.title = 'REPORTS.INVENTORY_AUDIT_TRAIL.TITLE';
  vm.sas = new SystemAuditService(objectTypes);
  vm.inventoryAuditTrail = true;
  vm.selectedInventory = [];
  vm.selectInventory = async function () {
    const inventoryModalResult = await openInventoryModal(vm.selectedInventory);
    if (inventoryModalResult.cancel) return;

    vm.selectedInventory = inventoryModalResult.selectedInventory;
    vm.sas.setDetailsFilter(vm.selectedInventory);
  };
  vm.handleExport = function () {
    return vm.sas.getRecords2Export().then(function (records) {
      return ExportService.exportSystemAudit2csv(records, 'inventory-audit-report.csv');
    });
  };

  vm.openScheduleModal = function () {
    return ModalService.open(scheduleReport()).then(res => {
      if (
        $rootScope.checkIfUserIs('admin') &&
        (!vm.sas.selectedCompany || !vm.sas.selectedCompany.id)
      ) {
        return SchedulerService.createReportForAllCompanies(
          res,
          vm.sas.companies,
          {
            objectTypes,
            inventoryIds: vm.selectedInventory
              .filter(val => val.inventories.length === 0)
              .map(inv => inv.id)
          },
          createScheduledReport
        );
      } else {
        return createScheduledReport(res, $rootScope.companyId, {
          objectTypes,
          inventoryIds: vm.selectedInventory
            .filter(val => val.inventories.length === 0)
            .map(inv => inv.id)
        });
      }
    });
  };

  function createScheduledReport(formObj, companyId, params = {}, quiet = false) {
    return SchedulerService.createReport(
      SchedulerService.jobs.report.INVENTORY_AUDIT_REPORT,
      companyId,
      formObj.emails,
      formObj.frequency,
      params,
      quiet
    );
  }

  async function openInventoryModal(checkedInventory = []) {
    try {
      const selectedInventory = await ModalService.open(
        selectInventory($rootScope.companyId, {
          checkedInventory,
          selectAllOnInit: false,
          emptyForAll: true
        })
      );

      return {
        selectedInventory,
        cancel: false
      };
    } catch (err) {
      if (!err || err === 'backdrop click')
        return {
          selectedInventory: checkedInventory,
          cancel: true
        };

      console.log(err);
    }
  }
}
