import app from '../../app';

app.directive('hazardsSelector', [
  'HazardService',
  function (HazardService) {
    return {
      restrict: 'E',
      scope: {
        hazards: '=',
        startCollapsed: '=',
        onChange: '&'
      },
      templateUrl: require('./hazardsSelector.html'),
      link: function (scope) {
        scope.HazardService = HazardService;
        scope.order = function (entry) {
          return HazardService.sortingGetter(entry.value);
        };
      }
    };
  }
]);
