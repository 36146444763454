import app from '../app';

app.directive('allowedValues', function () {
  return {
    restrict: 'A',
    require: 'ngModel',
    scope: {
      allowedValues: '=',
      allowedValuesPropToLook: '@'
    },
    link: function (scope, elem, attrs, ctrl) {
      ctrl.$validators.includes = function (viewValue) {
        const value =
          viewValue != null &&
          typeof viewValue === 'object' &&
          scope.allowedValuesPropToLook != null
            ? viewValue[scope.allowedValuesPropToLook]
            : viewValue;

        return !value || !!~scope.allowedValues.indexOf(value);
      };
    }
  };
});
