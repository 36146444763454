import { log } from '../../../vendor/exceljs/exceljs';
import app from '../../app';
import { createTree } from '../../helpers';

app.directive('tagSelector', [
  '$filter',
  'CompanyService',
  function ($filter, CompanyService) {
    return {
      restrict: 'E',
      scope: {
        tags: '=',
        startCollapsed: '=',
        companyId: '=',
        filterArchived: '=',
        onChange: '&'
      },
      templateUrl: require('./tagSelector.html'),
      link: function (scope) {
        const structurize = function (flatTags) {
          const tree = createTree(flatTags, 'tagId', 'tags');

          calculateTagAmounts(tree);

          return tree;
        };
        const has = function (itms, cb) {
          return itms.reduce(function (found, itm) {
            return found || cb(itm);
          }, false);
        };
        const getTagTitleFilter = $filter('getTagTitle');

        scope.searchByParentTags = false;
        scope.tagsTree = [];
        scope.$watch('tags', function (tags) {
          scope.tagsTree = structurize(tags).filter(tag => scope.companyTag(tag));
        });
        scope.$watch('companyId', function (companyId) {
          scope.companyId = companyId;

          CompanyService.CompanySettings(companyId, false).$promise.then(settings => {
            scope.searchByParentTags = settings.searchByParentTags;
          });
        });
        scope.universalTag = function (tag) {
          return !tag.companyId && tag.amount;
        };
        scope.companyRestrictedTag = function (tag) {
          return (
            tag.companyId &&
            (!scope.companyId || scope.companyId === tag.companyId) &&
            tag.restricted &&
            tag.amount
          );
        };
        scope.companyTag = function (tag) {
          return (
            tag.companyId &&
            (!scope.companyId || scope.companyId === tag.companyId) &&
            !tag.restricted &&
            tag.amount &&
            (!scope.filterArchived || tag.archived === false)
          );
        };
        scope.hasTags = function (tags, identifier) {
          return has(tags, identifier);
        };
        scope.hasAmount = function (tag) {
          return tag.amount;
        };
        scope.getTagTitle = function (tag) {
          const restrictedPrefix =
            !scope.companyId && tag.restricted && tag.companyName ? ' - ' + tag.companyName : '';
          return getTagTitleFilter(tag) + restrictedPrefix;
        };
        scope.toggleTagTree = function (event, uiTreeNode) {
          let tag = uiTreeNode.$modelValue;
          if (event.target instanceof HTMLInputElement) return;

          uiTreeNode.toggle();
        };
        scope.toggleParentTag = function (tag) {
          toggleChildrenTags(tag.tags, tag.checked);
        };
      }
    };
  }
]);

function toggleChildrenTags(tags = [], status) {
  for (let tag of tags) {
    tag.checked = status;
    tag.disabled = status;

    toggleChildrenTags(tag.tags, status);
  }
}

function calculateTagAmounts(tags) {
  let amount = 0;

  if (!tags.length) return amount;

  for (let tag of tags) {
    tag.amount += calculateTagAmounts(tag.tags);
    amount += tag.amount;
  }

  return amount;
}
