import app from '../app';

app.filter('QRCodesSearchFilter', function () {
  return function (QRCodes, input, scope) {
    var filteredQRCodes = scope.filter('filter')(QRCodes, input);
    var limitedFilteredQRCodes = scope.filter('limitTo')(
      filteredQRCodes,
      scope.qrCodeViewModel.PER_PAGE,
      scope.qrCodeViewModel.qrcodeStart
    );
    scope.qrCodeViewModel.totalItems = filteredQRCodes.length;

    if (filteredQRCodes.length && !limitedFilteredQRCodes.length)
      scope.qrCodeViewModel.qrcodeStart = 0;

    return limitedFilteredQRCodes;
  };
});
