import app from '../../app';
import supplierInfo from '../../modals/supplierInfo';
import { getOffset } from '../../helpers';

app.controller('productsDontRequireSdsReportCtrl', [
  'SpinnerService',
  'ProductService',
  'ExportService',
  'ModalService',
  function (SpinnerService, ProductService, ExportService, ModalService) {
    const vm = this;
    const getProductsDontRequireSds = SpinnerService.wrap(function () {
      return ProductService.getProductsDontRequireSds(
        getOffset(vm.startFrom, vm.PER_PAGE),
        vm.PER_PAGE
      ).then(function (response) {
        vm.totalItems = vm.totalItems || response.count;
        vm.productsDontRequireSds = response.products;
        vm.productsDontRequireSds.forEach(el => {
          el.size = ProductService.showCorrectProductSize(el);
        });
      });
    });
    vm.startFrom = 1;
    vm.PER_PAGE = 20;
    vm.productsDontRequireSds = [];

    vm.handlePaginate = getProductsDontRequireSds;
    vm.handleExport = ExportService.exportProductsDontRequireSdsReport2csv;

    vm.openSupplierInfoModal = function (product) {
      return ModalService.open(supplierInfo({ suppliers: product.suppliers }));
    };

    getProductsDontRequireSds(vm.startFrom);
  }
]);
