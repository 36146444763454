import angular from 'angular';
import app from '../../app';

app.directive('editingAttachment', [
  '$rootScope',
  '$q',
  'SpinnerService',
  'ModalService',
  'Attachment',
  'AttachmentService',
  'ContainerService',
  function (
    $rootScope,
    $q,
    SpinnerService,
    ModalService,
    Attachment,
    AttachmentService,
    ContainerService
  ) {
    return {
      restrict: 'E',
      scope: {
        attachment: '=',
        companies: '='
      },
      templateUrl: require('./editingAttachment.html'),
      link: function ($scope) {
        $scope.containerService = new ContainerService('attachment');
        $scope.accessAddAttachments = $rootScope.accessAddAttachments();
        $scope.AttachmentService = AttachmentService;
        let attachCompaniesHardUpdate = false;
        const onCompanySelect = SpinnerService.wrap(function (
          attachment,
          newCollection,
          oldCollection
        ) {
          attachCompaniesHardUpdate = true;
          return AttachmentService.onCompanySelect(attachment, newCollection, oldCollection);
        });

        $scope.$watchCollection('attachment.companies', function (newCollection, oldCollection) {
          if (attachCompaniesHardUpdate) return;
          return onCompanySelect($scope.attachment, newCollection, oldCollection).then(function () {
            attachCompaniesHardUpdate = false;
            $scope.$emit('attachmentsChange');
          });
        });

        $scope.editAttachment = function (attachment) {
          attachment.editing = angular.copy(attachment);
          attachment.editing.companyTitle =
            attachment.editing.companyTitle || attachment.editing.title;
        };

        $scope.deleteAttachment = function (attachment) {
          ModalService.open({
            templateUrl: require('../../../views/modals/confirm-message.html'),
            resolve: {
              message: [
                '$q',
                '$translate',
                function ($q, $translate) {
                  return $q.all({
                    title: $translate('COMMON.ACTIONS.CONFIRM_DELETE.TITLE', {
                      name: attachment.title
                    }),
                    message: $translate('COMMON.ACTIONS.CONFIRM_DELETE.MESSAGE')
                  });
                }
              ]
            },
            controller: [
              '$uibModalInstance',
              'message',
              function ($uibModalInstance, message) {
                var modal = this;

                modal.title = message.title;
                modal.message = message.message;
                modal.cancel = $uibModalInstance.dismiss;
                modal.confirm = function () {
                  if (attachment.typeOfLink === 'file') {
                    $scope.containerService
                      .removeFile(`${attachment.id}${attachment.link}`)
                      .then(() => {
                        $uibModalInstance.close();
                      });
                  } else {
                    $uibModalInstance.close();
                  }
                };
              }
            ]
          }).then(function () {
            $scope.$emit('deleteAttachment', attachment);
          });
        };

        $scope.closeEditingAttachment = function (attachment) {
          attachment.editing = undefined;
        };

        $scope.getTitleForMultiselect = function (attachment) {
          return attachment.companies
            .map(function (el) {
              return el.name;
            })
            .join(', ');
        };

        function getCompany(companies, companyId) {
          let ret;
          for (let index = 0; index < companies.length; index++) {
            const element = companies[index];
            if (element.id === companyId) {
              ret = element;
              break;
            }
          }
          return ret;
        }

        $scope.saveEditingAttachment = SpinnerService.wrap(function (attachment) {
          let company = getCompany(attachment.editing.companies, $rootScope.companyId);
          if (!company) company = getCompany(attachment.editing.companies, null);
          if (!company) return $q.resolve(false);
          return Attachment.updateForCompany({
            id: attachment.editing.id,
            company: {
              title: company.companyTitle || attachment.editing.companyTitle,
              id: company.id
            }
          }).$promise.then(function () {
            attachment.companyTitle = company.companyTitle || attachment.editing.companyTitle;
            $scope.closeEditingAttachment(attachment);
          });
        });
      }
    };
  }
]);
