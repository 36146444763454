import app from '../../app';

app.directive('docUnverified', [
  '$rootScope',
  'SearchPageService',
  function ($rootScope, SearchPageService) {
    return {
      restrict: 'E',
      transclude: true,
      scope: {
        doc: '='
      },
      templateUrl: require('./docUnverified.html'),
      link: function (scope) {
        scope.isAdmin = $rootScope.checkIfUserIs(['admin', 'sdsEditor']);
        scope.pdfOnly = scope.isAdmin ? false : $rootScope.companySettings.pdfOnly;
        scope.isVerified = function (doc) {
          return SearchPageService.isVerifiedByLang(this.doc);
        };
      }
    };
  }
]);
