import angular from 'angular';
import { get } from 'lodash';
import app from '../app';
import { waitForProp } from '../helpers';

app.service('UserService', [
  '$rootScope',
  '$translate',
  'SdsUser',
  'RoleFactory',
  'Inventory',
  'DenyToInventory',
  'CompanySetting',
  'CompanyFactory',
  'CompanyService',
  'SpinnerService',
  'MessagesService',
  'ErrorService',
  function (
    $rootScope,
    $translate,
    SdsUser,
    RoleFactory,
    Inventory,
    DenyToInventory,
    CompanySetting,
    CompanyFactory,
    CompanyService,
    SpinnerService,
    MessagesService,
    ErrorService
  ) {
    const vm = this;
    const simpleCatch = ErrorService.simpleCatch;
    const translations = {};
    const waitForCompanyId = waitForProp($rootScope, 'companyId');
    let passwordNotifyShowed = false;

    const testRegex = function (arr, modelValue) {
      let res;
      for (let i = 0; i < arr.length; i++) {
        res = modelValue.match(arr[i]) !== null;
        if (!res) break;
      }

      return res;
    };

    // check if user's tags restriction available for user
    vm.isShowRestrictedTagsBlock = function (role) {
      let usersCanRemove = vm.companySettings.labelRemoveTags
        ? vm.companySettings.accessRemoveTags
        : [];
      let usersCanAdd = vm.companySettings.labelAddTags ? vm.companySettings.accessAddTags : [];
      let showFor = [...usersCanRemove, ...usersCanAdd].filter(
        role => role !== 'teamAdmin' && role !== 'firstResponder'
      );

      return $rootScope.checkIfUserIs(['admin', 'teamAdmin']) && showFor.includes(role.name);
    };

    vm.checkpassword = function (modelValue) {
      if (!modelValue) return false;

      const capsCharacter = vm.companySettings.checkpassword_capsCharacter,
        digit = vm.companySettings.checkpassword_digit,
        length = vm.companySettings.checkpassword_length || 1,
        symbol = vm.companySettings.checkpassword_symbol;

      const test = [];
      if (capsCharacter) test.push(/[A-Z]/);
      if (digit) test.push(/[\d]/);
      if (symbol) test.push(/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/);
      test.push(new RegExp('.{' + length + ',}'));

      // /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/

      const isValid = testRegex(test, modelValue);

      if (!isValid && !passwordNotifyShowed) {
        notifyAboutPasswordRules();
        passwordNotifyShowed = true;

        // show the notification again after some time passed
        setTimeout(() => (passwordNotifyShowed = false), 3000);
      }

      return isValid;
    };

    vm.getCompanySettings = function (companyId) {
      CompanySetting.findById(
        { id: companyId, filter: { include: ['storageCodes', 'disposalCodes'] } },
        function (data) {
          vm.companySettings = data;
          vm.companySettings.checkpassword_length = data.checkpassword_length;
          vm.companySettings.checkpassword_capsCharacter = data.checkpassword_capsCharacter;
          vm.companySettings.checkpassword_digit = data.checkpassword_digit;
          vm.companySettings.checkpassword_symbol = data.checkpassword_symbol;

          getPasswordRulesTranslations(data.checkpassword_length);
        },
        function (err) {
          console.log('ERROR : ' + JSON.stringify(err), err);
          vm.companySettings.checkpassword_length = 0;
          vm.companySettings.checkpassword_capsCharacter = false;
          vm.companySettings.checkpassword_digit = false;
          vm.companySettings.checkpassword_symbol = false;
        }
      );
    };

    vm.searchUsers = function (searchString, companyId, offset, limit = 20) {
      const options = {
        searchString,
        companyId,
        filter: {
          limit,
          offset
        }
      };

      return SdsUser.search(options)
        .$promise.then(res => {
          return RoleFactory.getRoles().then(roles => [res, roles]);
        })
        .then(([userRes, roles]) => {
          userRes.users = mapUsersWithRoles(userRes.users, roles);

          return userRes;
        })
        .catch(simpleCatch);
    };

    vm.getAllCompanyUsers = SpinnerService.wrap(function (companyId) {
      return vm.searchUsers('', companyId, 0, Number.MAX_SAFE_INTEGER).then(res => res.users);
    });

    vm.createUser = SpinnerService.wrap(function (newUser, roleId, companyId) {
      newUser.roleId = roleId;
      newUser.companyId = companyId;

      return vm.updateUser(newUser);
    });

    vm.updateUser = SpinnerService.wrap(function (user) {
      return SdsUser.saveUser(user)
        .$promise.then(function (updatedUser) {
          return DenyToInventory.updateMany({
            where: vm.InventoryAccess.inventories.map(function (el) {
              return { userId: updatedUser.id, inventoryId: el.id, deny: !el.enable };
            })
          }).$promise.then(() => updatedUser);
        })
        .catch(err => {
          if (err.data.error.message === 'Email exists') {
            MessagesService.error('COMMON.MESSAGES.EMAIL_EXISTS');

            return Promise.reject(err);
          }

          if (err.data.error.message === 'Username exists') {
            MessagesService.error('COMMON.MESSAGES.USERNAME_EXISTS');

            return Promise.reject(err);
          }

          return simpleCatch(err);
        });
    });

    vm.deleteUser = SpinnerService.wrap(function (user) {
      return Promise.all([
        RoleFactory.DeleteUserMapping(user.id),
        CompanyFactory.DeleteUserMapping(user.id),
        SdsUser.deleteUserById({ id: user.id }).$promise
      ]).catch(simpleCatch);
    });

    vm.InventoryAccess = {
      inventories: [],
      inventoryList: [],
      denyToInventory: [],
      getInventories: function (user, currentCompanyId) {
        return Promise.all([
          Inventory.find({
            filter: {
              fields: ['name', 'id', 'order'],
              order: 'order ASC',
              where: { companyId: currentCompanyId, groupId: 'root' }
            }
          }).$promise.then(function (result) {
            vm.InventoryAccess.inventoryList = result;
            pipeInventoryAccess();
          }),
          DenyToInventory.find({
            filter: {
              where: { userId: user ? user.id : undefined }
            }
          }).$promise.then(function (result) {
            vm.InventoryAccess.denyToInventory = result;
            pipeInventoryAccess();
          })
        ]);
      },
      selectAll: function () {
        vm.InventoryAccess.inventories.forEach(function (el) {
          el.enable = true;
        });
      },
      unselectAll: function () {
        vm.InventoryAccess.inventories.forEach(function (el) {
          el.enable = false;
        });
      },
      showInventoryAccess: function (role) {
        const userRole = RoleFactory.getRoleById(role.roleId || role.id).name;

        return (
          $rootScope.isModuleEnabled('Inventory', true) &&
          (userRole === 'teacher' || userRole === 'teamMember' || userRole === 'invManagerRead')
        );
      }
    };

    vm.checkUserSpecialAccess = function (accessName, user) {
      user = user != null ? user : $rootScope.currentUser || {};

      return get(user, `specialAccess.${accessName}`, false);
    };

    vm.companySettings = {};

    init();

    async function init() {
      await waitForCompanyId();

      if ($rootScope.companyId) {
        vm.getCompanySettings($rootScope.companyId);
      } else {
        CompanyService.getCurrentCompanyPromise().$promise.then(function () {
          vm.getCompanySettings($rootScope.companyId);
        });
      }
    }

    function getPasswordRulesTranslations(passwordMinLength) {
      return $translate(
        [
          'SETTINGS.PASSWORD_REQUIREMENTS.NOTIFICATIONS.MUST_CONTAIN',
          'SETTINGS.PASSWORD_REQUIREMENTS.NOTIFICATIONS.MIN_LENGTH',
          'SETTINGS.PASSWORD_REQUIREMENTS.NOTIFICATIONS.CAP_LETTER',
          'SETTINGS.PASSWORD_REQUIREMENTS.NOTIFICATIONS.DIGIT',
          'SETTINGS.PASSWORD_REQUIREMENTS.NOTIFICATIONS.SYMBOL'
        ],
        { number: passwordMinLength }
      ).then(rules => (translations.passwordRules = rules));
    }

    function pipeInventoryAccess() {
      vm.InventoryAccess.inventories = [];

      for (let index = 0; index < vm.InventoryAccess.inventoryList.length; index++) {
        const inventory = vm.InventoryAccess.inventoryList[index];
        let accessDenied;

        vm.InventoryAccess.denyToInventory.forEach(function (access) {
          if (access.inventoryId === inventory.id) accessDenied = access;
        });

        const obj = angular.copy(inventory);
        obj.enable = !accessDenied || !accessDenied.deny;

        vm.InventoryAccess.inventories.push(obj);
      }
    }

    function mapUsersWithRoles(users = [], roles) {
      if (users == null) return;

      return users.map(user => {
        const role = roles.find(role => role.id === user.roleId);

        return {
          ...user,
          roleName: role.name,
          roleVisibleName: role.visibleName
        };
      });
    }

    function notifyAboutPasswordRules() {
      if (!translations.passwordRules) return console.error('no password rules translations');

      const capsCharacter = vm.companySettings.checkpassword_capsCharacter;
      const digit = vm.companySettings.checkpassword_digit;
      const symbol = vm.companySettings.checkpassword_symbol;

      let notifyString =
        translations.passwordRules['SETTINGS.PASSWORD_REQUIREMENTS.NOTIFICATIONS.MUST_CONTAIN'] +
        ' ' +
        translations.passwordRules['SETTINGS.PASSWORD_REQUIREMENTS.NOTIFICATIONS.MIN_LENGTH'];

      if (capsCharacter) {
        notifyString +=
          ', ' +
          translations.passwordRules['SETTINGS.PASSWORD_REQUIREMENTS.NOTIFICATIONS.CAP_LETTER'];
      }

      if (digit) {
        notifyString +=
          ', ' + translations.passwordRules['SETTINGS.PASSWORD_REQUIREMENTS.NOTIFICATIONS.DIGIT'];
      }

      if (symbol) {
        notifyString +=
          ', ' + translations.passwordRules['SETTINGS.PASSWORD_REQUIREMENTS.NOTIFICATIONS.SYMBOL'];
      }

      notifyString += '.';

      return MessagesService.info(notifyString);
    }
  }
]);
