import app from '../../app';

app.directive('docTags', [
  '$filter',
  '$rootScope',
  '$timeout',
  'HelperService',
  'SearchPageService',
  function ($filter, $rootScope, $timeout, HelperService, SearchPageService) {
    return {
      restrict: 'E',
      transclude: true,
      scope: {
        tags: '=',
        only: '@',
        doc: '='
      },
      templateUrl: require('./docTags.html'),
      link: function (scope) {
        scope.quickFilter = function (obj, filterClass) {
          scope.$emit('onQuickFilter', { obj: obj, filterClass: filterClass });
        };

        scope.getLabelStyle = function (tag) {
          return tag.restricted
            ? 'label-warning'
            : tag.administrative
            ? 'label-info'
            : 'label-primary';
        };
        scope.getTagStyle = function (tag) {
          return (
            tag.tagColor && {
              background: tag.tagColor,
              color: HelperService.getBrighterColor(tag.tagColor)
            }
          );
        };
        scope.getTagTitle = $filter('getTagTitle');
        scope.restrictedTag = function (tag) {
          return tag.restricted;
        };
        scope.getRestrictedTooltip = function (tag) {
          if (scope.only !== 'restricted') return;

          const tryDecode = $filter('decodeURIComponent');
          return tryDecode(scope.doc['restricted_in_' + tag.companyId]);
        };
        scope.universalTag = function (tag) {
          return !tag.restricted && !tag.companyId;
        };
        scope.companyTag = function (tag) {
          if ($rootScope.checkIfUserIs('admin')) return !tag.restricted && tag.companyId;
          return !tag.restricted && tag.companyId === $rootScope.companyId;
        };
        scope.filter = function (tag) {
          const noop = function () {
            return true;
          };
          return (
            {
              restricted: scope.restrictedTag,
              universal: scope.universalTag,
              company: scope.companyTag
            }[scope.only] || noop
          )(tag);
        };

        scope.tagMouseenter = function ($event, tag) {
          $timeout(function () {
            tag.widthForSafari = 1;
          }, 40);
          tag.holder = true;
        };
        scope.tagMouseout = function ($event, tag) {
          tag.widthForSafari = 0;
          tag.holder = false;
        };

        scope.isThroughOtherCompany = function () {
          if (!SearchPageService.throughOtherCompany) return false;

          const docCompanyIds = scope.doc ? scope.doc.companyId || [] : [];

          return docCompanyIds.includes($rootScope.currentCompany.id);
        };
      }
    };
  }
]);
