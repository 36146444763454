import app from '../../app';
import scheduleReport from '../../modals/scheduleReport';
import selectInventory from '../../modals/selectInventory';

app.controller('fireResponseReport', [
  '$rootScope',
  '$scope',
  'CompanyService',
  'SpinnerService',
  'HazardService',
  'FireResponseReport',
  'SchedulerService',
  'ModalService',
  'ExportService',
  function (
    $rootScope,
    $scope,
    CompanyService,
    SpinnerService,
    HazardService,
    FireResponseReport,
    SchedulerService,
    ModalService,
    ExportService
  ) {
    const vm = this;
    vm.service = $rootScope;
    vm.HazardService = HazardService;
    vm.totalItems = 0;
    vm.selectedInventory = [];

    function init() {
      vm.service.dataInitFireReport = true;
      vm.service.startFrom = 1;
      vm.service.PER_PAGE = 100;
      vm.service.dataFireReport = [];
      vm.service.selectedCompany = {};

      if (!$rootScope.checkIfUserIs('admin')) {
        CompanyService.getCurrentCompanyPromise().$promise.then(function () {
          if ($rootScope.currentCompany.childSdsCompanies.length) return; // handle this by companySelector

          vm.onCompanySelected({ id: $rootScope.companyId });
        });
      }
    }

    $scope.$on('$stateChangeSuccess', function (ev, to, toParams, from, fromParams) {
      if (!vm.service.dataInitFireReport) {
        init();
      }
    });

    $scope.$watch('siteLanguage', function (newValue, oldValue) {
      if (newValue === oldValue) return;
      vm.retrieve(vm.service.selectedCompany.id, vm.service.PER_PAGE, 0);
    });

    vm.retrieve = SpinnerService.wrap(function (companyId, limit, offset) {
      return FireResponseReport.getData(
        {
          companyId: companyId
        },
        { inventoryIds: vm.selectedInventory.map(inv => inv.id) }
      ).$promise.then(function (data) {
        vm.service.dataFireReport = data.map(el => {
          let r = { products: [] };
          r.name = el.name;
          Object.values(el.inventories).forEach(inventory => {
            inventory.forEach(product => {
              r.products = r.products.concat(product);
            });
          });
          return r;
        });
      });
    });

    vm.handleExport = function (type = 'xls') {
      exportReport(type);
    };

    async function exportReport(type) {
      try {
        const inventoryModalResult = await openInventoryModal(vm.selectedInventory);
        if (inventoryModalResult.cancel) return;

        const inventoryIds = inventoryModalResult.selectedInventory.map(inv => inv.id);
        return await ExportService.exportFireResponseReport(
          vm.service.selectedCompany.id,
          type,
          inventoryIds
        );
      } catch (err) {
        console.log(err);
      }
    }

    vm.openScheduleModal = function () {
      return ModalService.open(scheduleReport()).then(res =>
        createScheduledReport(res, $rootScope.companyId, {
          inventoryIds: vm.selectedInventory.map(inv => inv.id)
        })
      );
    };

    vm.onCompanySelected = function (selectedCompany) {
      vm.service.selectedCompany = selectedCompany;
      vm.service.startFrom = 1;

      vm.retrieve(vm.service.selectedCompany.id, vm.service.PER_PAGE, 0);
    };

    vm.selectInventory = async function () {
      const inventoryModalResult = await openInventoryModal(vm.selectedInventory);
      if (inventoryModalResult.cancel) return;

      vm.selectedInventory = inventoryModalResult.selectedInventory;

      vm.retrieve(vm.service.selectedCompany.id, vm.service.PER_PAGE, 0);
    };

    function createScheduledReport(formObj, companyId, params = {}) {
      params.lang = $rootScope.siteLanguage;
      return SchedulerService.createReport(
        SchedulerService.jobs.report.FIRE_RESPONSE_REPORT,
        companyId,
        formObj.emails,
        formObj.frequency,
        params
      );
    }

    async function openInventoryModal(checkedInventory = []) {
      try {
        const selectedInventory = await ModalService.open(
          selectInventory(vm.service.selectedCompany.id, {
            checkedInventory,
            selectAllOnInit: false,
            emptyForAll: true
          })
        );

        return {
          selectedInventory,
          cancel: false
        };
      } catch (err) {
        if (!err || err === 'backdrop click')
          return {
            selectedInventory: checkedInventory,
            cancel: true
          };

        console.log(err);
      }
    }
  }
]);
