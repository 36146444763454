import app from '../../app';

app.directive('docInventories', [
  '$rootScope',
  '$timeout',
  'InventoryService',
  function ($rootScope, $timeout, InventoryService) {
    return {
      restrict: 'E',
      transclude: true,
      scope: {
        onLoad: '&',
        doc: '=',
        list: '='
      },
      templateUrl: require('./docInventories.html'),
      link: function (scope) {
        scope.invTree = {};
        scope.rootInventories = {};
        scope.inventories = [];
        scope.openedInv = {};
        InventoryService.getTreeInfo($rootScope.companyId)
          .then(invTree => {
            scope.invTree = invTree;
            (scope.doc.inventories || [])
              .filter(id => invTree[id])
              .forEach(id => {
                if (invTree[id].rootId) {
                  scope.rootInventories[invTree[id].rootId] = [invTree[id], ...(scope.rootInventories[invTree[id].rootId] || [])]
                } else {
                  scope.rootInventories[id] = null;
                }
              });

            scope.inventories = Object.keys(scope.rootInventories);
            scope.$apply();
            scope.onLoad({ items: scope.inventories, tree: invTree });
          });
        
        scope.getName = function (invData) {
          return invData.data.name;
        }

        scope.getPath = function (invData) {
          return invData.path;
        }

        scope.quickFilter = function (invData) {
          scope.$emit('onQuickFilter', { obj: invData.data, filterClass: 'inventory' });
        };

        scope.invMouseenter = function ($event, inv) {
          scope.openedInv[inv] = {};
          $timeout(function () {
            scope.openedInv[inv].widthForSafari = 1;
          }, 40);
          scope.openedInv[inv].holder = true;
        };

        scope.invMouseout = function ($event, inv) {
          scope.openedInv[inv].widthForSafari = 0;
          scope.openedInv[inv].holder = false;
        };
      }
    };
  }
]);