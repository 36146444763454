import app from '../app';

app.filter('decodeURIComponent', function () {
  return function (str) {
    try {
      return window.decodeURIComponent(str);
    } catch (e) {
      console.log('ERROR: ' + e.message);
      return str;
    }
  };
});
