import angular from 'angular';
import app from '../../app';

app.controller('forgotPasswordCtrl', [
  '$scope',
  '$rootScope',
  'SdsUser',
  function ($scope, $rootScope, SdsUser) {
    $scope.login = '';
    $scope.message = '';
    $scope.errorMessage = '';

    $scope.recoverPassword = function () {
      const params = angular.extend({ login: this.login });
      SdsUser.forgotPassword(params)
        .$promise.then(function (res) {
          $scope.message = res;
          $scope.errorMessage = '';
        })
        .catch(function (err) {
          $scope.message = '';
          $scope.errorMessage = err.data.error.message;
        });
    };
  }
]);
