import app from '../../app';

app.directive('companiesPanel', [
  '$rootScope',
  function ($rootScope) {
    return {
      restrict: 'E',
      scope: {
        companies: '='
      },
      templateUrl: require('./companiesPanel.html'),
      link: function (scope) {
        const getNames = function (companies) {
          companies = companies.filter(company => !!company.id);

          if ($rootScope.checkIfUserIs('sdsEditor')) {
            companies = companies.filter(company => isCompanyAllowed(company.id));
          }

          scope.names = companies.map(function (itm) {
            return itm.name;
          });
        };
        const isCompanyAllowed = function (companyId) {
          return $rootScope.currentUser.allowedCompanies.some(company => company.id === companyId);
        };
        scope.names = [];
        scope.buttonCompany = $rootScope.sitename;
        scope.$watch('companies', function (companies) {
          getNames(companies);
        });

        scope.buttonCompanyOnClick = function () {
          $rootScope.onChangeMainCompany(scope.buttonCompany, true);
        };
        //getNames(scope.companies);
      }
    };
  }
]);
