export default function supplierInfo({ suppliers = [], editable = false } = {}) {
  return {
    templateUrl: require('./supplierInfo.html'),
    keyboard: true,
    backdrop: true,
    controllerAs: 'vm',
    controller: [
      '$uibModalInstance',
      function ($uibModalInstance) {
        const vm = this;

        vm.dismiss = $uibModalInstance.dismiss;
        vm.suppliers = [...suppliers];
        vm.editable = editable;
        vm.form = {
          name: '',
          sku: ''
        };
        vm.errorMessage = '';

        vm.removeSupplier = function (index) {
          vm.suppliers.splice(index, 1);
        };
        vm.addSupplier = function (newSupplier) {
          if (
            vm.suppliers.some(
              supplier => supplier.name === newSupplier.name && supplier.sku === newSupplier.sku
            )
          ) {
            vm.errorMessage = 'PRODUCTS.FORM.SUPPLIER_ALREADY_ADDED';
            return;
          }

          vm.suppliers.push({ ...newSupplier });
          resetForm();
        };

        vm.save = $uibModalInstance.close.bind(null, vm.suppliers);

        function resetForm() {
          vm.errorMessage = '';
          vm.form.name = '';
          vm.form.sku = '';
          vm.supplierInfoForm.$setUntouched();
        }
      }
    ]
  };
}
