import app from '../../app';

app.directive('msdsLabel', [
  function () {
    return {
      restrict: 'E',
      transclude: true,
      scope: {
        msdsFormat: '='
      },
      templateUrl: require('./msdsLabel.html')
    };
  }
]);
