export default function ppePresentationModal({ ppeSelected, publicPpes }) {
  return {
    templateUrl: require('./ppePresentationModal.html'),
    keyboard: true,
    backdrop: true,
    controllerAs: 'ctrl',
    controller: [
      '$uibModalInstance',
      'PpeService',
      function ($uibModalInstance, PpeService) {
        const vm = this;
        vm.PpeService = PpeService;
        vm.ppeSelection = ppeSelected || [];
        vm.publicPpes = publicPpes || [];
        vm.cancel = $uibModalInstance.dismiss;
      }
    ]
  };
}
