import app from '../../app';

app.directive('codesSelector', [
  function () {
    return {
      restrict: 'E',
      scope: {
        codes: '=',
        title: '@',
        startCollapsed: '=',
        companyId: '=',
        onChange: '&'
      },
      templateUrl: require('./codesSelector.html'),
      link: function (scope) {
        scope.$watch('companyId', function (companyId) {
          scope.companyId = companyId;
        });
        scope.visibleCode = function (code) {
          return (
            code.isEnabled && (!scope.companyId || scope.companyId === code.companySettingId)
          );
        };
        scope.$watch('codes', function (codes) {
          scope.filteredCodes = codes.filter(scope.visibleCode);
        });
        scope.isEnabled = function (code) {
          return code.isEnabled;
        };
      }
    };
  }
]);
