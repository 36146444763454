import angular from 'angular';
import app from '../../app';

app.directive('systemNotificationsEditor', [
  function () {
    return {
      restrict: 'E',
      templateUrl: require('./systemNotificationsEditor.html'),
      scope: {},
      controller: [
        '$scope',
        '$filter',
        'SystemSettingService',
        function ($scope, $filter, SystemSettingService) {
          $scope.sss = SystemSettingService;
          $scope.systemNotifications = [];
          $scope.unsavedData = false;
          $scope.treeOptions = {
            dropped: function (event) {
              $scope.setUnsavedStatus(true);
            }
          };
          $scope.generateUniqueID = function () {
            return $filter('generateUniqueID')(10);
          };

          $scope.setUnsavedStatus = function (status) {
            $scope.unsavedData = status;
          };

          $scope.addSystemNotification = function (notification) {
            $scope.systemNotifications.push(angular.copy(notification));

            delete notification.type;
            delete notification.message;

            $scope.setUnsavedStatus(true);
          };

          $scope.removeSystemNotification = function (index) {
            $scope.systemNotifications.splice(index, 1);
            $scope.setUnsavedStatus(true);
          };

          $scope.updateSystemNotifications = function (notifications) {
            return SystemSettingService.updateSystemNotifications(notifications).then(function () {
              $scope.setUnsavedStatus(false);
            });
          };

          SystemSettingService.getSettings().then(function (settings) {
            $scope.systemNotifications = settings.notifications || [];
          });
        }
      ]
    };
  }
]);
