import app from '../app';

app.service('HazardService', [
  '$translate',
  function ($translate) {
    const ctx = this;
    ctx.order = [
      'healthHazard',
      'flame',
      'exclamationMark',
      'corrosion',
      'gasCylinder',
      'explodingBomb',
      'skullAndCrossbones',
      'flameOverCircle',
      'environment',
      'biohazard',
      'flame_old',
      'exclamationMark_old',
      'corrosion_old',
      'gasCylinder_old',
      'skullAndCrossbones_old',
      'flameOverCircle_old',
      'reactiveMaterials_old',
      'biohazard_old'
    ];
    ctx.mapping = {
      healthHazard: {
        title: 'PROCESS.SOLR_FORM.HAZARDS.HEALTHHAZARD.TITLE',
        description: 'PROCESS.SOLR_FORM.HAZARDS.HEALTHHAZARD.DESCRIPTION'
      },
      flame: {
        title: 'PROCESS.SOLR_FORM.HAZARDS.FLAME.TITLE',
        description: 'PROCESS.SOLR_FORM.HAZARDS.FLAME.DESCRIPTION'
      },
      exclamationMark: {
        title: 'PROCESS.SOLR_FORM.HAZARDS.EXCLAMATIONMARK.TITLE',
        description: 'PROCESS.SOLR_FORM.HAZARDS.EXCLAMATIONMARK.DESCRIPTION'
      },
      corrosion: {
        title: 'PROCESS.SOLR_FORM.HAZARDS.CORROSION.TITLE',
        description: 'PROCESS.SOLR_FORM.HAZARDS.CORROSION.DESCRIPTION'
      },
      gasCylinder: {
        title: 'PROCESS.SOLR_FORM.HAZARDS.GASCYLINDER.TITLE',
        description: 'PROCESS.SOLR_FORM.HAZARDS.GASCYLINDER.DESCRIPTION'
      },
      explodingBomb: {
        title: 'PROCESS.SOLR_FORM.HAZARDS.EXPLODINGBOMB.TITLE',
        description: 'PROCESS.SOLR_FORM.HAZARDS.EXPLODINGBOMB.DESCRIPTION'
      },
      skullAndCrossbones: {
        title: 'PROCESS.SOLR_FORM.HAZARDS.SKULLANDCROSSBONES.TITLE',
        description:
          'PROCESS.SOLR_FORM.HAZARDS.SKULLANDCROSSBONES.DESCRIPTION'
      },
      flameOverCircle: {
        title: 'PROCESS.SOLR_FORM.HAZARDS.FLAMEOVERCIRCLE.TITLE',
        description: 'PROCESS.SOLR_FORM.HAZARDS.FLAMEOVERCIRCLE.DESCRIPTION'
      },
      environment: {
        title: 'PROCESS.SOLR_FORM.HAZARDS.ENVIRONMENT.TITLE',
        description: 'PROCESS.SOLR_FORM.HAZARDS.ENVIRONMENT.DESCRIPTION'
      },
      biohazard: {
        title: 'PROCESS.SOLR_FORM.HAZARDS.BIOHAZARD.TITLE',
        description: 'PROCESS.SOLR_FORM.HAZARDS.BIOHAZARD.DESCRIPTION'
      },
      // old
      flame_old: {
        title: 'PROCESS.SOLR_FORM.HAZARDS.FLAME.TITLE',
        description: 'PROCESS.SOLR_FORM.HAZARDS.FLAME.DESCRIPTION'
      },
      exclamationMark_old: {
        title: 'PROCESS.SOLR_FORM.HAZARDS.EXCLAMATIONMARK.TITLE',
        description: 'PROCESS.SOLR_FORM.HAZARDS.EXCLAMATIONMARK.DESCRIPTION'
      },
      corrosion_old: {
        title: 'PROCESS.SOLR_FORM.HAZARDS.CORROSION.TITLE',
        description: 'PROCESS.SOLR_FORM.HAZARDS.CORROSION.DESCRIPTION'
      },
      gasCylinder_old: {
        title: 'PROCESS.SOLR_FORM.HAZARDS.GASCYLINDER.TITLE',
        description: 'PROCESS.SOLR_FORM.HAZARDS.GASCYLINDER.DESCRIPTION'
      },
      skullAndCrossbones_old: {
        title: 'PROCESS.SOLR_FORM.HAZARDS.SKULLANDCROSSBONES.TITLE',
        description:
          'PROCESS.SOLR_FORM.HAZARDS.SKULLANDCROSSBONES.DESCRIPTION'
      },
      flameOverCircle_old: {
        title: 'PROCESS.SOLR_FORM.HAZARDS.FLAMEOVERCIRCLE.TITLE',
        description: 'PROCESS.SOLR_FORM.HAZARDS.FLAMEOVERCIRCLE.DESCRIPTION'
      },
      reactiveMaterials_old: {
        title: 'PROCESS.SOLR_FORM.HAZARDS.REACTIVEMATERIALS.TITLE',
        description: 'PROCESS.SOLR_FORM.HAZARDS.REACTIVEMATERIALS.DESCRIPTION'
      },
      biohazard_old: {
        title: 'PROCESS.SOLR_FORM.HAZARDS.BIOHAZARD.TITLE',
        description: 'PROCESS.SOLR_FORM.HAZARDS.BIOHAZARD.DESCRIPTION'
      }
    };
    ctx.identify = function (name) {
      return ctx.mapping[name] || {};
    };
    ctx.getTitle = function (name) {
      return $translate.instant(ctx.identify(name).title);
    };
    ctx.getDescription = ctx.getDesc = function (name) {
      return $translate.instant(ctx.identify(name).description);
    };
    ctx.getImage = ctx.getImg = ctx.getSrc = function (name) {
      return '/images/' + name + '.png';
    };
    ctx.getKnown = function (incOld) {
      const names = Object.keys(ctx.mapping),
        notOld = function (name) {
          return !/_old$/.test(name);
        };
      return incOld ? names : names.filter(notOld);
    };
    ctx.sortingGetter = function (type) {
      return ctx.order.indexOf(type);
    };
  }
]);
