import app from '../app';

app.directive('dateField', function () {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, elem, attrs, ctrl) {
      ctrl.$validators.date = function (viewValue) {
        let r = true;
        if (viewValue) {
          let milliseconds = Date.parse(viewValue);
          r = !!milliseconds && (milliseconds > 0);
        }
        return r;
      };
    }
  };
});
