import app from '../../app';
import { copyToClipboard } from '../../helpers';

app.controller('tagCtrl', [
  '$scope',
  'TAG_MAX_DEPTH',
  'Tag',
  'TagFactory',
  'TagService',
  'QRCode',
  'SpinnerService',
  'ErrorService',
  function (
    $scope,
    TAG_MAX_DEPTH,
    Tag,
    TagFactory,
    TagService,
    QRCode,
    SpinnerService,
    ErrorService
  ) {
    const vm = this;
    vm.MAX_DEPTH = TAG_MAX_DEPTH;
    const tagFactory = new TagFactory(Tag);
    const getTags = SpinnerService.wrap(function (companyId) {
      return tagFactory.getTags(companyId, HandleTagsLoaded, '', '', {
        disableParentColor: false
      }).$promise;
    });
    let selectedCompany = {};
    const simpleCatch = ErrorService.simpleCatch;

    //a list of the companies and which one we are looking at
    selectedCompany.id = '';
    $scope.editMode = false;

    //keep a list of tags to delete when the user clicks save
    var tagsToDelete = [];

    var qrcodes = {};
    var allCompanyTags = {};

    //removes a tag from the tags structure
    $scope.removeTag = function (scope) {
      var tag = scope.$modelValue;
      deleteTag(tag);
      scope.remove();
      $scope.editMode = false;

      if ($scope.tags.length == 0) CreateEmptyTag();
    };

    //create a list of tag ids that should be deleted when user saves
    function deleteTag(tag) {
      if (tag.tags && tag.tags.length > 0) {
        tag.tags.forEach(function (childTag) {
          deleteTag(childTag);
        });
      }

      tagsToDelete.push(tag.id);
    }

    //toggles the tree structure
    $scope.toggle = function (scope) {
      scope.toggle();
    };

    vm.backgroundColor = '#000000';
    vm.color = '#ffffff';

    vm.colorOnOpen = function (api, color, $event) {
      var colorPickerScope = api.getScope();
      if (!colorPickerScope.$parent.$nodeScope.$modelValue._tagColor) {
        colorPickerScope.$parent.$nodeScope.$modelValue._tagColor = '#f89406';
      }
    };

    vm.eventApi = {
      onOpen: vm.colorOnOpen
    };

    vm.options = {
      // input attributes
      required: false,
      disabled: false,
      // validation
      restrictToFormat: false,
      preserveInputFormat: false,
      allowEmpty: false,
      // color
      format: 'hexString',
      case: 'lower',
      // sliders
      hue: true,
      saturation: false,
      lightness: false,
      alpha: false,
      dynamicHue: true,
      dynamicSaturation: true,
      dynamicLightness: true,
      dynamicAlpha: true,
      // picker
      round: false,
      pos: 'bottom left',
      inline: false,
      horizontal: false,
      // swatch
      swatch: true,
      swatchOnly: false,
      swatchPos: 'left',
      swatchBootstrap: false,
      // show/hide events
      show: {
        swatch: true,
        focus: true
      },
      hide: {
        blur: true,
        escape: true,
        click: true
      },
      // buttons
      clear: {
        show: true,
        label: 'Clear',
        class: ''
      },
      reset: {
        show: true,
        label: 'Reset',
        class: ''
      }
    };

    vm.copyIdToClipboard = copyToClipboard;
    vm.pinTag = function (tag) {
      TagService.pinTag(tag).then(updatedTag => (tag.pinned = updatedTag.pinned));
    };

    //creates a new tag in the tree
    $scope.newSubItem = function (scope) {
      var nodeData = scope.$modelValue;
      $scope.editMode = true;

      nodeData.tags.push({
        title: 'New Tag',
        enableEdit: true,
        newTag: true,
        tags: []
      });
    };

    //enables edit mode on a tag title
    $scope.editTitle = function (scope) {
      var nodeData = scope.$modelValue;
      nodeData.wasAdministrativeBeforeEdit = scope.$modelValue.administrative;
      nodeData.enableEdit = true;
      nodeData.titleEdit = nodeData.title;
      $scope.editMode = true;
    };

    //when tag editing complete, saves to the new tag title
    $scope.saveTitleEdit = function (scope) {
      var nodeData = scope.$modelValue;
      var lang = nodeData.langForTitle;

      if (!nodeData.titleEdit) return;

      if (lang && lang !== 'en') {
        nodeData['title_' + lang] = nodeData.titleEdit;
      } else {
        nodeData.title = nodeData.titleEdit;
      }

      nodeData.tagColor = nodeData._tagColor ? nodeData._tagColor : '';
      delete nodeData['_tagColor'];
      nodeData.enableEdit = false;
      nodeData.newTag && delete nodeData.newTag;
      $scope.$modelValue = nodeData; //apply.scope()
      $scope.editMode = false;
    };

    //end editing without updating the tag title
    $scope.cancelTitleEdit = function (scope) {
      var nodeData = scope.$modelValue;
      nodeData.enableEdit = false;
      nodeData.administrative = nodeData.wasAdministrativeBeforeEdit;
      $scope.editMode = false;

      $scope.$modelValue = nodeData; //apply.scope()
      delete nodeData['_tagColor'];
    };

    //save the tags to the database!
    $scope.saveTagsClicked = SpinnerService.wrap(function () {
      return new Promise(resolve => {
        $scope.editMode = true;
        var numTagsToCreate = GetNumTagsToCreate($scope.tags);
        var tagsCreated = 0;

        var numTagsToDelete = tagsToDelete.length;
        var tagsDeleted = 0;

        var numTagsToUpdate = GetNumTagsToUpdate($scope.tags);
        var numUpdated = 0;

        var saveState = 'creating';

        CreateTags($scope.tags, function (err, tagCreated) {
          if (err) {
            simpleCatch(err);
            console.log('error creating tags' + err);
            tagsCreated++;
          }
          if (tagCreated) tagsCreated++;
          if (tagsCreated == numTagsToCreate && saveState == 'creating') {
            console.log('DONE CREATING TAGS');
            saveState = 'deleting';
            DeleteTags(function (err, deletedTag) {
              if (err) {
                simpleCatch('error deleting tags' + err);
                console.log('error deleting tags' + err);
                tagsDeleted++;
              }
              if (deletedTag) tagsDeleted++;
              if (tagsDeleted == numTagsToDelete && saveState == 'deleting') {
                console.log('DONE DELETING TAGS');
                saveState = 'updating';
                UpdateTags($scope.tags, null, function (err, tagUpdated) {
                  if (err) {
                    simpleCatch(err);
                    console.log('error updating tags' + err);
                    numUpdated++;
                  }
                  if (tagUpdated) numUpdated++;
                  if (numUpdated == numTagsToUpdate && saveState == 'updating') {
                    saveState = 'completed';
                    console.log('DONE UPDATING');
                    tagFactory.getTags(selectedCompany.id, HandleTagsLoaded, '', '', {
                      disableParentColor: false
                    });
                    resolve();
                  }
                });
              }
            });
          }
        });
      });
    });

    $scope.setNewLangTitle = function (tag, prevLang) {
      var lang = tag.langForTitle;

      if (prevLang === 'en') {
        tag.title = tag.titleEdit;
      } else {
        tag['title_' + prevLang] = tag.titleEdit;
      }

      if (lang === 'en') {
        tag.titleEdit = tag.title;
      } else {
        tag.titleEdit = tag['title_' + lang];
      }
    };

    init();

    function CreateTags(tags, callback) {
      tags.forEach(function (tag) {
        if (!tag.id) {
          CreateTag(tag, callback);
        }

        if (tag.tags && tag.tags.length > 0) {
          CreateTags(tag.tags, callback);
        }
      });

      //no matter what just callback when done issuing the create commands
      callback(null, null);
    }

    //count all the tags that need to be created
    function GetNumTagsToCreate(tags) {
      var count = 0;

      tags.forEach(function (tag) {
        if (!tag.id) count++;

        if (tag.tags && tag.tags.length > 0) count += GetNumTagsToCreate(tag.tags);
      });

      return count;
    }

    function GetNumTagsToUpdate(tags) {
      var count = 0;
      tags.forEach(function (tag) {
        count++;
        if (tag.tags && tag.tags.length > 0) count += GetNumTagsToUpdate(tag.tags);
      });
      return count;
    }

    //create a new tag and assign the id back to it
    function CreateTag(tag, callback) {
      clearTagModel(tag);

      Tag.create(
        tag,
        function (createdTag) {
          tag.id = createdTag.id;
          callback(null, createdTag);
        },
        function (err) {
          callback(err);
        }
      );
    }

    function UpdateTags(tags, parentTag, callback) {
      var order = 0;
      tags.forEach(function (tag) {
        tag.order = order;
        order++;

        UpdateTag(tag, parentTag, callback);
        if (tag.tags) UpdateTags(tag.tags, tag, callback);
      });

      callback(null, null);
    }

    function UpdateTag(tag, parentTag, callback) {
      if (!tag.id) {
        callback(null, true);
        return;
      }

      if (parentTag) {
        tag.tagId = parentTag.id;
      } else {
        tag.tagId = '';
      }

      if (selectedCompany && selectedCompany.id) {
        tag.companyId = selectedCompany.id;
      } else {
        tag.companyId = '';
      }

      clearTagModel(tag);

      Tag.upsert(
        tag,
        function (updatedTag) {
          callback(null, updatedTag);
        },
        function (err) {
          console.log('ERROR : ' + JSON.stringify(err));
          callback(err);
        }
      );
    }

    function DeleteTags(callback) {
      if (!tagsToDelete || tagsToDelete.length == 0) return callback(null, null);

      tagsToDelete.forEach(function (tagId) {
        DeleteTag(tagId, callback, allCompanyTags[tagId]);
      });

      tagsToDelete = [];

      //no matter what just callback when done issuing the create commands
      callback(null, null);
    }

    function DeleteTag(tagId, callback, tag) {
      if (!tagId) {
        callback(null, true);
        return;
      }

      var deleteStr = '{"id": "' + tagId + '"}';
      var deleteObj = JSON.parse(deleteStr);

      Tag.deleteById(
        deleteObj,
        function (response) {
          if (tag.qrcode && tag.qrcode.length) {
            tag.qrcode.forEach(function (qrcode) {
              removeTagFromQrCodes(qrcodes[qrcode], tag.id);
            });
          }

          callback(null, response);
        },
        function (err) {
          console.log('ERROR : ' + JSON.stringify(err));
          callback(err);
        }
      );
    }

    function removeTagFromQrCodes(qrCode, tagId) {
      if (!qrCode.tags[tagId]) return;

      delete qrCode.tags[tagId];

      QRCode.upsert(qrCode);
    }

    //company changed handler to reload the tags based on company id
    $scope.onCompanySelected = function (freshSelectedCompany) {
      selectedCompany = freshSelectedCompany;
      allCompanyTags = {};

      console.log('You have selected company : ' + JSON.stringify(selectedCompany));
      getTags(selectedCompany.id);
    };

    function HandleTagsLoaded() {
      $scope.tags = tagFactory.tags;

      tagFactory.allCompanyTags.forEach(function (tag) {
        allCompanyTags[tag.id] = tag;
      });

      $scope.editMode = false;

      if ($scope.tags.length == 0) CreateEmptyTag();
    }

    function CreateEmptyTag() {
      $scope.tags = [
        {
          title: '',
          companyId: '',
          tagId: '',
          order: 0,
          tags: []
        }
      ];
    }

    // remove surplus fields from the tag
    function clearTagModel(tag) {
      delete tag.wasAdministrativeBeforeEdit;
      delete tag.titleEdit;
      delete tag.enableEdit;
      delete tag.langForTitle;
    }

    function init() {
      QRCode.find(
        {},
        function (qrs) {
          qrs.forEach(function (qr) {
            qrcodes[qr.id] = qr;
          });
        },
        function (err) {
          console.error('ERROR : ' + err);
        }
      );
    }
  }
]);
