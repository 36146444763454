import '../../../views/advanced-search.html';
import angular from 'angular';
import app from '../../app';
import supplierInfo from '../../modals/supplierInfo';
import { uniq } from 'lodash';
import {
  isChecked,
  deObjectify,
  removeLastComma,
  getOffset as getOffsetHelper,
  createTree
} from '../../helpers';

app.controller('productsCtrl', [
  '$q',
  'manufacturers',
  '$rootScope',
  '$scope',
  'MessagesService',
  'CompanyFactory',
  'CompanyService',
  'Product',
  'ProductService',
  'InventoryService',
  'ModalService',
  'ErrorService',
  'SolrDocumentService',
  'SystemAuditService',
  'TagFactory',
  'SolrDocument',
  'ManufacturerService',
  'ExportService',
  'MeasureService',
  productsCtrl
]);

// TODO: replace Product with ProductService everywhere
function productsCtrl(
  $q,
  manufacturers,
  $rootScope,
  $scope,
  MessagesService,
  CompanyFactory,
  CompanyService,
  Product,
  ProductService,
  InventoryService,
  ModalService,
  ErrorService,
  SolrDocumentService,
  SystemAuditService,
  TagFactory,
  SolrDocument,
  ManufacturerService,
  ExportService,
  MeasureService
) {
  const vm = this;
  const inventoriesByCompany = {};
  const simpleCatch = ErrorService.simpleCatch;
  const companyId = $rootScope.companyId;

  vm.companies = [];
  vm.startFrom = 1;
  vm.PER_PAGE = 20;
  vm.totalItems = [];
  vm.products = [];
  vm.MeasureService = MeasureService;
  vm.manufacturers = manufacturers;
  vm.manufacturersForSearch = manufacturers;
  vm.bannedMultiselectLabels = {
    itemsSelected: ' ',
    select: ' '
  };
  vm.sizeTypes = ProductService.sizeTypes;

  vm.advancedSearch = {
    title: 'COMMON.ACTIONS.ADVANCED_SEARCH',
    isActive: false,
    manufacturers: vm.manufacturersForSearch,
    handleToggleManufaturer: function (manufacturer) {
      if (vm.advancedSearch.selectedManufactures[manufacturer.id] !== false) {
        vm.advancedSearch.selectedManufactures = {};
        vm.advancedSearch.selectedManufactures[manufacturer.id] = true;
      }

      vm.searchProducts(vm.searchProductName, 0);
    },
    inventory: [],
    inventoryTree: [],
    inventoryChanged: function () {
      vm.searchProducts(vm.searchProductName, 0);
    }
  };

  InventoryService.getWithProductIds(companyId).then(inventory => {
    vm.advancedSearch.inventory = inventory;
    vm.advancedSearch.inventoryTree = createTree(vm.advancedSearch.inventory, 'groupId', 'inventories');
  });

  if (!$rootScope.checkIfUserIs('admin')) {
    vm.advancedSearch = angular.extend(vm.advancedSearch, {
      UniversalTags: new TagFactory(),
      CompanyTags: new TagFactory(),
      handleToggleTag: function (tagId) {
        this.UniversalTags.toggleTagSelected(tagId);
        this.CompanyTags.toggleTagSelected(tagId);

        vm.searchProducts(vm.searchProductName, 0);
      }
    });

    vm.advancedSearch.UniversalTags.getTags('');
    vm.advancedSearch.CompanyTags.getTags(companyId);
  }

  vm.handlePaginate = function () {
    vm.searchProducts(vm.searchProductName, getOffset());
  };

  vm.onCreated = function () {
    resetAndSearch();
  };

  vm.initProductRow = function (product) {
    return angular.copy(product, {});
  };

  vm.startEditingProduct = ProductService.startEditingProduct;

  vm.cancelEdit = function (product) {
    if (!product.id) {
      const index = this.products.indexOf(product);
      this.products.splice(index, 1);
    } else product.editable = false;
  };

  vm.copy = function (product) {
    if (this.products.some(product => !product.id)) return;

    const newProduct = angular.copy(product, {});
    newProduct.id = undefined;
    newProduct.editable = true;
    newProduct.based = product.id;

    if (typeof newProduct.size === 'string') {
      newProduct.customSize = newProduct.size;
      newProduct.size = null;
    }

    const index = this.products.indexOf(product);
    this.products.splice(index, 0, newProduct);
  };

  vm.createOrUpdateProduct = function (product, editedProduct) {
    if (product.id) vm.updateProduct(product, editedProduct);
    else {
      ProductService.copy(editedProduct).then(function () {
        product.editable = false;
        reSearch();
      });
    }
  };

  vm.updateProduct = function (product, editedProduct) {
    return ProductService.updateProduct(product, editedProduct).then(res => {
      if (res) reSearch();
    });
  };

  vm.deleteProductAfterChecked = function (product, index) {
    Product.deleteById({ id: product.id })
      .$promise.then(function () {
        MessagesService.success('COMMON.MESSAGES.DELETED');
        reSearch();
        SystemAuditService.create({
          action: 'removed',
          objectType: SystemAuditService.PRODUCT_TYPE,
          objectName: product.name,
          objectId: product.id
        });
      })
      .catch(simpleCatch);
  };

  vm.deleteProduct = function (product, index) {
    ProductService.getPresentInInventory([product.id])
      .then(function (res) {
        const isPresent = Array.isArray(res.companies) && res.companies.length > 0;
        if (isPresent) {
          ModalService.open({
            templateUrl: require('../../../views/modals/confirm-message.html'),
            resolve: {
              message: [
                '$q',
                '$translate',
                function ($q, $translate) {
                  return $q.all({
                    title: $translate('PRODUCTS.DELETEWARNING.TITLE'),
                    message: $translate('PRODUCTS.DELETEWARNING.MESSAGE', {
                      companies: res.companies.toString()
                    })
                  });
                }
              ]
            },
            controller: [
              '$uibModalInstance',
              '$q',
              'message',
              function ($uibModalInstance, $q, message) {
                var modal = this;

                modal.title = message.title;
                modal.message = message.message;
                modal.cancel = $uibModalInstance.dismiss;
                modal.confirm = function () {
                  vm.deleteProductAfterChecked(product, index);
                  $uibModalInstance.close();
                };
              }
            ]
          });
        } else {
          vm.deleteProductAfterChecked(product, index);
        }
      })
      .catch(simpleCatch);
    return;
  };

  vm.search = function (text) {
    vm.searchProducts(text, 0);
    vm.startFrom = 1;
  };

  vm.searchProducts = function (text, offset) {
    $scope.isLoading = true;

    var manufacturerToSearch = deObjectify(vm.advancedSearch.selectedManufactures);
    var searchObj = {
      text: (text = text || '*'),
      filter: {
        limit: vm.PER_PAGE,
        offset,
        where: {}
      }
    };

    if (manufacturerToSearch.length) {
      searchObj.filter.where.manufacturerId = { inq: manufacturerToSearch };
    }

    if (!$rootScope.checkIfUserIs('admin')) {
      var selectedTags = vm.advancedSearch.UniversalTags.getSelectedTags().concat(
        vm.advancedSearch.CompanyTags.getSelectedTags()
      );

      searchObj.companyId = companyId;
    } else {
      searchObj.filter.where.verified = true;
    }

    const selectedInventory = vm.advancedSearch.inventory.filter(inv => inv.checked && !inv.disabled);

    if (selectedInventory.length > 0) {
      const inventoryIds = selectedInventory.map(inv => inv.id);

      if (inventoryIds.length > 0) {
        searchObj.filter.inventoryIds = uniq(inventoryIds);
      }
    }

    if (selectedTags && selectedTags.length) {
      return getDocumentIdsByTags(selectedTags).then(function (documentIds) {
        // just put the fake ID if there were no documents
        if (!documentIds.length) documentIds[0] = 'no sds';

        var sdsIds = documentIds.map(function (id) {
          return { sdsId: id };
        });

        searchObj.filter.where = angular.extend({ or: sdsIds }, searchObj.filter.where);

        return searchProducts(searchObj);
      });
    } else {
      return searchProducts(searchObj);
    }
  };

  vm.handleLinkProductToCompany = function (product) {
    ProductService.linkProductToCompany(product, companyId).then(function () {

      vm.searchProducts(vm.searchProductName, getOffset());
    });
  };

  vm.handleUnlinkProductFromCompany = function (product) {
    ProductService.unlinkProductFromCompany(product, companyId).then(reSearch);
  };

  vm.isProductBannedInCompany = function (product) {
    return product.bannedInCompanies.some(function (bannedCompanyId) {
      return bannedCompanyId === companyId;
    });
  };

  vm.openModal = function (product) {
    ModalService.open({
      templateUrl: require('../../../views/modals/inventories-with-item.html'),
      resolve: {
        productInCompanies: [
          'Product',
          function (Product) {
            return Product.companies({ id: product.id }).$promise;
          }
        ],
        amountInInventories: [
          'InventoryProductMapping',
          function (InventoryProductMapping) {
            var filter = { filter: { where: { productId: product.id } } };

            return InventoryProductMapping.find(filter).$promise;
          }
        ]
      },
      controller: [
        '$uibModalInstance',
        'productInCompanies',
        'amountInInventories',
        function ($uibModalInstance, productInCompanies, amountInInventories) {
          var modal = this;
          var companyTagsToAdd = {};

          modal.itemType = 'product';
          modal.companies = vm.companies;
          modal.companiesHaveProduct = {};
          modal.inventoriesHaveProduct = {};
          modal.inventoriesByCompany = inventoriesByCompany;

          modal.loadMoreInventories = function (company) {
            if (company.isLoading) return;

            vm['inventoryService_' + company.id].getInventories().then(function (inventories) {
              modal.inventoriesByCompany[company.id] = inventories;
            });
          };

          modal.handleToggleCompany = function (companyId) {
            if (
              !product.sdsId.length ||
              companyTagsToAdd[companyId] ||
              !modal.companiesHaveProduct[companyId]
            )
              return;

            let companyTags = new TagFactory();

            companyTags.openModalToSelectCompanyTags(companyId).then(function (tags) {
              companyTagsToAdd[companyId] = tags;
            });
          };

          if ($rootScope.checkIfUserIs('admin')) {
            vm.companies.forEach(function (company) {
              modal.companiesHaveProduct[company.id] = productInCompanies.some(function (
                productInCompany
              ) {
                return productInCompany.id === company.id;
              });
            });
          } else {
            modal.companiesHaveProduct[companyId] = true;
          }

          modal.validateAmount = function (obj, oldAmount) {
            if (
              obj.amount < 0 ||
              obj.amount == undefined ||
              ProductService.handleLinkingBannedProduct(product, obj.companyId)
            ) {
              obj.amount = oldAmount;

              return;
            }

            obj.changed = true;
          };

          modal.initInventory = function (inv) {
            const id = inv.id;
            const companyId = inv.companyId;
            let inventory = (modal.inventoriesHaveProduct[id] =
              modal.inventoriesHaveProduct[id] || {});

            amountInInventories.some(function (amountInInventory) {
              if (amountInInventory.inventoryId === id) {
                inventory.amount = amountInInventory.amount;

                return true;
              }
            });

            inventory.amount = inventory.amount || 0;
            inventory.companyId = inventory.companyId || companyId;
          };

          modal.save = function () {
            var data = {
              companiesToUpdate: objectToArrayOfObjects(modal.companiesHaveProduct),
              inventoriesToUpdate: filterAmountUpdate(
                modal.inventoriesHaveProduct,
                modal.companiesHaveProduct
              ),
              companyTagsToAdd: companyTagsToAdd
            };

            Product.updateRelations({ id: product.id }, data)
              .$promise.then(function () {
                MessagesService.success('COMMON.MESSAGES.UPDATED');
                $uibModalInstance.close('save');

                createAuditEntryUpdateInInventories(data.inventoriesToUpdate);
              })
              .catch(simpleCatch);
          };

          modal.cancel = function () {
            $uibModalInstance.dismiss('cancel');
          };

          function createAuditEntryUpdateInInventories(inventoriesToUpdate) {
            var companyIds = deObjectify(modal.companiesHaveProduct);

            if (!companyIds.length) return;

            var updatedInventories = inventoriesToUpdate.map(function (inventoryAmount) {
              var inventoryId = Object.keys(inventoryAmount)[0];
              var amountInInventory = inventoryAmount[inventoryId];
              var updatedInventory = {};

              companyIds.forEach(function (companyId) {
                modal.inventoriesByCompany[companyId].forEach(function (inventory) {
                  matchInventoryById(inventory, inventoryId);
                });
              });

              return updatedInventory;

              function matchInventoryById(inventory, id) {
                if (inventory.id === id) {
                  updatedInventory.amount = amountInInventory;
                  return angular.extend(updatedInventory, inventory);
                }

                if (inventory.inventories && inventory.inventories.length) {
                  inventory.inventories.forEach(function (inventory) {
                    matchInventoryById(inventory, id);
                  });
                }
              }
            });

            var data = companyIds
              .map(function (companyId) {
                var auditData = {
                  action: '',
                  objectType: SystemAuditService.PRODUCT_TYPE,
                  objectName: product.name,
                  objectId: product.id,
                  companyId: companyId,
                  newValue: ''
                };

                updatedInventories.forEach(function (inventory) {
                  if (companyId === inventory.companyId) {
                    auditData.action = auditData.action || 'updated in inventories: ';
                    auditData.action += inventory.name + ', ';
                    auditData.newValue += inventory.amount + ', ';
                  }
                });

                auditData.action = removeLastComma(auditData.action);
                auditData.newValue = removeLastComma(auditData.newValue);

                return auditData;
              })
              .filter(function (auditData) {
                return auditData.action;
              });

            data.forEach(SystemAuditService.create);
          }
        }
      ]
    });
  };

  vm.handleSDSModal = SolrDocumentService.openModalOrSDSbyProduct;
  vm.companySettings = new CompanyService.CompanySettings(companyId).settings;
  vm.isChecked = isChecked;
  vm.exportProducts2csv = function () {
    const productName = vm.searchProductName;
    const exportByManufacturers = deObjectify(vm.advancedSearch.selectedManufactures);
    const companyId = !$rootScope.checkIfUserIs('admin') ? $rootScope.companyId : undefined;

    ExportService.exportProducts2csv(productName, exportByManufacturers, companyId);
  };
  vm.updateMeasureForProduct = ProductService.updateMeasureForProduct;
  vm.getProductSize = ProductService.showCorrectProductSize;

  vm.openSupplierInfoModal = function (product, editable = false) {
    return ModalService.open(supplierInfo({ suppliers: product.suppliers, editable })).then(
      suppliers => {
        product.suppliers = suppliers;
      }
    );
  };

  init();

  function init() {
    vm.MeasureService.getMeasureUnits();
    getCompaniesAndInventories();
    getProducts().then(updateSearchResultsFromResponse);
  }

  function getDocumentIdsByTags(tagIds) {
    var searchParams = tagIds.map(function (id) {
      return { searchKey: 'tag', searchTerms: id };
    });

    var params = {
      searchParams: JSON.stringify(searchParams),
      companyId: companyId,
      fieldList: 'id',
      start: 0,
      rows: 1000000
    };

    return SolrDocument.search(params).$promise.then(function (response) {
      var documents = response.response.docs;
      var ids = documents.map(function (doc) {
        return doc.id;
      });

      return ids;
    });
  }

  function searchProducts(searchObj) {
    return Product.search(searchObj).$promise.then(function (response) {
      updateSearchResultsFromResponse(response);

      $scope.isLoading = false;
    });
  }

  function createAuditEntryUpdateProduct(product, editedProduct) {
    var changedFields = getObjectFieldsChanged(product, editedProduct);
    var newValues = changedFields.map(function (field) {
      return editedProduct[field];
    });

    newValues = newValues.map(function (value) {
      return (value = value !== '' ? value : '-');
    });

    ifManufacturerFieldUpdated();

    var changedFieldsStr = changedFields.join(', ');
    var newValuesStr = newValues.join(', ');

    SystemAuditService.create({
      action: 'updated: ' + changedFieldsStr,
      objectType: SystemAuditService.PRODUCT_TYPE,
      objectName: product.name,
      objectId: product.id,
      newValue: newValuesStr
    });

    function ifManufacturerFieldUpdated() {
      var manufacturerFieldIndex = changedFields.indexOf('manufacturerId');

      if (~manufacturerFieldIndex) {
        var manufacturer = vm.manufacturers[editedProduct[changedFields[manufacturerFieldIndex]]];
        changedFields[manufacturerFieldIndex] = 'manufacturer';

        newValues = newValues.map(function (value) {
          if (value === manufacturer.id) value = manufacturer.name;

          return value;
        });
      }
    }
  }

  /**
   * Returns an array with fields those values were changed in original object
   *
   * @param {Object} originalObj the original object to compare
   * @param {Object} newObj the new object to compare
   * @returns {Array} an array with fields those were changed
   */
  function getObjectFieldsChanged(originalObj, newObj) {
    var fields = Object.keys(newObj);
    var changedFields = [];

    changedFields = fields.filter(function (field) {
      if (field === 'editable') return false;

      return JSON.stringify(originalObj[field]) !== JSON.stringify(newObj[field]);
    });

    return changedFields;
  }

  function getCompaniesAndInventories() {
    var companyId = null;

    if (!$rootScope.checkIfUserIs(['admin'])) {
      companyId = $rootScope.companyId;
    }

    CompanyFactory.GetCompanies(null, companyId)
      .$promise.then(function (allCompanies) {
        vm.companies = allCompanies;

        const promises = vm.companies.map(function (company) {
          const maxInventoriesNumber = 1000;
          vm['inventoryService_' + company.id] = new InventoryService(company.id, company, [
            'products'
          ], maxInventoriesNumber);

          return vm['inventoryService_' + company.id].count();
        });

        return $q.all(promises);
      })
      .then(function () {
        const promises = vm.companies.map(function (company) {
          return vm['inventoryService_' + company.id].getInventories();
        });

        return $q.all(promises);
      })
      .then(sortInventoriesByCompany)
      .catch(simpleCatch);
  }

  function sortInventoriesByCompany() {
    vm.companies.forEach(function (company) {
      inventoriesByCompany[company.id] = [];

      inventoriesByCompany[company.id] = vm['inventoryService_' + company.id].inventories.filter(
        function (inventory) {
          return inventory.companyId === company.id;
        }
      );
    });
  }

  function getProducts() {
    if ($rootScope.checkIfUserIs('admin'))
      return Product.search({
        filter: { where: { verified: true }, limit: 20 }
      }).$promise;

    return Product.search({
      companyId: $rootScope.companyId,
      filter: { limit: 20 }
    }).$promise;
  }

  function updateSearchResultsFromResponse(response) {
    let products = response.products;

    if ($rootScope.checkIfUserIs('admin')) mapBannedListToCompany(products);

    vm.products = products;
    vm.totalItems = response.count;
  }

  function resetAndSearch() {
    vm.advancedSearch.selectedManufactures = {};
    vm.searchProducts('', 0);
  }

  function reSearch() {
    const offset = getOffset();

    if (vm.products.length === 1 && offset >= vm.PER_PAGE) {
      // if the last product on the page is deleted, redirect user to the next last page
      vm.searchProducts(vm.searchProductName, offset - vm.PER_PAGE);
    } else {
      vm.searchProducts(vm.searchProductName, offset);
    }
  }

  function getOffset() {
    return getOffsetHelper(vm.startFrom, vm.PER_PAGE);
  }

  function mapBannedListToCompany(products) {
    products.forEach(function (product) {
      product.bannedInCompanies = product.bannedInCompanies.map(function (bannedCompanyId) {
        return vm.companies.filter(function (company) {
          return company.id === bannedCompanyId;
        })[0];
      });
    });
  }
}

function filterAmountUpdate(inventories, companies) {
  var filteredInventories = [];
  var inventoryIds = Object.keys(inventories);
  var companyIds = Object.keys(companies);

  inventoryIds.forEach(function (inventoryId) {
    if (inventories[inventoryId].changed !== true) return;

    companyIds.some(function (companyId) {
      if (inventories[inventoryId].companyId === companyId && companies[companyId] === true) {
        var filteredInventory = {};

        filteredInventory[inventoryId] = inventories[inventoryId].amount;

        filteredInventories.push(filteredInventory);
      }
    });
  });

  return filteredInventories;
}

function objectToArrayOfObjects(obj) {
  return Object.keys(obj).map(function (key) {
    var newItem = {};

    newItem[key] = obj[key];

    return newItem;
  });
}
