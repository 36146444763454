import app from '../../app';

app.directive('codesControl', function () {
  return {
    restrict: 'E',
    templateUrl: require('./codesControl.html'),
    scope: {
      codes: '=',
      current: '=',
      onChange: '&'
    },
    link: function (scope) {
      scope.onSelect = function (code) {
        scope.current = code;
        scope.onChange();
      };
      scope.onReset = function () {
        scope.current = null;
        scope.onChange();
      };
    }
  };
});
