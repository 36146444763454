import app from '../../app';

app.directive('docEdit', [
  '$rootScope',
  function ($rootScope) {
    return {
      restrict: 'E',
      transclude: true,
      scope: {
        doc: '='
      },
      templateUrl: require('./docEdit.html'),
      link: function (scope) {
        scope.isAdmin = $rootScope.checkIfUserIs(['admin', 'sdsEditor']);
      }
    };
  }
]);
