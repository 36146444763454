import app from '../../app';

app.directive('settingsSdsRequests', function () {
  return {
    restrict: 'E',
    templateUrl: require('./settingsSdsRequests.html'),
    scope: {
      access: '=',
      companyId: '='
    },
    controllerAs: 'vm',
    controller: function ($rootScope, $scope, UserService, MessagesService) {
      if (!$rootScope.checkIfUserIs($scope.access)) return;

      const vm = this;
      vm.userToAutoApprove = null;
      vm.autoApproveUsers = [];

      vm.validateUser = function (user) {
        if (typeof user === 'string') return vm.usersToAutoApproveForm.$setValidity('user', false);

        vm.usersToAutoApproveForm.$setValidity('user', true);
      };

      vm.grantAutoApprove = function (user) {
        const autoApproveSdsRequest = true;

        return UserService.updateUser({ ...user, autoApproveSdsRequest }).then(() => {
          user.autoApproveSdsRequest = autoApproveSdsRequest;

          resetAutoApproveForm();
          MessagesService.success('COMMON.MESSAGES.ADDED');
        });
      };

      vm.forbidAutoApprove = function (user) {
        const autoApproveSdsRequest = false;

        return UserService.updateUser({ ...user, autoApproveSdsRequest }).then(() => {
          user.autoApproveSdsRequest = autoApproveSdsRequest;

          MessagesService.success('COMMON.MESSAGES.REMOVED');
        });
      };

      init();

      function init() {
        $scope.$watch('companyId', async function () {
          if ($scope.companyId) {
            vm.users = await UserService.getAllCompanyUsers($scope.companyId);
            $scope.$apply();
          }
        });
      }

      function resetAutoApproveForm() {
        vm.userToAutoApprove = null;
        vm.usersToAutoApproveForm.$setUntouched();
      }
    }
  };
});
