import app from '../../app';

app.directive('langSelector', [
  function () {
    return {
      restrict: 'E',
      scope: {
        langs: '=',
        startCollapsed: '=',
        onChange: '&'
      },
      templateUrl: require('./langSelector.html')
    };
  }
]);
