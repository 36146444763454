import app from '../app';

// TODO: replace everywhere with tagTitle filter
app.filter('getTagTitle', [
  '$rootScope',
  function ($rootScope) {
    const getLangTitle = function (tag, lang) {
      return tag['title_' + lang];
    };
    return function (tag) {
      return getLangTitle(tag, $rootScope.siteLanguage) || tag.title;
    };
  }
]);
