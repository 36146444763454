import app from '../../app';
import scheduleReport from '../../modals/scheduleReport';
import selectTags from '../../modals/selectTags';
import { get } from 'lodash';
import selectInventory from '../../modals/selectInventory';

app.controller('addedRemovedSdsReportCtrl', [
  '$scope',
  '$rootScope',
  'AddedRemovedSdsReportService',
  'CompanyFactory',
  'CompanyService',
  'HazardService',
  'SchedulerService',
  'ModalService',
  'Tag',
  'TagFactory',
  'InventoryService',
  'ExportService',
  function (
    $scope,
    $rootScope,
    AddedRemovedSdsReportService,
    CompanyFactory,
    CompanyService,
    HazardService,
    SchedulerService,
    ModalService,
    Tag,
    TagFactory,
    InventoryService,
    ExportService
  ) {
    const vm = this;

    $scope.companyTags = new TagFactory(Tag);
    $scope.selectedTagsResult = {};
    $scope.tagIds = [];
    $scope.selectedInventory = [];

    $scope.HazardService = HazardService;
    $scope.service = AddedRemovedSdsReportService;

    $scope.radioModel = 'Any';
    $scope.docs = [];
    $scope.selectedCompany = {};
    $scope.companySettings = {};
    vm.showSDSTags = vm.showSDSInventory = false;
    $scope.invTree = {};

    $scope.date = {
      start: 0,
      startStr: '30daysAgo',
      end: 0,
      endStr: 'today'
    };
    $scope.service.evalDate($scope.date);

    $scope.isLoading = true;

    $scope.getTagsList = tagsObject => Object.values(tagsObject);

    $scope.search = function () {
      $scope.loadData();
    };

    $scope.onChangeRadioModel = function () {
      $scope.loadData();
    };

    $scope.changeDate = function (date, datepickerDate) {
      if (!datepickerDate) {
        $scope.date.startStr = date;
        $scope.date.endStr = 'today';
        $scope.service.evalDate($scope.date);
        $scope.showDateRange = false;
      } else {
        $scope.showDateRange = true;

        if (date === 'start') {
          $scope.date.startStr = datepickerDate.format('YYYY-MM-DD');
        } else {
          $scope.date.endStr = datepickerDate.format('YYYY-MM-DD');
        }
        $scope.service.evalDate($scope.date);
      }

      $scope.loadData();
    };

    $scope.onCompanySelected = async function (selectedCompany) {
      $scope.selectedCompany = selectedCompany;

      await CompanyService.CompanySettings(selectedCompany.id, false).$promise.then(settings => {
        $scope.companySettings = settings;
      });
      await $scope.companyTags.getTags(selectedCompany.id, null, null, true, {
        sortByIdFor: 'allCompanyTags'
      });
      await InventoryService.getTreeInfo(selectedCompany.id)
        .then(invTree => {
          $scope.invTree = invTree;
        });

      $scope.loadData();
    };

    $scope.handleExport = function () {
      ExportService.exportAddedRemovedSds($scope.docs, {
        day: $scope.date.end,
        showSDSTags: vm.showSDSTags
      });
    };

    /**
     * Load report data and save to $scope.docs
     */
    $scope.loadData = async function () {
      await $scope.service.reqData($scope);

      // add tags data to SDS
      for (let i in $scope.docs) {
        for (let k in $scope.docs[i].SDS) {
          let doc = $scope.docs[i].SDS[k];
          let tags = $scope.getSDSCompanyTags(doc);
          $scope.docs[i].SDS[k].tags = tags;
          $scope.docs[i].SDS[k].tagsPath = $scope.getSDSTagsPath(doc.tag);
          $scope.docs[i].SDS[k].inventoryList = (doc.inventories || [])
            .map(invId => get($scope.invTree, `${ invId }.path`, null))
            .filter( v => v );
        }
      }
    };

    /**
     * Get SDS tags path
     * @param {Array} tags
     * @returns {Array}
     */
    $scope.getSDSTagsPath = function (tags, pathDelimiter = ' / ') {
      let tagsPath = [];
      (tags || []).forEach(tagId => {
        let tag = $scope.companyTags.tagsById[tagId];
        if (tag) {
          let path = tag.path ? [tag.path, tag.title].join(pathDelimiter) : tag.title;
          tagsPath.push(path);
        }
      });
      return tagsPath;
    };

    /**
     * Get Company SDS tags
     * @param {Object} Sds
     * @returns {Array} Company tags for SDS
     */
    $scope.getSDSCompanyTags = function (sds) {
      let tags = [];
      (sds.tag || []).forEach(tagId => {
        let tag = $scope.companyTags.tagsById[tagId];
        if (tag) {
          tags.push(tag);
        }
      });

      return tags;
    };

    vm.openScheduleModal = function () {
      return ModalService.open(scheduleReport()).then(res => {
        if (
          $rootScope.checkIfUserIs('admin') &&
          (!$scope.selectedCompany || !$scope.selectedCompany.id)
        ) {
          return SchedulerService.createReportForAllCompanies(
            res,
            $scope.companies,
            {
              showSDSTags: !!vm.showSDSTags,
              companyInclude:
                $scope.radioModel === 'Any' ? undefined : $scope.radioModel === 'Include'
            },
            createScheduledReport
          );
        } else {
          return createScheduledReport(res, $rootScope.companyId, {
            showSDSTags: !!vm.showSDSTags,
            tagsChange: $scope.radioModel === 'tagsChange' || undefined,

            companyInclude:
              $scope.radioModel === 'Any' ? undefined : $scope.radioModel === 'Include',
            tagIds:
              $scope.radioModel === 'Any' || $scope.radioModel === 'tagsChange' ? $scope.tagIds : []
          });
        }
      });
    };

    vm.selectTags = function () {
      return ModalService.open(
        selectTags($scope.selectedCompany.id, {
          showAndOrDropdown: false,
          checkedTags: $scope.selectedTagsResult.tags || [],
          allowSearchByParentTags: false,
          message: 'TAGS.FILTER_BY_TAGS',
          saveButtonText: 'COMMON.ACTIONS.SELECT'
        })
      ).then(result => {
        $scope.selectedTagsResult = result;
        $scope.tagIds = (result.tags || []).map(tag => tag.id);

        $scope.loadData();
      });
    };

    $scope.selectInventory = async function () {
      const inventoryModalResult = await openInventoryModal($scope.selectedInventory);
      if (inventoryModalResult.cancel) return;

      $scope.selectedInventory = inventoryModalResult.selectedInventory;

      $scope.loadData();
    };

    async function openInventoryModal(checkedInventory = []) {
      try {
        const selectedInventory = await ModalService.open(
          selectInventory($rootScope.companyId, {
            checkedInventory,
            selectAllOnInit: false,
            emptyForAll: true
          })
        );

        return {
          selectedInventory,
          cancel: false
        };
      } catch (err) {
        if (!err || err === 'backdrop click')
          return {
            selectedInventory: checkedInventory,
            cancel: true
          };

        console.log(err);
      }
    }

    $('.dropdown.keep-open').on({
      'shown.bs.dropdown': function () {
        this.closable = false;
      },
      'hide.bs.dropdown': function () {
        return this.closable;
      }
    });

    $('html').on({
      click: function (e) {
        var nodeName = e.target.nodeName;
        var parents = $(e.target).parentsUntil('#datepicker').length;
        var offsetParent = e.target.offsetParent ? e.target.offsetParent.nodeName : null;

        // console.log(nodeName);
        // console.log(parents);
        // console.log(offsetParent);

        if (
          (offsetParent !== null || nodeName === 'BODY' || nodeName === 'HTML') &&
          parents !== 14 &&
          nodeName !== 'TH' &&
          offsetParent !== 'TD' &&
          parents !== 15
        ) {
          var dropdown = $('.datepicker-dropdown')[0];
          dropdown && (dropdown.closable = true);
        }
      }
    });

    if ($rootScope.checkIfUserIs('admin')) {
      CompanyFactory.GetCompanies(function (companies) {
        $scope.companies = companies;
      });
    } else {
      CompanyService.getCurrentCompanyPromise().$promise.then(function () {
        if ($rootScope.currentCompany.childSdsCompanies.length) return; // handle this by companySelector

        $scope.onCompanySelected({ id: $rootScope.companyId });
      });
    }

    function createScheduledReport(formObj, companyId, params = {}, quiet = false) {
      return SchedulerService.createReport(
        SchedulerService.jobs.report.ADDED_REMOVED_SDSS_REPORT,
        companyId,
        formObj.emails,
        formObj.frequency,
        params,
        quiet
      );
    }
  }
]);
