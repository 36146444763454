import app from '../../app';
import { reselectChildTag } from '../../helpers';

app.directive('pinnedTags', [
  function () {
    return {
      restrict: 'E',
      scope: {
        tags: '=',
        companyId: '=',
        onSelect: '&'
      },
      templateUrl: require('./pinnedTags.html'),
      link: function (scope) {
        scope.tagsTree = [];
        const structurize = function (flatTags) {
          const prepare = function (tag) {
              // keep the reference
              tag.tags = tag.isParent ? tag.tags || [] : [];
              return tag;
            },
            exists = function (tags, target) {
              return tags.reduce(function (sum, tag) {
                return sum || tag.id === target.id;
              }, false);
            },
            pinnedTags = flatTags.map(prepare).filter(tag => tag.pinned);
          pinnedTags.forEach(function (tag) {
            if (tag.tagId) {
              pinnedTags.forEach(function (parentTag) {
                if (parentTag.id === tag.tagId && !exists(parentTag.tags, tag)) {
                  parentTag.amount = parentTag.amount || 0;
                  parentTag.amount += tag.amount || 0;
                  parentTag.tags.push(prepare(tag));
                }
              });
            }
          });

          return pinnedTags
            .map(function (tag) {
              if (tag.tags && tag.tags.length && tag.amount === 0) {
                tag.amount = tag.tags.reduce(function (sum, subTag) {
                  return sum + (subTag.amount || 0);
                }, 0);
              }
              return tag;
            })
            .filter(function (tag) {
              return !tag.tagId;
            });
        };
        scope.$watch('tags', function (tags) {
          scope.tagsTree = structurize(tags);
        });
        scope.$watch('companyId', function (companyId) {
          scope.companyId = companyId;
        });
        scope.companyTag = function (tag) {
          return (
            tag.companyId &&
            (!scope.companyId || scope.companyId === tag.companyId) &&
            !tag.restricted &&
            tag.amount &&
            (!scope.filterArchived || tag.archived === false)
          );
        };
        scope.selectTag = function (tag) {
          reselectChildTag(scope.tags, tag);
          scope.onSelect();
        };
      }
    };
  }
]);
