import angular from 'angular';
import app from '../app';
import { waitForProp, removeLastComma, getOffset } from '../helpers';

app.service('SystemAuditService', [
  '$rootScope',
  'SystemAudit',
  'CompanyFactory',
  'CompanyService',
  'SpinnerService',
  function ($rootScope, SystemAudit, CompanyFactory, CompanyService, SpinnerService) {
    const SystemAuditService = function (types) {
      const self = this;
      const typeFilter = { objectType: { inq: types } };

      let companyId;
      let orderType = 'date';
      let order = ' DESC';
      let dateFrom = null;
      let dateTo = null;
      let adminOnlyEvents = false;

      this.rangePickerApi = {};
      this.PER_PAGE = 20;
      this.startFrom = 1;
      this.totalItems = 0;
      this.companies = [];
      this.orders = {
        date: true
      };
      this.detailsFilter = null;

      const waitForGetEvalDate = waitForProp(this.rangePickerApi, 'getEvalDate');

      this.toggleAdminOnlyEvents = function (bool) {
        adminOnlyEvents = bool;

        self.getRecords(self.startFrom, orderType + order);
        createPagination();
      };

      this.handlePaginate = function () {
        const offset = getOffset(self.startFrom, self.PER_PAGE);

        self.getRecords(offset, orderType + order);
      };

      this.onCompanySelected = function (selectedCompany) {
        companyId = selectedCompany.id;
        self.selectedCompany = selectedCompany;
        self.startFrom = 1;

        self.changeDate(self.rangePickerApi.getEvalDate());
      };

      this.reverseOrder = function (type) {
        self.orders[type] = !self.orders[type];
        orderType = type;
        order = self.orders[type] ? ' DESC' : ' ASC';

        self.getRecords(self.startFrom, type + order);
      };

      this.getRecords = SpinnerService.wrap(function (offset, order) {
        return getRecords({
          limit: self.PER_PAGE,
          offset,
          order: order,
          companyId: companyId,
          adminOnly: adminOnlyEvents,
          typeFilter: typeFilter,
          from: dateFrom,
          to: dateTo,
          detailsFilter: self.detailsFilter
        }).then(function (records) {
          self.records = records;

          return records;
        });
      });

      this.getRecords2Export = SpinnerService.wrap(function () {
        return getRecords({
          limit: 0,
          offset: 0,
          order: 'date DESC',
          companyId: companyId,
          adminOnly: adminOnlyEvents,
          typeFilter: typeFilter,
          from: dateFrom,
          to: dateTo,
          detailsFilter: self.detailsFilter
        }).then(function (records) {
          return records;
        });
      });

      this.changeDate = function (date) {
        self.startFrom = 1;
        dateFrom = date.start;
        dateTo = date.end;

        self.getRecords(0);
        createPagination();
      };

      this.setDetailsFilter = function (details) {
        if (details.length) {
          const detailsFilter = details.reduce((arr, current) => {
            if (current.inventories.length === 0) {
              return [...arr, `{"inventoryId":"${current.id}"}`];
            }
            return arr;
          }, []);
          self.detailsFilter = { details: { inq: detailsFilter } };
        } else {
          self.detailsFilter = null;
        }
        self.getRecords();
      };

      if ($rootScope.checkIfUserIs('admin')) {
        CompanyFactory.GetCompanies(function (companies) {
          self.companies = companies;
        });
      } else {
        CompanyService.getCurrentCompanyPromise().$promise.then(function () {
          companyId = $rootScope.companyId;

          waitForGetEvalDate(function () {
            self.changeDate(self.rangePickerApi.getEvalDate());
          });
        });
      }

      function createPagination() {
        return count({
          companyId: companyId,
          adminOnly: adminOnlyEvents,
          typeFilter: typeFilter,
          from: dateFrom,
          to: dateTo,
          detailsFilter: self.detailsFilter
        }).then(function (totalRecords) {
          self.totalItems = totalRecords.count;
        });
      }
    };

    SystemAuditService.create = function (info) {
      var data = {
        date: new Date(),
        action: '',
        actionType: '',
        objectType: '',
        objectName: '',
        objectId: '',
        userName: $rootScope.currentUser ? $rootScope.currentUser.username : 'Guest',
        userId: $rootScope.currentUser ? $rootScope.currentUser.id : null,
        companyId: '',
        previousValue: '',
        newValue: '',
        details: ''
      };

      data = angular.extend({}, data, info);

      return SystemAudit.create(data).$promise.catch(function (err) {
        console.warn('Couldn\'t be able to create a system audit record: ', err);
      });
    };

    SystemAuditService.createAuditEntryUpdateInInventories = function (auditOptions) {
      const inventories = auditOptions.inventories;
      const item = auditOptions.item;
      const companyId = auditOptions.companyId;
      const type = auditOptions.type;

      let action = 'updated in inventories: ';
      let newValue = '';

      inventories.forEach(function (inventory) {
        action += inventory.name + ', ';
        newValue += inventory.itemAmount.amount + ', ';
      });

      action = removeLastComma(action);
      newValue = removeLastComma(newValue);

      SystemAuditService.create({
        action: action,
        objectType: type,
        objectName: item.name,
        objectId: item.id,
        companyId: companyId,
        newValue: newValue
      });
    };

    SystemAuditService.PRODUCT_TYPE = 'product';
    SystemAuditService.CONTAINER_TYPE = 'container';
    SystemAuditService.EQUIPMENT_TYPE = 'equipment';
    SystemAuditService.FILE_TYPE = 'file';

    return SystemAuditService;

    // function getRecords(limit, offset, order, companyId, typeFilter, dateFrom, dateTo) {
    function getRecords(options) {
      const limit = options.limit || 0;
      const offset = options.offset || 0;
      const order = options.order || 'date DESC';
      const companyId = options.companyId;
      const adminOnly = options.adminOnly || false;
      const typeFilter = options.typeFilter || {};
      const dateFilter =
        options.from && options.to
          ? [{ date: { gt: options.from } }, { date: { lt: options.to } }]
          : [];
      const detailsFilter = options.detailsFilter || {};

      if (companyId) {
        return SystemAudit.find({
          filter: {
            where: {
              and: [{ companyId: companyId }, typeFilter, detailsFilter].concat(dateFilter),
              adminOnly: adminOnly
            },
            limit: limit,
            offset: offset,
            order: order
          }
        }).$promise;
      } else {
        return SystemAudit.find({
          filter: {
            limit: limit,
            offset: offset,
            order: order,
            where: {
              and: [typeFilter, detailsFilter].concat(dateFilter),
              adminOnly: adminOnly
            }
          }
        }).$promise;
      }
    }

    function count(options) {
      const companyId = options.companyId;
      const adminOnly = options.adminOnly || false;
      const typeFilter = options.typeFilter || {};
      const dateFilter =
        options.from && options.to
          ? [{ date: { gt: options.from } }, { date: { lt: options.to } }]
          : [];
      const detailsFilter = options.detailsFilter || {};

      return companyId
        ? SystemAudit.count({
          where: {
            and: [{ companyId: companyId }, typeFilter, detailsFilter].concat(dateFilter),
            adminOnly: adminOnly
          }
        }).$promise
        : SystemAudit.count({
          where: {
            and: [typeFilter, detailsFilter].concat(dateFilter),
            adminOnly: adminOnly
          }
        }).$promise;
    }
  }
]);
