import app from '../app';

app.directive('confirmpassword', function () {
  return {
    require: 'ngModel',
    link: function (scope, elm, attrs, ctrl) {
      ctrl.$validators.confirmpassword = function (modelValue) {
        return scope.password === modelValue;
      };
    }
  };
});
