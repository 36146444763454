import app from '../../app';

app.directive('productSizeChanger', [
  function () {
    return {
      restrict: 'A',
      templateUrl: require('./productSizeChanger.html'),
      scope: {
        product: '='
      },
      controllerAs: 'vm',
      controller: function (ProductService, MeasureService) {
        const vm = this;

        vm.MeasureService = MeasureService;
        vm.sizeTypes = ProductService.sizeTypes;
        vm.updateMeasureForProduct = ProductService.updateMeasureForProduct;

        init();

        function init() {
          vm.MeasureService.getMeasureUnits();
        }
      }
    };
  }
]);
