import app from '../../app';

app.directive('labelsPanel', [
  function () {
    return {
      restrict: 'E',
      scope: {
        labels: '='
      },
      templateUrl: require('./labelsPanel.html')
    };
  }
]);
