import angular from 'angular';
import app from '../app';

app.service('ModalService', [
  '$uibModal',
  function ($uibModal) {
    this.openedControllers = {};
    /**
     * https://angular-ui.github.io/bootstrap/#!#modal
     *
     * @param {object} options
     * @returns {Promise}
     */
    this.open = function (options) {
      if (options == null) return Promise.resolve();

      if (options.controller) {
        if (this.openedControllers[options.controller]) {
          return Promise.resolve();
        }
        this.openedControllers[options.controller] = true;
      }
      
      const parentElem = options.parentSelector
        ? angular.element($document[0].querySelector(options.parentSelector))
        : undefined;
      const modalInstance = $uibModal.open({
        animation: true,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: options.templateUrl,
        size: options.size,
        keyboard: options.keyboard == null ? true : options.keyboard,
        backdrop: options.backdrop == null ? true : options.backdrop,
        appendTo: parentElem,
        resolve: options.resolve,
        controllerAs: options.controllerAs || 'modal',
        controller: options.controller,
        scope: options.scope,
      });


      modalInstance.closed.then(() => {
        this.openedControllers[options.controller] = false;
      });

      return modalInstance.result;
    };
  }
]);
