import app from '../../app';
import supplierInfo from '../../modals/supplierInfo';

app.directive('productElement', function () {
  return {
    restrict: 'E',
    scope: {
      product: '='
    },
    templateUrl: require('./productElement.html'),
    controllerAs: 'vm',
    controller: function (ProductService, ModalService) {
      const vm = this;
      vm.getProductSize = ProductService.showCorrectProductSize;
      vm.openSupplierInfoModal = function (product) {
        return ModalService.open(supplierInfo({ suppliers: product.suppliers }));
      };
    }
  };
});
