import angular from 'angular';
import app from '../app';
import { replaceUndefinedFieldsWithEmptyStr } from '../helpers';
import { uniq } from 'lodash';

app.service('ProductService', [
  '$translate',
  '$q',
  '$rootScope',
  'INV_MAX_DEPTH',
  'Product',
  'SystemAuditService',
  'ModalService',
  'MessagesService',
  'ErrorService',
  'ManufacturerService',
  'TagFactory',
  function (
    $translate,
    $q,
    $rootScope,
    INV_MAX_DEPTH,
    Product,
    SystemAuditService,
    ModalService,
    MessagesService,
    ErrorService,
    ManufacturerService,
    TagFactory
  ) {
    const self = this;
    const simpleCatch = ErrorService.simpleCatch;
    const requiredFields = ['name', 'manufacturerId'];
    let manufacturers = [];

    ManufacturerService.manufacturersPromise().then(function (mnfs) {
      manufacturers = mnfs;
    });

    this.sizeTypes = {
      specific: {
        id: 0,
        title: ''
      },
      general: {
        id: 1,
        title: ''
      },
      custom: {
        id: 2,
        title: ''
      }
    };

    this.noSdsReasons = {
      nonRegulated: {
        id: 0,
        title: ''
      },
      noSds: {
        id: 1,
        title: ''
      }
    };

    /**
     * Gets products by IDs
     *
     * @param {Array<string>} ids product IDs
     * @param {Array<string>} fields fields to return
     * @returns {Promise}
     */
    this.getByIds = function (ids, fields) {
      const filter = {
        filter: {
          where: { id: { inq: ids } },
          include: ['manufacturer', 'measure']
        }
      };

      if (fields) filter.filter.fields = fields.concat('id');

      return Product.find(filter).$promise.catch(simpleCatch);
    };

    this.getInventoriesTree = function (ids, companyId) {
      let requestFilter = { where: {} };
      if (companyId) {
        requestFilter.where.companyId = companyId;
      }
      return Product.getInventoriesTree({
        productIds: ids,
        filter: requestFilter
      }).$promise.catch(simpleCatch);
    };

    this.getPresentInInventory = function (ids, companyId) {
      return Product.getPresentInInventory({
        productIds: ids,
        filter: { where: { companyId: companyId } }
      }).$promise.catch(simpleCatch);
    };

    this.getUnverifiedProducts = function () {
      const unverifiedProductsFilter = {
        filter: {
          where: { verified: false },
          include: [
            { relation: 'measure' },
            {
              relation: 'companies',
              scope: { fields: ['name'] }
            },
            {
              relation: 'manufacturer',
              scope: { fields: ['name'] }
            },
            {
              relation: 'inventories',
              scope: {
                fields: ['name', 'id', 'groupId'],
                include: [deepParentIncluding(INV_MAX_DEPTH, 'parentInventory')]
              }
            }
          ]
        }
      };

      return Product.find(unverifiedProductsFilter).$promise.catch(simpleCatch);
    };

    // gets non WHMIS / OSHA regulated SDSs
    this.getProductsDontRequireSds = function (offset, limit) {
      const countFilter = {
        where: { sdsRequired: false, or: [{ noSdsReason: 0 }, { noSdsReason: { exists: false } }] }
      };

      return countAndFindByFilter(countFilter, { offset: offset, limit: limit });
    };

    this.getBannedProducts = function (offset, limit, companyId, inventoried = null) {
      const countFilter = { where: { bannedInCompanies: { regexp: '/.*/' } } };

      if (companyId) {
        countFilter.where.bannedInCompanies = companyId;
      }

      if (inventoried !== null) {
        countFilter.where.inventoriedInCompanies = companyId || { regexp: '/.*/' };
      }

      return countAndFindByFilter(countFilter, { offset: offset, limit: limit });
    };

    /**
     * Assing inventory path to product in the list
     *
     * @param {Array<Object>} productIds an array of products
     * @param {string} companyId the company Id
     * @param {Function(Array<Object>)} listViewCall array path call
     * @returns {Array<Object>} an array of products
     */
    this.assignInventoryPath = async function (products, companyId, listViewCall = null) {
      let productIds = products.map(el => el.id);
      await this.getProductsInventoryPath(productIds, companyId).then(response => {
        products.forEach(el => {
          el.inventoryTree = listViewCall ? listViewCall(response[el.id]) : response[el.id];
        });
      });

      return products;
    };

    /**
     * Get full inventory path of products
     *
     * @param {Array<Object>} productIds an array of products IDs
     * @param {string} companyId the company Id
     * @returns {Array<Object>} an array of products path
     */
    this.getProductsInventoryPath = async function (productIds, companyId) {
      const pathDelemiter = ' / ';
      let tree = await this.getInventoriesTree(productIds, companyId);

      let productPath = {};

      let inventoryPathByTree = function func(dir, tree) {
        let lpath = dir;
        tree.forEach(el => {
          lpath = (dir ? dir + pathDelemiter : '') + el.name;
          if (el.inventories.length) {
            lpath = func(lpath, el.inventories);
          }

          if (el.products && el.products.length) {
            el.products.forEach(pel => {
              productPath[pel.id] = [...(productPath[pel.id] || []), lpath];
            });
          }
        });
        return lpath;
      };

      inventoryPathByTree('', tree);

      productIds.forEach(el => {
        productPath[el] = uniq(productPath[el]);
      });
      return productPath;
    };

    /**
     * Filter all products those are verified or those are unverified but added to the company
     *
     * @param {Array<Object>} products an array of products
     * @param {string} companyId the companyId
     * @returns {Array<Object>} an array of products with "verified: true" and "verified: false && company: companyId"
     */
    this.filterCompanyUnverified = function (products, companyId) {
      return products.filter(function (product) {
        return (
          product.verified ||
          (!product.verified &&
            product.companies.some(function (company) {
              return company.id === companyId;
            }))
        );
      });
    };

    this.create = function (product) {
      let promise;

      if ($rootScope.checkIfUserIs('admin')) {
        product.verified = true;

        promise = Product.create(product).$promise;
      } else if ($rootScope.checkIfUserIs(['teamAdmin', 'teacher'])) {
        var companyId = $rootScope.currentUser.companyId;
        product.verified = false;

        promise = Product.createCompanyProduct({ companyId: companyId }, product).$promise;
      } else {
        return MessagesService.error('COMMON.MESSAGES.ACCESS_DENIED');
      }

      return promise
        .then(function (newProduct) {
          MessagesService.success('COMMON.MESSAGES.ADDED');

          SystemAuditService.create({
            action: 'added',
            objectType: SystemAuditService.PRODUCT_TYPE,
            objectName: newProduct.name,
            objectId: newProduct.id,
            companyId: $rootScope.checkIfUserIs('admin') ? '' : companyId
          });

          return newProduct;
        })
        .catch(simpleCatch);
    };

    this.copy = function (product) {
      if (!fieldsValidation(product)) return $q.reject();

      let promise;

      const copyProduct = angular.copy(product);
      copyProduct.verified = false;

      if ($rootScope.checkIfUserIs('admin')) {
        copyProduct.verified = true;
      }

      copyProduct.manufacturer = manufacturers[copyProduct.manufacturerId];
      copyProduct.bannedInCompanies = copyProduct.bannedInCompanies.map(function (company) {
        return company.id;
      });

      promise = Product.copyProduct(copyProduct).$promise;

      return promise
        .then(function (newProduct) {
          MessagesService.success('COMMON.MESSAGES.ADDED');

          SystemAuditService.create({
            action: 'copied',
            objectType: SystemAuditService.PRODUCT_TYPE,
            objectName: newProduct.name,
            objectId: newProduct.id,
            companyId: ''
          });
        })
        .catch(simpleCatch);
    };

    this.startEditingProduct = function (product, editedProduct) {
      angular.copy(product, editedProduct);

      if (typeof editedProduct.size === 'string') {
        editedProduct.customSize = editedProduct.size;
        editedProduct.size = null;
      }

      product.editable = true;
    };

    this.updateProduct = function (product, editedProduct) {
      if (!fieldsValidation(editedProduct)) return $q.resolve();

      editedProduct = replaceUndefinedFieldsWithEmptyStr(editedProduct);
      editedProduct.manufacturer = manufacturers[editedProduct.manufacturerId];
      editedProduct.bannedInCompanies = editedProduct.bannedInCompanies.map(function (company) {
        return company.id;
      });

      delete editedProduct.inventoriedInCompanies;

      return Product.prototype$updateAttributes({ id: product.id }, editedProduct)
        .$promise.then(function () {
          product.editable = false;

          angular.extend(product, editedProduct);
          MessagesService.success('COMMON.MESSAGES.UPDATED');
          createAuditEntryUpdateProduct(product, editedProduct);

          return editedProduct;
        })
        .catch(simpleCatch);
    };

    this.changeVerified = function (product, value) {
      product.verified = value;

      return Product.prototype$updateAttributes({ id: product.id }, product)
        .$promise.then(function () {
          MessagesService.success('COMMON.MESSAGES.' + (value ? 'VERIFIED' : 'UPDATED'));
        })
        .catch(simpleCatch);
    };

    this.deleteProduct = function (product) {
      return Product.deleteById({ id: product.id })
        .$promise.then(function () {
          MessagesService.success('COMMON.MESSAGES.DELETED');

          SystemAuditService.create({
            action: 'removed',
            objectType: SystemAuditService.PRODUCT_TYPE,
            objectName: product.name,
            objectId: product.id
          });
        })
        .catch(simpleCatch);
    };

    this.replaceWithAnotherProduct = function (oldProduct) {
      return ModalService.open({
        templateUrl: require('../../views/modals/associate-product.html'),
        controller: [
          '$uibModalInstance',
          function ($uibModalInstance) {
            const modal = this;

            modal.existedProducts = [oldProduct];

            modal.handleAssociate = function (newProduct) {
              Product.replaceWithAnotherProduct(
                { oldProductId: oldProduct.id, newProductId: newProduct.id },
                null
              )
                .$promise.then(function () {
                  $uibModalInstance.close();
                  MessagesService.success('COMMON.MESSAGES.UPDATED');
                })
                .catch(simpleCatch);
            };
          }
        ]
      });
    };

    this.unlinkProductFromCompany = function (product, companyId) {
      return Product.inventories({ id: product.id, filter: { where: { companyId: companyId } } })
        .$promise.then(function (inventories) {
          var numberOfInventories = inventories.length;

          if (!numberOfInventories) return;

          return ModalService.open({
            templateUrl: require('../../views/modals/confirm-message.html'),
            resolve: {
              message: [
                '$translate',
                function ($translate) {
                  return $translate('PRODUCTS.COMPANY_UNLINK_NOTE', {
                    number: numberOfInventories
                  });
                }
              ]
            },
            controller: [
              '$uibModalInstance',
              '$q',
              'message',
              function ($uibModalInstance, $q, message) {
                var modal = this;

                modal.message = message;

                modal.confirm = function () {
                  var inventoryIds = inventories.map(function (inventory) {
                    return inventory.id;
                  });

                  $q.all(unlinkProductFromInventory(product.id, inventoryIds))
                    .then(function () {
                      $uibModalInstance.close();
                    })
                    .catch(simpleCatch);
                };

                modal.cancel = function () {
                  $uibModalInstance.dismiss('cancel');
                };
              }
            ]
          });
        })
        .then(function () {
          return unlinkProductFromCompany(product.id, companyId);
        })
        .then(function () {
          SystemAuditService.create({
            action: 'removed from the school board',
            objectType: SystemAuditService.PRODUCT_TYPE,
            objectName: product.name,
            objectId: product.id,
            companyId: companyId
          });
        });
    };

    this.linkProductToCompany = function (product, companyId) {
      if (!product.sdsId.length) return linkProductToCompany(product, companyId);

      let companyTags = new TagFactory();

      return companyTags.openModalToSelectCompanyTags(companyId).then(function (tags) {
        return linkProductToCompany(product, companyId, tags);
      });
    };

    this.updateCompanyProductBanning = function (companyId, product, status) {
      const productId = product.id;

      return Product.updateCompanyProductBanning(
        { companyId: companyId, productId: productId, status: status },
        null
      )
        .$promise.then(function (updatedProduct) {
          MessagesService.success('COMMON.MESSAGES.UPDATED');

          SystemAuditService.create({
            action: 'updated banning',
            objectType: SystemAuditService.PRODUCT_TYPE,
            objectName: product.name,
            objectId: product.id,
            newValue: status ? 'added' : 'removed',
            companyId: companyId
          });

          return updatedProduct;
        })
        .catch(simpleCatch);
    };

    this.handleLinkingBannedProduct = function (product, companyId) {
      if (self.isProductBanned(product, companyId)) {
        MessagesService.warning('PRODUCTS.BANNED');

        return true;
      }

      return false;
    };

    this.isProductBanned = function (product, companyId) {
      if (!product) return false;

      const bannedInCompanies = product.bannedInCompanies.map(function (company) {
        return typeof company === 'string' ? company : company.id;
      });

      return bannedInCompanies.some(function (bannedCompanyId) {
        return bannedCompanyId === companyId;
      });
    };

    this.getProductsWithoutSds = function (newStart, perPage) {
      const filter = {
        filter: {
          where: { and: [{ sdsId: [] }, { sdsRequired: true }] },
          limit: perPage,
          offset: newStart,
          include: [
            {
              relation: 'manufacturer',
              scope: { fields: ['name'] }
            },
            { relation: 'measure' }
          ]
        }
      };

      return Product.find(filter).$promise.catch(simpleCatch);
    };

    this.showCorrectProductSize = function (product) {
      const measure = product.measure || { title: '' };
      switch (product.sizeType) {
        case self.sizeTypes.specific.id:
          return `${product.size} ${measure.title}`;
        case self.sizeTypes.general.id:
          return measure.title;
        case self.sizeTypes.custom.id:
          return product.customSize;
        default:
          return product.size || product.customSize;
      }
    };

    this.updateMeasureForProduct = function (product, measure) {
      product.measureId = measure.id;
      product.measure = measure;
    };

    init();

    async function init() {
      self.sizeTypes.specific.title = 'PRODUCTS.FORM.SIZE_TYPE.SPECIFIC';
      self.sizeTypes.general.title = 'PRODUCTS.FORM.SIZE_TYPE.GENERAL';
      self.sizeTypes.custom.title = 'PRODUCTS.FORM.SIZE_TYPE.CUSTOM';

      self.noSdsReasons.nonRegulated.title = 'REPORTS.PRODUCTS_DONT_REQUIRE_SDS.TITLE';
      self.noSdsReasons.noSds.title = 'PRODUCTS.NO_SDS_PROVIDED';
    }

    function fieldsValidation(product) {
      const required = [...requiredFields];

      switch (product.sizeType) {
        case self.sizeTypes.specific.id:
          required.push('size');
          required.push('measureId');
          break;
        case self.sizeTypes.general.id:
          required.push('measureId');
          break;
        case self.sizeTypes.custom.id:
          required.push('customSize');
          break;
      }

      return required.every(function (field) {
        if (!product[field]) {
          // just show "size" instead of "customSize"
          if (field === 'customSize') field = 'size';

          MessagesService.warning('COMMON.MESSAGES.IS_REQUIRED', {
            field: field
          });

          return false;
        }

        return true;
      });
    }

    function createAuditEntryUpdateProduct(product, editedProduct) {
      var changedFields = getObjectFieldsChanged(product, editedProduct);
      var newValues = changedFields.map(function (field) {
        return editedProduct[field];
      });

      newValues = newValues.map(function (value) {
        return (value = value !== '' ? value : '-');
      });

      ifManufacturerFieldUpdated();

      var changedFieldsStr = changedFields.join(', ');
      var newValuesStr = newValues.join(', ');

      return SystemAuditService.create({
        action: 'updated: ' + changedFieldsStr,
        objectType: SystemAuditService.PRODUCT_TYPE,
        objectName: product.name,
        objectId: product.id,
        newValue: newValuesStr
      });

      function ifManufacturerFieldUpdated() {
        var manufacturerFieldIndex = changedFields.indexOf('manufacturerId');

        if (~manufacturerFieldIndex) {
          var manufacturer = manufacturers[editedProduct[changedFields[manufacturerFieldIndex]]];
          changedFields[manufacturerFieldIndex] = 'manufacturer';

          newValues = newValues.map(function (value) {
            if (value === manufacturer.id) value = manufacturer.name;

            return value;
          });
        }
      }
    }

    /**
     * Returns an array with fields those values were changed in original object
     *
     * @param {Object} originalObj the original object to compare
     * @param {Object} newObj the new object to compare
     * @returns {Array} an array with fields those were changed
     */
    function getObjectFieldsChanged(originalObj, newObj) {
      var fields = Object.keys(newObj);
      var changedFields = [];

      changedFields = fields.filter(function (field) {
        if (field === 'editable') return false;

        return JSON.stringify(originalObj[field]) !== JSON.stringify(newObj[field]);
      });

      return changedFields;
    }

    function unlinkProductFromCompany(id, companyId) {
      return Product.companies.unlink({ id: id, fk: companyId }, null).$promise.then(function () {
        MessagesService.success('COMMON.MESSAGES.REMOVED');
      });
    }

    /**
     * Unlinks a product from a bunch of inventory
     *
     * @param {string} id the product ID
     * @param {Array<string>} inventoryList an array of inventory IDs
     * @returns {Array<Promise>} an array of Promises
     */
    function unlinkProductFromInventory(id, inventoryList) {
      return inventoryList.map(function (inventoryId) {
        return Product.inventories.unlink({ id: id, fk: inventoryId }).$promise;
      });
    }

    function linkProductToCompany(product, companyId, tagsToAdd) {
      const additionalData = tagsToAdd ? { tagsToAdd: tagsToAdd } : null;

      return Product.companies
        .link({ id: product.id, fk: companyId }, additionalData)
        .$promise.then(function () {
          MessagesService.success('COMMON.MESSAGES.ADDED');

          SystemAuditService.create({
            action: 'added to the school board',
            objectType: SystemAuditService.PRODUCT_TYPE,
            objectName: product.name,
            objectId: product.id,
            companyId: companyId
          });
        })
        .catch(simpleCatch);
    }

    function countAndFindByFilter(countFilter, position) {
      position = position || {};
      let count = 0;
      let findInclude = [
        {
          relation: 'manufacturer',
          scope: { fields: ['name'] }
        },
        { relation: 'measure' }
      ];

      return Product.count(countFilter)
        .$promise.then(function (response) {
          const findFilter = {
            filter: angular.extend({}, countFilter, {
              offset: position.offset || 0,
              limit: position.limit || 0,
              include: findInclude
            })
          };
          count = response.count;

          if (!count) return [];

          return Product.find(findFilter).$promise;
        })
        .then(function (products) {
          return {
            products: products,
            count: count
          };
        })
        .catch(simpleCatch);
    }

    function deepParentIncluding(depth, relationName) {
      depth--;

      const include = {
        relation: relationName,
        scope: { fields: ['name', 'id', 'groupId'] }
      };

      if (depth > 1) {
        include.scope.include = deepParentIncluding(depth, relationName);
      }

      return include;
    }
  }
]);
