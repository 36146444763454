import app from '../app';

app.filter('getVisibleRoleName', [
  '$rootScope',
  function ($rootScope) {
    return function (rolename) {
      switch (rolename) {
        case 'admin':
          return 'admin';
        case 'teamAdmin':
          return 'team admin';
        case 'teamMember':
          return 'team member';
        case 'teacher':
          return 'team ' + $rootScope.companyStaffTitle;
        case 'firstResponder':
          return 'First Responder';
        case 'sdsEditor':
          return 'SDS Editor';
        case 'invManagerRead':
          return 'Inventory (Read Only)';
        default:
          return rolename;
      }
    };
  }
]);
