import app from '../app';

app.directive('commitableForm', [
  function () {
    return {
      require: '?form',
      link: {
        pre: function (scope, elem, attrs, form) {
          form.commit = function (data) {
            const formElem = elem[0];
            for (let key in data) {
              formElem[key].value = data[key];
            }
            formElem.submit();
          };
        }
      }
    };
  }
]);
