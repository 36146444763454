import app from '../../app';

app.directive('uploadFiles', function ($rootScope, ContainerService) {
  return {
    restrict: 'E',
    templateUrl: require('./uploadFiles.html'),
    scope: {
      filters: '=',
      formData: '=',
      uniqName: '=',
      api: '=',
      container: '@',
      onUploadFiles: '&',
      onRemoveFile: '&'
    },
    link: {
      pre: function (scope) {
        const container = scope.container || 'upload';

        scope.api = scope.api || {};
        scope.filters = scope.filters || [];
        scope.containerService = new ContainerService(container);
        scope.uploader = scope.containerService.getUploader(
          scope.formData,
          $rootScope.currentUser.tokenId,
          scope.uniqName
        );
      },
      post: function (scope) {
        const getReady = function () {
          return scope.uploader.queue.filter(function (itm) {
            return !itm.isUploaded || !itm.isSuccess;
          });
        };

        scope.uploader.filters.push({
          name: 'ignore dups',
          fn: function (file) {
            return !scope.uploader.queue.find(function (item) {
              return file.name === item.file.name;
            });
          }
        });
        scope.uploader.filters = scope.uploader.filters.concat(scope.filters);

        scope.uploader.onErrorItem = function (item, response, status, headers) {
          item.error = (response.error || {}).message || 'something failed';
        };
        scope.uploader.onSuccessItem = function (item, response, status, headers) {
          item.file.newName = response.name; // we change it on the server
          item.file.id = response.id;
        };
        scope.uploader.onCompleteAll = function () {
          const justUploadedFiles = scope.uploader.queue
            .filter(item => item.isUploaded && item.isSuccess && !item.isPrevUploaded)
            .map(item => ({
              id: item.file.id,
              name: item.file.newName,
              originalName: item.file.name,
              container: scope.container
            }));

          scope.uploader.queue.forEach(item => {
            if (item.isUploaded && item.isSuccess) item.isPrevUploaded = true;
          });

          scope.onUploadFiles({ files: justUploadedFiles });
        };

        scope.hasReady = function () {
          return !!getReady().length;
        };
        scope.uploadAll = function () {
          scope.uploader.uploadAll();
        };
        scope.delete = item => {
          item.deleting = true;
          item.isUploading = false;
          item.isSuccess = false;
          item.isError = false;
          item.error = null;

          return scope.containerService
            .removeFile(item.file.newName)
            .then(function () {
              item.deleting = false;
              item.remove();
              scope.onRemoveFile({
                file: {
                  id: item.file.id,
                  name: item.file.newName,
                  originalName: item.file.name,
                  container: scope.container
                }
              });
            })
            .catch(function () {
              item.deleting = false;
              item.isUploaded = false;
              item.error = 'Failed to delete file';
            });
        };
        scope.deleteAll = () => {
          return Promise.all(scope.uploader.queue.map(scope.delete));
        };

        scope.api = Object.assign(scope.api, {
          uploadAll: scope.uploadAll,
          delete: scope.delete,
          deleteAll: scope.deleteAll
        });
      }
    }
  };
});
