export const searchCtrlResolve = {
  companyPromise: [
    'CompanyService',
    function (CompanyService) {
      return CompanyService.getCurrentCompanyPromise().$promise;
    }
  ]
};

export const processCtrlResolve = {
  manufacturers: [
    'ManufacturerService',
    function (ManufacturerService) {
      return ManufacturerService.manufacturersPromise();
    }
  ]
};

export const printoutCtrlResolve = {
  docs: [
    'LabelBatchService',
    function (LabelBatchService) {
      return LabelBatchService.getLabelBatch();
    }
  ]
};

export const settingsCtrlResolve = {
  roles: [
    'RoleFactory',
    function (RoleFactory) {
      return RoleFactory.find();
    }
  ],
  companyTypes: [
    'CompanyType',
    function (CompanyType) {
      return CompanyType.find().$promise;
    }
  ]
};

export const productsWithoutSdsCtrlResolve = {
  totalProducts: [
    'Product',
    function (Product) {
      return Product.count({
        where: { and: [{ sdsId: [] }, { sdsRequired: true }] }
      }).$promise;
    }
  ]
};
