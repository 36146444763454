import angular from 'angular';
import { uniq } from 'lodash';
import app from '../../app';
import { isChecked, flatWoods, getOffset } from '../../helpers';
import confirmMessage from '../../modals/confirmMessage';
import selectTags from '../../modals/selectTags';

app.controller('inventoryCtrl', [
  '$scope',
  '$rootScope',
  '$timeout',
  '$window',
  'INV_MAX_DEPTH',
  'MessagesService',
  'CompanyService',
  'InventoryService',
  'InventoryReviewService',
  'InventoryContainerService',
  'ModalService',
  'ExportService',
  'EquipmentService',
  'SearchPageService',
  function inventoryCtrl(
    $scope,
    $rootScope,
    $timeout,
    $window,
    INV_MAX_DEPTH,
    MessagesService,
    CompanyService,
    InventoryService,
    InventoryReviewService,
    InventoryContainerService,
    ModalService,
    ExportService,
    EquipmentService,
    SearchPageService
  ) {
    var vm = this;
    var companyId = $rootScope.companyId;
    var inventorySchema = {
      name: '',
      companyId: companyId,
      groupId: 'root',
      order: 0,
      alertEnabled: {
        low: false,
        max: false
      },
      alertThreshold: {
        low: 0,
        max: 0
      },
      alertEmailsList: {
        low: [],
        max: []
      }
    };
    var explicitNodeRemoving = false;
    var inventoriesShowContent = InventoryService.inventoriesShowContent;

    vm.inventories = [];
    vm.selectedCompany = null;
    vm.companies = [];
    vm.MAX_DEPTH = INV_MAX_DEPTH;
    vm.selectedTagsResult = { tags: [] };
    vm.pinInv = function (el) {
      InventoryService.pinInv(el).then(updated => (el.pinned = updated.pinned));
    };
    vm.treeOptions = {
      beforeDrop: function (event) {
        var droppedInventory = event.source.nodeScope.inventory;
        var destInventories = event.dest.nodesScope.$modelValue;
        var canBeDropped = false;

        canBeDropped = !destInventories.some(function (inventory) {
          return (
            inventory.name === droppedInventory.name &&
            inventory.groupId !== droppedInventory.groupId
          );
        });

        if (!canBeDropped)
          MessagesService.error('COMMON.MESSAGES.NOT_UNIQUE', {
            field: 'name'
          });

        return canBeDropped;
      },
      dropped: function (event) {
        $timeout(function () {
          var sourceParentInventory = event.source.nodesScope.inventory;
          var droppedInventory = event.source.nodeScope.inventory;
          var destInventories = angular.copy(event.dest.nodesScope.$modelValue);
          var parentNode = event.dest.nodesScope.$nodeScope;
          var destParentInventory = event.dest.nodesScope.inventory;
          var newOrder = event.dest.index;
          var updatedInventories = [];

          // if just picked up and released then prevent the further executing
          if (
            droppedInventory.groupId === (destParentInventory ? destParentInventory.id : 'root') &&
            droppedInventory.order === newOrder
          )
            return;

          droppedInventory.order = newOrder;

          destInventories = destInventories.filter(function (inventory) {
            return inventory.id === droppedInventory.id
              ? inventory.order === newOrder
              : !!inventory.id;
          });

          destInventories.splice(newOrder, 0, droppedInventory);

          parentNode && parentNode.expand();

          updatedInventories = destInventories.map(function (inventory, index) {
            return {
              id: inventory.id,
              groupId: (inventory.groupId =
                droppedInventory.id === inventory.id
                  ? destParentInventory
                    ? destParentInventory.id
                    : 'root'
                  : inventory.groupId),
              order: (inventory.order = index)
            };
          });

          vm.inventoryService.updateInventories(updatedInventories).then(function () {
            vm.inventoryService.refreshTotalItemsOfInventory(droppedInventory, vm.inventories);
            vm.inventoryService.refreshTotalItemsOfInventory(sourceParentInventory, vm.inventories);

            if (vm.companySettings.inventoryAlertsEnabled) {
              if (!droppedInventory.alertEnabled.low) {
                const newParentAlert = vm.getParentInventoryWithEnabledAlerts(droppedInventory).low;
                const oldParentAlert = vm.getParentInventoryWithEnabledAlerts(sourceParentInventory)
                  .low;
                updateAlertsAfterDropInventory(
                  sourceParentInventory,
                  newParentAlert,
                  oldParentAlert,
                  InventoryService,
                  droppedInventory,
                  'low'
                );
              }

              if (!droppedInventory.alertEnabled.max) {
                const newParentAlert = vm.getParentInventoryWithEnabledAlerts(droppedInventory).max;
                const oldParentAlert = vm.getParentInventoryWithEnabledAlerts(sourceParentInventory)
                  .max;
                updateAlertsAfterDropInventory(
                  sourceParentInventory,
                  newParentAlert,
                  oldParentAlert,
                  InventoryService,
                  droppedInventory,
                  'max'
                );
              }
            }
          });
        });
      },
      removed: function (node) {
        $timeout(function () {
          var removedInventory = node.inventory;
          var parentNode = node.$parentNodeScope;
          const parentInventoryId = parentNode && parentNode.$modelValue.id;
          var siblingsNodes = node.siblings();
          var siblingInventories = getParentSiblingsInventories(siblingsNodes);
          var destParentInventory = parentInventoryId
            ? InventoryService.findInventoryInSetById(parentInventoryId, vm.inventories)
            : parentNode && parentNode.$modelValue;

          // if removed inventory had child inventories then rewrite their
          // groupId field to removed inventory's parent id
          if (
            explicitNodeRemoving &&
            removedInventory.inventories &&
            removedInventory.inventories.length
          ) {
            updateNestedInventories(
              destParentInventory,
              removedInventory.inventories,
              siblingInventories
            ).then(function () {
              vm.inventoryService.refreshTotalItemsOfInventory(removedInventory, vm.inventories);
            });
            updateParentTree(destParentInventory, removedInventory.inventories);
          }

          if (explicitNodeRemoving && siblingInventories && siblingInventories.length) {
            var updatedInventories = siblingInventories.map(function (siblingInventory, index) {
              return {
                id: siblingInventory.id,
                order: (siblingInventory.order = index)
              };
            });

            vm.inventoryService.updateInventories(updatedInventories, true);
          }

          if (explicitNodeRemoving && vm.inventories.length === 0) createFirstInventory();

          explicitNodeRemoving = false;
          $scope.isUpdating = false;
        });
      }
    };

    vm.canManageInventories = function () {
      return !vm.exportMode && $rootScope.checkIfUserIs(['admin', 'teamAdmin']);
    };

    vm.onCompanySelected = function (selectedCompany) {
      if (!vm.selectedCompany) vm.selectedCompany = selectedCompany;
      if (
        !$rootScope.checkIfUserIs('admin') &&
        selectedCompany.settings &&
        !$rootScope.isModuleEnabled($rootScope.moduleNames.inv, false, selectedCompany.settings)
      ) {
        return MessagesService.warning('SETTINGS.MODULE_REQUIRED', {
          moduleName: $rootScope.moduleNames.inv
        });
      }

      inventorySchema.companyId = companyId = selectedCompany.id;

      return getCompanySettings(companyId).then(applyCompanySettings);
    };

    vm.newSubItem = function (scope) {
      var parentInventory = scope.$modelValue;
      var newInventory = angular.copy(inventorySchema);
      vm.editMode = true;

      newInventory.isNewInventory = true;
      newInventory.groupId = parentInventory.id ? parentInventory.id : 'root';
      newInventory.inventoryPath = parentInventory.inventoryPath || [];

      scope.expand();

      if (!parentInventory.inventories) parentInventory.inventories = [];

      parentInventory.inventories.push(newInventory);
    };

    vm.addInventory = function (inventory, name, uiTreeNode) {
      inventory.name = name;
      inventory.order = uiTreeNode.$parentNodesScope.$modelValue.length - 1;

      vm.inventoryService.addInventory(inventory).then(function () {
        inventory.inventoryPath.push({
          name: inventory.name,
          id: inventory.id
        });
        vm.editMode = false;
      });
    };

    vm.updateInventoryName = function (inventory, name) {
      const data = angular.copy(inventory);
      data.name = name;

      vm.inventoryService.updateInventoryById(inventory.id, data).then(function (updatedInventory) {
        if (!updatedInventory) return;

        inventory.id = updatedInventory.id;
        inventory.name = updatedInventory.name;
        inventory.enableEdit = false;
      });
    };

    vm.removeInventory = function (inventory, uiTreeNode) {
      return ModalService.open(confirmMessage()).then(() => {
        let promise = {
          then: function (cb) {
            return cb();
          }
        };

        if (vm.companySettings.inventoryAlertsEnabled) {
          const newParentAlerts = vm.getParentInventoryWithEnabledAlerts(inventory);

          if (inventory.alertEnabled.low) {
            promise =
              newParentAlerts.low.alertEnabled.low && inventory.id !== newParentAlerts.low.id
                ? InventoryService.updateSettingsLink(inventory, newParentAlerts.low, null, 'low')
                : InventoryService.removeAlertsBySettingsId(inventory.id, 'low');
          }

          if (inventory.alertEnabled.max) {
            promise =
              newParentAlerts.max.alertEnabled.max && inventory.id !== newParentAlerts.max.id
                ? InventoryService.updateSettingsLink(inventory, newParentAlerts.max, null, 'max')
                : InventoryService.removeAlertsBySettingsId(inventory.id, 'max');
          }
        }

        return promise
          .then(function () {
            return vm.inventoryService.removeInventory(inventory, true);
          })
          .then(function () {
            return vm.inventoryService.refreshTotalItemsOfInventory(inventory, vm.inventories);
          })
          .then(function () {
            explicitNodeRemoving = true;

            uiTreeNode.remove();
          });
      });
    };

    vm.isGrouping = function (inventory) {
      return inventory.inventories && inventory.inventories.length;
    };

    vm.ifInventoryAlerts = function (inventory) {
      return (
        vm.inventories[0].id &&
        vm.companySettings.inventoryAlertsEnabled &&
        (vm.isGrouping(inventory) || inventory.groupId === 'root') &&
        $rootScope.checkIfUserIs(['admin', 'teamAdmin'])
      );
    };

    vm.openModalAccessUser = function (inventory) {
      ModalService.open({
        templateUrl: require('../../../views/modals/denyAccessUserInventory.html'),
        controller: [
          '$scope',
          'DenyToInventory',
          function ($scope, DenyToInventory) {
            $scope.users = [];
            for (let index = 0; index < $scope.$resolve.userList.length; index++) {
              const user = $scope.$resolve.userList[index];
              let accessDenied;
              $scope.$resolve.denyToInventory.forEach(function (access) {
                if (access.userId === user.id) accessDenied = access;
              });

              const obj = angular.copy(user);
              obj.enable = !accessDenied || !accessDenied.deny;
              $scope.users.push(obj);
            }

            $scope.selectAll = function () {
              $scope.users.forEach(function (el) {
                el.enable = true;
              });
            };

            $scope.unselectAll = function () {
              $scope.users.forEach(function (el) {
                el.enable = false;
              });
            };

            $scope.close = function () {
              DenyToInventory.updateMany({
                where: $scope.users.map(function (el) {
                  return { inventoryId: inventory.id, userId: el.id, deny: !el.enable };
                })
              })
                .$promise.then(function (model) {
                  $scope.$close(true);
                })
                .catch(function (err) {
                  return false;
                });
            };
          }
        ],
        resolve: {
          userList: [
            'SdsUser',
            function (SdsUser) {
              return SdsUser.getCompanyUsersByRoleNames({
                roleNames: ['teamMember', 'teacher'],
                companyId: inventory.companyId
              }).$promise.then(function (result) {
                return result;
              });
            }
          ],
          denyToInventory: [
            '$rootScope',
            'DenyToInventory',
            function ($rootScope, DenyToInventory) {
              return DenyToInventory.find({
                filter: {
                  where: { inventoryId: inventory.id }
                }
              }).$promise.then(function (result) {
                return result;
              });
            }
          ]
        }
      }).then(function (users) {});
    };

    vm.openModal = function (inventory) {
      ModalService.open({
        templateUrl: require('../../../views/modals/add-content-to-inventory.html'),
        backdrop: 'static',
        controller: [
          '$q',
          '$uibModalInstance',
          'SolrDocumentService',
          'Product',
          'ProductService',
          'TagFactory',
          function (
            $q,
            $uibModalInstance,
            SolrDocumentService,
            Product,
            ProductService,
            TagFactory
          ) {
            var modal = this;

            modal.parentView = vm;
            modal.handleSDSModal = SolrDocumentService.openModalOrSDSbyProduct;
            modal.productsToLink = [];
            modal.inventoryContainersEnabled = vm.companySettings.inventoryContainersEnabled;
            modal.inventoryEquipmentEnabled = vm.companySettings.inventoryEquipmentEnabled;
            modal.inventoryContainers = vm.inventoryContainers;
            modal.equipment = vm.equipment;
            modal.companyId = companyId;
            modal.fileUploaderApi = { deleteAll: () => Promise.resolve() };
            modal.fileContainer = 'inventory_files';

            modal.handleSelectTab = function (relationName) {
              modal.activeTab = relationName;
              modal[relationName + 'ToLink'] = modal[relationName + 'ToLink'] || [];
            };

            modal.handleAddProductToLink = function (item) {
              if (ProductService.handleLinkingBannedProduct(item, inventory.companyId))
                return $q.reject();

              handleAddItemToLink(item, 'products');
            };

            modal.handleAddContainerToLink = function (item) {
              handleAddItemToLink(item, 'inventoryContainers');
            };

            modal.handleAddEquipmentToLink = function (item) {
              handleAddItemToLink(item, 'equipment');
            };

            modal.handleAddFilesToLink = files => {
              files.forEach(file => handleAddItemToLink(file, 'files'));
            };

            modal.handleRemoveItemToLink = function (linkedItem, relationName) {
              modal[relationName + 'ToLink'] = modal[relationName + 'ToLink'].filter(function (
                item
              ) {
                return item.id !== linkedItem.id;
              });
            };

            modal.areAdditionalRelationsPresent = function (additionalRelations) {
              if (!Array.isArray(additionalRelations)) additionalRelations = [additionalRelations];

              if (additionalRelations.every(relationIsNotPresent) && modal.productsToLink.length)
                return true;

              return additionalRelations.some(function (relationName) {
                return modal.activeTab === relationName && modal[relationName + 'ToLink'].length;
              });

              function relationIsNotPresent(relationName) {
                return !modal[relationName + 'Enabled'] || relationName === 'products';
              }
            };

            modal.save = function (relationName) {
              const itemsToLink = modal[relationName + 'ToLink'];
              let promise = Promise.resolve();
              let tags;

              if (!itemsToLink.length) return modal.cancel();

              if (relationName === 'products') {
                if (doProductsHaveAnySds(itemsToLink)) {
                  let companyTags = new TagFactory();

                  modal.isLoading = true;

                  promise = companyTags
                    .openModalToSelectCompanyTags(companyId, inventory.name)
                    .then(selectTags => (tags = selectTags));
                }
              }

              $q.resolve(promise)
                .then(function () {
                  return vm.inventoryService.linkItemsToInventory(
                    itemsToLink,
                    inventory,
                    relationName
                  );
                })
                .then(() => linkProducts(itemsToLink, companyId, tags))
                .then(() => {
                  if (modal.activeTab === 'files') return;

                  return modal.fileUploaderApi.deleteAll();
                })
                .then(function () {
                  modal.isLoading = false;

                  $uibModalInstance.close('save');

                  return vm.inventoryService.refreshTotalItemsOfInventory(
                    inventory,
                    vm.inventories
                  );
                })
                .catch(function () {
                  modal.isLoading = false;

                  return modal.fileUploaderApi.deleteAll();
                });
            };

            modal.cancel = () => {
              return modal.fileUploaderApi.deleteAll().then(() => $uibModalInstance.dismiss());
            };

            function handleAddItemToLink(item, relationName) {
              if (modal[relationName + 'ToLink'].some(isItemAlreadyInList(item))) return;

              item.inventoryId = inventory.id;
              item.amount = 0;

              modal[relationName + 'ToLink'] = modal[relationName + 'ToLink'] || [];
              modal[relationName + 'ToLink'] = modal[relationName + 'ToLink'].concat(item);

              function isItemAlreadyInList(item) {
                return function (itemInList) {
                  return itemInList.id === item.id;
                };
              }
            }

            function linkProducts(productsToLink, companyId, tags) {
              return $q.all(
                productsToLink.map(function (product) {
                  return linkProductOnCompany(product, companyId, tags);
                })
              );
            }

            function linkProductOnCompany(product, companyId, tagsToAdd) {
              const additionalData = tagsToAdd ? { tagsToAdd: tagsToAdd } : null;

              return Product.companies.link({ id: product.id, fk: companyId }, additionalData)
                .$promise;
            }

            function doProductsHaveAnySds(productsToLink) {
              return productsToLink.some(function (product) {
                return product.sdsId && product.sdsId.length;
              });
            }
          }
        ]
      });
    };

    // this handle should be placed on a <form> tag
    vm.handleShowContentOnBar = function (inventory, event, scope) {
      if (event.target.tagName !== 'FORM' && event.target.tagName !== 'LABEL') return;
      if (
        vm.isGrouping(inventory) &&
        (event.target.tagName === 'FORM' || event.target.tagName === 'LABEL')
      )
        return scope.collapsed ? scope.expand() : scope.collapse();

      inventoriesShowContent.toggle(inventory.id);
    };

    vm.handleShowContent = function (inventory, event) {
      if (
        !vm.canManageInventories() &&
        !vm.isGrouping(inventory) &&
        (event.target.tagName === 'FORM' || event.target.tagName === 'LABEL')
      )
        return;

      inventoriesShowContent.toggle(inventory.id);
    };

    vm.getParentInventoryWithEnabledAlerts = function (inventory) {
      return InventoryService.getParentInventoryWithEnabledAlerts(inventory, vm.inventories);
    };

    vm.handleLastReviewed = function (inventory) {
      const inventoryId = inventory.id;
      const companyId = inventory.companyId;
      const PER_PAGE = 20;

      ModalService.open({
        templateUrl: require('../../../views/modals/inventory-review.html'),
        resolve: {
          reviews: [
            '$rootScope',
            'InventoryReviewService',
            function ($rootScope, InventoryReviewService) {
              if ($rootScope.checkIfUserIs(['admin', 'teamAdmin']))
                return InventoryReviewService.getReviews(inventory.id, 0, PER_PAGE);

              return InventoryReviewService.getLatestReview(inventoryId);
            }
          ],
          totalItems: [
            '$q',
            '$rootScope',
            'InventoryReviewService',
            function ($q, $rootScope, InventoryReviewService) {
              if ($rootScope.checkIfUserIs(['admin', 'teamAdmin']))
                return InventoryReviewService.countReviews(inventoryId);

              return $q.resolve(0);
            }
          ]
        },
        controller: [
          '$uibModalInstance',
          '$filter',
          'reviews',
          'totalItems',
          'InventoryReviewService',
          function ($uibModalInstance, $filter, reviews, totalItems, InventoryReviewService) {
            var modal = this;

            modal.startFrom = 1;
            modal.PER_PAGE = PER_PAGE;
            modal.totalItems = totalItems;
            modal.reviews = reviews;
            modal.reviewIsPassed = false;
            modal.formatDate = formatDate;
            modal.reviewPeriod = vm.companySettings.inventoryReviewsPeriod;

            modal.handlePaginate = function () {
              return InventoryReviewService.getReviews(
                inventoryId,
                getOffset(modal.startFrom, modal.PER_PAGE),
                PER_PAGE
              ).then(function (reviews) {
                modal.reviews = reviews;
              });
            };

            modal.update = function () {
              const reviewer = $rootScope.currentUser.username;

              InventoryReviewService.updateReviews(reviewer, inventoryId, companyId).then(
                function () {
                  MessagesService.success('COMMON.MESSAGES.UPDATED');
                  $uibModalInstance.close('updated');
                  inventory.isReviewPassed = false;
                }
              );
            };

            modal.cancel = function () {
              $uibModalInstance.dismiss('cancel');
            };

            if (InventoryReviewService.checkIfReviewIsPassed(inventoryId)) {
              modal.reviewIsPassed = true;
            } else {
              modal.nextPassedDate = formatDate(
                InventoryReviewService.nextPassedDateIs(inventoryId)
              );
            }

            function formatDate(date) {
              return $filter('date')(date, 'yyyy-MM-dd');
            }
          }
        ]
      });
    };

    vm.prompt4action = function (action, inventories) {
      const flatInventories = flatWoods(inventories, 'inventories');
      const checkedInventories = flatInventories.reduce(
        (sum, inv) => (inv.checked ? sum.concat(inv) : sum),
        []
      );
      const productIds = uniq(
        checkedInventories
          .reduce(
            (sum, inv) => sum.concat(getProductsFromInventory(inv), getProductsFromContainers(inv)),
            []
          )
          .map(product => product.id)
      );

      return SearchPageService.inventoryPrompt4action(action, productIds, vm.selectedTagsResult);
    };

    vm.exportInventories = (inventories, tags, format) => {
      let exportTags = tags;
      
      //don't use filter by tags if all are selected
      if (tags.allSelected && tags.andOrStrategy === 'or') {
        exportTags = [];
      }
      return ExportService.exportInventories(inventories, exportTags, format);
    };
    vm.exportEquipment2csv = ExportService.exportInventoryEquipment2csv;
    vm.exportMode = false;
    vm.toggleExportMode = function () {
      getInventories(10000); // get all entries, just set the limit of 10000 (some big number)
      vm.exportMode = !vm.exportMode;
    };

    vm.isChecked = isChecked;

    vm.toggleCheck = function (invs, check) {
      (Array.isArray(invs) ? invs : [invs]).forEach(function (inv) {
        const toCheck = check == null ? !vm.isChecked(inv) : check;
        inv.checked = toCheck;
        (inv.inventories || []).forEach(function (subInv) {
          subInv.checked = toCheck;
          vm.toggleCheck(subInv, toCheck);
        });
      });
    };

    vm.hasChecked = function (invs) {
      return (Array.isArray(invs) ? invs : [invs]).some(function (inv) {
        return vm.isChecked(inv) || vm.hasChecked(inv.inventories || []);
      });
    };

    vm.hasInventories = function () {
      return vm.inventories.length && !!vm.inventories[0].id;
    };

    vm.filterExportInventory = function () {
      return ModalService.open(
        selectTags(companyId, {
          showAndOrDropdown: true,
          checkedTags: vm.selectedTagsResult.tags,
          andOrStrategy: vm.selectedTagsResult.andOrStrategy,
          message: null
        })
      ).then(result => {
        vm.selectedTagsResult = result;
      });
    };

    vm.handleInfiniteScroll = getInventories;
    vm.onShowContent = inventoriesShowContent.checkIfInventoryShowsContent;

    init();

    function init() {
      if (!$rootScope.checkIfUserIs('admin')) {
        CompanyService.getCurrentCompanyPromise()
          .$promise.then(function () {
            companyId = $rootScope.companyId;
            return getCompanySettings(companyId);
          })
          .then(applyCompanySettings);
      }
    }

    function applyCompanySettings(settings) {
      let relations = ['products', 'files'];

      if (
        settings.inventoryReviewsEnabled &&
        $rootScope.checkIfUserIs(['admin', 'teamAdmin', 'teacher'])
      )
        initReviews(companyId, settings.inventoryReviewsPeriod);

      if (settings.inventoryContainersEnabled) {
        relations = relations.concat('inventoryContainers');

        InventoryContainerService.getContainers(0, 0, companyId).then(function (containers) {
          vm.inventoryContainers = containers;
        });
      }

      if (settings.inventoryEquipmentEnabled) {
        relations = relations.concat('equipment');

        EquipmentService.getEquipmentsByCompany(companyId).then(function (equipment) {
          vm.equipment = equipment;
        });
      }

      return initInventoryService(companyId, relations);
    }

    function initInventoryService(companyId, relations) {
      vm.editMode = false;
      vm.inventoryService = new InventoryService(companyId, $scope, relations);

      InventoryService.inventoriesShowContent.setLastOpenedInventoryId(null);

      return vm.inventoryService
        .count()
        .then(function (count) {
          if (!count) return;

          return getInventories();
        })
        .then(function (inventories) {
          if (!inventories) {
            MessagesService.warning('INVENTORY.NO_INVENTORY');
            createFirstInventory();
          }
        });
    }

    function getCompanySettings(companyId) {
      const companySettings = new CompanyService.CompanySettings(companyId, false);

      vm.companySettings = companySettings.settings;

      return companySettings.$promise;
    }

    function initReviews(companyId, reviewPeriod) {
      vm.isReviewPassed = InventoryReviewService.checkIfReviewIsPassed;

      InventoryReviewService.setReviewPeriod(reviewPeriod);
      InventoryReviewService.getLatestReviews(companyId).then(function (reviews) {
        vm.latestReviews = reviews;
      });
    }

    function getInventories(perPage, start) {
      if (vm.inventoryService)
        return vm.inventoryService.getInventories(perPage, start).then(function (inventories) {
          vm.inventories = inventories;

          return inventories;
        });

      return [];
    }

    function updateNestedInventories(parentInventory, inventories, siblingInventories) {
      var groupId = parentInventory ? parentInventory.id : 'root';
      var updatedInventories = [];
      var notUniqueInventories = getNotUniqueInventories(siblingInventories, inventories);
      var inventoriesNeedToUpdate =
        notUniqueInventories &&
        notUniqueInventories.map(function (inventory) {
          return {
            id: inventory.id,
            name: (inventory.name = makeNameUnique(inventory.name)),
            groupId: groupId
          };
        });

      inventories.forEach(function (inventory) {
        inventory.groupId = groupId;

        updatedInventories.push({
          id: inventory.id,
          groupId: groupId
        });
      });

      if (notUniqueInventories) {
        MessagesService.warning('INVENTORY.NOT_UNIQUE_UPDATED');
        updatedInventories = mergeTwoArraysWithObjectsByIds(
          updatedInventories,
          inventoriesNeedToUpdate
        );
      }

      return vm.inventoryService.updateInventories(updatedInventories, true);
    }

    function updateParentTree(parentInventory, inventories) {
      if (!parentInventory) {
        vm.inventories = vm.inventories.concat(inventories);
      } else {
        parentInventory.inventories = parentInventory.inventories || [];
        parentInventory.inventories = parentInventory.inventories.concat(inventories);
      }
    }

    function getParentSiblingsInventories(nodes) {
      const notNullSiblings = nodes.filter(function (node) {
        return node != null;
      });

      return notNullSiblings.map(function (node) {
        return node.$modelValue;
      });
    }

    function getNotUniqueInventories(siblingInventories, inventories) {
      var notUniqueInventories = [];
      var siblingInventoriesNames = siblingInventories.map(function (siblingInventory) {
        return siblingInventory.name;
      });

      notUniqueInventories = inventories.filter(function (inventory) {
        return siblingInventoriesNames.some(function (name) {
          return name === inventory.name;
        });
      });

      return notUniqueInventories.length ? notUniqueInventories : null;
    }

    function makeNameUnique(name) {
      var timestamp = Date.now();

      return name + ' - ' + timestamp;
    }

    function createFirstInventory() {
      inventorySchema.companyId = companyId;
      vm.inventories = [angular.copy(inventorySchema)];
    }

    function mergeTwoArraysWithObjectsByIds(array1, array2) {
      var biggerArr = array1.length > array2.length ? array1 : array2;
      var smallerArr = array1.length < array2.length ? array1 : array2;

      return biggerArr.map(function (item1) {
        var smallerArrItem = {};

        smallerArr.some(function (item2) {
          return item1.id === item2.id ? (smallerArrItem = item2) : false;
        });

        return angular.extend({}, item1, smallerArrItem);
      });
    }

    function getProductsFromInventory(inv) {
      return inv.products || [];
    }

    function getProductsFromContainers(inv) {
      return (inv.inventoryContainers || []).map(container => ({ id: container.productId }));
    }

    function updateAlertsAfterDropInventory(
      sourceParentInventory,
      newParentAlert,
      oldParentAlert,
      InventoryService,
      droppedInventory,
      alertType
    ) {
      let previousParentAlert =
        sourceParentInventory && sourceParentInventory.alertEnabled[alertType]
          ? sourceParentInventory
          : oldParentAlert;

      if (
        !newParentAlert.alertEnabled[alertType] &&
        (!previousParentAlert || !previousParentAlert.alertEnabled[alertType])
      )
        return;
      if (newParentAlert.id === (previousParentAlert && previousParentAlert.id)) return;

      if (
        (!previousParentAlert || !previousParentAlert.alertEnabled[alertType]) &&
        newParentAlert.alertEnabled[alertType]
      ) {
        return InventoryService.updateSettingsLink(
          newParentAlert,
          newParentAlert,
          droppedInventory,
          alertType
        );
      } else if (
        previousParentAlert &&
        previousParentAlert.alertEnabled[alertType] &&
        !newParentAlert.alertEnabled[alertType]
      ) {
        return InventoryService.removeInventoryAlerts(droppedInventory, previousParentAlert);
      }

      InventoryService.updateSettingsLink(
        previousParentAlert,
        newParentAlert,
        droppedInventory,
        alertType
      );
    }
  }
]);
