import app from '../app';

app.factory('CompanyFactory', function CompanyFactory(Company, CompanyMapping) {
  //initilize some values
  CompanyFactory.selectedCompany = {};
  CompanyFactory.selectedCompany.id = '';
  CompanyFactory.companies = [];

  CompanyFactory.GetCompanies = function (callback, companyId) {
    var filter = {
      filter: {
        include: [
          { relation: 'companyType' },
          { relation: 'childSdsCompanies', scope: { fields: ['id', 'name'] } }
        ]
      }
    };

    if (companyId) filter.filter = { where: { id: companyId } };

    return Company.find(
      filter,
      function (companies) {
        CompanyFactory.companies = [];
        CompanyFactory.companies = companies;

        if (CompanyFactory.selectedCompany.id == '') {
          CompanyFactory.selectedCompany = CompanyFactory.selectedCompany.id
            ? CompanyFactory.selectedCompany
            : CompanyFactory.companies[0];
        } else {
          companies.forEach(function (company) {
            CompanyFactory.selectedCompany = company;
          });
        }

        if (callback) callback(companies);
      },
      function (err) {
        console.log('error getting companies ' + err);
        if (callback) callback(err);
      }
    );
  };

  //gets the mapped role for a userId
  CompanyFactory.GetUserCompanyMapping = function (userId, callback) {
    return CompanyMapping.find(
      { filter: { where: { principalId: userId } } },
      function (companyMap) {
        return callback && callback(null, companyMap[0]);
      },
      function (err) {
        return callback && callback(err, null);
      }
    ).$promise.then(function (companyMap) {
      return companyMap[0];
    });
  };

  //save the users rolemapping
  CompanyFactory.SaveUserCompanyMapping = function (mappedCompany, callback) {
    if (!mappedCompany) {
      return callback({ 'error:': 'no mapped Company' }, null);
    }

    return CompanyMapping.upsert(
      mappedCompany,
      function (updatedMap) {
        return callback && callback(null, updatedMap);
      },
      function (err) {
        return callback && callback(err, null);
      }
    ).$promise;
  };

  CompanyFactory.DeleteUserMapping = function (userId, callback) {
    return CompanyFactory.GetUserCompanyMapping(userId)
      .then(function (mappedCompany) {
        if (!mappedCompany) return callback && callback(null, null);

        const options = {
          id: mappedCompany.id
        };

        return CompanyMapping.deleteById(
          options,
          function (response) {
            callback && callback(null, response);
          },
          function (err) {
            callback && callback(err, null);
          }
        ).$promise;
      })
      .catch(function (err) {
        return callback && callback(err, null);
      });
  };

  return CompanyFactory;
});
