export default function firstAidMeasuresModal(chemicalFriendlyName, firstAidMeasuresInfo) {
  return {
    templateUrl: require('./firstAidMeasuresModal.html'),
    keyboard: true,
    backdrop: true,
    controller: [
      '$uibModalInstance',
      function ($uibModalInstance) {
        const vm = this;
        vm.chemicalFriendlyName = chemicalFriendlyName;
        vm.firstAidMeasuresInfo = firstAidMeasuresInfo;
        vm.dismiss = $uibModalInstance.dismiss;
      }
    ],
    controllerAs: 'ctrl'
  };
}
