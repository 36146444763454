import app from '../app';

app.service('ErrorService', [
  '$q',
  'MessagesService',
  function ($q, MessagesService) {
    this.simpleCatch = function (err) {
      console.error(err);

      const errMessage = err.data
        ? err.data.error.message || err.data.error.errmsg
        : err.result
          ? err.result.error.message
          : err.message
            ? err.message
            : typeof err === 'string'
              ? err
              : 'COMMON.MESSAGES.SOMETHING_WRONG';

      MessagesService.error(errMessage);

      return $q.reject(err);
    };
  }
]);
