import app from '../app';

app.service('SystemSettingService', [
  '$rootScope',
  '$timeout',
  'SystemSetting',
  'toastr',
  'MessagesService',
  'ErrorService',
  function ($rootScope, $timeout, SystemSetting, toastr, MessagesService, ErrorService) {
    const simpleCatch = ErrorService.simpleCatch;
    let settingsPromise = null;
    this.TYPES = {
      notification: { text: 'notification', textClass: 'text-warning', toastr: 'warning' },
      warning: { text: 'warning', textClass: 'text-danger', toastr: 'error' }
    };

    this.getSettings = function (forced) {
      if (!settingsPromise || forced) {
        return (settingsPromise = SystemSetting.get()
          .$promise.then(function (settings) {
            return ($rootScope.systemSettings = settings.toJSON());
          })
          .catch(simpleCatch));
      }

      return settingsPromise;
    };

    this.updateSystemNotifications = function (notifications) {
      return SystemSetting.updateNotifications({}, { notifications: notifications })
        .$promise.then(updatedMessage)
        .catch(simpleCatch);
    };

    this.showNotifications = function () {
      const types = this.TYPES;
      const toastrOptions = {
        closeButton: true,
        positionClass: 'toast-top-full-width', // it's ignored so we set it afterwards
        timeOut: 0,
        extendedTimeOut: 0
      };

      return this.getSettings().then(function (settings) {
        const notifications = settings.notifications || [];

        if (!notifications.length) return;

        notifications.forEach(function (notification) {
          toastr[types[notification.type].toastr](notification.message, toastrOptions);
        });

        $timeout(function () {
          const toastContainer = document.getElementById('toast-container');
          toastContainer.classList.remove('toast-top-center');
          toastContainer.classList.add('toast-top-full-width', 'no-opacity');
        });
      });
    };

    function updatedMessage() {
      return MessagesService.success('COMMON.MESSAGES.UPDATED');
    }
  }
]);
