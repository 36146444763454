import app from '../../app';

app.directive('tagLabel', [
  '$rootScope',
  '$timeout',
  'HelperService',
  function ($rootScope, $timeout, HelperService) {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        tag: '=',
        edit: '='
      },
      templateUrl: require('./tagLabel.html'),
      link: function (scope) {
        scope.dropup = $rootScope.isIe();
        scope.$watch('edit', function (edit) {
          scope.edit = edit;
        });
        scope.tagMouseenter = function ($event, tag) {
          $timeout(function () {
            tag.widthForSafari = 1;
          }, 40);
          tag.holder = true;
        };
        scope.tagMouseout = function ($event, tag) {
          tag.widthForSafari = 0;
          tag.holder = false;
        };
        scope.getStyle = function (tag) {
          return (
            tag.tagColor && {
              background: tag.tagColor,
              color: HelperService.getBrighterColor(tag.tagColor)
            }
          );
        };
      }
    };
  }
]);
