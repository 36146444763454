export default function addSdsRequestModal() {
  return {
    templateUrl: require('./addSdsRequestModal.html'),
    keyboard: true,
    backdrop: true,
    controller: [
      '$uibModalInstance',
      function ($uibModalInstance) {
        const vm = this;
        vm.email = '';
        vm.dismiss = $uibModalInstance.dismiss;
        vm.proceed = function () {
          $uibModalInstance.close({
            email: vm.email
          });
        };
      }
    ],
    controllerAs: 'ctrl'
  };
}
