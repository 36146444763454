import app from '../app';

app.service('GoogleClassroomService', [
  '$q',
  '$timeout',
  function ($q, $timeout) {
    var clientReady = $q.defer();
    var scope;
    // Client ID and API key from the Developer Console
    var CLIENT_ID = '192909373257-pc2potcq6fqk4odjdfc6pp9r32fbk9oj.apps.googleusercontent.com';
    var API_KEY = 'AIzaSyCAy86umhR-thUORadBnmIDip29qKXbUcs';

    // Array of API discovery doc URLs for APIs used by the quickstart
    var DISCOVERY_DOCS = [
      'https://www.googleapis.com/discovery/v1/apis/classroom/v1/rest',
      'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'
    ];

    // Authorization scopes required by the API; multiple scopes can be
    // included, separated by spaces.
    var SCOPES = [
      'https://www.googleapis.com/auth/classroom.courses.readonly',
      'https://www.googleapis.com/auth/classroom.coursework.me',
      'https://www.googleapis.com/auth/classroom.coursework.students',
      'https://www.googleapis.com/auth/drive'
    ].join(' ');

    /**
     *  On load, called to load the auth2 library and API client library.
     */
    this.handleClientLoad = function (scp, signedIn, signedOut) {
      scope = scp || {};

      waitUntilGapiIsReady(function () {
        window.gapi.load('client:auth2', function () {
          initClient(signedIn, signedOut);
        });
      });

      return clientReady.promise;
    };

    this.isSignedIn = function () {
      return window.gapi.auth2.getAuthInstance()
        ? window.gapi.auth2.getAuthInstance().isSignedIn.get()
        : false;
    };

    /**
     *  Initializes the API client library and sets up sign-in state
     *  listeners.
     */
    function initClient(signedIn, signedOut) {
      scope.showClassroomSpinner = true;

      window.gapi.client
        .init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          discoveryDocs: DISCOVERY_DOCS,
          scope: SCOPES
        })
        .then(function () {
          scope.showClassroomSpinner = false;

          // Listen for sign-in state changes.
          window.gapi.auth2.getAuthInstance().isSignedIn.listen(function (isSignedIn) {
            updateSigninStatus(isSignedIn, signedIn, signedOut);
          });

          // Handle the initial sign-in state.
          updateSigninStatus(
            window.gapi.auth2.getAuthInstance().isSignedIn.get(),
            signedIn,
            signedOut
          );

          clientReady.resolve(window.gapi.auth2.getAuthInstance().isSignedIn.get());
        });
    }

    /**
     *  Called when the signed in status changes, to update the UI
     *  appropriately. After a sign-in, the API is called.
     */
    function updateSigninStatus(isSignedIn, signedIn, signedOut) {
      if (isSignedIn) {
        signedIn && signedIn();
      } else {
        signedOut && signedOut();
      }

      scope.$apply && scope.$apply();
    }

    /**
     *  Sign in the user upon button click.
     */
    this.handleAuthClick = function () {
      return window.gapi.auth2.getAuthInstance().signIn();
    };

    /**
     *  Sign out the user upon button click.
     */
    this.handleSignoutClick = function () {
      return window.gapi.auth2.getAuthInstance().signOut();
    };

    /**
     * Print the names of the first 10 courses the user has access to. If
     * no courses are found an appropriate message is printed.
     */
    this.listCourses = function (pageSize) {
      var requestParameters = {};

      if (pageSize) requestParameters.pageSize = pageSize;

      scope.showClassroomSpinner = true;

      return $q(function (resolve, reject) {
        window.gapi.client.classroom.courses.list(requestParameters).then(
          function (res) {
            resolve(res);
            scope.showClassroomSpinner = false;
            scope.$apply && scope.$apply();
          },
          function (err) {
            reject(err);
            scope.showClassroomSpinner = false;
            scope.$apply && scope.$apply();
          }
        );
      });
    };

    this.listCourseWorks = function (courseId) {
      if (!courseId) return $q.reject('Error: lack of courseID');

      var requestParameters = {
        courseId: courseId
      };

      scope.showClassroomSpinner = true;

      return $q(function (resolve, reject) {
        window.gapi.client.classroom.courses.courseWork.list(requestParameters).then(
          function (res) {
            resolve(res);
            scope.showClassroomSpinner = false;
            scope.$apply && scope.$apply();
          },
          function (err) {
            reject(err);
            scope.showClassroomSpinner = false;
            scope.$apply && scope.$apply();
          }
        );
      });
    };

    this.createCourseWork = function (courseId, title) {
      if (!courseId) return $q.reject('Error: lack of courseID');
      if (!title) return $q.reject('Error: lack of coursework title');

      var requestBody = {
        workType: 'ASSIGNMENT',
        state: 'PUBLISHED',
        courseId: courseId,
        title: title
      };

      return $q(function (resolve, reject) {
        window.gapi.client.classroom.courses.courseWork.create(requestBody).then(
          function (res) {
            resolve(res);
            scope.$apply && scope.$apply();
          },
          function (err) {
            reject(err);
            scope.$apply && scope.$apply();
          }
        );
      });
    };

    this.listStudentSubmissions = function (submissionMeta) {
      if (!submissionMeta.courseId || !submissionMeta.courseWorkId)
        return $q.reject('Error: lack of ID');

      var requestParameters = {
        courseId: submissionMeta.courseId,
        courseWorkId: submissionMeta.courseWorkId
      };

      return $q(function (resolve, reject) {
        window.gapi.client.classroom.courses.courseWork.studentSubmissions
          .list(requestParameters)
          .then(
            function (res) {
              resolve(res.result.studentSubmissions);
              scope.$apply && scope.$apply();
            },
            function (err) {
              reject(err);
              scope.$apply && scope.$apply();
            }
          );
      });
    };

    this.modifySubmissionAttachments = function (submissionMeta) {
      if (!submissionMeta.courseId) return $q.reject('Error: lack of courseId');
      if (!submissionMeta.courseWorkId) return $q.reject('Error: lack of courseWorkId');
      if (!submissionMeta.submissionId) return $q.reject('Error: lack of submissionId');
      if (!submissionMeta.documentId) return $q.reject('Error: lack of documentId');

      var requestBody = {
        courseId: submissionMeta.courseId,
        courseWorkId: submissionMeta.courseWorkId,
        id: submissionMeta.submissionId,
        addAttachments: [
          {
            driveFile: {
              id: submissionMeta.documentId
            }
          }
        ]
      };

      return $q(function (resolve, reject) {
        window.gapi.client.classroom.courses.courseWork.studentSubmissions
          .modifyAttachments(requestBody)
          .then(
            function (res) {
              resolve(res);
              scope.$apply && scope.$apply();
            },
            function (err) {
              reject(err);
              scope.$apply && scope.$apply();
            }
          );
      });
    };

    this.turnInSubmission = function (submissionMeta) {
      if (!submissionMeta.courseId) return $q.reject('Error: lack of courseId');
      if (!submissionMeta.courseWorkId) return $q.reject('Error: lack of courseWorkId');
      if (!submissionMeta.submissionId) return $q.reject('Error: lack of submissionId');

      var requestParameters = {
        courseId: submissionMeta.courseId,
        courseWorkId: submissionMeta.courseWorkId,
        id: submissionMeta.submissionId
      };

      return $q(function (resolve, reject) {
        window.gapi.client.classroom.courses.courseWork.studentSubmissions
          .turnIn(requestParameters)
          .then(
            function (res) {
              resolve(res);
              scope.$apply && scope.$apply();
            },
            function (err) {
              reject(err);
              scope.$apply && scope.$apply();
            }
          );
      });
    };

    this.reclaimSubmission = function (submissionMeta) {
      if (!submissionMeta.courseId) return $q.reject('Error: lack of courseId');
      if (!submissionMeta.courseWorkId) return $q.reject('Error: lack of courseWorkId');
      if (!submissionMeta.submissionId) return $q.reject('Error: lack of submissionId');

      var requestParameters = {
        courseId: submissionMeta.courseId,
        courseWorkId: submissionMeta.courseWorkId,
        id: submissionMeta.submissionId
      };

      return $q(function (resolve, reject) {
        window.gapi.client.classroom.courses.courseWork.studentSubmissions
          .reclaim(requestParameters)
          .then(
            function (res) {
              resolve(res);
              scope.$apply && scope.$apply();
            },
            function (err) {
              reject(err);
              scope.$apply && scope.$apply();
            }
          );
      });
    };

    this.createGdocWithResults = function (content, title) {
      var boundary = 'delimiter';
      var delimiter = '\r\n--' + boundary + '\r\n';
      var closedDelimiter = '\r\n--' + boundary + '--';

      var metadata = {
        name: title,
        mimeType: 'application/vnd.google-apps.document'
      };
      var multipartRequestBody =
        delimiter +
        'Content-Type: application/json\r\n\r\n' +
        JSON.stringify(metadata) +
        delimiter +
        'Content-Type: text/html\r\n\r\n' +
        content +
        closedDelimiter;

      return window.gapi.client.request({
        path: 'https://www.googleapis.com/upload/drive/v3/files',
        method: 'POST',
        params: { uploadType: 'multipart' },
        headers: {
          'Content-Type': 'multipart/mixed; boundary=' + boundary
        },
        body: multipartRequestBody
      });
    };

    function waitUntilGapiIsReady(cb) {
      $timeout(function () {
        return window.gapi.load && typeof window.gapi.load === 'function';
      }).then(function (ready) {
        if (ready) {
          cb();
        } else {
          waitUntilGapiIsReady(cb);
        }
      });
    }
  }
]);
