import app from '../../app';

app.directive('cdrFilter', [
  function () {
    return {
      restrict: 'E',
      scope: {
        search: '&onSearch',
        onDateChange: '&onSearch',
        searchCriteria: '=',
        day: '=',
        span: '=',
        msdsFormat: '='
      },
      templateUrl: require('./cdrFilter.html'),
      link: function (scope, elem) {
        scope.day = scope.day || new Date();
        scope.format = 'MMMM d, yy';
        scope.opts = {
          dateDisabled: false,
          formatYear: 'yy',
          maxDate: new Date(),
          showButtonBar: false,
          startingDay: 1
        };
        scope.visible = false;
        scope.toggle = function () {
          scope.visible = !scope.visible;
        };
      }
    };
  }
]);
