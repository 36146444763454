import app from '../app';

app.service('SpinnerService', [
  'usSpinnerService',
  function (usSpinnerService) {
    const ctx = this;
    let spinners = [];
    ctx.key = 'spinner-service';

    this.start = function () {
      usSpinnerService.spin(ctx.key);
    };

    this.stop = function () {
      usSpinnerService.stop(ctx.key);
    };

    /**
     * Wrap around a function,
     * spinner starts before wrapee gets called,
     * and ends after or on error.
     * @param {fn} function that returns Promise.
     * @return function to be called.
     */
    this.wrap = function (fn) {
      return function () {
        if (!spinners.length) ctx.start();

        spinners = spinners.concat(fn);

        return fn
          .apply(null, arguments)
          .catch(function (err) {
            throw err;
          })
          .finally(function () {
            spinners = spinners.filter(spinnerFn => spinnerFn !== fn);

            if (!spinners.length) ctx.stop();
          });
      };
    };
  }
]);
