import angular from 'angular';
import app from '../../app';
import { getOffset } from '../../helpers';

app.controller('portalCtrl', [
  '$rootScope',
  '$location',
  'QRCode',
  'SpinnerService',
  'AuthService',
  'CompanyService',
  function ($rootScope, $location, QRCode, SpinnerService, AuthService, CompanyService) {
    const vm = this;
    const initalGet = SpinnerService.wrap(function initalGet() {
      return getQRcodesCount(function (res) {
        vm.totalItems = res.count;

        return getQRcodes(0, updateQRcodesView);
      });
    });

    vm.noQRerror = false;
    vm.QRcodes = [];
    vm.startFrom = 1;
    vm.PER_PAGE = 12;

    vm.searchQRcodeById = function (id) {
      QRCode.findById(
        { id: id },
        function (res) {
          $location.url('/qrcode/' + id);
        },
        function (err) {
          console.error('ERROR: ', err);

          if (err.status === 404) return (vm.noQRerror = true);
        }
      );
    };

    vm.handlePaginate = SpinnerService.wrap(function () {
      return getQRcodes(getOffset(vm.startFrom, vm.PER_PAGE), updateQRcodesView);
    });

    init();

    function init() {
      if ($rootScope.checkIfUserIs()) {
        initalGet();
      } else {
        CompanyService.getIPWhitelistedPromise().then(initalGet);
      }
    }

    function updateQRcodesView(qrcodes) {
      qrcodes.forEach(function (qrcode) {
        qrcode.picturePreview = qrcode.picture
          ? '/api/containers/qrcode_images/download/' + qrcode.picture
          : '/images/logos/sds-logo.png';
      });

      vm.QRcodes = qrcodes;
    }

    function getQRcodesCount(callback) {
      var whereObj = {
        where: {
          companyId: $rootScope.companyId
        }
      };

      whereObj.where = selectVisibleQRcodes(whereObj.where);

      return QRCode.count(
        whereObj,
        function (count) {
          if (callback) callback(count);
        },
        function (err) {
          console.error('ERROR: ', err);
        }
      ).$promise;
    }

    //get all the QRcodes
    function getQRcodes(start, callback) {
      var filterObj = {
        filter: {
          order: 'order ASC',
          limit: vm.PER_PAGE,
          offset: start,
          where: {
            companyId: $rootScope.companyId
          }
        }
      };

      filterObj.filter.where = selectVisibleQRcodes(filterObj.filter.where);

      return QRCode.find(
        filterObj,
        function (qrcodes) {
          if (callback) callback(qrcodes);
        },
        function (err) {
          console.error('ERROR: ', err);
        }
      ).$promise;
    }

    function selectVisibleQRcodes(obj) {
      var role = AuthService.getUserRole();

      var filter = {
        or: [
          {
            visibility: {
              exists: false
            }
          },
          {
            visibility: role
          }
        ]
      };

      return angular.extend({}, obj, filter);
    }
  }
]);
