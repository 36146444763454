import app from '../../app';

app.directive('inventoryPanel', [
  function (
  ) {
    return {
      restrict: 'E',
      scope: {
        doc: '='
      },
      templateUrl: require('./inventoryPanel.html'),
      link: function (scope, elem) {
        scope.inventories = [];
        scope.inventoryLoad = function (items, tree) {
          scope.inventories = items;
        }
      }
    };
  }
]);
