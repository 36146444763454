export default function confirmMessage(message = '', messageParams = {}) {
  return {
    templateUrl: require('./confirmMessage.html'),
    resolve: {
      message: [
        '$translate',
        function ($translate) {
          return $translate(message || 'COMMON.ACTIONS.CONFIRM', messageParams);
        }
      ]
    },
    controller: [
      '$uibModalInstance',
      'message',
      function ($uibModalInstance, message) {
        var modal = this;
        modal.title = message;
        modal.confirm = $uibModalInstance.close;
        modal.cancel = function () {
          $uibModalInstance.dismiss('cancel');
        };
      }
    ],
    controllerAs: 'ctrl'
  };
}
