import app from '../app';

app.filter('getSdsNameAggregate', [
  '$filter',
  function ($filter) {
    const getFilename = $filter('getFilename'),
      getName = function (name) {
        try {
          return name ? name : '';
        } catch (error) {
          return name;
        }
      };
    return function (doc) {
      const name = getName(doc.chemicalFriendlyName) || getFilename(doc.resourcePath);
      return name;
    };
  }
]);
