﻿import angular from 'angular';
import app from '../../app';
import { mBytesToBytes } from '../../helpers';
import { EKS } from '../../../../node_modules/aws-sdk/index';

app.controller('editAttachmentCtrl', [
  '$scope',
  '$rootScope',
  '$state',
  '$q',
  '$timeout',
  'Attachment',
  'FileUploader',
  'ContainerService',
  'SpinnerService',
  'companies',
  'MessagesService',
  editAttachmentCtrl
]);

function editAttachmentCtrl(
  scope,
  rootScope,
  state,
  q,
  $timeout,
  Attachment,
  FileUploader,
  ContainerService,
  SpinnerService,
  companies,
  MessagesService
) {
  
  const maxFileUploadSize = 15; // size in Mb

  scope.containerService = new ContainerService('attachment');
  scope.removeFile = SpinnerService.wrap(function (item) {
    return scope.containerService.removeFile(`${item.id}${item.link}`);
  });
  scope.itemIsReady = false;
  scope.setItemStatus = function (status) {
    scope.itemIsReady = status;
  };
  scope.attachOptions = {
    typeOfLink: 'link'
  };
  scope.isModal = !!scope.$dismiss;
  scope.close = function (isOK) {
    if (!isOK) {
      scope.attachments.forEach(function (el) {
        if (el.id && el.typeOfLink === 'file') {
          scope.removeFile(el).then(() => {
            return Attachment.destroyById({ id: el.id }).$promise;
          });
        } else if (el.id) {
          Attachment.destroyById({ id: el.id });
        }
      });
    }
    scope.isModal ? scope.$close(isOK ? scope.attachments : []) : historyBack();
  };

  scope.formInvalid = function () {
    if (scope.attachOptions.typeOfLink === 'file') {
      let res = scope.attachOptions.isUploaded
        ? false
        : scope.formAddAttachment.$invalid ||
          (!scope.isUploaded &&
            (scope.uploader.progress !== 100 || scope.uploader.queue.length < 1));

      if (!res) {
        for (let i in scope.uploader.queue) {
          res = !scope.uploader.queue[i].isSuccess;
          if (res) break;
        }
      }
      return res;
    } else if (scope.attachOptions.typeOfLink === 'link') {
      return scope.formAddAttachment.$invalid;
    }
  };

  scope.clearAttachment = function () {
    scope.uploader.queue = [];
    scope.itemIsReady = false;
    scope.attachOptions.title = null;
  };

  scope.addAttachment = SpinnerService.wrap(function () {
    if (scope.formInvalid()) return;

    const obj = angular.extend({}, scope.attachOptions);
    if (!rootScope.checkIfUserIs('admin') && scope.isNew) {
      obj.companies = [{ id: rootScope.companyId, title: scope.attachOptions.title }];
    } else obj.companies = [];
    if (scope.attachOptions.typeOfLink === 'file') {
      obj.externalLink = false;
    } else if (scope.attachOptions.typeOfLink === 'link') {
      obj.externalLink = true;
    }

    obj.sourceId = [scope.sourceId];

    return Attachment.create(obj).$promise.then(function (result) {
      scope.attachOptions = result;
      if (rootScope.checkIfUserIs('admin')) {
        scope.attachOptions.companies = [{ name: 'Global', id: null }];
      }
      scope.attachments.push(scope.attachOptions);
      scope.attachOptions = {
        typeOfLink: scope.attachOptions.typeOfLink
      };
      scope.clearAttachment();
      return true;
    });
  });

  scope.uploader = new FileUploader({
    scope: scope, // to automatically update the html. Default: $rootScope
    url:
      '/api/containers/temporary/uploadFile?access_token=' +
      (rootScope.currentUser ? rootScope.currentUser.tokenId : ''),
    queueLimit: 1,
    formData: [{ key: 'value' }],
    onSuccessItem: function (item, filter, options) {
      scope.attachOptions.uploadedLink = item.file.name;
      scope.itemIsReady = true;
    },
    onWhenAddingFileFailed: function (item, filter, options) {
      if (filter && filter.name === 'enforceMaxFileSize') {
        MessagesService.error('COMMON.MESSAGES.MAX_FILE_SIZE', {
          size: maxFileUploadSize
        });
        scope.clearAttachment();
      }
    },
  });

  scope.uploader.filters.push({
    'name': 'enforceMaxFileSize',
    'fn': function (item) {
        return item.size <= mBytesToBytes(maxFileUploadSize);
    }
  });

  scope.uploader.onAfterAddingFile = function () {
    if (scope.uploader.queue.length > 0) scope.uploader.uploadAll();
  };

  scope.companies = companies;
  scope.saveAttachments = function () {
    const allPromise = [];
    for (let i in scope.attachments) {
      const el = scope.attachments[i];

      el.companies.forEach(function (company) {
        company.title = el.title;
      });

      allPromise.push(
        Attachment.updateAll(
          { where: { id: el.id } },
          {
            title: el.title,
            companies: el.companies.map(function (company) {
              return {
                id: company.id,
                title: el.title
              };
            }),
            sourceId: el.sourceId,
            link: el.link,
            externalLink: el.externalLink
          }
        ).$promise
      );
    }

    const all = q.all(allPromise);
    all.then(function () {
      scope.close(true);
    });
  };

  scope.onChangeUploadFile = function (event) {
    if (!scope.attachOptions.title) {
      const file = event.target.value.split('\\').pop();
      scope.attachOptions.title = file
        .split('.')
        .slice(0, -1)
        .join('.');
    }

    $timeout(function () {
      if (scope.uploader.queue.length > 0) scope.uploader.uploadAll();
    });
  };

  const id =
    (state.current.controller === 'editAttachmentCtrl' && state.params && state.params.id) ||
    undefined;
  if (id) scope.id = id;
  scope.isNew = !id;
  scope.attachments = [];
  const attachment = {};
  if (scope.id) {
    Attachment.findById({ id: scope.id }).$promise.then(function (res) {
      attachment.isUploaded = !res.externalLink && !!res.link;
      attachment.title = res.title;
      attachment.companies = res.companies;
      attachment.id = res.id;
      attachment.sourceId = res.sourceId;
      if (res.externalLink) {
        attachment.typeOfLink = 'link';
        attachment.link = res.link;
      } else {
        attachment.typeOfLink = 'file';
      }
      scope.attachments.push(attachment);
    });
  } else {
    attachment.sourceId = [scope.sourceId];
  }

  function historyBack() {
    if (window.history.length === 1) state.go('search');
    else window.history.back();
  }

  scope.$on('attachmentDelete', function (event, item) {
    let promise = Promise.resolve();

    if (item.typeOfLink === 'file') {
      promise = scope.removeFile(item);
    }

    promise.then(() => {
      scope.attachments.splice(scope.attachments.indexOf(item), 1);

      if (!scope.isModal) historyBack();

      if (item.typeOfLink !== 'file') {
        scope.$apply();
      }
    });
  });
}
