import app from '../../../app';
import scheduleReport from '../../../modals/scheduleReport';

app.controller('sdsAuditLogCtrl', [
  '$rootScope',
  'SystemAuditService',
  'ExportService',
  'SchedulerService',
  'ModalService',
  sdsAuditLogCtrl
]);

function sdsAuditLogCtrl(
  $rootScope,
  SystemAuditService,
  ExportService,
  SchedulerService,
  ModalService
) {
  const vm = this;
  const objectTypes = ['user', 'sds', 'attr', 'qrcode', 'equipment', 'quiz', 'attachment'];

  vm.title = 'REPORTS.AUDIT_LOG.TITLE';
  vm.sas = new SystemAuditService(objectTypes);

  vm.handleExport = function () {
    return vm.sas.getRecords2Export().then(function (records) {
      return ExportService.exportSystemAudit2csv(records, 'sds-audit-report.csv');
    });
  };

  vm.openScheduleModal = function () {
    return ModalService.open(scheduleReport()).then(res => {
      if (
        $rootScope.checkIfUserIs('admin') &&
        (!vm.sas.selectedCompany || !vm.sas.selectedCompany.id)
      ) {
        return SchedulerService.createReportForAllCompanies(
          res,
          vm.sas.companies,
          { objectTypes },
          createScheduledReport
        );
      } else {
        return createScheduledReport(res, $rootScope.companyId, { objectTypes });
      }
    });
  };

  function createScheduledReport(formObj, companyId, params = {}, quiet = false) {
    return SchedulerService.createReport(
      SchedulerService.jobs.report.SDS_AUDIT_REPORT,
      companyId,
      formObj.emails,
      formObj.frequency,
      params,
      quiet
    );
  }
}
