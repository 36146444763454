import app from '../../app';

app.directive('docHazards', [
  'HazardService',
  function (HazardService) {
    return {
      restrict: 'E',
      transclude: true,
      scope: {
        hazardType: '='
      },
      templateUrl: require('./docHazards.html'),
      link: function (scope) {
        scope.quickFilter = function (obj, filterClass) {
          scope.$emit('onQuickFilter', { obj: obj, filterClass: filterClass });
        };
        scope.HazardService = HazardService;
      }
    };
  }
]);
