export default function ppeSelectionModal({
  companyId,
  sdsId,
  ppeSelected,
  ppe_private_companyIds,
  publicPpes
}) {
  return {
    templateUrl: require('./ppeSelectionModal.html'),
    keyboard: true,
    backdrop: true,
    controllerAs: 'ctrl',
    controller: [
      '$uibModalInstance',
      'SolrDocument',
      'SpinnerService',
      'PpeService',
      function ($uibModalInstance, SolrDocument, SpinnerService, PpeService) {
        const vm = this;
        vm.isChanged = false;
        vm.PpeService = PpeService;
        vm.ppeSelection = ppeSelected || [];
        vm.ppe_private_companyIds = ppe_private_companyIds || [];
        vm.isPublicPpes = !vm.ppe_private_companyIds.includes(companyId);
        vm.publicPpes = publicPpes || [];
        vm.cancel = $uibModalInstance.dismiss;
        vm.save = SpinnerService.wrap(() => {
          const idx = vm.ppe_private_companyIds.indexOf(companyId);
          if (idx === -1) {
            vm.ppe_private_companyIds.push(companyId);
          }
          const payload = {
            id: sdsId,
            [`ppe_${companyId}`]: { set: vm.ppeSelection },
            ppe_private_companyIds: { set: vm.ppe_private_companyIds }
          };
          const postData = { data: '[' + JSON.stringify(payload) + ']' };
          return SolrDocument.update(postData).$promise.then(() =>
            $uibModalInstance.close({
              ppeSelected: vm.ppeSelection,
              ppe_private_companyIds: vm.ppe_private_companyIds
            })
          );
        });
        vm.resetToPublicPpe = SpinnerService.wrap(() => {
          const idx = vm.ppe_private_companyIds.indexOf(companyId);
          if (idx > -1) {
            vm.ppe_private_companyIds.splice(idx, 1);
          }
          const payload = {
            id: sdsId,
            [`ppe_${companyId}`]: { set: vm.publicPpes },
            ppe_private_companyIds: { set: vm.ppe_private_companyIds }
          };
          const postData = { data: '[' + JSON.stringify(payload) + ']' };
          return SolrDocument.update(postData).$promise.then(() =>
            $uibModalInstance.close({
              ppeSelected: vm.publicPpes,
              ppe_private_companyIds: vm.ppe_private_companyIds
            })
          );
        });
        vm.togglePPE = function (ppe) {
          const idx = vm.ppeSelection.indexOf(ppe);
          if (idx > -1) {
            vm.ppeSelection.splice(idx, 1);
          } else {
            vm.ppeSelection.push(ppe);
          }
          vm.ppeSelection = vm.ppeSelection.sort();
          vm.isChanged = true;
        };
      }
    ]
  };
}
