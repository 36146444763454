import app from '../app';

app.service('CookieManager', [
  '$cookies',
  function ($cookies) {
    this.removeCookies = function (entries) {
      const oldDomain = window.location.hostname
        .split('.')
        .reverse()
        .slice(0, 2)
        .concat(null)
        .reverse()
        .join('.');
      entries = entries || [
        'currentUser',
        'rememberMe',
        'username',
        'userLang'
      ];
      entries.forEach(function (entry) {
        $cookies.remove(entry);
        $cookies.remove(entry, { domain: oldDomain });
      });
    };
  }
]);
