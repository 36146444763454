import app from '../../app';

app.directive('searchPageLinks', [
  '$rootScope',
  function ($rootScope) {
    return {
      restrict: 'E',
      transclude: true,
      scope: {
        links: '='
      },
      templateUrl: require('./searchPageLinks.html'),
      link: function (scope) {
        scope.searchPageLinks = $rootScope.companySettings.searchPageLinks || [];
        $rootScope.$watch('companySettings', function (companySettings) {
          scope.searchPageLinks = companySettings.searchPageLinks || [];
        });
      }
    };
  }
]);
