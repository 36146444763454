import app from '../app';

app.directive('file', ['$translate', 'MessagesService',
  function ($translate, MessagesService) {
    return {
      scope: {
        fileread: '=',
        accept: '@'//check list of mime types
      },
      link: function (scope, element) {
        element.bind('change', function (changeEvent) {
          if (scope.accept && !scope.accept.split(',').includes(element[0].files[0].type)) {
            element[0].value = '';
            MessagesService.error($translate.instant('COMMON.MESSAGES.WRONG_FILE_TYPE'));
          }

          scope.$apply();
        });
      }
    };
  }
]);
