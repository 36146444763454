import app from '../../app';

app.controller('denyRequestModalCtrl', ['$uibModalInstance', 'title', denyRequestModalCtrl]);

function denyRequestModalCtrl($uibModalInstance, title) {
  const modal = this;

  modal.title = title;
  modal.note = '';
  modal.cancel = $uibModalInstance.dismiss;

  modal.confirm = function () {
    $uibModalInstance.close(modal.note);
  };
}
