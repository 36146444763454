import app from '../../app';

app.directive('selectItemsToAdd', [
  function () {
    return {
      restrict: 'E',
      templateUrl: require('./selectItemsToAdd.html'),
      scope: {
        label: '=',
        itemType: '=',
        items: '=',
        onSelect: '='
      },
      link: function (scope, element, attrs) {
        scope.handleSelect = function (container) {
          scope.onSelect(container);
        };
      }
    };
  }
]);
