import app from '../../app';

app.directive('manageInventoryContent',
  function (SolrDocumentService, ModalService, InventoryService, ProductService, ContainerService) {
    let inventoriesShowContent = InventoryService.inventoriesShowContent;
    let contentItems = {};

    return {
      restrict: 'E',
      scope: {
        inventory: '=',
        inventories: '=',
        inventoryService: '=',
        companySettings: '='
      },
      templateUrl: require('./manageInventoryContent.html'),
      link: function (scope, element, attrs) {
        const currentInventoryId = scope.inventory.id;
        const companyId = scope.inventory.companyId;

        scope.isLoading = true;
        scope.showOnlyAmount = isGrouping(scope.inventory);
        scope.handleSDSModal = SolrDocumentService.openModalOrSDSbyProduct;

        scope.handleChangeAmount = function (item, oldAmount, relationName) {
          if (scope.showOnlyAmount) return;
          if (!validateAmount(item, oldAmount)) return;

          scope.inventoryService.updateAmountOfItemsInInventories(
            item,
            scope.inventory,
            relationName
          );
        };

        scope.checkIfInventoryOwnItem = function (inventory, item, relationName) {
          if (!inventory[relationName] || !inventory[relationName].length) return false;

          return inventory[relationName].filter(function (inventoryItem) {
            return inventoryItem.id === item.id;
          }).length;
        };

        scope.handleItemUnlink = function (unlinkedItem, relationName) {
          ModalService.open({
            templateUrl: require('../../../views/modals/confirm-message.html'),
            resolve: {
              message: [
                '$translate',
                function ($translate) {
                  return $translate('COMMON.ACTIONS.CONFIRM_DELETE.MESSAGE');
                }
              ]
            },
            controller: [
              '$uibModalInstance',
              'message',
              function ($uibModalInstance, message) {
                var modal = this;
                modal.title = message;
                modal.confirm = $uibModalInstance.close;
                modal.cancel = function () {
                  $uibModalInstance.dismiss('cancel');
                };
              }
            ]
          })
            .then(function () {
              return scope.inventoryService.unlinkItemsFromInventory(
                unlinkedItem,
                scope.inventory,
                relationName
              );
            })
            .then(function () {
              scope[relationName] = scope[relationName].filter(function (item) {
                return item.id !== unlinkedItem.id;
              });

              scope.inventoryService.refreshTotalItemsOfInventory(
                scope.inventory,
                scope.inventories
              );
            });
        };

        scope.isProductBanned = function (product) {
          return ProductService.isProductBanned(product, companyId);
        };

        scope.handleSelect = loadContentItems;

        scope.getFileLink = filename => {
          return ContainerService.getFileLink('inventory_files', filename);
        };

        loadContentItems('products');

        function isGrouping(inventory) {
          return inventory.inventories && inventory.inventories.length;
        }

        function loadContentItems(relationName) {
          const lastOpenedInventoryId = inventoriesShowContent.getLastOpenedInventoryId();

          if (lastOpenedInventoryId == null) delete contentItems[currentInventoryId];

          if (
            lastOpenedInventoryId === currentInventoryId &&
            contentItems[currentInventoryId] &&
            contentItems[currentInventoryId][relationName]
          ) {
            scope.isLoading = false;
            scope[relationName] = contentItems[currentInventoryId][relationName];
          } else {
            scope.isLoading = true;
            contentItems[currentInventoryId] = contentItems[currentInventoryId] || {};

            scope.inventoryService
              .getContentItems(scope.inventory, relationName)
              .then(function (items) {
                scope.isLoading = false;
                scope[relationName] = contentItems[currentInventoryId][relationName] = items;

                if (
                  lastOpenedInventoryId !== currentInventoryId &&
                  contentItems[lastOpenedInventoryId]
                )
                  delete contentItems[lastOpenedInventoryId][relationName];

                inventoriesShowContent.setLastOpenedInventoryId(currentInventoryId);
              });
          }
        }

        function validateAmount(obj, oldAmount) {
          oldAmount = oldAmount || 0;

          if (obj.amount < 0 || obj.amount == undefined) {
            obj.amount = oldAmount;

            return false;
          }

          return true;
        }
      }
    };
  }
);
