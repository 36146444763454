import app from '../../app';

app.controller('locationRequestsCtrl', ['LocationRequestService', 'ModalService', locationRequestsCtrl]);

function locationRequestsCtrl(LocationRequestService, ModalService) {
  const vm = this;

  vm.title = 'REPORTS.LOCATION_REQUESTS.TITLE';
  vm.requestService = LocationRequestService;

  LocationRequestService.init();

  vm.showHistory = function (e, request) {
    e.stopPropagation();

    return ModalService.open({
      keyboard: true,
      backdrop: true,
      controller: 'historyRequestModalCtrl',
      templateUrl: require('../historyRequestModalCtrl/history-sds-request.html'),
      resolve: {
        title: function () {
          return request.name;
        },
        history: [
          'LocationRequestService',
          function (LocationRequestService) {
            return LocationRequestService.getHistory(request.id);
          }
        ]
      }
    });
  };

  vm.showRequest = function (request) {
    return ModalService.open({
      keyboard: true,
      backdrop: true,
      controller: 'requestModalCtrl',
      templateUrl: require('../requestModalCtrl/sds-request.html'),
      resolve: {
        request: function () {
          return request;
        },
        behaviourOptions: function () {
          return {
            service: LocationRequestService,
            disableTagsSelection: true
          };
        }
      }
    }).then(function (statusCode) {
      request.status = statusCode;
    });
  };
}
