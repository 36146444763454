import { flatWoods } from '../../helpers';

export default function selectInventory(
  companyId,
  {
    checkedInventory = [],
    message = 'INVENTORY.SELECT_ADD',
    messageStyles = {},
    selectAllOnInit = false,
    emptyForAll = false
  } = {}
) {
  return {
    resolve: {
      translatedMessage: [
        '$translate',
        function ($translate) {
          if (message === null || message === '') return message;

          return $translate(message);
        }
      ]
    },
    templateUrl: require('./selectInventory.html'),
    keyboard: true,
    backdrop: true,
    controllerAs: 'vm',
    controller: [
      'translatedMessage',
      '$uibModalInstance',
      'InventoryService',
      'SpinnerService',
      'MAX_INT',
      function (translatedMessage, $uibModalInstance, InventoryService, SpinnerService, MAX_INT) {
        const vm = this;
        const inventoryService = new InventoryService(companyId);
        const getInventory = SpinnerService.wrap(async function () {
          vm.items = await inventoryService.getInventories(MAX_INT);
          vm.flatItems = flatWoods(vm.items, 'inventories');

          applyCheckedItems(vm.flatItems, checkedInventory);
          areAllItemsSelected();
        });

        vm.message = translatedMessage;
        vm.messageStyles = messageStyles;
        vm.companyId = companyId;
        vm.flatItems = [];
        vm.items = [];
        vm.allItemsSelected = false;
        vm.dismiss = $uibModalInstance.dismiss;
        vm.onSelect = item => {
          toggleAllSubItems(item, 'inventories');
          areAllItemsSelected();
        };
        vm.toggleSelection = bool => {
          vm.flatItems.forEach(item => (item.checked = bool));
        };
        vm.toggleTree = function (event, uiTreeNode) {
          if (event.target instanceof HTMLInputElement) return;
          uiTreeNode.toggle();
        };
        vm.save = () => {
          const selected = vm.allItemsSelected && emptyForAll
            ? []
            : vm.flatItems.filter(item => item.checked);
          $uibModalInstance.close(selected);
        };

        init();

        async function init() {
          await getInventory();

          if (selectAllOnInit) {
            vm.toggleSelection(true);
            vm.allItemsSelected = true;
          }
        }

        function areAllItemsSelected() {
          vm.allItemsSelected = vm.flatItems.every(item => item.checked);
        }
      }
    ]
  };
}

function applyCheckedItems(flatItems, checkedItems, key = 'id') {
  checkedItems.forEach(cItem => {
    flatItems.forEach(item => item[key] === cItem[key] && (item.checked = true));
  });
}

function toggleAllSubItems(item, childName = '') {
  (item[childName] || []).forEach(subItem => {
    subItem.checked = item.checked;

    toggleAllSubItems(subItem, childName);
  });
}
