import angular from 'angular';
import app from '../app';
import { makeObjectWithIdsFromArrayOfObjects, clearObject } from '../helpers';
import { sortNameCaseInsensitive } from '../../../common/helpers/sortNameCaseInsensitive';

app.service('ManufacturerService', [
  '$q',
  'Manufacturer',
  'MessagesService',
  'ErrorService',
  function ($q, Manufacturer, MessagesService, ErrorService) {
    var simpleCatch = ErrorService.simpleCatch;
    var cachedManufacturers;

    this.find = function (customFilter) {
      var filter = { filter: { order: 'name ASC', include: 'products' } };

      customFilter && angular.extend(filter.filter, customFilter.filter);

      return Manufacturer.find(filter)
        .$promise.then(sortCaseInsensitive)
        .then(makeObjectWithIdsFromArrayOfObjects)
        .then(function (manufacturers) {
          return (cachedManufacturers = manufacturers);
        })
        .catch(simpleCatch);
    };

    this.manufacturersPromise = function () {
      return cachedManufacturers ? $q.resolve(cachedManufacturers) : this.find();
    };

    this.create = function (manufacturerObj) {
      return Manufacturer.create(manufacturerObj)
        .$promise.then(function () {
          MessagesService.success('COMMON.MESSAGES.ADDED');
          clearObject(manufacturerObj);
        })
        .catch(simpleCatch);
    };

    this.deleteById = function (id) {
      return Manufacturer.deleteById({ id: id })
        .$promise.then(function () {
          MessagesService.success('COMMON.MESSAGES.DELETED');
        })
        .catch(simpleCatch);
    };

    this.updateName = function (id, name) {
      return Manufacturer.prototype$updateAttributes({ id: id }, { name: name })
        .$promise.then(function () {
          MessagesService.success('COMMON.MESSAGES.UPDATED');
        })
        .catch(simpleCatch);
    };

    // since MongoDB until version 3.4 doesn't support case insensitive index - https://jira.mongodb.org/browse/SERVER-90
    // and there is no easy way to make it happen
    // we just sort Manufacturers on the client side
    function sortCaseInsensitive(arr) {
      return arr.sort(sortNameCaseInsensitive);
    }
  }
]);
