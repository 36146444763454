import app from '../../app';

app.directive('nonRegulatedMessage', [
  '$rootScope',
  '$translate',
  '$q',
  function ($rootScope, $translate, $q) {
    return {
      restrict: 'E',
      scope: {
        product: '='
      },
      templateUrl: require('./nonRegulatedMessage.html'),
      link: function (scope) {
        const PRODUCTS_TO_SHOW = 5,
          composeXmoreText = function () {
            const totalProducts = scope.product.length;
            const xMoreProducts =
              totalProducts > PRODUCTS_TO_SHOW ? totalProducts - PRODUCTS_TO_SHOW : 0;
            return xMoreProducts
              ? ' ' +
                  $translate.instant('COMMON.MESSAGES.AND_X_MORE', {
                    x: xMoreProducts
                  })
              : '';
          },
          composeTemplate = function (template, value) {
            return template
              .replace('{product/s}', value + composeXmoreText())
              .replace('{is/are}', scope.product.length > 1 ? 'are' : 'is');
          },
          trKey = 'COMMON.MESSAGES.NON_REGULATED',
          composeGeneric = function (value) {
            return $translate([trKey], {
              name: value + composeXmoreText(),
              isAre: scope.product.length > 1 ? 'are' : 'is'
            }).then(function (tr) {
              return tr[trKey];
            });
          };

        let template = ($rootScope.companySettings || {})[`nonRegulatedMessage_${$rootScope.siteLanguage}`]
          || ($rootScope.companySettings || {}).nonRegulatedMessage;

        const composeNames = function (products) {
            return products
              .reduce(function (names, productName, index) {
                return index < PRODUCTS_TO_SHOW ? (names += '"' + productName + '", ') : names;
              }, '')
              .replace(/,\s$/, '');
          },
          composeMsg = function () {
            const names = composeNames(scope.product);
            return (template
              ? $q.resolve(composeTemplate(template, names))
              : composeGeneric(names)
            ).then(function (msg) {
              scope.msg = msg;
            });
          };
        scope.$watch('product', function (product) {
          scope.product = product;
          composeMsg();
        });

        $rootScope.$watch('siteLanguage', function (newValue, oldValue) {
          if (newValue === oldValue) return;
          template = ($rootScope.companySettings || {})[`nonRegulatedMessage_${$rootScope.siteLanguage}`]
            || ($rootScope.companySettings || {}).nonRegulatedMessage;
          
          composeMsg();
        });
      }
    };
  }
]);
