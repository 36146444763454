import app from '../../app';

app.directive('poweredLogo', [
  '$rootScope',
  '$injector',
  'CompanyService',
  function ($rootScope, $injector, CompanyService) {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: require('./poweredLogo.html'),
      link: function (scope) {
        CompanyService.getCurrentCompanyPromise().$promise.then(function () {
          const sps = $injector.get('SearchPageService');

          scope.isVisible = function () {
            return (
              $rootScope.isActive('login') ||
              ($rootScope.isActive('search') && !sps.search.meta.query)
            );
          };
        });
      }
    };
  }
]);
