import app from '../app';

app.filter('getSdsIdAggregate', [
  '$rootScope',
  function ($rootScope) {
    return function (doc) {
      const lang = $rootScope.siteLanguage,
        same = ~doc.language.indexOf(lang);

      return doc[`${lang}VersionId`] || (same && doc._id.SDS) || doc.enVersionId || doc._id.SDS;
    };
  }
]);
