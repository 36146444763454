import app from '../../app';

app
  .controller('changePasswordCtrl', [
    '$scope',
    '$state',
    '$rootScope',
    '$timeout',
    '$translate',
    'SdsUser',
    'LoopBackAuth',
    'MessagesService',
    function ($scope, $state, $rootScope, $timeout, $translate, SdsUser, LoopBackAuth, MessagesService) {
      $scope.password = '';
      $scope.errorMessage = '';
      $scope.readyToChange = true;
      if ($state.params.access_token) {
        if (!$rootScope.currentUser) {
          $scope.readyToChange = false;
          SdsUser.identifyByToken({ token: $state.params.access_token })
            .$promise.then(function (data) {
              $scope.readyToChange = true;
              $scope.user = {
                id: data.id
              };
              LoopBackAuth.accessTokenId = $state.params.access_token;
              LoopBackAuth.currentUserId = data.id;
            })
            .catch(function (err) {
              $scope.errorMessage = err.data.error.message || err;
            });
        } else $state.go('search');
      }

      $scope.dismiss = function () {
        if ($rootScope.enforcePasswordChange) {
          $state.go('logout');
          if ($scope.$dismiss) $scope.$dismiss();
        } else {
          if ($scope.$dismiss) $scope.$dismiss();
          else $state.go('search');
        }
      };

      $scope.saveUser = function () {
        const userToSave = $scope.user ? { id: $scope.user.id } : { id: $rootScope.currentUser.id };
        userToSave.password = $scope.password;

        $scope.errorMessage = '';
        SdsUser.resetPasswordByToken(
          userToSave,
          function (updatedUser) {
            MessagesService.success('COMMON.MESSAGES.UPDATED');
            if ($rootScope.enforcePasswordChange) {
              $rootScope.enforcePasswordChange = false;
            }
            if ($scope.$close) {
              $timeout($scope.$close, 300);
            }
            else $state.go('login');
          },
          function (err) {
            console.log('ERROR : ' + JSON.stringify(err));
            $translate('COMMON.MESSAGES.' + err.data.error.message).then(function (translationsObj) {
              $scope.errorMessage = translationsObj;
            });
          }
        );
      };
    }
  ]);
