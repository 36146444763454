const { localVersion } = require('./index');

const legislations = [
  {
    code: 'AUS',
    country: 'Australia'
  },
  {
    code: 'BRA',
    country: 'Brazil'
  },
  {
    code: 'CAN',
    country: 'Canada'
  },
  {
    code: 'CHN',
    country: 'China'
  },
  {
    code: 'EU',
    country: 'Europe'
  },
  {
    code: 'IDN',
    country: 'Indonesia'
  },
  {
    code: 'JPN',
    country: 'Japan'
  },
  {
    code: 'KOR',
    country: 'Korea'
  },
  {
    code: 'MYS',
    country: 'Malaysia'
  },
  {
    code: 'MEX',
    country: 'Mexico'
  },
  {
    code: 'NZL',
    country: 'NZ'
  },
  {
    code: 'PHL',
    country: 'Philippines'
  },
  {
    code: 'TWN',
    country: 'Taiwan'
  },
  {
    code: 'THA',
    country: 'Thailand'
  },
  {
    code: 'TUR',
    country: 'Turkey'
  },
  {
    code: 'GBR',
    country: 'United Kingdom'
  },
  {
    code: 'USA',
    country: 'United States of America'
  },
  {
    code: 'VNM',
    country: 'Vietnam'
  }
];
let defaultLegislationCode;

const setDefaultLegislationCode = localeCode => {
  switch (localeCode) {
    case 'CA':
      defaultLegislationCode = 'CAN';
      break;
    case 'US':
      defaultLegislationCode = 'USA';
      break;
  }
};
const getDefaultLegislation = () => legislations.find(l => l.code === defaultLegislationCode);
const getLegislationByCode = code =>
  legislations.find(l => l.code === code) || { code, country: 'UNKNOWN' };
const getLegislationCountryByCode = code => getLegislationByCode(code).country;

setDefaultLegislationCode(localVersion());

module.exports = {
  legislations,
  setDefaultLegislationCode,
  getDefaultLegislation,
  getLegislationByCode,
  getLegislationCountryByCode
};
