import app from '../app';
import { decodeDocumentsChemicalName, getOffset, getSeconds } from '../helpers';

app.service('SolrDocumentService', [
  '$rootScope',
  '$state',
  '$window',
  '$q',
  'CompanyService',
  'LanguageService',
  'ModalService',
  'SolrDocument',
  'SpinnerService',
  function (
    $rootScope,
    $state,
    $window,
    $q,
    CompanyService,
    LanguageService,
    ModalService,
    SolrDocument,
    SpinnerService
  ) {
    this.getDiscontinuedSdss = SpinnerService.wrap(async function (
      companyId,
      { offset, limit, dateFrom, dateTo, availableLanguages }
    ) {
      let from;
      let to;

      if (dateFrom && dateTo) {
        from = getSeconds(dateFrom);
        to = getSeconds(dateTo);
      }

      const data = await SolrDocument.getDiscontinuedSdss({
        companyId,
        offset,
        limit,
        from,
        to,
        availableLanguages
      }).$promise;

      return data;
    });

    this.openModalOrSDSbyProduct = function (product) {
      const lang = LanguageService.getCurrentLanguage();
      const PER_PAGE = 20;
      const offset = 0;

      return searchSdsByProductId(product.id, offset, PER_PAGE, lang).then(function (sdsDocuments) {
        if (sdsDocuments.docs && sdsDocuments.docs.length === 1) {
          const url = $state.href('viewSds', { id: sdsDocuments.docs[0].id });

          $window.open(url, '_blank');
        } else {
          ModalService.open({
            templateUrl: require('../../views/modals/sds-with-product.html'),
            controller: [
              '$uibModalInstance',
              function ($uibModalInstance) {
                var modal = this;

                modal.startFrom = 1;
                modal.PER_PAGE = PER_PAGE;
                modal.sdsDocuments = sdsDocuments;

                modal.handlePaginate = SpinnerService.wrap(function () {
                  return searchSdsByProductId(
                    product.id,
                    getOffset(modal.startFrom, PER_PAGE),
                    PER_PAGE,
                    lang
                  ).then(function (sdsDocuments) {
                    modal.sdsDocuments = sdsDocuments;
                  });
                });

                modal.cancel = function () {
                  $uibModalInstance.dismiss('cancel');
                };
              }
            ]
          });
        }
      });
    };

    this.getCompanyPrintableAttrs = SpinnerService.wrap(function (sdsId, companyId) {
      return $q
        .all([
          SolrDocument.getCompanyAttrs({ id: sdsId, companyId }).$promise,
          CompanyService.getCurrentCompanyPromise().$promise
        ])
        .then(([{ attrs }]) => {
          return Object.keys(attrs).reduce((sum, attrKey) => {
            if (
              $rootScope.companySettings.printableAttrs &&
              !$rootScope.companySettings.printableAttrs.includes(attrKey)
            )
              return sum;

            return sum.concat(`[${attrKey}: ${attrs[attrKey]}]`);
          }, []);
        });
    });

    function searchSdsByProductId(productId, offset, PER_PAGE, lang) {
      var searchParams = {
        searchParams: [
          {
            searchKey: 'products',
            searchTerms: productId
          }
        ],
        fieldList: 'id, chemicalFriendlyName, resourcePath',
        start: offset,
        rows: PER_PAGE,
        filter: '-isArchived:true, language:' + lang
      };

      searchParams.searchParams = JSON.stringify(searchParams.searchParams);

      return SolrDocument.search(searchParams).$promise.then(function (response) {
        var docs = response.response.docs;
        var numFound = response.response.numFound;

        return {
          docs: decodeDocumentsChemicalName(docs),
          totalItems: numFound
        };
      });
    }
  }
]);
