import app from '../../app';
import confirmMessage from '../../modals/confirmMessage';
import listSdss from '../../modals/listSdss';

app.controller('manufacturersCtrl', [
  'ModalService',
  'ManufacturerService',
  'SpinnerService',
  manufacturersCtrl
]);

function manufacturersCtrl(ModalService, ManufacturerService, SpinnerService) {
  const vm = this;
  const getManufacturers = SpinnerService.wrap(function () {
    return ManufacturerService.find().then(manufacturers => (vm.manufacturers = manufacturers));
  });

  vm.manufacturers = [];

  vm.addManufacturer = function (manufacturer) {
    vm.isLoading = true;

    ManufacturerService.create(manufacturer)
      .then(function () {
        vm.newManufacturer_form.$setUntouched();
        vm.newManufacturer_form.$setPristine();
      })
      .then(refreshManufacturers);
  };

  vm.updateManufacturerName = function (manufacturer, name) {
    vm.isLoading = true;

    ManufacturerService.updateName(manufacturer.id, name).finally(refreshManufacturers);
  };

  vm.showProducts = function (products) {
    ModalService.open({
      templateUrl: require('../../../views/modals/list-of-products.html'),
      controller: [
        '$uibModalInstance',
        'SolrDocumentService',
        function ($uibModalInstance, SolrDocumentService) {
          var modal = this;
          modal.products = products;
          modal.showSds = true;

          modal.handleSDSModal = SolrDocumentService.openModalOrSDSbyProduct;

          modal.cancel = function () {
            $uibModalInstance.dismiss('cancel');
          };
        }
      ]
    });
  };

  vm.showSdss = function (manufacturer) {
    const manufacturerFilter = { field: 'manufacturer', value: manufacturer.id };

    return ModalService.open(listSdss({ filters: [manufacturerFilter] }));
  };

  vm.onDelete = function (manufacturer) {
    return ModalService.open(
      confirmMessage('COMMON.ACTIONS.CONFIRM_DELETE.TITLE', {
        name: manufacturer.name
      })
    ).then(function () {
      deleteManufacturerById(manufacturer.id);
    });
  };

  init();

  async function init() {
    await getManufacturers();
  }

  function refreshManufacturers() {
    return ManufacturerService.find()
      .then(function (manufacturers) {
        vm.isLoading = false;

        return (vm.manufacturers = manufacturers);
      })
      .catch(function () {
        vm.isLoading = false;
      });
  }

  function deleteManufacturerById(id) {
    vm.isLoading = true;

    return ManufacturerService.deleteById(id).then(refreshManufacturers);
  }
}
