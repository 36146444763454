import app from '../../app';

app.directive('pinnedInventories', [
  function () {
    return {
      restrict: 'E',
      scope: {
        inventories: '=',
        companyId: '='
      },
      templateUrl: require('./pinnedInventories.html'),
      link: function (scope) {
        scope.inventoriesTree = [];
        const structurize = function (inventories) {
          scope.inventoriesTree = inventories.filter(el => el.pinned);

          return scope.inventoriesTree;
        };
        scope.$watch('inventories', function (inventories) {
          scope.inventoriesTree = structurize(inventories);
        });
        scope.$watch('companyId', function (companyId) {
          scope.companyId = companyId;
        });
      }
    };
  }
]);
