import angular from 'angular';
import app from '../../app';
import { validateAmount } from '../../helpers';

app.controller('equipmentCtrl', [
  '$scope',
  '$rootScope',
  'EquipmentService',
  'FileUploader',
  '$translate',
  'Container',
  'QRCode',
  '$filter',
  'MessagesService',
  'InventoryService',
  'ModalService',
  'CompanyService',
  function (
    $scope,
    $rootScope,
    EquipmentService,
    FileUploader,
    $translate,
    Container,
    QRCode,
    $filter,
    MessagesService,
    InventoryService,
    ModalService,
    CompanyService
  ) {
    var vm = this;
    var translations = {};
    var fileRemovingQueue = [];
    var companyId = '';
    var qrcodes = {};
    var generateUniqueID = function () {
      return $filter('generateUniqueID')(10) + '_';
    };

    // the equipment item model that will be added to the db
    vm.equipmentItem = {
      name: '',
      companyId: '',
      documents: []
    };

    vm.fileTypeError = false;

    var uploader = (vm.uploader = new FileUploader({
      url: '/api/containers/equipment/uploadFile?access_token=' + $rootScope.currentUser.tokenId,
      removeAfterUpload: true,
      formData: [{ key: 'value' }]
    }));

    var uploaderEditing = (vm.uploaderEditing = new FileUploader({
      url: '/api/containers/equipment/uploadFile?access_token=' + $rootScope.currentUser.tokenId,
      removeAfterUpload: true,
      formData: [{ key: 'value' }]
    }));

    var fileFormatFilter = {
      name: 'fileFormatFilter',
      fn: function (item) {
        return /\.(?:pdf|jpe?g|png)$/i.test(item.name);
      }
    };

    uploader.filters.push(fileFormatFilter);
    uploaderEditing.filters.push(fileFormatFilter);

    uploader.onAfterAddingFile = function (item) {
      onAfterAddingFileHandler(item, vm.equipmentDocument);
    };

    uploaderEditing.onAfterAddingFile = function (item) {
      onAfterAddingFileHandler(item, vm.editingEquipmentDocument);
    };

    uploader.onWhenAddingFileFailed = uploaderEditing.onWhenAddingFileFailed = function (
      item /*{File|FileLikeObject}*/,
      filter,
      options
    ) {
      if (filter.name === 'fileFormatFilter') {
        vm.fileTypeError = true;
      }
    };

    vm.onCompanySelected = function (selectedCompany) {
      companyId = vm.equipmentItem.companyId = selectedCompany.id;

      if (
        !$rootScope.checkIfUserIs('admin') &&
        selectedCompany.settings &&
        !$rootScope.isModuleEnabled($rootScope.moduleNames.hse, false, selectedCompany.settings)
      ) {
        return MessagesService.warning('SETTINGS.MODULE_REQUIRED', {
          moduleName: $rootScope.moduleNames.hse
        });
      }

      return getEquipmentList(companyId);
    };

    vm.addToEquipmentDocuments = function (doc, documentsList, type) {
      var item = {
        name: doc.name,
        src: doc.src,
        type: doc.type || 'link',
        hidden: doc.hidden
      };

      vm.infoText = '';
      vm.editingInfoText = '';

      // check if the document is already in the list, it returns an index of the document or -1
      if (
        ~documentsList
          .map(function (d) {
            return d.name;
          })
          .indexOf(doc.name)
      ) {
        if (type === 'EDIT') {
          vm.editingInfoText = translations['EQUIPMENT.DOCUMENT_ALREADY_ADDED'];
        } else {
          vm.infoText = translations['EQUIPMENT.DOCUMENT_ALREADY_ADDED'];
        }

        return;
      }

      if (doc.type === 'file' && !doc.linkToFileInQueue) {
        item.linkToFileInQueue = doc.file;
        item.src = doc.src = encodeURIComponent(doc.file.file.name);
        item.originalFileName = doc.file.file.originalFileName;
        item.dateCreated = doc.file.file.lastModifiedDate;

        Container.getFile({ container: 'equipment', file: doc.file.file.name })
          .$promise.then(function (res) {
            vm.infoText = translations['EQUIPMENT.DOCUMENT_ALREADY_ADDED'];
          })
          .catch(function (err) {
            if (err.data.error.code === 'ENOENT') {
              // this is good, the file doesn't already exist, so we can actually add it
              console.log('%cthis is fine, there is nothing to worry about', 'color: green;');
              documentsList.push(item);

              if (type === 'EDIT') {
                uploaderEditing.uploadReady = true;
              } else {
                uploader.uploadReady = true;
              }
            } else {
              console.warn(`%cIt's not a drill!`, 'font-size: 20px;');
              console.error('%cSomething really bad happened!', 'font-size: 40px;');
              console.log(
                '%cLook up for details in the "Network" tab!',
                'font-size: 30px; color: lightblue;'
              );
              throw err.data.error;
            }
          });
      } else {
        documentsList.push(item);
      }

      // reset the form fields
      doc.name = '';
      doc.src = '';
      doc.type = 'link';
      doc.hidden = false;
      delete doc.file;
    };

    vm.removeFromEquipmentDocuments = function (doc, documentsList, index) {
      if (doc.type === 'file' && uploader.queue.length)
        uploader.removeFromQueue(doc.linkToFileInQueue);

      if (doc.type === 'file' && uploaderEditing.queue.length)
        uploaderEditing.removeFromQueue(doc.linkToFileInQueue);

      if (doc.type === 'file') fileRemovingQueue.push(doc.src);

      documentsList.splice(index, 1);
    };

    vm.addToEquipmentList = function (equipmentItem, type) {
      var itemsUploadingStatuses = [];

      if (!equipmentItem.companyId) equipmentItem.companyId = companyId;

      uploader.onSuccessItem = uploaderEditing.onSuccessItem = function () {
        itemsUploadingStatuses.push(true);
      };

      uploader.onErrorItem = uploaderEditing.onErrorItem = function () {
        itemsUploadingStatuses.push(false);
      };

      uploader.onCompleteAll = uploaderEditing.onCompleteAll = function () {
        if (
          itemsUploadingStatuses.every(function (status) {
            return status;
          })
        ) {
          updateDataBase(equipmentItem);
        } else {
          MessagesService.error();
        }

        itemsUploadingStatuses = [];
      };

      vm.equipmentInfoText = '';
      vm.editingEquipmentInfoText = '';

      // check if the equipment already exists
      EquipmentService.findEquipmentByName(equipmentItem.name).then(function (res) {
        if (checkIfEquipmentExists(res, equipmentItem)) {
          if (type === 'EDIT') {
            vm.editingEquipmentInfoText = translations['EQUIPMENT.EQUIPMENT_ALREADY_ADDED'];
          } else {
            vm.equipmentInfoText = translations['EQUIPMENT.EQUIPMENT_ALREADY_ADDED'];
          }
        } else {
          if (uploader.queue.length && uploader.uploadReady === true) {
            uploader.uploadAll();
          } else if (uploaderEditing.queue.length && uploaderEditing.uploadReady === true) {
            uploaderEditing.uploadAll();
          } else if (!uploader.queue.length && !uploader.uploadReady) {
            updateDataBase(equipmentItem);
          }
        }
      });
    };

    vm.removeFromEquipmentList = function (equipment) {
      equipment.documents.forEach(function (doc) {
        if (doc.type !== 'file') return;

        doc.src = decodeURIComponent(doc.src);

        Container.removeFile({
          container: 'equipment',
          file: doc.src
        }).$promise.catch(function (err) {
          console.error(err);
        });
      });

      EquipmentService.deleteEquipmentById(equipment.id).then(function (res) {
        getEquipmentList(companyId);

        if (equipment.qrcode && equipment.qrcode.length) {
          equipment.qrcode.forEach(function (qrcode) {
            removeEquipmentFromQrCodes(qrcodes[qrcode], equipment.id);
          });
        }
      });
    };

    vm.editEquipment = function (equipment) {
      vm.editingEquipmentItem = angular.copy(equipment);
      vm.editingEquipmentItem.documents = vm.editingEquipmentItem.documents.map(function (doc) {
        doc.previewLink = getPreviewLink(doc);

        return doc;
      });
    };

    vm.handleInventoryModalOpening = function (equipment) {
      ModalService.open({
        templateUrl: require('../../../views/modals/inventories-with-item.html'),
        controller: [
          '$uibModalInstance',
          'EquipmentService',
          'InventoryService',
          function ($uibModalInstance, EquipmentService, InventoryService) {
            let modal = this;
            let flatInventories = [];

            vm.inventoryService.setScope(modal);
            getInventories(0);

            modal.handleInfiniteScroll = getInventories;
            modal.itemType = 'equipment';

            modal.validateAmount = function (inventory, oldAmount) {
              inventory.itemAmount.amount = validateAmount(inventory.itemAmount.amount, oldAmount);
              inventory.changed = true;
            };

            modal.initInventory = function (inventory) {
              inventory.itemAmount = InventoryService.getContainerAmountFromInventory(
                equipment.id,
                inventory,
                'equipmentAmounts',
                'equipmentId'
              );

              flatInventories = flatInventories.concat(inventory);
            };

            modal.save = function () {
              const inventoriesToUpdate = flatInventories.filter(function (inventory) {
                return inventory.changed;
              });
              const amountsToUpdate = inventoriesToUpdate.map(function (inventory) {
                return inventory.itemAmount;
              });

              EquipmentService.updateAmounts(amountsToUpdate, {
                inventories: inventoriesToUpdate,
                item: equipment,
                companyId: companyId
              }).then(function () {
                $uibModalInstance.close('save');
              });
            };

            modal.cancel = function () {
              $uibModalInstance.dismiss('cancel');
            };

            function getInventories(startFrom) {
              return vm.inventoryService
                .getInventories(null, startFrom)
                .then(function (companyInventories) {
                  modal.companyInventories = companyInventories;
                });
            }
          }
        ]
      });
    };

    $('#editEquipmentModal').on('hidden.bs.modal', function () {
      vm.fileTypeError = false;
      vm.editingEquipmentInfoText = '';
      vm.editingInfoText = '';
      fileRemovingQueue = [];

      $scope.$apply();
    });

    $translate(['EQUIPMENT.EQUIPMENT_ALREADY_ADDED', 'EQUIPMENT.DOCUMENT_ALREADY_ADDED']).then(
      function (trns) {
        translations = trns;
      }
    );

    if ($rootScope.checkIfUserIs(['teamAdmin', 'teacher'])) {
      companyId = $rootScope.currentUser.companyId;

      getEquipmentList(companyId);
    }

    QRCode.find(
      {},
      function (qrs) {
        qrs.forEach(function (qr) {
          qrcodes[qr.id] = qr;
        });
      },
      function (err) {
        console.error('ERROR : ' + err);
      }
    );

    function removeEquipmentFromQrCodes(qrCode, equipmentId) {
      if (!qrCode.equipment[equipmentId]) return;

      delete qrCode.equipment[equipmentId];

      QRCode.upsert(qrCode);
    }

    function getPreviewLink(doc) {
      switch (doc.type) {
        case 'link':
          return /^https?:\/\//i.test(doc.src) ? doc.src : 'http://' + doc.src;
        case 'file':
          return '/api/containers/equipment/download/' + doc.src;
      }
    }

    function getEquipmentList(companyId) {
      return CompanyService.CompanySettings(companyId, false)
        .$promise.then(function () {
          if ($rootScope.isInventoryModuleAvailable()) initInventoryService(companyId);

          return EquipmentService.getEquipmentsByCompany(companyId);
        })
        .then(function (res) {
          vm.equipmentList = Array.prototype.slice.call(res);
        });
    }

    function updateDataBase(equipmentItem) {
      equipmentItem.documents = equipmentItem.documents.reduce(function (docs, doc) {
        if (doc.name !== undefined && doc.src !== undefined) docs.push(cleanModel(doc));

        return docs;
      }, []);

      fileRemovingQueue.forEach(function (file) {
        var decodedFile = decodeURIComponent(file);

        Container.removeFile({
          container: 'equipment',
          file: decodedFile
        }).$promise.catch(function (err) {
          console.error(err);
        });
      });

      EquipmentService.updateEquipment(equipmentItem)
        .then(function (res) {
          // reset the model
          vm.equipmentItem = {
            name: '',
            companyId: companyId,
            documents: []
          };

          uploader.uploadReady = uploaderEditing.uploadReady = false;
          vm.fileTypeError = false;

          $('#editEquipmentModal').modal('hide');

          getEquipmentList(companyId);
        })
        .catch(function (err) {
          console.error(err);
        });

      function cleanModel(model) {
        // clean the model
        delete model.linkToFileInQueue;
        delete model.$$hashKey;
        delete model.previewLink;

        return model;
      }
    }

    function checkIfEquipmentExists(equipments, equipment) {
      var equipmentInCompany = equipments.find(function (equip) {
        return equip.companyId === equipment.companyId;
      });

      return (
        equipments.length &&
        (equipmentInCompany && equipmentInCompany.id) !== equipment.id &&
        equipments.some(function (equipmentRes) {
          return equipmentRes.companyId === equipment.companyId;
        })
      );
    }

    function onAfterAddingFileHandler(item, editedDoc) {
      var uploaderRemoveItem = item.remove;

      item.file.originalFileName = item.file.name;
      item.file.name = generateUniqueID() + item.file.name;
      vm.fileTypeError = false;

      editedDoc.file = item;
      editedDoc.file.remove = function () {
        uploaderRemoveItem.call(item);
        item.cancel();
        delete editedDoc.file;
      };
    }

    function initInventoryService(companyId) {
      vm.inventoryService = new InventoryService(companyId, {}, ['equipmentAmounts']);

      return vm.inventoryService.count();
    }
  }
]);
