import app from '../app';

app.directive('checkpassword', function (UserService) {
  return {
    require: 'ngModel',
    link: function (scope, elm, attrs, ctrl) {
      if (attrs.required) {
        ctrl.$validators.checkpassword = UserService.checkpassword;
      }
    }
  };
});
