import app from '../app';

app.filter('getCompanyName', [
  '$rootScope',
  '$translate',
  function ($rootScope, $translate) {
    return function (company) {
      return company.id === null ? $translate.instant('COMMON.LABELS.ALL_COMPANIES') : company.name;
    };
  }
]);
