import app from '../app';

app.filter('getSdsName', [
  '$filter',
  function ($filter) {
    const getFilename = $filter('getFilename'),
      getName = function (name) {
        let r = '';
        try {
          r = decodeURIComponent(name);
        } catch (error) {
          r = name;
        }
        return r || null;
      };
    return function (doc) {
      return (
        getName(doc.chemicalFriendlyName) || getFilename(doc.resourcePath)
      );
    };
  }
]);
