import { _ } from 'core-js';
import app from '../../app';

app.directive('docFieldsMatched', [
  '$rootScope',
  function ($rootScope) {
    return {
      restrict: 'E',
      scope: {
        matched: '='
      },
      templateUrl: require('./docFieldsMatched.html'),
      link: function (scope) {
        const translateAttr = function() {
          scope.matchedLang = (scope.matched || []).map(el => {
            let r = el[0];
            const attrMatch = r.match(/.+(?==)/),
              attrLang = attrMatch
                ? _.get($rootScope.companySettings.attrsLang, `${attrMatch[0]}.${$rootScope.siteLanguage}`, null)
                : null;

            if (attrLang) {
              r = r.replace(attrMatch[0], attrLang.name);
            }
            return  r;
          });
        };
        
        scope.$watch('matched', translateAttr);
        $rootScope.$watch('siteLanguage', translateAttr);
      }
    };
  }
]);
