// it's better to use this modal instead of TagService openModalToSelectCompanyTags
import { mergeBy } from '../../helpers';

const andOrStrategyStrings = ['and', 'or'];
const checkTypeEnums = {
  checkbox: 'checkbox',
  radio: 'radio'
};

export default function selectTags(
  companyId,
  {
    showAndOrDropdown = false,
    checkedTags = [],
    andOrStrategy = 'and',
    message = 'TAGS.SELECT_TAGS',
    messageStyles = {},
    saveButtonText = 'COMMON.ACTIONS.SAVE',
    checkType = checkTypeEnums.checkbox,
    tcollapsed = false,
    allowSearchByParentTags = true,
    disabledTags = [],
    showRestrictedTag = true,
    showArchivedTag = true,
    allTagsSelected = false
  } = {}
) {
  return {
    resolve: {
      translatedMessage: [
        '$translate',
        function ($translate) {
          if (message === null || message === '') return message;

          return $translate(message);
        }
      ],
      saveButtonText: [
        '$translate',
        function ($translate) {
          if (saveButtonText === null || saveButtonText === '') return saveButtonText;

          return $translate(saveButtonText);
        }
      ]
    },
    templateUrl: require('./selectTags.html'),
    keyboard: true,
    backdrop: true,
    controllerAs: 'vm',
    controller: [
      'translatedMessage',
      'saveButtonText',
      '$uibModalInstance',
      'TagService',
      'SpinnerService',
      function (translatedMessage, saveButtonText, $uibModalInstance, TagService, SpinnerService) {
        const vm = this;
        const getTags = SpinnerService.wrap(function (companyId) {
          let promise;

          if (companyId) {
            promise = TagService.getCompanyTags(companyId, { force: true });
          } else {
            promise = TagService.getUniversalTags();
          }

          return promise.then((tags = []) => {
            vm.tags = mergeBy(
              tags.map(tag => tag.toJSON()),
              checkedTags,
              'id'
            );

            if (allTagsSelected) {
              vm.tags = vm.tags.map(tag => {
                return { ...tag, ...(!tag.tags.length ? { checked: true } : {}) };
              });
            }
            vm.areAllTagsSelected();
          });
        });

        vm.checkType = checkTypeEnums[checkType] || checkTypeEnums.checkbox;
        vm.tcollapsed = tcollapsed;
        vm.allowSearchByParentTags = allowSearchByParentTags;
        vm.disabledTags = disabledTags;
        vm.showRestrictedTag = showRestrictedTag;
        vm.showArchivedTag = showArchivedTag;
        vm.message = translatedMessage;
        vm.messageStyles = messageStyles;
        vm.saveButtonText = saveButtonText;
        vm.companyId = companyId;
        vm.showAndOrDropdown = showAndOrDropdown;
        vm.tags = [];
        vm.andOrStrategy = andOrStrategy;
        vm.dismiss = $uibModalInstance.dismiss;
        vm.areAllTagsSelected = tag => {
          if (vm.checkType === checkTypeEnums.checkbox) {
            vm.allTagsSelected = vm.tags.every(
              tag => (tag.tags && tag.tags.length > 0) || tag.checked || tag.disabled
            );
          } else if (vm.checkType === checkTypeEnums.radio) {
            vm.tags.forEach(tag => delete tag.checked);

            if (tag) tag.checked = 'true'; // value="true" in html
          }
        };
        vm.toggleSelection = bool => {
          vm.tags.forEach(tag => {
            if (!tag.tags || !tag.tags.length) {
              tag.checked = tag.disabled ? false : bool;
            } else {
              tag.parentChecked = bool;
            }
          });
        };
        vm.changeAndOrStrategy = strategyStr => {
          vm.andOrStrategy = andOrStrategyStrings.find(s => s === strategyStr);
        };
        vm.save = () => {
          const result = {
            tags: vm.tags.filter(tag => {
              if (!showRestrictedTag && tag.restricted) return false;
              if (!showArchivedTag && tag.archived) return false;

              return tag.checked;
            }),
            allSelected: vm.allTagsSelected
          };

          if (vm.showAndOrDropdown) result.andOrStrategy = vm.andOrStrategy;

          $uibModalInstance.close(result);
        };

        getTags(companyId);
      }
    ]
  };
}
