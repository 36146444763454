import app from '../../app';

app.controller('companyCtrl', [
  '$scope',
  '$http',
  '$state',
  'Tag',
  'Company',
  'CompanyFactory',
  function ($scope, $http, $state, Tag, Company, CompanyFactory) {
    var vm = this;
    vm.companyFactory = CompanyFactory;

    //company changed handler to reload the tags based on company id
    $scope.OnCompanySelected = function () {
      console.log('You have selected company : ' + JSON.stringify($scope.selectedCompany));
    };

    //make the companie available to the view
    function HandleLoadedCompanies() {
      console.log('Companies Loaded');
    }

    function Initialize() {
      CompanyFactory.GetCompanies(HandleLoadedCompanies);
    }

    Initialize();
  }
]);
