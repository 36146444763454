import app from '../../app';
import supplierInfo from '../../modals/supplierInfo';
import { getOffset } from '../../helpers';

app.controller('productsWithoutSdsCtrl', [
  'ProductService',
  'SpinnerService',
  'ExportService',
  'totalProducts',
  'ModalService',
  productsWithoutSdsCtrl
]);

function productsWithoutSdsCtrl(
  ProductService,
  SpinnerService,
  ExportService,
  totalProducts,
  ModalService
) {
  var vm = this;

  vm.startFrom = 1;
  vm.PER_PAGE = 20;
  vm.totalItems = totalProducts.count;

  vm.handlePaginate = SpinnerService.wrap(getProductsWithoutSds);
  vm.handleExport = ExportService.exportProductsWithoutSds2csv;

  vm.openSupplierInfoModal = function (product) {
    return ModalService.open(supplierInfo({ suppliers: product.suppliers }));
  };

  getProductsWithoutSds();

  function getProductsWithoutSds() {
    return ProductService.getProductsWithoutSds(
      getOffset(vm.startFrom, vm.PER_PAGE),
      vm.PER_PAGE
    ).then(function (response) {
      vm.productsWithoutSds = response;
      vm.productsWithoutSds.forEach(el => {
        el.size = ProductService.showCorrectProductSize(el);
      });
    });
  }
}
