import angular from 'angular';
import app from '../../app';

app.controller('archiveFilesCtrl', function (
  $uibModalInstance,
  document,
  companyIds,
  attrs,
  Company,
  Tag,
  ErrorService,
  SpinnerService,
  SolrDocument,
  Manufacturer,
  StorageCodes,
  DisposalCodes,
  $q,
  HazardService,
  $scope,
  ModalService,
  $state
) {
  const vm = this;
  vm.curr = document;
  vm.companyIds = companyIds;
  vm.currentCompanyId = vm.companyIds[0];
  vm.companies = {};
  vm.formData = {
    companyId: companyIds
  };

  const adjustTZ = function (date) {
      if (!date) {
        return date;
      }
      if (!(date instanceof Date)) {
        date = new Date(date);
      }
      const offset = date.getTimezoneOffset() * 60 * 1000;
      date.setTime(date.getTime() - offset);
      return date;
    },
    formatDate = function (date) {
      if (/^\d{4}-\d{2}-\d{2}$/.test(date)) {
        return date;
      }
      if (!(date instanceof Date)) {
        date = adjustTZ(new Date(date));
      }
      return date
        ? date
          .toISOString()
          .split('T')
          .shift()
        : null;
    },
    isValidDate = function (value) {
      return !!Date.parse(value);
    };

  vm.currentFile = null;
  vm.docs2edit = {};
  vm.restrictedTag = {};
  vm.storageCodes = {};
  vm.disposalCodes = {};
  vm.HazardService = HazardService;

  vm.knownManuNames = [];
  vm.knownManu = {};
  Manufacturer.find({ filter: { fields: ['id', 'name'] } })
    .$promise.then(function (resp) {
      vm.knownManuNames = [];
      vm.knownManu = resp.reduce(function (sum, itm) {
        sum[itm.name] = itm.id;
        vm.knownManuNames.push(itm.name);
        return sum;
      }, {});
    })
    .catch(ErrorService.simpleCatch);

  vm.onUpload = function (doc) {
    doc.dirty = false;
    doc.savedAndOk = vm.isValid(doc);
    doc.issueDateValue = isValidDate(doc.issueDate) ? adjustTZ(doc.issueDate) : new Date();
    doc.synonyms = (doc.synonyms || []).join('; ');
    doc.restricted = {};
    doc.restrictedText = {};
    doc.storageCodes = {};
    doc.disposalCodes = {};
    vm.docs2edit[doc.filename] = doc;
  };
  vm.onPickDate = function (doc) {
    if (isValidDate(doc.issueDate)) {
      doc.issueDateValue = adjustTZ(doc.issueDate);
    }
  };
  vm.onDatePicked = function (doc) {
    doc.issueDate = formatDate(doc.issueDateValue);
    vm.onChange();
  };
  vm.onRemove = function (filename) {
    delete vm.docs2edit[filename];
    if (vm.currentFile === filename) {
      vm.currentFile = null;
    }
  };
  vm.hasAny = function (docs) {
    return Object.keys(docs).length;
  };
  vm.onSave = SpinnerService.wrap(function (doc) {
    const data = {
      id: doc.id,
      chemicalFriendlyName: doc.chemicalFriendlyName,
      issueDate: formatDate(doc.issueDate),
      companyId: vm.companyIds,
      manufacturer: vm.knownManu[doc.manufacturer] || null,
      safe_handling: doc.safe_handling || null,
      synonyms: (doc.synonyms || '')
        .split(/[;/]/)
        .map(function (itm) {
          return itm.trim();
        })
        .filter(function (itm) {
          return itm;
        }),
      storageCodes: Object.keys(doc.storageCode || {}).reduce(function (sum, companyId) {
        const code = doc.storageCode[companyId];
        return code ? (sum || []).concat(code.id) : sum;
      }, null),
      disposalCodes: Object.keys(doc.disposalCode || {}).reduce(function (sum, companyId) {
        const code = doc.disposalCode[companyId];
        return code ? (sum || []).concat(code.id) : sum;
      }, null),
      hazardType: Object.keys(doc.hazards || {}).reduce(function (sum, type) {
        const checked = doc.hazards[type];
        return checked ? sum.concat(type) : sum;
      }, []),
      tag: []
    };
    angular.forEach(doc.restricted, function (enabled, companyId) {
      if (enabled) {
        data.tag.push(vm.restrictedTag[companyId].id);
        const text = doc.restrictedText[companyId];
        if (text) {
          const field = 'restricted_in_' + companyId;
          data[field] = text;
        }
      }
    });

    if (!doc.id) {
      return $q.resolve(ErrorService.simpleCatch('document has no id'));
    }
    return SolrDocument.changeById(data)
      .$promise.then(function (resp) {
        doc.dirty = false;
        doc.savedAndOk = true;
        return resp;
      })
      .then(function (resp) {
        return SolrDocument.setAttrs({
          id: resp.doc.id,
          attrs: attrs
        }).$promise;
      });
  });

  vm.isValid = function (data) {
    return (
      data.chemicalFriendlyName &&
      !!Date.parse(data.issueDate) &&
      (!data.manufacturer || !!~vm.knownManuNames.indexOf(data.manufacturer))
    );
  };
  vm.onChange = function () {
    vm.docs2edit[vm.currentFile].dirty = true;
  };

  vm.datePicker = {
    options: {
      maxDate: new Date(),
      showWeeks: false
    },
    defaultFormat: 'yyyy-MM-dd',
    altInputFormats: [
      'MM dd, yy',
      'MMMM dd, yy',
      'MMMM dd, yyyy',
      'yyyy-MM-dd',
      'dd-MMMM-yyyy',
      'yyyy/MM/dd',
      'dd.MM.yyyy',
      'shortDate'
    ],
    show: false
  };

  vm.getTags = SpinnerService.wrap(function (companyIds) {
    return Tag.find({
      filter: {
        where: {
          restricted: true
        }
      }
    })
      .$promise.then(function (resp) {
        vm.restrictedTag = resp.reduce(function (sum, tag) {
          if (tag.restricted) {
            sum[tag.companyId] = tag;
          }
          return sum;
        }, {});
      })
      .catch(ErrorService.simpleCatch);
  });

  vm.getCodes = function (ids) {
    const filter = {
      filter: {
        where: {
          companySettingId: {
            inq: ids
          },
          isEnabled: true
        }
      }
    };
    return $q
      .all({
        storageCodes: StorageCodes.find(filter).$promise,
        disposalCodes: DisposalCodes.find(filter).$promise
      })
      .then(function (resp) {
        const pivot = function (codes) {
          return codes.reduce(function (sum, code) {
            if (!sum[code.companySettingId]) {
              sum[code.companySettingId] = [];
            }
            sum[code.companySettingId].push(code);
            return sum;
          }, {});
        };
        vm.storageCodes = pivot(resp.storageCodes);
        vm.disposalCodes = pivot(resp.disposalCodes);
      })
      .catch(ErrorService.simpleCatch);
  };

  vm.getCompanies = SpinnerService.wrap(function (ids) {
    return Company.find({
      filter: {
        where: {
          id: {
            inq: ids
          }
        },
        fields: ['id', 'name']
      }
    })
      .$promise.then(function (resp) {
        vm.companies = resp.reduce(function (sum, company) {
          sum[company.id] = company.name;
          return sum;
        }, {});
        return $q.all([vm.getTags(ids), vm.getCodes(ids)]);
      })
      .catch(ErrorService.simpleCatch);
  });

  vm.onCompanySelected = function (companyId) {
    vm.currentCompanyId = companyId;
  };

  vm.onFileSelected = function (filename) {
    vm.currentFile = filename;
  };

  vm.onEditTabSelected = function () {
    const files = Object.keys(vm.docs2edit);
    if (!vm.currentFile && files.length) {
      vm.currentFile = files[0];
    }
  };

  const docs2deletePrompt = function () {
    const docs2delete = [];
    angular.forEach(vm.docs2edit, function (doc, filename) {
      if (!vm.isValid(doc) || !doc.savedAndOk) {
        docs2delete.push(doc);
      }
    });
    if (!docs2delete.length) {
      return $q.resolve(true);
    }
    return ModalService.open({
      keyboard: true,
      backdrop: 'static',
      controllerAs: 'ctrl',
      controller: function () {},
      templateUrl: require('../../../views/docs2deletePrompt.html'),
      resolve: {}
    })
      .then(function (deleteFiles) {
        if (!deleteFiles) {
          return $q.resolve(true);
        }
        const deleteDocs = SpinnerService.wrap(function (docs) {
          return docs.reduce(function (promise, doc) {
            return promise.then(function () {
              return SolrDocument.deleteById({ id: doc.id }).$promise;
            });
          }, $q.when(true));
        });
        return deleteDocs(docs2delete)
          .then(function () {
            return true;
          })
          .catch(ErrorService.simpleCatch);
      })
      .catch(angular.noop);
  };

  vm.dismiss = function () {
    return docs2deletePrompt().then(function (toDismiss) {
      toDismiss && $uibModalInstance.dismiss();
    });
  };
  let skipPrompt = false;
  $scope.$on('$stateChangeStart', function (event, toState, toParams) {
    if (skipPrompt) {
      return;
    }
    event.preventDefault();
    docs2deletePrompt().then(function (toProceed) {
      if (toProceed) {
        skipPrompt = true;
        $uibModalInstance.dismiss();
        return $state.go(toState, toParams);
      }
    });
  });

  vm.getCompanies(vm.companyIds).then(function () {
    vm.onCompanySelected(vm.currentCompanyId);
  });
});
