import app from '../../app';

app.directive('docName', [
  function () {
    return {
      restrict: 'E',
      transclude: true,
      scope: {
        doc: '=',
        target: '=',
        onClick: '&'
      },
      templateUrl: require('./docName.html')
    };
  }
]);
