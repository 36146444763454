import app from '../app';

app.filter('tagTitle', [
  '$rootScope',
  function ($rootScope) {
    const getLangTitle = function (tag, lang) {
      return tag[`title_${lang}`] || tag[`name_${lang}`];
    };
    return function (tag) {
      return (
        tag[`fullPathTitle_${$rootScope.siteLanguage}`] ||
        tag.fullPathTitle ||
        getLangTitle(tag, $rootScope.siteLanguage) ||
        tag.title ||
        tag.name
      );
    };
  }
]);
