import app from '../../app';

app.directive('pagination', [
  '$rootScope',
  function ($rootScope) {
    return {
      restrict: 'E',
      templateUrl: require('./pagination.html'),
      require: 'ngModel',
      scope: {
        currentPage: '=ngModel',
        totalItems: '=',
        itemsPerPage: '=',
        maxSize: '=',
        api: '='
      },
      link: function (scope, _element, _attrs, ngModelCtrl) {
        scope.pageObj = {
          page: scope.currentPage
        };
        scope.maxSize = $rootScope.checkIfUserIs(['admin', 'sdsEditor'])
          ? Number.MAX_SAFE_INTEGER
          : scope.maxSize || 10;
        scope.api = scope.api || {};

        scope.$watch('currentPage', function (newValue) {
          scope.pageObj.page = newValue;
        });
        scope.api.goToPage = function (pageNum = 0) {
          const prevPage = scope.pageObj.page || pageNum;
          scope.pageObj.page = pageNum;

          ngModelCtrl.$setViewValue(pageNum);

          if (prevPage === pageNum) {
            ngModelCtrl.$viewChangeListeners.forEach(f => f());
          }
        };

        scope.onPageChange = function (newPage) {
          ngModelCtrl.$setViewValue(newPage);
        };
      }
    };
  }
]);
