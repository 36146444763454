import app from '../app';

app.factory('RoleFactory', function RoleFactory(
  $rootScope,
  $filter,
  Role,
  RoleMapping,
  CompanyService,
  ErrorService
) {
  const simpleCatch = ErrorService.simpleCatch;
  let gettingRolesPromise = null;

  //initilize some values
  RoleFactory.selectedRole = {};
  RoleFactory.selectedRole.id = '';
  RoleFactory.roles = [];
  RoleFactory.defaultRole = {};

  RoleFactory.getVisibleRoleName = $filter('getVisibleRoleName');

  RoleFactory.checkIfUserIs = function (role, user, forbiddenRoles = []) {
    user = user != null ? user : $rootScope.currentUser;

    if (role == undefined) return user || $rootScope.IPallowed ? true : false; // role doesn't matter but IP does

    if (
      forbiddenRoles.some(function (role) {
        return isUserSameRole(user, role);
      })
    ) {
      return false;
    }

    if (!role.length) return true;

    if (!Array.isArray(role)) {
      role = [role];
    }

    return role.some(function (role) {
      return isUserSameRole(user, role);
    });
  };

  RoleFactory.extendRole = function (user) {
    switch (user.role) {
      case 'invManagerRead':
        user.extendedRole = 'teacher';
        break;
    }
  };

  RoleFactory.find = function () {
    return Role.find().$promise.then(addVisibleName);
  };

  RoleFactory.getRoles = function (callback) {
    if (RoleFactory.roles.length) return callback && callback(), Promise.resolve(RoleFactory.roles);
    if (gettingRolesPromise)
      return gettingRolesPromise
        .then(() => (callback && callback(), Promise.resolve(RoleFactory.roles)))
        .catch(err => (callback && callback(err), Promise.reject(err)));

    const rolesToGet = ['teamAdmin', 'teacher', 'teamMember', 'firstResponder', 'invManagerRead'];

    gettingRolesPromise = CompanyService.getCurrentCompanyPromise()
      .$promise.then(
        () => Role.find({ filter: { where: { or: rolesToGet.map(name => ({ name })) } } }).$promise
      )
      .then(roles => {
        addVisibleName(roles);

        roles.forEach(function (role) {
          RoleFactory.roles.push(role);
        });

        RoleFactory.selectedRole = RoleFactory.roles[0];
        setDefaultRole(roles);

        callback && callback();

        return RoleFactory.roles;
      })
      .catch(err => {
        console.log('error getting roles ' + err);
        callback && callback(err);

        return Promise.reject(err);
      });

    return gettingRolesPromise;
  };

  RoleFactory.getSystemRoles = function () {
    const rolesToGet = ['sdsEditor'];

    return Role.find({ filter: { where: { or: rolesToGet.map(name => ({ name })) } } })
      .$promise.then(addVisibleName)
      .catch(simpleCatch);
  };

  RoleFactory.getRoleById = function (id) {
    return (
      RoleFactory.roles.filter(function (role) {
        return role.id === id;
      })[0] || {}
    );
  };

  RoleFactory.SetSelectedRole = function (id) {
    RoleFactory.roles.forEach(function (role) {
      if (role.id == id) {
        RoleFactory.selectedRole = role;
      }
    });
  };

  //gets the mapped role for a userId
  RoleFactory.GetUserRoleMapping = function (userId, callback) {
    return RoleMapping.find(
      { filter: { where: { principalId: userId } } },
      function (roleMap) {
        return callback && callback(null, roleMap[0]);
      },
      function (err) {
        return callback && callback(err, null);
      }
    ).$promise.then(function (roleMap) {
      return roleMap[0];
    });
  };

  //save the users rolemapping
  RoleFactory.SaveUserRoleMapping = function (mappedRole, callback) {
    if (!mappedRole) {
      return callback({ 'error:': 'no mapped role' }, null);
    }

    return RoleMapping.upsert(
      mappedRole,
      function (updatedRoleMap) {
        return callback && callback(null, updatedRoleMap);
      },
      function (err) {
        return callback && callback(err, null);
      }
    ).$promise;
  };

  RoleFactory.DeleteUserMapping = function (userId, callback) {
    return RoleFactory.GetUserRoleMapping(userId)
      .then(function (mappedRole) {
        if (!mappedRole) return callback && callback(null, null);

        const options = {
          id: mappedRole.id
        };

        return RoleMapping.deleteById(
          options,
          function (response) {
            callback && callback(null, response);
          },
          function (err) {
            callback && callback(err, null);
          }
        ).$promise;
      })
      .catch(function (err) {
        return callback && callback(err, null);
      });
  };

  function addVisibleName(roles = []) {
    roles.forEach(function (role) {
      role.visibleName = RoleFactory.getVisibleRoleName(role.name);
    });

    return roles;
  }

  function setDefaultRole(roles) {
    roles.forEach(function (role) {
      if (role.name === 'teamMember') {
        RoleFactory.defaultRole = role;
      }
    });
  }

  return RoleFactory;
});

function isUserSameRole(user, role) {
  return !!(user && (user.extendedRole === role || user.role === role));
}
