import app from '../../app';

app.controller('sdsRequestsCtrl', ['SdsRequestService', 'ModalService', sdsRequestsCtrl]);

function sdsRequestsCtrl(SdsRequestService, ModalService) {
  const vm = this;

  vm.title = 'REPORTS.SDS_REQUESTS.TITLE';
  vm.requestService = SdsRequestService;

  SdsRequestService.init();

  vm.showHistory = function (e, request) {
    e.stopPropagation();

    return ModalService.open({
      keyboard: true,
      backdrop: true,
      controller: 'historyRequestModalCtrl',
      templateUrl: require('../historyRequestModalCtrl/history-sds-request.html'),
      resolve: {
        title: function () {
          return request.name;
        },
        history: [
          'SdsRequestService',
          function (SdsRequestService) {
            return SdsRequestService.getHistory(request.id);
          }
        ]
      }
    });
  };

  vm.showRequest = function (request) {
    return ModalService.open({
      keyboard: true,
      backdrop: true,
      controller: 'requestModalCtrl',
      templateUrl: require('../requestModalCtrl/sds-request.html'),
      resolve: {
        request: function () {
          return request;
        },
        behaviourOptions: {}
      }
    }).then(function (statusCode) {
      request.status = statusCode;
    });
  };
}
