import app from '../../app';

app.directive('noResults', [
  '$rootScope',
  function ($rootScope) {
    return {
      restrict: 'E',
      transclude: true,
      scope: {
        throughBroaderSearch: '='
      },
      templateUrl: require('./noResults.html'),
      link: function (scope) {
        scope.loggedIn = !!$rootScope.currentUser;
      }
    };
  }
]);
