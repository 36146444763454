export default function listSdss(params = {}) {
  return {
    templateUrl: require('./listSdss.html'),
    keyboard: true,
    backdrop: true,
    controllerAs: 'vm',
    controller: [
      '$uibModalInstance',
      'SearchPageService',
      function ($uibModalInstance, SearchPageService) {
        const vm = this;

        vm.sps = SearchPageService;

        vm.save = $uibModalInstance.close.bind(null, vm.form);
        vm.dismiss = $uibModalInstance.dismiss;

        init();

        function init() {
          SearchPageService.onReset();
          SearchPageService.doSearch(params);
        }
      }
    ]
  };
}
