import angular from 'angular';
import app from '../../app';
import { calculatePages, getNameFromFile } from '../../helpers';

app.directive('pdfViewer', [
  '$window',
  '$q',
  'ToolsLayer',
  'SpinnerService',
  'ProcessingService',
  'ErrorService',
  function ($window, $q, ToolsLayer, SpinnerService, ProcessingService, ErrorService) {
    return {
      restrict: 'E',
      templateUrl: require('./pdfViewer.html'),
      scope: {
        url: '=',
        enableTools: '=',
        onProcess: '=',
        toolTypes: '=',
        toolsApi: '=',
        useMobileHack: '='
      },
      link: function (scope, element) {
        if (!scope.url) throw 'No PDF url provided';

        const simpleCatch = ErrorService.simpleCatch;
        const toolTypes = scope.toolTypes || {
          table: { type: 'table', color: '#269abc', lineWidth: 2 },
          bold: { type: 'bold', color: '#399739', lineWidth: 2 },
          ignore: { type: 'ignore', color: '#c67605', lineWidth: 2 }
        };
        const wrapper = element.find('div.pdf-wrapper')[0];
        const pdfCanvas = element.find('canvas.pdf')[0];
        const pdfCanvasCtx = pdfCanvas.getContext('2d');
        const toolsCanvas = element.find('canvas.tools')[0];
        const toolsLayer = (window.toolsLayer = new ToolsLayer(toolsCanvas, toolTypes));

        let PDFJS = window['pdfjs-dist/build/pdf'];
        let doc;
        let viewportScale = 1;

        scope.currentTemplate = null;
        scope.toolsApi = scope.toolsApi || {};
        scope.toolTypes = toolTypes;
        scope.totalItems = 0;
        scope.currentPageNum = 1;
        scope.getToolType = toolsLayer.getToolType;
        scope.isExtractionsShowing = toolsLayer.isExtractionsShowing;
        scope.handlePaginate = () => loadPage(scope.currentPageNum);
        scope.clearSelections = toolsLayer.clearSelections;
        scope.toggleExtractions = toolsLayer.toggleExtractions;

        const getDocument = SpinnerService.wrap(function getDocument(url) {
          const loadDoc = function (url) {
            return PDFJS.getDocument(url).promise.then(function (pdfDoc) {
              doc = pdfDoc;
              scope.totalItems = pdfDoc.numPages;

              return loadPage(scope.currentPageNum);
            });
          };

          return loadDoc(url)
            .catch(function (err) {
              if (err.name === 'MissingPDFException') {
                const s3url =
                  'https://s3.ca-central-1.amazonaws.com/canadasds/sds/' +
                  getNameFromFile(url, true);

                return loadDoc(s3url);
              }

              return err;
            })
            .catch(simpleCatch);
        });

        preloadScripts()
          .then(waitForCanvas)
          .then(function () {
            scope.$watch('url', drawDocument);
          });

        if (scope.enableTools) {
          if (!ProcessingService.templates) {
            ProcessingService.getTemplates().then(function (templates) {
              scope.templates = templates;
            });
          } else {
            scope.templates = ProcessingService.templates;
          }

          scope.selectTool = function (type) {
            toolsLayer.setToolType(toolTypes[type].type);
          };

          scope.process = function () {
            scope.onProcess({
              selections: scope.toolsApi.getSelectionRects(),
              scale: scope.toolsApi.getScale()
            });
          };

          scope.saveSelections = function (template) {
            if (!template) return;

            const data = {
              title: typeof template === 'object' ? template.title : template,
              selections: scope.toolsApi.getSelectionRects()
            };

            ProcessingService.upsertTemplate(template.id, data).then(function (templates) {
              scope.templates = templates;
              scope.currentTemplate = data;
            });
          };

          scope.selectTemplate = function (template) {
            scope.currentTemplate = template;

            applySelections(template.selections);
          };

          scope.deleteTemplate = function (template) {
            ProcessingService.deleteTemplate(template).then(function (templates) {
              scope.templates = templates;
              toolsLayer.clearSelections();
              scope.currentTemplate = null;
            });
          };

          // return selection rects mapped to pages, e.g. {1: [rects], 2: [rects]};
          scope.toolsApi.getSelectionRects = function () {
            return Object.keys(toolTypes).reduce(function (obj, key) {
              obj[toolTypes[key].type] = toolsLayer
                .getSelections(toolTypes[key].type)
                .reduce(function (obj, selections, index) {
                  if (selections.length)
                    obj[index] = selections.map(function (selection) {
                      return selection.rect.map(function (coord) {
                        return coord / viewportScale;
                      });
                    });
                  return obj;
                }, {});

              return obj;
            }, {});
          };

          scope.toolsApi.setSelectionRects = function (rects) {
            const selections = [];

            Object.keys(rects).forEach(function (type) {
              Object.keys(rects[type]).forEach(function (page) {
                selections[page] = (selections[page] || []).concat(
                  createSelectionsFromRects(rects[type][page], type)
                );
              });
            });

            toolsLayer.setSelections(selections);
          };

          scope.toolsApi.getScale = function () {
            return viewportScale;
          };

          toolsLayer.addListeners();
        }

        function applySelections(selections) {
          scope.toolsApi.setSelectionRects(selections);
        }

        function createSelectionsFromRects(rects, type) {
          return rects.map(function (rect) {
            return {
              rect: rect.map(function (coord) {
                return coord * viewportScale;
              }),
              type: type,
              color: toolTypes[type].color
            };
          });
        }

        function preloadScripts() {
          if (PDFJS) return $q.resolve();

          const pdfMinJs = document.createElement('script');
          const pdfWorkerMinJs = document.createElement('script');

          pdfMinJs.src = '/vendor/pdfjs-dist/build/pdf.min.js';
          pdfWorkerMinJs.src = '/vendor/pdfjs-dist/build/pdf.worker.min.js';

          document.body.prepend(pdfMinJs);
          document.body.prepend(pdfWorkerMinJs);

          return $q(function (resolve) {
            const interval = setInterval(function () {
              PDFJS = window['pdfjs-dist/build/pdf'];

              if (PDFJS) {
                clearInterval(interval);
                resolve();
              }
            }, 30);
          });
        }

        function waitForCanvas() {
          if (pdfCanvas.getBoundingClientRect().width) return $q.resolve();

          return $q(function (resolve, reject) {
            const iterationLimit = 30;
            let currentIteration = 1;

            const interval = setInterval(function () {
              if (pdfCanvas.getBoundingClientRect().width) {
                clearInterval(interval);
                resolve();
              }

              if (currentIteration > iterationLimit) {
                console.error('Still can\'t see the canvas for PDF to draw. Stop waiting.');
                clearInterval(interval);
                reject();
              }

              currentIteration++;
            }, 200);
          });
        }

        function drawDocument(url) {
          scope.currentPageNum = 1;

          if (scope.enableTools && scope.currentTemplate)
            applySelections(scope.currentTemplate.selections);

          getDocument(url);
        }

        function loadPage(pageNum) {
          return doc
            .getPage(pageNum)
            .then(function (page) {
              const pageWidthScale =
                pdfCanvas.getBoundingClientRect().width / page.getViewport({ scale: 1 }).width;
              const viewport = page.getViewport({ scale: pageWidthScale });
              viewportScale = viewport.scale;
              wrapper.style.height = viewport.height + 'px';
              wrapper.style.width = viewport.width + 'px';

              setCanvasDimensions(pdfCanvas, viewport.width, viewport.height);

              if (scope.enableTools) {
                setCanvasDimensions(toolsCanvas, viewport.width, viewport.height);

                page.getTextContent().then(function (textContent) {
                  const rects = textContent.items.map(function (item) {
                    return convertToCanvasCoords(viewport, item);
                  });

                  toolsLayer.setExtractions(rects);
                });

                toolsLayer.changePage(pageNum);
              }

              scope.$apply();

              return page
                .render({ canvasContext: pdfCanvasCtx, viewport: viewport })
                .promise.then(function () {
                  if (scope.useMobileHack) {
                    setCanvasDimensions(pdfCanvas, viewport.width, viewport.height);

                    return page.render({ canvasContext: pdfCanvasCtx, viewport: viewport }).promise;
                  }

                  return $q.resolve();
                });
            })
            .catch(simpleCatch);
        }

        function convertToCanvasCoords(viewport, item) {
          const transform = PDFJS.Util.transform(viewport.transform, item.transform);
          const x = transform[4];
          const y = transform[5];
          const width = item.width;
          const height = Math.abs(transform[3]);

          return [x, y - height, width * viewport.scale, height];
        }

        function setCanvasDimensions(canvas, w, h) {
          const ratio = backingScale(canvas);
          canvas.width = Math.floor(w * ratio);
          canvas.height = Math.floor(h * ratio);
          canvas.style.width = Math.floor(w) + 'px';
          canvas.style.height = Math.floor(h) + 'px';
          canvas.getContext('2d').setTransform(ratio, 0, 0, ratio, 0, 0);
          return canvas;
        }

        function backingScale(canvas) {
          const ctx = canvas.getContext('2d');
          const dpr = $window.devicePixelRatio || 1;
          const bsr =
            ctx.webkitBackingStorePixelRatio ||
            ctx.mozBackingStorePixelRatio ||
            ctx.msBackingStorePixelRatio ||
            ctx.oBackingStorePixelRatio ||
            ctx.backingStorePixelRatio ||
            1;

          return dpr / bsr;
        }
      }
    };
  }
]);

app.factory('ToolsLayer', [
  'Brush',
  'Selection',
  function (Brush, Selection) {
    return function ToolsLayer(canvas, types) {
      const ctx = canvas.getContext('2d');
      const brush = new Brush(ctx, types);
      const extractionsColor = 'red';
      const extractionsLineWidth = 1;

      let selections = [[]];
      let extractionRects = [];
      let extractionsShowing = false;
      let dragging = false;
      let startX = null;
      let startY = null;
      let initMouseX = null;
      let initMouseY = null;
      let page = 0;
      let activeSelection = null;
      let selectionToRemove = null;

      const mouseDown = function (e) {
        dragging = true;
        selectionToRemove = activeSelection;

        const mousePosition = getMouse(e);

        activeSelection = selections[page].filter(function (selection) {
          return selection.isOnBorders(mousePosition.x, mousePosition.y);
        })[0];

        if (!brush.getType() && !activeSelection) return (dragging = false);

        updateStartCoords(e);
      };
      const mouseMove = function (e) {
        changeCursor(e);

        if (!dragging || (!brush.getType() && !activeSelection) || !isMouseMoved(e)) return;

        if (activeSelection) {
          removeSelection(activeSelection);
        }

        const mousePosition = getMouse(e);

        draw(mousePosition.x, mousePosition.y);
      };
      const mouseUp = function (e) {
        if (!dragging) return;

        if (isOnRemoveButton(e, selectionToRemove)) {
          removeSelection(selectionToRemove);
        }

        const mousePosition = getMouse(e);

        if (
          !isMouseMoved(e) ||
          startX === mousePosition.x || // just horizontal line
          startY === mousePosition.y || // just vertical line
          (startX == null && startY == null)
        )
          return cancelDrawing();

        const x = startX;
        const y = startY;

        addSelection(ctx, x, y, mousePosition.x, mousePosition.y);
        cancelDrawing();
      };
      const isMouseMoved = function (e) {
        const mousePosition = getMouse(e);

        return mousePosition.x !== initMouseX || mousePosition.y !== initMouseY;
      };
      const getMouse = function (e) {
        const OFFSET = 0; // additional offset, use 0.5 to make lines more narrow with lineWidth 1
        const elementBox = canvas.getBoundingClientRect();

        return { x: e.clientX - elementBox.x + OFFSET, y: e.clientY - elementBox.y + OFFSET };
      };
      const changeCursor = function (e) {
        if (isOnRemoveButton(e, activeSelection)) {
          canvas.style.cursor = 'pointer';
        } else if (isSelectionHovered(e)) {
          canvas.style.cursor = 'crosshair';
        } else {
          canvas.style.cursor = 'default';
        }
      };
      const isSelectionHovered = function (e) {
        const mousePosition = getMouse(e);

        return selections[page].some(function (selection) {
          return selection.isOnBorders(mousePosition.x, mousePosition.y);
        });
      };
      const isOnRemoveButton = function (e, selection) {
        if (!selection) return false;

        const mousePosition = getMouse(e);

        return selections[page].some(function (sel) {
          return sel === selection && sel.isOnRemoveButton(mousePosition.x, mousePosition.y);
        });
      };
      const updateStartCoords = function (e) {
        const mousePosition = getMouse(e);
        initMouseX = mousePosition.x;
        initMouseY = mousePosition.y;

        if (activeSelection) {
          startX = activeSelection.x;
          startY = activeSelection.y;
        } else {
          // we check startX,startY for null in case mouseUp was fired outside canvas
          initMouseX = startX = startX != null ? startX : mousePosition.x;
          initMouseY = startY = startY != null ? startY : mousePosition.y;
        }
      };
      const draw = function (x, y) {
        redraw();

        if (activeSelection) {
          drawActiveRect(x - startX, y - startY);
        } else {
          drawRect(x - startX, y - startY);
        }
      };
      const drawRect = function (width, height) {
        brush.drawRect(startX, startY, width, height);
      };
      const drawActiveRect = function (width, height) {
        const prevColor = brush.getColor();
        const prevLineWidth = brush.getLineWidth();

        brush.setColor(activeSelection.color);
        brush.setLineWidth(activeSelection.lineWidth);

        brush.drawDashedRect(startX, startY, width, height);

        brush.setColor(prevColor);
        brush.setLineWidth(prevLineWidth);
      };
      const redraw = function () {
        clear();
        drawExtractions();
        drawSelections();
      };
      const cancelDrawing = function () {
        dragging = false;

        resetStart();
        redraw();
      };
      const clear = function () {
        return ctx.clearRect(0, 0, canvas.width, canvas.height);
      };
      const resetStart = function () {
        startX = null;
        startY = null;
      };
      const getSelectionsByType = function (type) {
        return selections.map(function (selectionsByPage) {
          return selectionsByPage.filter(function (selection) {
            return selection.type === type;
          });
        });
      };
      const addSelection = function (ctx, x1, y1, x2, y2, toolType, color, lineWidth) {
        selections[page].push(
          new Selection(
            ctx,
            x1,
            y1,
            x2,
            y2,
            toolType || (activeSelection ? activeSelection.type : brush.getType()),
            color || (activeSelection ? activeSelection.color : brush.getColor()),
            lineWidth || (activeSelection ? activeSelection.lineWidth : brush.getLineWidth())
          )
        );
      };
      const removeSelection = function (selectionToRemove) {
        return (selections[page] = selections[page].filter(function (selection) {
          return selection !== selectionToRemove;
        }));
      };
      const createSelectionsPage = function (pageNum) {
        selections[pageNum] = selections[pageNum] || [];
      };
      const drawSelections = function () {
        (selections[page] || []).forEach(function (selection) {
          if (selection === activeSelection) {
            activeSelection.drawDash();
          } else {
            selection.draw();
          }
        });
      };
      const showExtractions = function () {
        extractionsShowing = true;
      };
      const hideExtractions = function () {
        extractionsShowing = false;
      };
      const drawExtractions = function () {
        if (extractionsShowing) {
          const prevColor = brush.getColor();
          const prevLineWidth = brush.getLineWidth();

          brush.setColor(extractionsColor);
          brush.setLineWidth(extractionsLineWidth);

          extractionRects.forEach(function (canvasBox) {
            brush.drawRect.apply(brush, canvasBox);
          });

          brush.setColor(prevColor);
          brush.setLineWidth(prevLineWidth);
        }
      };
      const clearExtractions = function () {
        if (!extractionsShowing) {
          redraw();
        }
      };

      this.getCanvas = function () {
        return canvas;
      };
      this.getContext = function () {
        return ctx;
      };
      this.setLineWidth = brush.setLineWidth.bind(brush);
      this.getToolType = brush.getType.bind(brush);
      this.setToolType = brush.setType.bind(brush);

      /**
       * creates and draws selections from given ones
       * @param {Array<Array<Selection>>} newSelections a 2d array just like getSelections method returns
       */
      this.setSelections = function (newSelections) {
        const p = page;
        selections = [[]];

        newSelections.forEach(function (selectionsByPage, index) {
          page = index;
          selections[page] = [];

          selectionsByPage.forEach(function (selection) {
            const x1 = selection.rect[0];
            const y1 = selection.rect[1];
            const x2 = selection.rect[2];
            const y2 = selection.rect[3];
            const color = selection.color || types[selection.type].color || Brush.defaultColor;

            addSelection(ctx, x1, y1, x2, y2, selection.type, color);
          });
        });

        page = p;

        redraw();
      };

      /**
       *
       * @param {string} [type] if specified returns only selections that match the type
       * @returns {Array<Selection>} a 2d array where the the first level contains pages where
       * selections appear and the second level contains selections themself
       * e.g. [[Selection, Selection], [Selection]] where page 1 contains 2 selections and page 2 - 1
       */
      this.getSelections = function (type) {
        return type ? getSelectionsByType(type) : angular.copy(selections);
      };
      this.clearSelections = function () {
        selections = [[]];

        redraw();
        createSelectionsPage(page);
      };
      this.setExtractions = function (items) {
        extractionRects = items;
        hideExtractions();
      };
      this.toggleExtractions = function () {
        if (extractionsShowing) {
          hideExtractions();
          clearExtractions();
        } else {
          showExtractions();
          drawExtractions();
          drawSelections();
        }
      };
      this.isExtractionsShowing = function () {
        return extractionsShowing;
      };
      this.changePage = function (pageNum) {
        page = pageNum;

        createSelectionsPage(pageNum);
        drawSelections();
      };
      this.addListeners = function () {
        canvas.addEventListener('mousedown', mouseDown);
        canvas.addEventListener('mousemove', mouseMove);
        canvas.addEventListener('mouseup', mouseUp);
      };
      this.removeListeners = function () {
        canvas.removeListener('mousedown', mouseDown);
        canvas.removeListener('mousemove', mouseMove);
        canvas.removeListener('mouseup', mouseUp);
      };
    };
  }
]);

app.factory('Brush', [
  function () {
    const Brush = function Brush(ctx, types) {
      let activeType = null;
      let activeColor = Brush.defaultColor;
      let activeLineWidth = Brush.defaultLineWidth;

      this.ctx = ctx;
      this.types = types;

      this.getType = function () {
        return activeType;
      };
      this.setType = function (type) {
        this.setColor(this.types[type].color || Brush.defaultColor);
        this.setLineWidth(this.types[type].lineWidth || Brush.defaultLineWidth);

        return (activeType = this.types[type].type || null);
      };

      this.getColor = function () {
        return activeColor;
      };
      this.setColor = function (color) {
        activeColor = color || Brush.defaultColor;

        return (this.ctx.strokeStyle = activeColor);
      };

      this.getLineWidth = function () {
        return activeLineWidth;
      };
      this.setLineWidth = function (width) {
        activeLineWidth = width || Brush.defaultLineWidth;

        return (this.ctx.lineWidth = activeLineWidth);
      };
    };

    Brush.defaultColor = '#000000';
    Brush.defaultLineWidth = 2;

    Brush.prototype.drawRect = function (startX, startY, width, height) {
      this.ctx.strokeRect(startX, startY, width, height);
    };
    Brush.prototype.drawDashedRect = function (startX, startY, width, height) {
      this.ctx.setLineDash([10, 5]);
      this.ctx.strokeRect(startX, startY, width, height);
      this.ctx.setLineDash([0, 0]);
    };

    return Brush;
  }
]);

app.factory('Selection', [
  function () {
    const removeButtonWidth = 20;
    const removeButtonHeight = 20;

    const Selection = function Selection(ctx, x1, y1, x2, y2, type, color, lineWidth) {
      if (!type) throw 'type isn\'t set';

      this.ctx = ctx;
      this.rect = Selection.normalizeRect([x1, y1, x2, y2]);
      this.x = this.rect[0];
      this.y = this.rect[1];
      this.width = Math.abs(x1 - x2);
      this.height = Math.abs(y1 - y2);
      this.type = type;
      this.color = color;
      this.lineWidth = lineWidth;
    };

    Selection.prototype.draw = function (canvasCtx) {
      const ctx = canvasCtx || this.ctx;
      const prevColor = ctx.strokeStyle;
      const prevLineWidth = ctx.lineWidth;

      ctx.strokeStyle = this.color;
      ctx.lineWidth = this.lineWidth;

      ctx.strokeRect(this.x, this.y, this.width, this.height);

      ctx.strokeStyle = prevColor;
      ctx.lineWidth = prevLineWidth;
    };

    Selection.prototype.drawDash = function (canvasCtx) {
      const ctx = canvasCtx || this.ctx;

      ctx.setLineDash([10, 5]);
      this.draw(ctx);
      ctx.setLineDash([0, 0]);

      this.drawRemoveButton(ctx);
    };

    Selection.prototype.isOnBorders = function (x, y) {
      const misclickShifting = 2; // precise of clicking on borders
      const halfLine = this.lineWidth / 2;
      const outerWidth = this.width + this.lineWidth;
      const outerHeight = this.height + this.lineWidth;
      const innerWidth = this.width - this.lineWidth;
      const innerHeight = this.height - this.lineWidth;
      const outerX = this.x - halfLine - misclickShifting;
      const outerX2 = outerX + outerWidth + misclickShifting * 2;
      const outerY = this.y - halfLine - misclickShifting;
      const outerY2 = outerY + outerHeight + misclickShifting * 2;
      const innerX = this.x + halfLine + misclickShifting;
      const innerX2 = innerX + innerWidth - misclickShifting * 2;
      const innerY = this.y + halfLine + misclickShifting;
      const innerY2 = innerY + innerHeight - misclickShifting * 2;
      const onTopBorder = x >= outerX && x <= outerX2 && y >= outerY && y <= innerY;
      const onRightBorder = x >= outerX && x <= innerX && y >= outerY && y <= outerY2;
      const onBottomBorder = x >= outerX && x <= outerX2 && y >= innerY2 && y <= outerY2;
      const onLeftBorder = x >= innerX2 && x <= outerX2 && y >= outerY && y <= outerY2;

      return onTopBorder || onRightBorder || onBottomBorder || onLeftBorder;
    };

    Selection.prototype.isOnRemoveButton = function (x, y) {
      const buttonX = this.x + this.width - removeButtonWidth;
      const buttonY = this.y + this.height - removeButtonHeight;

      // All we have to do is make sure the Mouse X,Y fall in the area between
      // the shape's X and (X + Width) and its Y and (Y + Height)
      return (
        buttonX <= x &&
        buttonX + removeButtonWidth >= x &&
        buttonY <= y &&
        buttonY + removeButtonHeight >= y
      );
    };

    Selection.prototype.drawRemoveButton = function (canvasCtx) {
      const ctx = canvasCtx || this.ctx;
      const x = this.x + this.width - removeButtonWidth;
      const y = this.y + this.height - removeButtonHeight;

      ctx.fillStyle = '#ec4e4a';
      ctx.fillRect(x, y, removeButtonWidth, removeButtonHeight);

      ctx.fillStyle = '#ffffff';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText('X', x + removeButtonWidth / 2, y + removeButtonHeight / 2);
    };

    // Normalize rectangle rect=[x1, y1, x2, y2] so that (x1,y1) < (x2,y2)
    // For coordinate systems whose origin lies in the bottom-left, this
    // means normalization to (BL,TR) ordering. For systems with origin in the
    // top-left, this means (TL,BR) ordering.
    Selection.normalizeRect = (function () {
      if (window.pdfjsLib) return window.pdfjsLib.Util.normalizeRect;

      return function Util_normalizeRect(rect) {
        var r = rect.slice(0); // clone rect
        if (rect[0] > rect[2]) {
          r[0] = rect[2];
          r[2] = rect[0];
        }
        if (rect[1] > rect[3]) {
          r[1] = rect[3];
          r[3] = rect[1];
        }
        return r;
      };
    })();

    return Selection;
  }
]);
