import app from '../app';

app.filter('toDay', [
  '$filter',
  function ($filter) {
    const dateFilter = $filter('date'),
      defaultFormat = 'yyyy-MM-dd';
    return function (input, format) {
      if (/^\d{10}$/.test(input)) {
        // make seconds into milliseconds
        const type = typeof input;
        if (type === 'string') {
          input += '000';
        } else if (type === 'number') {
          input *= 1000;
        }
      }
      return dateFilter(input, format || defaultFormat);
    };
  }
]);
