import app from '../../app';
import supplierInfo from '../../modals/supplierInfo';
import { getOffset } from '../../helpers';

app.controller('bannedProductsReportCtrl', [
  '$translate',
  '$rootScope',
  'CompanyFactory',
  'SpinnerService',
  'ProductService',
  'ExportService',
  'ModalService',
  function (
    $translate,
    $rootScope,
    CompanyFactory,
    SpinnerService,
    ProductService,
    ExportService,
    ModalService
  ) {
    const vm = this;
    const getBannedProducts = SpinnerService.wrap(function () {
      return ProductService.getBannedProducts(
        getOffset(vm.startFrom, vm.PER_PAGE),
        vm.PER_PAGE,
        vm.selectedCompany.id,
        vm.showOnlyInventoried || null
      ).then(async function (response) {
        vm.totalItems = vm.totalItems || response.count;

        if (vm.showOnlyInventoried && response.products.length) {
          vm.bannedProducts = await ProductService.assignInventoryPath(
            response.products,
            vm.selectedCompany.id
          );
        } else {
          vm.bannedProducts = response.products;
        }
        vm.bannedProducts.forEach(el => {
          el.size = ProductService.showCorrectProductSize(el);
        });
        return;
      });
    });
    vm.startFrom = 1;
    vm.PER_PAGE = 20;
    vm.bannedProducts = [];
    vm.selectedCompany = {};
    vm.showOnlyInventoried = null;

    vm.handlePaginate = getBannedProducts;

    vm.handleExport = function () {
      ExportService.exportBannedProductsReport2csv(vm.selectedCompany.id, vm.showOnlyInventoried);
    };

    vm.onCompanySelected = function () {
      vm.startFrom = 1;
      vm.totalItems = null;
      vm.showOnlyInventoried = null;

      if (!vm.selectedCompany.name && $rootScope.sitename) {
        for (let i = 0; i < vm.companies.length; i++) {
          if ($rootScope.sitename === vm.companies[i].name.toLowerCase()) {
            vm.selectedCompany = vm.companies[i];
            break;
          }
        }
      }
      if (!vm.selectedCompany.id) vm.selectedCompany = vm.companies[0];
      if (vm.selectedCompany.id) $rootScope.onChangeMainCompany(vm.selectedCompany.name);

      getBannedProducts();
    };

    vm.onShowOnlyInventoried = function () {
      vm.startFrom = 1;
      vm.totalItems = null;
      getBannedProducts();
    };

    vm.onShowOnlyInventoried = function () {
      vm.startFrom = 0;
      vm.totalPages = null;
      getBannedProducts(vm.startFrom);
    };

    vm.openSupplierInfoModal = function (product) {
      return ModalService.open(supplierInfo({ suppliers: product.suppliers }));
    };

    init();

    function init() {
      CompanyFactory.GetCompanies(function (companies) {
        vm.companies = [
          { name: $translate.instant('COMMON.LABELS.ALL_COMPANIES'), id: null }
        ].concat(companies);
        vm.onCompanySelected();
      });
    }
  }
]);
