import { extend } from 'angular';
import app from '../app';

app.factory('FileUploaderFactory', [
  '$rootScope',
  'FileUploader',
  function ($rootScope, FileUploader) {
    
    return class extends FileUploader {
      constructor(options = {}) {
        super(options);

        this.options = Object.assign({
          'addCopyToSameNamed': true,
          'copyText': 'Copy of',
        }, options);
      }

      /**
       * Modify onAfterAddingFile behaviour
       */
      set onAfterAddingFile(callback) {
        super.onAfterAddingFile = (fileItem) => {
          if (this.options.addCopyToSameNamed) {
            fileItem.file.name = this.getFileName(fileItem);
          }
          fileItem.checked = true;

          callback(fileItem);
        }
      }

      /**
       * Check if file name of file item is added
       * @param {Object} fileItem Files queue item
       * 
       * @returns {Boolean} 
       */
      isFileNameAdded(fileItem) {
        let r = false;
        this.queue.forEach(item => {
          if (fileItem.file.name == item.file.name && item.checked) {
            r = true;
          }
        });
        return r;
      }

      /**
       * Get files queue unique file name
       * @param {Object} fileItem Files queue item
       * 
       * @returns {String} File name 
       */
      getFileName(fileItem) {
        let name = fileItem.file.name;
        if (this.isFileNameAdded(fileItem)) {
          fileItem.file.name = this.options.copyText + ' ' + fileItem.file.name;
          name = this.getFileName(fileItem);
        }
        return name;
      }
    }
  }
]);